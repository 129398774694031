import CustomInput from "../../eventcreation/components/CustomInput";
import { makeStyles } from "@mui/styles";
import { customTheme as theme } from "../../../theme/customTheme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FocusTrap from "@mui/base/FocusTrap";
import useValidator from "../../../hooks/useValidator";
import { afterValidate, setInput } from "../../../utils/commonServices";
import { updateUserState } from "../../../redux/userSlice";
// import useAuthRequest from "../../../hooks/useAuthRequest";
import movieIcon from '../../../assets/movieIcon.svg';
import { Box, Grid, Paper, Typography } from '@mui/material';
import UnauthLayout from '../components/UnauthLayout'
import CustomButton from "../../../components/CustomButton";
import { LoadingButton } from "@mui/lab";
import EmailVerificationPopup from "./components/EmailVerificationPopup";
import { gaEvents } from "../../../ga/actions";
import { PRODUCT_ID } from "../../../constants/constants";
import useAuthFlow from "../hooks/useAuthFlow";


const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    minHeight: "100%",
  },
}));

const SignIn = () => {

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const forgotPasswordFlag = query.get("forgot-password");
  const token = query.get("token");
  const {userLogin} = useAuthFlow()
  const [openEmailVerificationPopup, setEmailVerificationPopup] = useState(
    token ? true : false
  );
  const classes = useStyles();
  const [pageData, setPageData] = useState({
    email: "",
    password: "",
    product_id: process.env.REACT_APP_PRODUCT_ID
  });
  const pendingCallReducer = useSelector((state) => state.pendingCallReducer);
  const [errorObjectArray, checkEmptyFields, checkValue] = useValidator(
    pageData,
    "signin"
  );

  const [openForgotPopup, setOpenForgotPopup] = useState(
    forgotPasswordFlag ? forgotPasswordFlag : false
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Submit button handler function

  const submitClickHandler = async (event) => {
    await checkEmptyFields(event);
    afterValidate(afterValidatCallback);
  };

  const afterValidatCallback = () => {
   // console.log({product_id: process.env.REACT_APP_PRODUCT_ID, param : pageData})
    // userSignin({product_id: PRODUCT_ID.lucihub, param : pageData})
     userLogin(pageData)
  };


  const forgotPasswordClickHandler = () => {
    navigate("/forgotpassword", {replace: true});
  };

  //code to detect enter key for editing Organization Name
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        submitClickHandler(event);
      }
    };
    openForgotPopup === false
      ? document.addEventListener("keydown", keyDownHandler)
      : document.removeEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [pageData, openForgotPopup]);


  // useEffect(() => {
  //    if (pendingCallReducer.signinStatus == "unverified") {
  //     navigate('/resendlink', {state: {pageData: pageData, title: 'Your email verification is pending', icon: movieIcon, btnText: 'Resend Email', message:'please check your email and click on the verify now button to continue.', signIn: true}, replace:true})
  //    }
  // }, [pendingCallReducer.signinStatus]);


  return (
    <UnauthLayout signin>
      <Box className={classes.center} square component="Box">

        <Box container justifyContent="flex-start" spacing={3} mt={{xs:1, lg:6, xl:10}}>
          <Box item xs={11} sm={8} md={7}>
            <Box sx={{marginY:{xs:'15px',md:'20px',lg:'25px',xl:'38px'}}}>
              <Typography
                variant="h4"
                color="primary"
                style={{
                  fontWeight: 600,
                  color:'#FFFFFF'
                }}
                sx={{fontSize:{xs:'30px',sm:'25px',md:'35px',lg:'41px',xl:'60px'}, fontFamily:'Rubik'}}
              >
                Welcome back.
              </Typography>
            </Box>
          </Box>

          <Box item xs={11} sm={8} md={7} gap={0.8} sx={{display:'flex', flexDirection:'column',width:{sm:'300px', lg:'400px', xl:'600px'}}}>
            <CustomInput
              id={errorObjectArray[0]?.id}
              onFocus={checkEmptyFields}
              onBlur={checkValue}
              required
              errorFlag={errorObjectArray[0]?.errorFlag}
              errorMsg={errorObjectArray[0]?.error}
              type="text"
              max={40}
              placeholder="Email Address*"
              onChange={(event) => {
                setInput(
                  pageData?.email != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "email",
                  pageData,
                  setPageData
                );
                checkEmptyFields(event);
              }}
              value={pageData?.email}
            />
            <CustomInput
              id={errorObjectArray[1]?.id}
              onFocus={checkEmptyFields}
              required
              errorFlag={errorObjectArray[1]?.errorFlag}
              errorMsg={errorObjectArray[1]?.error}
              type="password"
              max={40}
              placeholder="Password*"
              onChange={(event) => {
                setInput(
                  pageData?.password != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "password",
                  pageData,
                  setPageData
                );
                checkEmptyFields(event);
              }}
              value={pageData?.password}
            />
          </Box>
          <Box
            item
            xs={11}
            sm={8}
            md={7}
            style={{ display: "flex", flexDirection: "row", paddingTop: 0 }}
            sx={{ justifyContent: { xs: "center", sm: "flex-end" } }}
          >
            <Box maxWidth="140px" pt={1}>
              <Typography
                onClick={forgotPasswordClickHandler}
                style={{ cursor: "pointer", color: '#FFFFFF' , letterSpacing:'0.55px'}}
                sx={{fontSize:{xs:'8px',md:'9px',xl:'11px'}, fontFamily:'Rubik', fontWeight:600}}
              >
                Forgot password ?
              </Typography>
            </Box>

          </Box>
          <Box item xs={11} sm={8} md={7}>
            <Box
              container
              justifyContent="space-between"
              sx={{
                flexDirection: { xs: "column-reverse", sm: "row" },
                alignItems: "center",
                paddingTop: 0,
              }}
            >
            
              <Box item xs={8} sm={4} sx={{display:'flex', justifyContent:'flex-end', marginTop:{xs:'20px',sm:'28px',md:'28px',lg:'33px',xl:'50px'}}}>
                {/* <CustomButton
                  disabled={signinStatus == "loading"}
                  btnText="Login"
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={submitClickHandler}
                  style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#00D744', textTransform:'none', }} 
                  sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,}}
                /> */}
                 <LoadingButton
                    disabled={pendingCallReducer.signin}
                    onClick={submitClickHandler}
                    style={{borderRadius: '5px', backgroundColor:'#00CEFF', color:'#08231B', textTransform:'none', }} 
                    sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,  minWidth: '94px !important' }}
                    loading={pendingCallReducer.signin}
                    loadingPosition="start"
                    variant="contained"
                    >
                   Login
               </LoadingButton> 
              </Box>
              <Box
                sx={{ marginTop: { xs:'40px',sm:'50px',md:'60px',lg:'75px',xl:'120px' } }}
                item
                xs={12}
                sm={8}
                textAlign='center'
              >
              <Typography sx={{fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13px',xl:'20px'}, fontFamily:'Rubik', color:'#FFFFFF'}}>
               {`Don’t have an account? `} 
                <Link
                  to="/signup"
                  style={{
                    textDecoration: "none",
                    fontWeight:600,
                    color:'#21D644'
                  }}
                  sx={{fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13px',xl:'20px'}, fontFamily:'Rubik',  fontWeight:600}}
                >
                  Create a new account.
                </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
       

      </Box>
      {openEmailVerificationPopup && (
        <EmailVerificationPopup
          openHandler={openEmailVerificationPopup}
          closeHandler={setEmailVerificationPopup}
          token={token}
          forgotPopupHandler={setOpenForgotPopup}
        />
      )}
    </UnauthLayout>
  )
}

export default SignIn