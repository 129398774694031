import { Backdrop, Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, Input, Slider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

import { updateVoiceState } from "../../../../../redux/voiceSlice";
import CustomTooltip from "../../../../../components/CustomTooltip";
import CustomAutocomplete from "../../../../eventcreation/components/CustomAutoComplete";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SaveIcon from '@mui/icons-material/Save';
import { azureLanguageNames } from "../../../../../constants/constants";
import QuantityInput from "../../../../../components/QuantityInput";
import CustomInput from "../../../../eventcreation/components/CustomInput";
import CustomButton from "../../../../../components/CustomButton";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import useVoiceProfileActions from "../hooks/useVoiceProfileActions";
import { getErrorMsg } from "../../../../../utils/validator";
import { afterValidate, getFirstWord, sortArrayByCategory } from "../../../../../utils/commonServices";
import CustomGroupedAutoComplete from "../../../../eventcreation/components/CustomGroupedAutoComplete";

const VoiceProfileCreationPopup = (props) => {
    const { audioRef, handleConvertTextToSpeech, pauseSpeech, playSpeech,
        sampleRef, handleGenerateVoiceSample, pauseSample, playSample, generateSpeechClickHandler,
        playSampleHandler, commonChangeHandler } = props
    const dispatch = useDispatch()
    const pendingCallReducer = useSelector((state) => state.pendingCallReducer);
    const voiceReducer = useSelector((state) => state.voiceReducer);
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const {createVoiceProfile, updateVoiceProfile} = useVoiceProfileActions();
    const [profileNameValidationFlag, setProfileNameValidationFlag] = useState(false)
    const [profileDescriptionValidationFlag, setProfileDescriptionValidationFlag] = useState(false)
    const [profileVoiceValidationFlag, setProfileVoiceValidationFlag] = useState(false)
    const [toneValidationFlag, setToneValidationFlag] = useState(false)
    const [accentValidationFlag, setAccentValidationFlag] = useState(false)

    const categoryDescription = {
        'Global Accents' : "Choose voices with various accents to add cultural distinction to your content.",
        "Versatile Tones" : "Select voice styles to match the desired tone and mood of your content.",
        "Standard Voices" : "Clear and straightforward voices without accents or style variations."
    }
    const options = sortArrayByCategory(dropdownReducer?.voices?.length > 0 && dropdownReducer?.voices?.map((item)=> {
        return {...item, category: item.SecondaryLocaleList?.length > 0 ? "Global Accents" : item.StyleList?.length > 0 ? "Versatile Tones" : "Standard Voices"}
    }), {'Global Accents' : 1, "Versatile Tones" : 2, "Standard Voices" : 3})

    const onSubmit =  () => {
        const payload = {
            name: voiceReducer?.profile_name,
            description: voiceReducer?.profile_description,
            voice: voiceReducer?.selected_voice?.ShortName,
            voiceStyle: !!voiceReducer?.selected_style ? voiceReducer?.selected_style : "",
            voiceAccent: !!voiceReducer?.selected_accent ? voiceReducer?.selected_accent : "",
            voicePitch: voiceReducer?.pitch,
            voiceSpeed: voiceReducer?.rate
          }
          if(voiceReducer?.is_profile_edit) {
            updateVoiceProfile({...payload, id: voiceReducer?.selected_voice_profile }, updateProfileCallback )
          } else {
            createVoiceProfile(payload, createSuccessCallback)
          }
         
    }
    const updateProfileCallback = ()=> {
        dispatch(updateVoiceState({is_profile_edit: false}))
        handleClose();
    }
    const createSuccessCallback = ()=> {
        dispatch(updateVoiceState({voice_profile_view: true, selected_voice_profile: voiceReducer?.voice_profiles[0]?.id,   is_selected_default: !!voiceReducer?.voice_profiles[0]?.is_default_profile  }))
        handleClose();
    }

    const handleClose = () => {
        commonChangeHandler();
        dispatch(updateVoiceState({is_profile_edit: false, voice_profile_creation_popup: false }))
    };

    const validateInputs = (flag) => {
        setProfileNameValidationFlag(flag)
        setProfileDescriptionValidationFlag(flag)
        setProfileVoiceValidationFlag(flag)
    }

    const finishedHandler = ()=> {
        validateInputs(true)
        afterValidate(onSubmit);
        }


    return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: 'blur(32px) !important', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', background: '#08231B88 0% 0% no-repeat padding-box', }}
        open={voiceReducer.voice_profile_creation_popup}
    >
        <Typography textAlign={'right'} width='95%' sx={{ fontSize: '16px', cursor: 'pointer', mt: 5, mr: 3 }} onClick={handleClose}>Close X</Typography>
        <Box className="flex col j-center" sx={{ flex: 1, width: { xs: '90%', sm: '85%', md: '80%', xl: '70%' } }} >
            <Box className='flex col j-center a-center' gap={3}>
                <Typography sx={{color: '#ffffff', fontSize: '30px', fontWeight: 500, textAlign: 'left', width: '100%'}} >Enter a name and description for your voice profile.</Typography>
                <Box className='flex col j-center' sx={{ flex: 1, width: '100%' }}   >
                    
                    <CustomInput
                        id='name'
                        onFocus={() => setProfileNameValidationFlag(false)}
                        onBlur={() => setProfileNameValidationFlag(true)}
        // type='sendInput'
                        required
                        errorFlag={profileNameValidationFlag && getErrorMsg("voice_profile_name", voiceReducer?.profile_name) != ""}
                        errorMsg={getErrorMsg("voice_profile_name", voiceReducer?.profile_name)}
                        fullWidth
                        medium
                        multiline
                        maxRows={1}
                        max={100}
                        bottom={-20}
                        msgColor='red'
                        placeholder="Enter a name for this profile"
                        onChange={(e)=> dispatch(updateVoiceState({profile_name: e.target.value}))}
                        value={voiceReducer?.profile_name}
                    //  onButtonClick={pageIndex !== totalScreens ? finishedHandler : null}
                    />
                </Box>
                <Box className='flex col j-center' sx={{ flex: 1, width: '100%' }}  >
                    <CustomInput
                        id='description'
                        onFocus={() => {
                            setProfileNameValidationFlag(true)
                            setProfileDescriptionValidationFlag(false)
                        }}
                        onBlur={() => setProfileDescriptionValidationFlag(true)}
                        // type='sendInput'
                        required
                        errorFlag={profileDescriptionValidationFlag && getErrorMsg("voice_profile_description", voiceReducer?.profile_description) != ""}
                        errorMsg={getErrorMsg("voice_profile_description", voiceReducer?.profile_description)}
                        fullWidth
                        medium
                        multiline
                        maxRows={4}
                        max={500}
                        bottom={-20}
                        msgColor='red'
                        placeholder="Enter a description for this profile"
                        onChange={(e)=> dispatch(updateVoiceState({profile_description: e.target.value}))}
                        value={voiceReducer?.profile_description}
                    // onButtonClick={pageIndex !== totalScreens ? finishedHandler : null}
                    />
                </Box>

            </Box>
            <Box className='flex' gap={3} mb={5} sx={{ width: '100%', flexDirection: {xs: 'column', md: 'row'}, justifyContent: {xs: 'center' , md: 'space-between' }, alignItems: {xs: 'stretch', md: 'center'}}} >
                <Box className="flex col j-center a-center" mt={2} gap={3} sx={{ flex: 1, width: { xs: '100%', md: '80%', xl: '70%' } }}  >
                    {
                        dropdownReducer?.voices?.length > 0 && <Box sx={{ flex: 1, width: '100%', mb: 2 }}  >
                             <CustomGroupedAutoComplete
                        id="voices"
                       // options={dropdownReducer?.voices}
                       options={options}
                       medium
                        sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, }}
                        headerTooltip = {categoryDescription}
                        onFocus={() => {
                            setProfileNameValidationFlag(true)
                            setProfileDescriptionValidationFlag(true)
                            setProfileVoiceValidationFlag(false)
                            }}
                            onBlur={() =>setProfileVoiceValidationFlag(true)}
                            required
                            errorFlag={profileVoiceValidationFlag && getErrorMsg("voice", !!dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ?  dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : dropdownReducer?.voices[5]) != ""}

                            errorMsg={getErrorMsg("voice",!!dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ?  dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : dropdownReducer?.voices[5])}
                        value={!!options?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ?  options?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : options[5]}
                        disabled={voiceReducer?.is_generating_speech}
                        onChange={(event, selection) => {
                            //!!voiceReducer?.is_sample_playing && pauseSample();

                            commonChangeHandler()
                            dispatch(updateVoiceState({
                                selected_style: null,
                                selected_accent: null,
                                selected_voice: selection
                            }))
                            !!selection?.StyleList ? dispatch(updateVoiceState({ styles: ["default", ...selection?.StyleList] })) : dispatch(updateVoiceState({ styles: [] }))
                            !!selection?.SecondaryLocaleList ? dispatch(updateVoiceState({ accents: selection?.SecondaryLocaleList })) : dispatch(updateVoiceState({ accents: [] }))

                        }}
                        label=""
                        placeholder='Select Voice'
                        groupBy={(option) => option.category}
                        getOptionLabel={(option) =>
                            `${option.DisplayName} - ${option.LocaleName} - ${option.Gender}`
                        }   
                        // renderGroup={(params) => (
                        //     <li key={params.key}>
                        //        <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#000000' }}>{params.group}</Typography>
                        //        <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#000000' }}>{params.children}</Typography>
                        //     </li>
                        //   )}
                        renderOption={(props, option) => {
                            return <Box {...props} className='flex col j-start' mb={1.5} ml={1} sx={{borderBottom: '1px solid #cccccc', pb: 0.5}} >
                                <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#000000' }}>{`${getFirstWord(option.DisplayName)} | ${option.Gender}`}</Typography>
                                {
                                    option.SecondaryLocaleList?.length > 0 ? <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>
                                       {`Available in `} <Typography component='span' sx={{ fontSize: 12, fontWeight: 600, color: '#1a2721' }}>{option.SecondaryLocaleList?.length}</Typography>{option.SecondaryLocaleList?.length > 1 ? ` different accents` : `accent`}
                                    </Typography> : option.StyleList?.length > 0 ? <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>
                                    {`Available in `}<Typography component='span' sx={{ fontSize: 12, fontWeight: 600, color: '#1a2721' }}>{option.StyleList?.length}</Typography>{option.StyleList?.length > 1 ? ` different styles` : ` style` }
                                    </Typography> : <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>{option.LocaleName}</Typography>
                                }
                                
                                
                            </Box>
                        }}    
                        msgColor='red'
                    />
                        {/* <CustomAutocomplete
                            id="voices"
                            options={dropdownReducer?.voices}
                            medium
                            onFocus={() => {
                            setProfileNameValidationFlag(true)
                            setProfileDescriptionValidationFlag(true)
                            setProfileVoiceValidationFlag(false)
                            }}
                            onBlur={() =>setProfileVoiceValidationFlag(true)}
                            required
                            errorFlag={profileVoiceValidationFlag && getErrorMsg("voice", !!dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ?  dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : dropdownReducer?.voices[5]) != ""}

                            errorMsg={getErrorMsg("voice",!!dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ?  dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : dropdownReducer?.voices[5])}

                            value={!!dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ?  dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : dropdownReducer?.voices[5]}
                            disabled={voiceReducer?.is_generating_speech}
                            onChange={(event, selection) => {
                                //!!voiceReducer?.is_sample_playing && pauseSample();
                                console.log("selection", selection)
                                commonChangeHandler()
                                dispatch(updateVoiceState({
                                    selected_style: null,
                                    selected_accent: null,
                                    selected_voice: selection
                                }))
                                !!selection?.StyleList ? dispatch(updateVoiceState({ styles: ["default", ...selection?.StyleList] })) : dispatch(updateVoiceState({ styles: [] }))
                                !!selection?.SecondaryLocaleList ? dispatch(updateVoiceState({ accents: selection?.SecondaryLocaleList })) : dispatch(updateVoiceState({ accents: [] }))

                            }}
                            label=""
                            placeholder='Select Voice'
                            getOptionLabel={(option) =>
                                `${option.DisplayName} - ${option.LocaleName} - ${option.Gender}`
                            }
                            msgColor='red'
                        /> */}


                    </Box>
                    }
                    
                    {
                        voiceReducer?.styles?.length > 0 && <Box my={1} sx={{ flex: 1, width: '100%', mb: 2 }}  >
                            <CustomAutocomplete
                                id="style_list"
                                //  sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, }}
                                // onFocus={() =>{
                                // setProfileNameValidationFlag(true)
                                // setProfileDescriptionValidationFlag(true)
                                // setProfileVoiceValidationFlag(true)
                                // setToneValidationFlag(false)}}
                                // onBlur={() => setToneValidationFlag(true)}
        // type='sendInput'
                                // required
                                // errorFlag={toneValidationFlag && getErrorMsg("voice_tone", voiceReducer?.selected_style) != ""}
                                // errorMsg={getErrorMsg("voice_tone",voiceReducer?.selected_style)}
                                disabled={voiceReducer?.styles?.length == 0}
                                options={voiceReducer?.styles}
                                value={voiceReducer?.selected_style}
                                onChange={(event, selection) => {
                                    commonChangeHandler()
                                    dispatch(updateVoiceState({
                                        selected_accent: null,
                                        selected_style: selection
                                    }))
                                }}
                                medium
                                label=""
                                placeholder='Select Tone'
                                getOptionLabel={(option) =>
                                    `${option}`
                                }
                                msgColor='red'
                            />
                        </Box>
                    }
                    {
                        voiceReducer?.accents?.length > 0 && <Box my={1} sx={{ flex: 1, width: '100%' }}  >
                            <CustomAutocomplete
                                id="accent_list"
                                // sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, }}
                                // onFocus={() =>{
                                // setProfileNameValidationFlag(true)
                                // setProfileDescriptionValidationFlag(true)
                                // setProfileVoiceValidationFlag(true)
                                // setToneValidationFlag(true)
                                // setAccentValidationFlag(false)}}
                                // onBlur={() => setAccentValidationFlag(true)}
        // type='sendInput'
                                // required
                                // errorFlag={accentValidationFlag && getErrorMsg("voice_accent", voiceReducer?.selected_accent) != ""}
                                // errorMsg={getErrorMsg("voice_accent",voiceReducer?.selected_accent)}
                                disabled={voiceReducer?.accents?.length == 0}
                                options={voiceReducer?.accents}
                                value={voiceReducer?.selected_accent}
                                onChange={(event, selection) => {
                                    commonChangeHandler();
                                    dispatch(updateVoiceState({
                                        selected_accent: selection,
                                    }))
                                }}
                                label=""
                                medium
                                placeholder='Select Accent'
                                getOptionLabel={(option) =>
                                    `${!!azureLanguageNames[option] ? azureLanguageNames[option] : ""}`
                                }
                                msgColor='red'
                            />
                        </Box>
                    }
                    <Box className='flex row j-start a-center' sx={{ flex: 1, width: '100%', mt: 1 }}  >
                        {/* <CustomTooltip title={voiceReducer?.is_sample_playing ? 'Pause sample' : 'Play sample'} >
                            <IconButton
                                style={{ padding: 1 }}
                                disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
                                onClick={playSampleHandler}
                            >
                                <VolumeUpIcon style={{ color: voiceReducer?.is_sample_playing ? '#00ceff' : '#ffffff', fontSize: '28px' }} />
                            </IconButton>
                        </CustomTooltip> */} 
                    </Box>
                </Box>
                <Box className='flex col j-between' sx={{ gap: 2, flex: 2 }} >
                    <Box width='100%' gap={3} className='flex row a-center' >
                        <Typography sx={{ color: "#fff" }} >Pitch:</Typography>
                        <Slider
                            sx={{ color: "#fff", }}
                            aria-label="Pitch"
                            //defaultValue={0}
                            value={voiceReducer?.pitch}
                            // getAriaValueText={(value) => `${value}%`}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${value}%`}
                            disabled={voiceReducer?.is_generating_speech}
                            // shiftStep={30}
                            onChange={(e) => {
                                commonChangeHandler();
                                dispatch(updateVoiceState({ pitch: e.target.value }))
                            }}
                            step={10}
                            marks
                            min={-50}
                            max={50}
                        />
                        <Box sx={{ width: '100px' }}>
                            <Input
                                sx={{
                                    marginBottom: 1.5,
                                    padding: 0,
                                    // backgroundColor:'white',
                                    // borderRadius:'5px',
                                    border: '1px solid #ffffff',
                                    position: 'relative',
                                    input: {
                                        textAlign: 'center',
                                        // fontSize: medium ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'8px',lg:'9px',xl:'13px'},
                                        fontWeight: 600,
                                        fontFamily: 'Rubik',
                                        padding: 0,
                                        color: '#ffffff',
                                        // borderBottom:'1px solid #ffffff',
                                        "&::placeholder": {    // <----- Add this.
                                            // opacity:light == true ? 0.4 : 1,
                                        },
                                    }
                                }}
                                type="number"
                                //  min={-50}
                                // max={50}
                                disabled={voiceReducer?.is_generating_speech}
                                //defaultValue={0}
                                value={voiceReducer?.pitch}
                                onChange={(event, value) => {
                                    commonChangeHandler()
                                    dispatch(updateVoiceState({ pitch: (event.target.value >= -50 && event.target.value <= 50) ? event.target.value : voiceReducer?.pitch }))
                                }}
                            //shiftMultiplier={10}
                            />
                        </Box>
                    </Box>
                    <Box width='100%' gap={3} className='flex row a-center' >
                        <Typography sx={{ color: "#fff" }} >Rate:</Typography>
                        <Slider
                            sx={{ color: "#fff" }}
                            aria-label="Rate/Speed"
                            //defaultValue={0}
                            //aria-valuetext={`${voiceReducer?.rate} %`}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${value}%`}
                            value={voiceReducer?.rate}
                            onChange={(e) => {
                                commonChangeHandler();
                                dispatch(updateVoiceState({ rate: e.target.value }))
                            }}
                            //getAriaValueText={(value) => `${voiceReducer?.rate} %`}
                            //valueLabelDisplay="auto"
                            //shiftStep={30}
                            step={10}
                            disabled={voiceReducer?.is_generating_speech}
                            marks
                            min={-50}
                            max={50}
                        />
                        <Box sx={{ width: '100px' }}>
                            <Input
                                sx={{
                                    marginBottom: 1.5,
                                    // padding:0,
                                    // backgroundColor:'white',
                                    // borderRadius:'5px',
                                    border: '1px solid #ffffff',
                                    position: 'relative',
                                    input: {
                                        textAlign: 'center',
                                        // fontSize: medium ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'8px',lg:'9px',xl:'13px'},
                                        fontWeight: 600,
                                        fontFamily: 'Rubik',
                                        padding: 0,
                                        color: '#ffffff',
                                        // borderBottom:'1px solid #ffffff',
                                        "&::placeholder": {    // <----- Add this.
                                            // opacity:light == true ? 0.4 : 1,
                                        },
                                    }
                                }}
                                type="number"
                                //  min={-50}
                                // max={50}
                                disabled={voiceReducer?.is_generating_speech}
                                //defaultValue={0}
                                value={voiceReducer?.rate}
                                onChange={(event, value) => {
                                    commonChangeHandler()
                                    dispatch(updateVoiceState({ rate: (event.target.value >= -50 && event.target.value <= 50) ? event.target.value : voiceReducer?.rate }))
                                }}
                            //shiftMultiplier={10}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className='flex row j-between a-center'>
                <Box className='flex col j-center a-center' sx={{ gap: 2, mt: 1, mr: 3 }} >
                            {/* <audio ref={audioRef} aria-disabled={!voiceReducer?.audio_url} style={{ height: '32px' }} controls></audio> */}
                            <CustomButton
                                             type='button'
                                            btnText = {voiceReducer?.is_sample_playing ? 'Pause Sample' : 'Play Sample'}
                                            style={{
                                                borderRadius: '5px',
                                               // border: "none",
                                               color: '#000000',
                                               border: '1px solid #00CEFF',
                                                textTransform: 'none',
                                                backgroundColor: '#00CEFF', 
                                            
                                            }}
                                            sx={{ minWidth: '142px', fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, fontWeight: 600, height: { xs: '18px', sm: '20px', md: '22px', lg: '24px', xl: "32px" }, fontFamily: 'Rubik', width: { xs: '80px', md: '100px', lg: '130px', xl: '160px' } }}
               
                                            disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
                                            // color="secondary"
                                            onClick={playSampleHandler}
                                           // endIcon={<VolumeUpIcon style={{ fontSize: 18 }} />}
                                           endIcon={pendingCallReducer?.voice_sample ? <CircularProgress thickness={4} size={18} /> : voiceReducer?.is_sample_playing ? <PauseIcon style={{ fontSize: 16 }} /> : <PlayArrowIcon style={{ fontSize: 24 }} />}
                                           />
                </Box>
                {/* <audio ref={sampleRef} aria-disabled={!!voiceReducer?.sample_url}></audio> */}
                <Box className='flex row j-end a-center' gap={2} sx={{flex:2}} >
                    <Button variant='outlined' onClick={handleClose} style={{ borderRadius: '5px', backgroundColor: '#00D244', color: '#08231B', textTransform: 'none', }} sx={{ fontSize: { xs: '9px', sm: '9px', md: '9px', lg: '11px', xl: '16px', }, fontWeight: 600, width: { xs: '76px', sm: '76px', md: '76px', lg: '86px', xl: '129px' }, height: '28px', paddingY: 0.4, }}>Cancel</Button>
                    <CustomTooltip title= {`Save your settings to add a new voice profile to 'My Voices' for easy access and future use`} >
                        <Button variant='outlined' onClick={finishedHandler} style={{ borderRadius: '5px', backgroundColor: '#08231B', color: '#00D744', textTransform: 'none', }} sx={{ fontSize: { xs: '9px', sm: '9px', md: '9px', lg: '11px', xl: '16px', }, fontWeight: 600, width: { xs: '100px', md: '120px', xl: '150px' }, height: '28px', paddingY: 0.4, }}>{voiceReducer?.is_profile_edit ? "Update Profile" : "Create Profile"}</Button>
                    </CustomTooltip>
                </Box>
            </Box>
        </Box>
    </Backdrop>
}

export default VoiceProfileCreationPopup