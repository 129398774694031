import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduceWords, removeHtmlTags } from '../../../../../utils/commonServices';
import { setDropDownArray } from '../../../../../redux/dropdownSlice';
import { updateVoiceState } from '../../../../../redux/voiceSlice';
import { updatePendingCallState } from '../../../../../redux/pendingCallSlice';

export default ()=> {
    const [audioUrl, setAudioUrl] = useState('');
    // const [sampleUrl, setSampleUrl] = useState('');
    // const [isSample, setIsSample] = useState(true)
    // const [voices, setVoices] = useState([]);
    // const [selectedVoice, setSelectedVoice] = useState('');
    // const [isSpeaking, setIsSpeaking] = useState(false);
    // const [styleList, setStyleList] = useState([]);
    // const [style, setStyle] = useState();
    // const [playbackRate, setPlaybackRate] = useState(1.0); // Default playback rate
    const sampleRef = useRef(null);
    const contentReducer = useSelector((state) => state.contentReducer)
    const voiceReducer = useSelector((state) => state.voiceReducer)
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer)
    const subscriptionKey = process.env.REACT_APP_SPEECH_KEY;
    const region = process.env.REACT_APP_SPEECH_REGION;
    const dispatch = useDispatch();
  
    // useEffect(() => {
      
    //   const fetchVoices = async () => {
    //     try {
    //       const response = await fetch(`https://${region}.tts.speech.microsoft.com/cognitiveservices/voices/list`, {
    //         headers: {
    //           'Ocp-Apim-Subscription-Key': subscriptionKey
    //         }
    //       });
    //       const data = await response.json();
    //       const filterdList = data?.filter((voice)=>(
    //                             voice?.DisplayName == "Ava Multilingual" || voice?.DisplayName == "Aria" ||
    //                               voice?.DisplayName == "Emma Multilingual" || voice?.DisplayName == "Jane" ||
    //                                 voice?.DisplayName == "Sarah" || voice?.DisplayName == "Andrew Multilingual" ||
    //                                   voice?.DisplayName == "Brandon" || voice?.DisplayName == "Brian Multilingual" ||
    //                                     voice?.DisplayName == "Guy" || voice?.DisplayName == "Jason"))
    //       dispatch(setDropDownArray({voices:filterdList}))
    //       dispatch(updateVoiceState({selected_voice: filterdList[5]}))
    //     } catch (error) {
    //       console.error('Error fetching voices:', error);
    //     }
    //   };
    //  // console.log("before api text", contentReducer?.editor_text)
    //  if(dropdownReducer?.voices?.length == 0) {
    //   fetchVoices();
    //  } else {
    //   dispatch(updateVoiceState({selected_voice: dropdownReducer?.voices[5]}))
    //  }
      
    // }, [region, subscriptionKey]);
  
    const handleGenerateVoiceSample = async () => {
      dispatch(updatePendingCallState({voice_sample: true}))
      if (sampleRef.current) {
        sampleRef.current.pause();
        sampleRef.current.src = ''; // Clear the current audio source
      }
      
      dispatch(updateVoiceState({is_generating_sample: true}))
     // setIsSpeaking(true);
     const ssml = `
             <speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang='en-US'>
                <voice name='${voiceReducer?.selected_voice?.ShortName}'>
                  <mstts:express-as style='${voiceReducer?.selected_style && voiceReducer?.selected_style != 'default' ? voiceReducer?.selected_style : ""}'>
                    <prosody pitch='${voiceReducer?.pitch ? voiceReducer?.pitch : 0}%' rate='${voiceReducer?.rate ? voiceReducer?.rate : 0}%'>
                      <lang xml:lang='${voiceReducer?.selected_accent}'>
                      ${voiceReducer?.sample_text}
                      </lang>
                    </prosody>
                  </mstts:express-as>
              </voice>
          </speak>`
  
    //  const ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang='en-US'>
    //                   <voice name="en-US-BrianMultilingualNeural">
    //                   <mstts:express-as style="">
    //                  <prosody pitch="0%" rate="0%">
    //                   <lang xml:lang="es-MX">
    //                         "That’s remarkable! You’re a genius! Mom said to her son."
    //                   </lang>
    //                   </prosody>
    //                    </mstts:express-as>
    //                   </voice>
    //                 </speak>` 

      try {
    
        const response = await fetch(`https://${region}.tts.speech.microsoft.com/cognitiveservices/v1`, {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': subscriptionKey,
            'Content-Type': 'application/ssml+xml',
            'X-Microsoft-OutputFormat': 'audio-48khz-192kbitrate-mono-mp3'
          },
          body: ssml
        });
  
        if (!response.ok) {
          throw new Error(`Error synthesizing speech: ${response.statusText}`);
        }
        const audioBlob = await response.blob();
        const url = URL.createObjectURL(audioBlob); 
        dispatch(updateVoiceState({sample_url: url}))
        dispatch(updatePendingCallState({voice_sample: false}))
       // setAudioUrl(url);
      } catch (error) {
        dispatch(updatePendingCallState({voice_sample: false}))
        console.error('Error synthesizing speech:', error);
      }
      dispatch(updateVoiceState({is_generating_sample: false}))
    };

    // useEffect(()=> {
    //     if(!!selectedVoice?.styleList) {
    //         setStyleList(selectedVoice?.styleList)
    //     }
    // }, [selectedVoice])
  
    useEffect(() => {
      if (!!voiceReducer?.sample_url && sampleRef.current) {
        sampleRef.current.src = voiceReducer?.sample_url;
        playSample();
      }
    }, [voiceReducer?.sample_url]);
  
    const playSample = () => {
      !!sampleRef.current &&  sampleRef.current.play();
      dispatch(updateVoiceState({is_sample_playing: true}))
    };
  
    // const pauseSample = () => {
    //   sampleRef.current.pause();
    // };
    const pauseSample = () => {
     !!sampleRef.current && sampleRef.current.pause();
     dispatch(updateVoiceState({is_sample_playing: false}))
    };
  
    // const handleSeek = (event) => {
    //   audioRef.current.currentTime = event.target.value;
    // };
  
    // const handlePlaybackRateChange = (event) => {
    //   const rate = parseFloat(event.target.value);
    //  // setPlaybackRate(rate);
    //   if (audioRef.current) {
    //     audioRef.current.playbackRate = rate;
    //   }
    // };
  
    // const handleDownload = () => {
    //   const link = document.createElement('a');
    //   link.href = audioUrl;
    //   link.download = 'speech.wav';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // };
  return {
          sampleRef, 
          playSample, 
          pauseSample,
          handleGenerateVoiceSample, 
        }
}
