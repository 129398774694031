import React from 'react'
import { exampleStoryboard } from '../../../constants/staticData'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import img from '../../../assets/edit.svg'
import ScrollToBottom from '../../../components/ScrollToBottom'
import CustomTooltip from '../../../components/CustomTooltip'
import { useSelector } from 'react-redux'

const Storyboard = ({data}) => {
    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    const scriptReducer = useSelector((state) => state.scriptReducer);
    let tempArr = [...data];
    // if(userReducer.isLoading) {
    //     tempArr = [...content, {data: null, image_url: null} ]
    // }
    
   
  //  return  <Box component='div' sx={{display:'flex', width:'100%', height:'100%'}}>
  //  <Grid container id='story-board' xs={12} gap={4} sx={{ justifyContent:'center', alignItems: 'center', height: '100%', paddingLeft:{lg:2},}}>
  //   Stroyboards currently not available. Coming soon!!
  //   </Grid>
  //   </Box>
  return (
    <>
   {scriptReducer?.enable_story ? (<Box id='download-content' component='div' sx={{display:'flex', justifyContent:'center', width:'100%'}}>
    <Grid container id='story-board' xs={12} gap={4} sx={{ justifyContent:'flex-start', paddingLeft:{lg:2},}}>
        {/* {exampleStoryboard?.map((shot, index)=>( */}
          {tempArr?.length > 0 && tempArr?.map((shot, index)=>
            <Grid item xs={5} sm={4} lg={3}>
                <Box gap={2} sx={{display:'flex', mt: 1.5, flexDirection:'column'}}>
                   {shot.image_url === null ? <Box mt={3} className= 'flex col j-center a-center' gap={2}  ><CircularProgress style={{color: '#666666'}} /><Typography sx={{fontSize: 16}}>Creating...</Typography><ScrollToBottom/> </Box>: <Box  flex={2} sx={{backgroundColor:'white', border:'1px solid black', width: '100%', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                       
                          <img src={shot.image_url} style={{width:'100%', borderRadius:'10px', objectFit:'contain'}}/>
                       
                    </Box>}
                    { shot.title !== null && <CustomTooltip title={shot?.title}>
                    <Box component='div' flex={1} sx={{ width: '100%', backgroundColor: '#fff', border:'1px solid black', padding:'6px',   borderRadius:'10px',  maxHeight: '116px', minHeight: '116px',
                     overflowY: 'clip'}}>
                        {/* <Typography  sx={{fontSize:{xs:'9px',lg:'10px',xl:'12px'},  fontFamily:'Rubik', color:'#08231B', fontWeight:600}}>{shot.title}</Typography> */}
                        <Typography  sx={{fontSize:{xs:'9px',lg:'10px',xl:'12px'}, my:1,  fontWeight: 800, textAlign: 'left', fontFamily:'Rubik', color:'#08231B'}}>{`Shot ${index+1}`}</Typography>
                        {/* <Typography  sx={{fontSize:{xs:'9px',lg:'10px',xl:'12px'}, mt: 1, fontWeight: 600, textAlign: 'left', fontFamily:'Rubik', color:'#08231B'}}>Script</Typography> */}
                        <Typography  sx={{fontSize:{xs:'9px',lg:'10px',xl:'12px'}, marginBottom: '8px', textAlign: 'left', fontFamily:'Rubik', color:'#08231B'}}>{shot.title}</Typography>
                    </Box>
                    </CustomTooltip>}
                </Box>
            </Grid>
          )}
        {/* ))} */}
    </Grid>
    </Box>): (
      <Box component='div' sx={{display:'flex', width:'100%', height:'100%'}}>
        <Grid container id='story-board' xs={12} gap={4} sx={{ justifyContent:'center', alignItems: 'center', height: '100%', paddingLeft:{lg:2},}}>
            Stroyboards currently not available. Coming soon!!
        </Grid>
      </Box>
    )}
    </>
  )
}

export default Storyboard