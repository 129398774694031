import { Backdrop, Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import CustomAlert from "../../events/components/CustomAlert";
import CustomInput from "../../eventcreation/components/CustomInput";
import { updateUserState } from "../../../redux/userSlice";

const DeleteConfirmationPopup = (props) => {
    const {open, closeHandler, deleteConfirmHandler, message, status} = props
   // const [closeHandler, setCloseHandler] = useState(false)
    const isDisabled = false
    const dispatch = useDispatch()
    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    const scriptReducer = useSelector((state)=> state.scriptReducer)
    const contentReducer = useSelector((state)=> state.contentReducer)
    const [text, setText] = useState("")

    const handleClose = () => {
       closeHandler()
       // dispatch(updateUserState({account_delete_popup : false})) 
       };

       


    const confirmHandler= async ()=>{
      //will send api req ,to deleet user, on success redux reset, and logout navigate to signin page
      if(text === "delete") {
       // deleteHandler(userReducer.user_id)  
       deleteConfirmHandler()
      
      }
    }

    const cancelHandler =()=>{
      handleClose();
    }
    const handleChange = (event) => {
      event.stopPropagation()
        setText(event.target.value.toLowerCase())
      }

  return (
    
//     <Backdrop
//     sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(15px)', display:'flex', flexDirection:'column', background:'transparent'}}
//   open={userReducer.account_delete_popup}
//   >
//            <Box p={5} className="flex col j-center" sx={{ height:'100%'}}>
          
//             <Box   mb={{xs:1 ,md:2}} mt= {1}>
//               <Typography variant="h4" sx={{fontFamily:'Rubik', fontSize:{xs:'13px',sm:'13px',md:'13px',lg:'13px',xl:'18px'}, color:'black'}}>{`Are you sure you want to delete your account ? Your projects and data will be deleted permanently and you will be logged out. Please type "delete" in the below box to confirm`}</Typography>
//             </Box>
    
//             <Box>
//              <CustomInput onChange={handleChange} value={text} placeholder = "delete" max={6} light/>
//             </Box>
    
//             <Box sx={{display:'flex',justifyContent:'end' }} gap={2} mt={{xs:1 ,md:2}} >
    
//               <Button variant='outlined' onClick={cancelHandler} style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#00D744', textTransform:'none', }} sx={{fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, paddingY:0.4,}}>Cancel</Button>
    
//               <LoadingButton
//                    // size="small"
//                     disabled={status == 'loading' || text !== 'delete'}
//                     onClick={confirmHandler}
//                     style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#00D744', textTransform:'none', opacity: text !=='delete' && '50%' }} 
//                     sx={{ fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, minWidth: '110px !important'}}
//                     loading={status == 'loading'}
//                     loadingPosition="start"
//                     variant="contained"
//                     >
//                    Continue
//                </LoadingButton> 
//             </Box>
//            </Box>
//      </Backdrop>

       <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={open}
    >
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
          <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3}} onClick={handleClose}>Close X</Typography>
          <Box px={10} p={5} className="flex col j-center a-center" sx={{ height:'100%', width:'100%', }}>
          
          <Box   mb={{xs:1 ,md:2}} mt= {1} sx={{width:'60%'}}>
            <Typography variant="h4" sx={{fontFamily:'Rubik', whiteSpace: 'pre-wrap', fontSize:{xs:'13px',sm:'13px',md:'15px',lg:'18px',xl:'24px'}, color:'#ffffff'}}>{message}</Typography>
          </Box>
  
          <Box sx={{width:'60%'}}>
           <CustomInput onChange={handleChange} value={text} placeholder = "delete" max={6} light/>
          </Box>
  
          <Box sx={{display:'flex',justifyContent:'end', width:'60%' }} gap={2} mt={{xs:1 ,md:2}} >
  
            <Button variant='outlined' onClick={handleClose} style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#00D744', textTransform:'none', }} sx={{fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, paddingY:0.4,}}>Cancel</Button>
  
            <LoadingButton
                 // size="small"
                  disabled={userReducer.common_backdrop_open || text !== 'delete'}
                  onClick={confirmHandler}
                  style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#00D744', textTransform:'none', opacity: text !=='delete' && '50%' }} 
                  sx={{ fontSize:{xs:'9px',sm:'9px',md:'9px',lg:'11px',xl:'16px', },fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, minWidth: '110px !important'}}
                  loading={userReducer.common_backdrop_open}
                  loadingPosition="start"
                  variant="contained"
                  >
                 Continue
             </LoadingButton> 
          </Box>
         </Box>
        
        </Box>
    </Backdrop>
      
  )
}

export default DeleteConfirmationPopup

