import { SvgIcon } from "@mui/material";
import React from "react";

const AddIcon = (props)=> {
    return <SvgIcon {...props} width="127" height="127" viewBox="15 15 90 90" >
        <defs>
    <filter id="Rectangle_29" x="0" y="0" width="127" height="127" filterUnits="userSpaceOnUse">
      <feOffset dy="5" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="7.5" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <rect id="Rectangle_43" data-name="Rectangle 43" width="41.439" height="41.597" fill="none"/>
    </clipPath>
  </defs>
  <g id="Add_Project" data-name="Add Project" transform="translate(-196.5 -326.5)">
    <g transform="matrix(1, 0, 0, 1, 196.5, 326.5)" filter="url(#Rectangle_29)">
      <rect id="Rectangle_29-2" data-name="Rectangle 29" width="82" height="82" rx="10" transform="translate(22.5 17.5)" fill="#08231b"/>
    </g>
    <g id="Group_62" data-name="Group 62" transform="translate(239.281 364.202)">
      <g id="Group_61" data-name="Group 61" clip-path="url(#clip-path)">
        <path id="Path_40" data-name="Path 40" d="M15.326,8.558c0,1.984-.028,3.969.015,5.953.015.689-.2.885-.88.88-3.795-.029-7.59-.016-11.384-.013C1,15.38.008,16.362,0,18.415Q0,20.8,0,23.177c.007,2.013,1.013,3.029,3.011,3.032,3.795.005,7.59.024,11.384-.016.774-.008.95.233.944.97-.035,3.844-.021,7.689-.013,11.534a2.641,2.641,0,0,0,2.861,2.882c1.612.013,3.226-.045,4.836.016a2.859,2.859,0,0,0,3.109-3.134c-.084-3.793-.008-7.59-.039-11.385-.005-.686.194-.885.877-.88,3.844.029,7.689.019,11.533.011A2.672,2.672,0,0,0,41.43,23.3c.013-1.637.008-3.274,0-4.911a2.692,2.692,0,0,0-3.037-3.011c-3.819,0-7.639-.016-11.458.012-.656,0-.844-.19-.839-.843.028-3.87.018-7.739.011-11.609A2.641,2.641,0,0,0,23.224.01q-2.529-.018-5.06,0A2.607,2.607,0,0,0,15.33,2.829c-.012,1.91,0,3.82,0,5.73" transform="translate(0 0)" fill="#00d744"/>
        <path id="Path_41" data-name="Path 41" d="M15.328,8.558c0-1.91-.01-3.82,0-5.73A2.607,2.607,0,0,1,18.165.01q2.53-.018,5.06,0a2.641,2.641,0,0,1,2.884,2.929c.007,3.87.017,7.739-.011,11.609,0,.654.183.848.839.843,3.819-.028,7.639-.016,11.459-.012a2.691,2.691,0,0,1,3.037,3.011c.006,1.637.011,3.274,0,4.911a2.672,2.672,0,0,1-2.924,2.906c-3.844.007-7.689.018-11.533-.011-.684-.005-.883.194-.877.88.031,3.795-.045,7.591.039,11.385a2.858,2.858,0,0,1-3.109,3.134c-1.61-.062-3.224,0-4.835-.016A2.641,2.641,0,0,1,15.33,38.7c-.008-3.845-.022-7.69.013-11.534.007-.737-.17-.978-.944-.97-3.794.04-7.589.021-11.384.016-2,0-3-1.018-3.011-3.032Q-.005,20.8,0,18.415c0-2.052,1-3.034,3.074-3.036,3.795,0,7.59-.016,11.384.013.682.005.9-.191.88-.88-.043-1.984-.015-3.968-.014-5.953" transform="translate(0 0)" fill="#00d744"/>
      </g>
    </g>
  </g>

    </SvgIcon>
}

export default AddIcon