import { Button } from "@mui/base";
import { Backdrop, Box, Slide, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectCreationState } from "../../../../redux/projectCreationSlice";
import { useNavigate } from "react-router-dom";
import { voiceScriptOptions } from "../../../../constants/staticData";
import CustomRadioButton from "../CustomRadioButton";
import useEventCreationActions from "../../hooks/useEventCreationActions";
import { afterValidate } from "../../../../utils/commonServices";
import LeftIcon from "../../icons/LeftIcon";
import RightIcon from "../../icons/RightIcon";

const VoiceScriptOption = (props)=> {
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const isListView = commonReducer?.list_view
    const dispatch = useDispatch();
const {pageData, setTotalScreens, setPageData, slideDirection,  prevPageHandler, nextPageHandler, pageIndex,  closeHandler, totalScreens, totalAIScreens, totalUserScriptScreens} = props
 const navigate = useNavigate()
 const {createProject} = useEventCreationActions();
   const finishedHandler = ()=> {
     afterValidate(requestCallback);
   }

   const selectScriptOption = (needScript)=> {
    dispatch(updateProjectCreationState({voiceOverPageData: {...createProjectReducer.voiceOverPageData, needScript: needScript}}))
   }


   const createContent = async (data) => {
     try {
         const response = await fetch('https://asbutterfly-v1-beta.azurewebsites.net/api/projects', 
         {
             method: 'POST',
             headers: {
                 "Accept": "application/json-patch+json",
                 "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJwcm9kdWN0X2lkIjozLCJ1c2VyX2lkIjo0MjAsIm9yZ2FuaXNhdGlvbl9pZCI6MzQ3LCJleHAiOjE3MDMxODg3NDJ9.DBa8xdlIgkzH1WPgi8_V-YHhHXA0PfabysZ58DqeQP4",
                 "Content-Type": "application/json"
             },
             body: JSON.stringify(data)
         });
         if (!response.ok) {
             throw new Error(`HTTP error! status: ${response.status}`);
         }
         const result = await response.json();
         //console.log('Success:', result);
     } catch (error) {
         //console.error('Error:', error);
     }
 };
   const requestCallback = async ()=>{

   createProject(createProjectReducer.commonPageData)
    // console.log("pageData", createProjectReducer.pageData)
    // dispatch(updateProjectCreationState({open_backdrop_project_creation: false}))
     // createProject(payload);
   }


   useEffect(() => {
     const keyDownHandler = (event) => {
       if (event.key === "Enter") {
         event.preventDefault();
         nextPageHandler();
       }
     };
       document.addEventListener("keydown", keyDownHandler)
     return () => {
       document.removeEventListener("keydown", keyDownHandler);
     };
   }, [createProjectReducer.commonPageData.personaId]);


   const handleRadioChange = (event) => {
    if(event.target.value == '1') {
        setTotalScreens(totalAIScreens)
        dispatch(updateProjectCreationState({voiceOverPageData: {...createProjectReducer.voiceOverPageData, needScript: true}}))
    } else {
        setTotalScreens(totalUserScriptScreens)
        dispatch(updateProjectCreationState({voiceOverPageData: {...createProjectReducer.voiceOverPageData, needScript: false}, commonPageData: {...createProjectReducer.commonPageData, lengthId: 0 }}))
    }
    // dispatch(updateProjectCreationState({commonPageData: {...createProjectReducer.commonPageData, personaId: parseInt(event.target.value)}}))
   };
 

 return (
   <>
   {/* <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
   <Backdrop
   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
   open={createProjectReducer.open_backdrop_project_creation}
   > */}
       <Box sx={{ width:isListView ? '100%' : '90%', height:'100%', display:'flex', flexDirection:'column'}}>
        {(!isListView || contentReducer?.create_content_open) &&  <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3}} onClick={closeHandler}>Close X</Typography>}
         <Box flexGrow={1} className='flex col 'sx={{ paddingLeft: isListView ? '5vw' :'14vw', marginTop:isListView ?{xs:'6%',sm:'4%', md:'3%', xl:'4%'}: {xs:'8%',sm:'6%', md:'4%', xl:'6%'}, height:'100%', overflowY:'scroll'}}>
             <Box mb={{xs:1, sm:2}}>
               <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
               <Typography sx={{fontSize:{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik'}}>Do you need help creating a script?</Typography>
               <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>Select one.</Typography>
               <Box className='flex '  pt={3} pb={3} pl={{xs:7,sm:14, md:3}}  sx={{width:'90%', overflowY:'scroll', justifyContent: 'center', flexDirection:{xs:'column', md:'row', }}} gap={{xs:2, lg:3, xl:4}}>
               {
                 voiceScriptOptions?.map((item)=> {
                   return <CustomRadioButton
                   label='Yes'
                   id={item.id}   
                   name='vo_script_option' 
                   handleRadioChange={handleRadioChange}
                   height={{ xs:'95px',lg:'114px', xl:'155px'}}
                   width={{xs:'230px',lg:'250px', xl:'381px'}}
                   radioSize = {20}
                   selectedValue={createProjectReducer.voiceOverPageData.needScript ? 1 : 2}
                  // setSelectedValue={setPageData}
                   component={
                     <Box px={2} className='flex col a-center j-center' gap={1} sx={{width:'100%'}}>
                         <Typography sx={{color:'#08231B', fontSize:{xs:'15px',md:'20px',lg:'26px',xl:'40px'}, fontWeight:600,}}>{item.name}</Typography>
                         <Typography textAlign={'center'} sx={{color:'#08231B', fontSize:{xs:'8px',md:'9px',lg:'11px',xl:'15px'}, }}>{item.description}</Typography>
                     </Box>}
                 />
              
                 })
               }
             </Box>
             </Box>
         
           
         </Box>
         <Box className='flex' sx={{justifyContent:'flex-end',mb:3, mr:3}}>
           <LeftIcon sx={{opacity: pageIndex === 1 && 0.5, fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}, cursor:'pointer'}}  onClick={pageIndex !== 1 ? prevPageHandler : null}/>
           <RightIcon sx={{ cursor:'pointer', fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}}  onClick={ nextPageHandler}/>
         </Box>
       </Box>
   {/* </Backdrop>
   </Slide> */}
   </>
 )
}

export default VoiceScriptOption