import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    signup: false, 
    checkEmail: false, 
    signin: false, 
    forgotPassword: false,
    checkVerificationToken: false, 
    verifyTokenValidityStatus: "",
    verifyUserMail: false, 
    resetPassword: false, 
    resendLink: false, 
    deleteUser: false, 
    verifyAppleUser: false, 
    checkExistingUser: false,
    resetPasswordStatus: '',
    projectTypeList: false,
    projectLengthList: false,
    loader_backdrop_open: false,
    voice_sample: false,
    loader_text: {title: "Please wait", message: "Good stuff takes time..."}
};

export const pendingCallSlice = createSlice({
  name: "pending-call",
  initialState: initialValue,
  reducers: {
    updatePendingCallState: (state, action) => {
      assignValueInState(action.payload, state);
    },
    resetPendingCallState: (state) => {
      assignValueInState(initialValue, state);
    },
  },
});

const assignValueInState = (obj, state) => {
  for (const key in obj) {
    state[key] = obj[key];
  }
};

export const { updatePendingCallState, resetPendingCallState } = pendingCallSlice.actions;
export default pendingCallSlice.reducer;