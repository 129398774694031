import { Backdrop, Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserState } from "../../../redux/userSlice";
import { LoadingButton } from "@mui/lab";
import CustomAlert from "../../events/components/CustomAlert";
import CustomInput from "../../eventcreation/components/CustomInput";

const DisclaimerPopup = (props) => {
    const {submit} = props;
    const dispatch = useDispatch()
    const pendingCallReducer = useSelector((state) => state.pendingCallReducer);
    const userReducer = useSelector(state=> state.persistedReducers.userReducer)
 
    const onSubmit = async ()=> {
        await submit()
    }

    const handleClose = () => {
        dispatch(updateUserState({footer_disclaimer: false, signup_disclaimer: false}))
       };


  return  <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(32px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
  open={userReducer.footer_disclaimer || userReducer.signup_disclaimer}
  >
         <Typography textAlign={'right'} width='95%' sx={{fontSize:'16px', cursor:'pointer', mt:5, mr: 3}} onClick={handleClose}>Close X</Typography>
          <Box p={5} className="flex col j-center" sx={{ height: '100%', width: {xs: '90%', lg: '60%' } }}>
              <Box component= 'div' mb={{ xs: 1, md: 2 }} mt={1} sx={{overflowY: 'scroll'}} >
                {
          
                    <Typography variant="h4" sx={{ textAlign: 'justify', fontFamily: 'Rubik', fontSize: { xs: '16px', sm: '16px', md: '18px', lg: '18px', xl: '24px' }, color: '#FFFFFF', fontWeight: 600 }}><Typography component='span' sx={{ fontFamily: 'Rubik', fontWeight: 900, fontSize: { xs: '18px', sm: '18px', md: '20px', lg: '20px', xl: '26px' }, color: '#FFFFFF', textDecoration: 'underline #FFFFFF' }} >Disclaimer:</Typography> This software uses generative AI to produce content based on user input. Content may be inaccurate or offensive and does not represent Lucihub’s views. <br/><br/>


                    The content may contain errors, inconsistencies, or outdated information and should not be relied upon for any purpose. The content does not constitute legal, medical, financial, or any other professional advice. <br/><br/>
                    
                    
                    Users assume all risks from content use or misuse. The software claims no rights over the AI-generated content, which users can freely manage as per laws. <br/><br/>
                    
                    
                    By using this software, users accept its limitations, agree to its terms, and waive any claims against it, indemnifying its creators from any related damages or costs.</Typography>
                }
                 
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end' }} gap={2} mt={{ xs: 1, md: 2 }} >
                  <Button variant='outlined' onClick={handleClose} style={{ borderRadius: '5px', backgroundColor: '#00D244', color: '#08231B', textTransform: 'none', }} sx={{ fontSize: { xs: '9px', sm: '9px', md: '9px', lg: '11px', xl: '16px', }, fontWeight: 600, width: { xs: '76px', sm: '76px', md: '76px', lg: '86px', xl: '129px' }, paddingY: 0.4, }}>{!!submit ? "Cancel" : "Close"}</Button>
                 {!!submit && <Button variant='outlined' onClick={onSubmit} style={{ borderRadius: '5px', backgroundColor: '#08231B', color: '#00D744', textTransform: 'none', }} sx={{ fontSize: { xs: '9px', sm: '9px', md: '9px', lg: '11px', xl: '16px', }, fontWeight: 600, width: { xs: '76px', sm: '76px', md: '76px', lg: '86px', xl: '129px' }, paddingY: 0.4, }}>Accept</Button>}
              </Box>
          </Box>
      </Backdrop>
}

export default DisclaimerPopup