import { Backdrop, Box, Slide, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../CustomInput';
// import UpIcon from '../icons/UpIcon';
// import DownIcon from '../icons/DownIcon';
import { afterValidate, setInput } from '../../../../utils/commonServices';
import { getErrorMsg } from '../../../../utils/validator';
import { updateProjectCreationState } from '../../../../redux/projectCreationSlice';
import LeftIcon from '../../icons/LeftIcon';
import RightIcon from '../../icons/RightIcon';

const ProjectDescriptionPage = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex,  closeHandler, totalScreens, title, note} = props
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const dispatch = useDispatch()
    const [descriptionValidationflag, setDescriptionvalidationFlag] = useState(false)
    const charLimit = 25000;
    const [charLeft, setCharLeft] = useState(25000)
    const commonReducer = useSelector((state) => state.commonReducer);
    const isListView = commonReducer?.list_view
    const isMobileView = useMediaQuery('(max-width:599px)');

    const finishedHandler = ()=> {
      validateInputs(true)
      afterValidate(nextPageHandler);
  }

  const validateInputs = (flag) => {
    setDescriptionvalidationFlag(flag)
  }
  const onChangeHandler = (event)=> {
    setCharLeft(charLimit - event.target.value?.length)
    dispatch(updateProjectCreationState({commonPageData: {...createProjectReducer.commonPageData, description: createProjectReducer.commonPageData.description != "" ? event.target.value : event.target.value.trim() }}))
  }

  console.log("char left", charLeft)

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        !scriptReducer.open_delete_popup && finishedHandler();
      }
    };
      document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [createProjectReducer.commonPageData.description, scriptReducer.open_delete_popup]);

  return (
    <>
    {/* <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createProjectReducer.open_backdrop_project_creation}
    > */}
        <Box sx={{ width:isListView ? '100%' : '90%', height:'100%', display:'flex', flexDirection:'column'}}>
          {(!isListView || contentReducer?.create_content_open) && <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3}} onClick={closeHandler}>Close X</Typography>}
          <Box flexGrow={1} className='flex col ' sx={{ paddingLeft: isListView ? '5vw' :'14vw', marginTop:isListView ?{xs:'6%',sm:'4%', md:'3%', xl:'4%'}: {xs:'8%',sm:'6%', md:'4%', xl:'6%'}}}>
              <Box mb={6} className='flex col' sx={{width:'95%'}}>
                <Typography sx={{fontSize: isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} :{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik',  whiteSpace:'break-spaces'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography sx={{fontSize:isListView ? {xs:'20px',sm:'22px',md:'26px',lg:'35px',xl:'52px'} :{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik',  whiteSpace:'break-spaces'}}>{title}</Typography>
                <Typography sx={{fontSize: isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} :{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik',  whiteSpace:'break-spaces'}}>{note}</Typography>
              </Box>
              <Box className='flex col j-center' sx={{width: isMobileView ? '93%' : '80%'}}>
              <CustomInput
                id='description'
                onFocus={() => setDescriptionvalidationFlag(false) }
                onBlur={() => setDescriptionvalidationFlag(true)}
               // type='sendInput'
                required
                errorFlag={descriptionValidationflag && getErrorMsg("description", createProjectReducer.commonPageData.description) != ""}
                errorMsg={getErrorMsg("description", createProjectReducer.commonPageData.description)}
                fullWidth
                medium
                multiline
                maxRows = {6}
                max={25000}
                bottom={-20}
                msgColor='red'
                placeholder="Type your request here..."
                onChange={onChangeHandler } 
                value={createProjectReducer.commonPageData.description}
                onButtonClick={pageIndex !== totalScreens ? finishedHandler : null}
               />
                {charLeft < 25000 && <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Rubik',
                      color: "#BBBBBB",
                      fontSize:{xs:'12px',lg:'13px',xl:'18px'} ,
                     // position:'absolute',
                      
                      //top: '8px',
                      fontWeight : 600
                      //paddingTop: 20,
                    }}
                  > 
                   
                    <Box textAlign='left'>{`Characters left: ${charLeft}`}</Box>
                  </Typography>}
              </Box>
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', mb:3, mr:3}}>
            <LeftIcon sx={{opacity: pageIndex === 1 && 0.5, fontSize: isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}: {xs:'45px', sm:'55px', lg:'65px',xl:'82px'}, cursor:'pointer'}}  onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <RightIcon sx={{ cursor:'pointer', fontSize: isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}: {xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== totalScreens ? finishedHandler : null}/>
          </Box>
        </Box>
    {/* </Backdrop> */}
    {/* </Slide> */}
    </>
  )
}

export default ProjectDescriptionPage