import { SvgIcon } from "@mui/material";
import React from "react";

const RightIcon = (props)=> {
    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="127" height="127" viewBox="9 0 100 100">
  <defs xmlns="http://www.w3.org/2000/svg">
    <filter id="Rectangle_29" x="0" y="0" width="127" height="127" filterUnits="userSpaceOnUse">
      <feOffset dy="5" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="7.5" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <rect id="Rectangle_48" data-name="Rectangle 48" width="32.025" height="19.382" transform="translate(0 0)" fill="#00d744"/>
    </clipPath>
  </defs>
  <g xmlns="http://www.w3.org/2000/svg" id="Group_348" data-name="Group 348" transform="translate(22.5 17.5)">
    <g id="Add_Project" data-name="Add Project">
      <g transform="matrix(1, 0, 0, 1, -22.5, -17.5)" filter="url(#Rectangle_29)">
        <path id="Rectangle_29-2" data-name="Rectangle 29" d="M0,0H72A10,10,0,0,1,82,10V72A10,10,0,0,1,72,82H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(22.5 17.5)" fill="#08231b"/>
      </g>
    </g>
    <g id="Group_331" data-name="Group 331" transform="translate(31.309 57.013) rotate(-90)">
      <g id="Group_81" data-name="Group 81" >
        <path id="Path_42" data-name="Path 42" d="M28.309,0a10.271,10.271,0,0,0-2.13,1.342q-4.692,4.537-9.237,9.224c-.794.817-1.253.79-2.032-.011-3.095-3.179-6.257-6.292-9.4-9.427A3.164,3.164,0,0,0,2.131.193,2.706,2.706,0,0,0,0,2.645,4.441,4.441,0,0,0,.888,5.533c4.156,4.343,8.427,8.575,12.7,12.8a3.06,3.06,0,0,0,4.577.033Q24.643,12,31.032,5.549a3.045,3.045,0,0,0,.654-3.655C31.139.72,30.152.1,28.309,0" transform="translate(0 0)" fill="#00d744"/>
      </g>
    </g>
  </g>

      </SvgIcon>
    )
}

export default RightIcon