import { Box, Typography, Button } from "@mui/material"
import CustomCard from "./CustomCard"
import luciLogo from "../../../assets/copilot_logo_dark.png"
import { customTheme as theme } from "../../../theme/customTheme"
import { makeStyles } from "@mui/styles";
import {   capitalizeFirstLetter, formatDynamicDate, getNamesFromIds, removeWhiteSpaces, timeStampToDateTime, timeStampToDayString, timeStampToFormatTimeZone,  } from "../../../utils/commonServices";
import "@fontsource/rubik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../../components/CustomTooltip";
import FeedbackIcon from '@mui/icons-material/Feedback';
import { personaList } from "../../../constants/staticData";

const useStyles = makeStyles((theme) => ({
 fontWeightNormal:{
  fontWeight: 'normal'
 }, 
fontWeight:{
  fontWeight:600
},
rubik:{
  fontFamily:'Rubik'
}

}));



const ProjectDetailCard = (props) => {

  const userReducer = useSelector((state)=> state.persistedReducers.userReducer)
  const scriptReducer = useSelector((state)=> state.scriptReducer)
  const contentReducer = useSelector((state)=> state.contentReducer)
  const dropdownReducer = useSelector((state)=>state.persistedReducers.dropdownReducer)
  const event = userReducer.events?.find((event) => event.id == userReducer.production_event_id)
  const contentTypeId = dropdownReducer.project_type?.find(type=> removeWhiteSpaces(type.name)?.toLowerCase() == removeWhiteSpaces(contentReducer?.selected_content_details.content?.type)?.toLowerCase())?.id
  const navigate = useNavigate()
const isStoryboard = scriptReducer?.selected_project_details?.projectTypeId === 3
  const {eventData} = props

  const classes = useStyles();

  return (
    <CustomCard className='rubik' style={{ height:'100%', padding:0 , '& .MuiCardContent-root:last-child':{paddingBottom:0}}} >
      <Box className="rubik flex a-center " sx={{height:'100%', display:'flex', width: '100%', flexDirection:'column', paddingBottom:{xs:'20px',sm:'25px',md:'20px',lg:'30px',xl:'38px'} }}>
        <Box className="rubik " textAlign='center' sx={{ width: { xs: '70px', md: '125px', lg: '130px', xl: '170px' }, marginTop:{xs:'39px',sm:'42px',md:'10%', lg: '10%',xl:'10%'}, marginBottom:{xs:'35px',sm:'40px',md:'12%',lg:'12%',xl:'12%'},}} >
           <img src={luciLogo} width='100%' onClick={()=>navigate('/dashboard', {replace: true})} style={{cursor:'pointer'}}/>
        </Box>

        <Box className="rubik" sx={{ display:'felx', flexDirection:'column', width: '85%', flex: 1, fontSize: {xs:'10px',md:'12px',lg:'14px',xl:'16px'},  marginRight:{md:'10px',lg:'0.52vw',xl:'0.78vw'}, overflowX:'auto'}}>
          <Box mb={2}>
            <Typography mb={0.5} sx={{fontWeight:'normal',  fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik', color:'#fff'}}>Lucihub Project</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px', wordWrap: 'break-word'}, textTransform:'capitalize', fontFamily:'Rubik', color:'#fff'}}>{scriptReducer?.selected_project_details?.name}</Typography>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5} sx={{color:'#fff' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Type</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik', color:'#fff'}}>{isStoryboard ? scriptReducer?.selected_project_details?.projectType : `${capitalizeFirstLetter(contentReducer?.selected_content_details?.content?.type)} - ${getNamesFromIds(scriptReducer?.selected_project_details?.personaId, personaList)?.name}`}</Typography>
          </Box>
          <Box mb={2}>
            <Typography mb={0.5}  sx={{color:'#fff' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Length</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik', color:'#fff'}}>{isStoryboard ? `${scriptReducer?.selected_project_details?.length} Seconds` :`${contentReducer?.selected_content_details.content?.content_length} ${(parseInt(contentTypeId) < 4 || parseInt(contentTypeId) == 8) ? "Seconds" : "Words"}`}</Typography>
          </Box>
          <Box component='div' sx={{maxHeight: '100px', overflowY: 'hidden' }} mb={2}>
            <Typography mb={0.5}  sx={{color:'#fff' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Description</Typography>
            <CustomTooltip title= {isStoryboard ?scriptReducer?.selected_project_details?.description :  contentReducer?.selected_content_details.content?.prompt} placement = 'right'>
                <Typography sx={{fontWeight:theme.typography.fontWeightBold , textAlign: 'justify', fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik', color:'#fff'}}>{isStoryboard ?scriptReducer?.selected_project_details?.description :  contentReducer?.selected_content_details.content?.prompt}</Typography>
            </CustomTooltip>

          </Box>
          <Box mb={2}>
            <Typography  sx={{color:'#fff' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Created on</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, color: '#fff', fontFamily:'Rubik'}}> {scriptReducer?.selected_project_details?.startDate && formatDynamicDate(scriptReducer?.selected_project_details?.startDate)}</Typography>
          </Box>

          {/* <Box mb={2}>
            <Typography  sx={{color:'#fff' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>End Date</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, color: '#fff', fontFamily:'Rubik'}}>{userReducer?.selected_project_details?.endDate && formatDynamicDate(userReducer?.selected_project_details?.endDate)}</Typography>
          </Box> */}
        </Box>
        <Box width='100%' textAlign='center' >
            <Button
               href="mailto:bugreport@lucihub.com"
               variant= "outlined"
               style={{
                borderRadius: '5px',
                border: "none",
                textTransform:'none',
                backgroundColor: '#08231B', 
                color: '#00D744',
              }}
              sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:800, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},fontFamily:'Rubik'}}
            
              // color="secondary"
               endIcon ={<FeedbackIcon style={{fontSize: 18}}/>}
            >Send Feedback</Button>
          </Box>
      </Box>
    </CustomCard>
  )
}

export default ProjectDetailCard