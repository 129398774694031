import { Box } from "@mui/material";
import React from "react";

const CustomCard = (props)=> {
    const {customStyle} = props;
    return <Box
                {...props}
                sx={{
                    ...props.sx,
                    boxShadow: '0px 5px 15px #00000029',
                    borderRadius: customStyle?.borderRadius ? customStyle?.borderRadius : '10px',
                    // padding: props.padding,
                    // margin: props.margin
                    // backgroundColor:'',
                    background: '#FFFFFF40 0% 0% no-repeat padding-box',
                    color:'#fff',
                    zIndex:500
                }}
        >
            {props.children}
        </Box>
}
export default CustomCard;