import { Box, Typography } from '@mui/material'
import LoginImage from '../../../assets/loginImage.svg'
import React from 'react'
import "@fontsource/rubik";

const RightComponent = () => {
  return (
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', }}  pl={{xs:2,sm:4,md:8,lg: 10,xl:12}}>
        <Box mt={{xs:2,sm:1, md:2, lg:3, xl:8}}  sx={{height:{xs:'200px',sm:'200px',md:'300px',lg:'363px',xl: '550px'}, marginBottom:{xs:'15px', sm:'15px',md:'20px',lg:'22.66px',xl:'34px'}}}>
             <img src={LoginImage} alt="filmingCard" height='100%' style={{ borderRadius:'10px'}} />
        </Box>
    </Box>
  )
}

export default RightComponent