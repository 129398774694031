import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    project_content_list: [],
    selected_content_details:{},
    delete_content_id: null,
    open_delete_popup: false,
    create_content_open: false,
    is_voice: false,
    editor_text: ""
}


export const contentSlice = createSlice({
    name: 'content',
    initialState: initialValue,
    reducers: {
        updateContentState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetContentState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateContentState, resetContentState } = contentSlice.actions
export default contentSlice.reducer