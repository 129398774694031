
export const sortByOptions = [
    { value: 'date_added', label: 'Date Added' },
    { value: 'file_type', label: 'File Name' },
    { value: 'favourites', label: 'Favourites' },
    {value: 'file_size', label:'File Size'}
  ];
  export const eventSortOptions = [
    { value: 'date_added', label: 'Date Added' },
    // { value: 'start_dates', label: 'Start Dates' },
    // { value: 'end_dates', label: 'End Dates' },
    {value: 'a_z', label:'A-Z'}
  ];

  export const personaList = [
    {id: '1', name: 'Novice', description: 'Basic explanations and introductory information' },
   // {id: '2', name: 'Creative', description: 'Imaginative storytelling, metaphors, or creative analogies?' },
    {id: '3', name: 'Expert', description: 'Intricate details, offer analyses, and discuss cutting-edge developments' }
  ]
  export const voiceScriptOptions = [
    {id: '1', name: 'Yes', description: 'Lets create with AI' },
   // {id: '2', name: 'Creative', description: 'Imaginative storytelling, metaphors, or creative analogies?' },
    {id: '2', name: 'No', description: 'I already have my script' }
  ]

  export const imageStyleList = [
    {id: '1', name: 'Realistic'},
    {id: '2', name: 'Illustration' },
    {id: '3', name: 'Impression' },
    {id: '4', name: 'Abstract' },
    {id: '5', name: 'Cartoon' },
    {id: '6', name: 'Sketch' }
  ]
  export const imageAspectList = [
    {id: '1', name: 'square', width: 16, height: 16, ratio: '1:1'},
    {id: '2', name: 'landscape', width: 28.5, height: 16 , ratio: '16:9'},
    {id: '3', name: 'portrait', width: 16, height: 28.5, ratio: '9:16' },
  ]
  export const wordLengthList = [
    {id: '1', name: '500 words' }
  ]

  
  export const exampleScript =  'hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist hwllo this is an example script and i want to make sure that ist'


  export const exampleShotlist = [
    {
        shotName: "Opening Shot",
        description: "A close-up of a ring light turning on, with a soft glow emanating from the center."
    },
    {
      shotName: "Mastering the Ring Light",
      description: "Clips of individuals adjusting the position and height of the ring light in various settings, demonstrating its versatility."
    },
    {
      shotName: "Consistent Illumination",
      description: "A split-screen shot showing a person’s face with and without the ring light, highlighting the even illumination it provides."
    },
    {
      shotName: "Shadows Control",
      description: "Close-up of a hand turning up the brightness knob on the ring light to eliminate harsh shadows."
    },
    {
      shotName: "Skin Tone Control",
      description: "A clip showing the brightness being turned down on the ring light to prevent overexposure of the subject’s skin."
    },
    {
      shotName: "Subject Illumination",
      description: "Cinematic shot of a person being beautifully lit by the ring light, emphasizing the flattering illumination."
    },
    {
      shotName: "Color Temperature Adjustment",
      description: "Close-up of a hand adjusting the color temperature dial on the ring light to shift from warm to cool tones."
    },
    {
      shotName: "Atmosphere Customization",
      description: "Montage of shots demonstrating the ring light’s effect on the atmosphere, with warm and cool lighting settings."
    },
    {
      shotName: "Ring Light Portability",
      description: "Clips of the ring light being set up in different filming locations, indoors and outdoors, showcasing its adaptability."
    },
    {
      shotName: "Ring Light Reflection",
      description: "Creative shot capturing the ring light’s reflection in a mirror, glass surface, or subject’s eyes."
    },
    {
      shotName: "User-Friendly Design",
      description: "Close-up of the ring light’s user interface, showcasing its intuitive controls and seamless integration."
    },
    {
      shotName: "Corporate Testimonials",
        description: "Shots of a well-lit corporate setting, showcasing the ring light’s contribution to crafting compelling testimonials."
    }
];



export const exampleScriptThumbnails = [
  {id:1,type:'shotlist', title:'Script 1', thumbDesc: ' [Corporate Music Playing] Narrator: Welcome to the world of captivating testimonials. Today, we’ll explore how to use a ring light to create impactful testimonial videos for your corporate clients. [Scene: Professional setting with a person sitting in front of a ring light, holding a testimonial script] Narrator: Enhancing the visual quality of your testimonials is crucial. The ring light offers a seamless solution. [Visual: Person adjusting the position of the ring light] Narrator: Position the ring light at an ideal distance, providing even illumination without harsh shadows. [Visual: Person fine-tuning the placement of the ring light] Narrator: Customize the lighting to match the desired atmosphere.', desc:''},
  {id:2,type:'storyboard', title:'Script 2', thumbDesc: '  The ring light offers a seamless solution. [Visual: Person adjusting the position of the ring light] Narrator: Position the ring light at an ideal distance, providing even illumination without harsh shadows. [Visual: Person fine-tuning the placement of the ring light] Narrator: Customize the lighting to match the desired atmosphere.', desc:''},
  {id:3, title:'Script 3', thumbDesc: ' [Corporate Music Playing] Narrator: Welcome to the world of captivating testimonials. Today, we’ll explore how to use a ring light to create impactful testimonial videos for your corporate clients. [Scene: Professional setting with a person sitting in front of a ring light, holding a testimonial script] Narrator: Enhancing the visual quality of your testimonials is crucial. The ring light offers a seamless solution. [Visual: Person adjusting the position of the ring light] Narrator: Position the ring light at an ideal distance, providing even illumination without harsh shadows. [Visual: Person fine-tuning the placement of the ring light] Narrator: Customize the lighting to match the desired atmosphere.', desc:''},

  {id:4, title:'Script 4', thumbDesc: ' [Corporate Music Playing] Narrator: Welcome to the world of captivating testimonials. Today, we’ll explore how to use a ring light to create impactful testimonial videos for your corporate clients. [Scene: Professional setting with a person sitting in front of a ring light, holding a testimonial script] Narrator: Enhancing the visual quality of your testimonials is crucial. The ring light offers a seamless solution. [Visual: Person adjusting the position of the ring light] Narrator: Position the ring light at an ideal distance, providing even illumination without harsh shadows. [Visual: Person fine-tuning the placement of the ring light] Narrator: Customize the lighting to match the desired atmosphere.', desc:''},

  {id:5, title:'Script 5', thumbDesc: ' [Corporate Music Playing] Narrator: Welcome to the world of captivating testimonials. Today, we’ll explore how to use a ring light to create impactful testimonial videos for your corporate clients. [Scene: Professional setting with a person sitting in front of a ring light, holding a testimonial script] Narrator: Enhancing the visual quality of your testimonials is crucial. The ring light offers a seamless solution. [Visual: Person adjusting the position of the ring light] Narrator: Position the ring light at an ideal distance, providing even illumination without harsh shadows. [Visual: Person fine-tuning the placement of the ring light] Narrator: Customize the lighting to match the desired atmosphere.', desc:''},
]

export const exampleStoryboard = [
  {
      img: "script1.jpg",
      title: "Shot 1",
      description: "This is the description for Script 1."
  },
  {
      img: "script2.jpg",
      title: "Shot 2",
      description: "This is the description for Script 2."
  },
  {
      img: "script3.jpg",
      title: "Shot 3",
      description: "This is the description for Script 3."
  },
  {
    img: "script2.jpg",
    title: "Shot 4",
    description: "This is the description for Script 2."
},
{
  img: "script2.jpg",
  title: "Shot 5",
  description: "This is the description for Script 2."
},
{
  img: "script2.jpg",
  title: "Shot 6",
  description: "This is the description for Script 2."
},
{
  img: "script2.jpg",
  title: "Shot 7",
  description: "This is the description for Script 2."
},
{
  img: "script2.jpg",
  title: "Shot 8",
  description: "This is the description for Script 2."
},

{
  img: "script1.jpg",
  title: "Shot 1",
  description: "This is the description for Script 1."
},
{
  img: "script2.jpg",
  title: "Shot 2",
  description: "This is the description for Script 2."
},
{
  img: "script3.jpg",
  title: "Shot 3",
  description: "This is the description for Script 3."
},
{
img: "script2.jpg",
title: "Shot 4",
description: "This is the description for Script 2."
},



];

export const videoLength = [
  {
      id: 1,
      value: 15,
      name: "15 seconds",
  },
  {
      id: 2,
      value: 30,
      name: "30 seconds"
  },
  {
      id: 3,
      value: 60,
      name: "60 seconds"
  },
  {
      id: 4,
      value: 90,
      name: "90 seconds"
  },
  {
      id: 5,
      value: 120,
      name: "120 seconds"
  },
  {
      id: 6,
      value: 180,
      name: "180 seconds"
  },
  {
    id: 7,
    value: 240,
    name: "240 seconds"
}
]

export const exportOptions = [
  {id: 1, value: "PDF"},
  {id: 2, value: "DOC"},
  {id: 3, value: "RTF"},
]

export const voiceProfiles = [
  {
    id: 1,
    name: "Corporate Communicator",
    voice: "en-US-GuyNeural",
    pitch: 10,
    rate: 0,
    style: 'friendly',
  },
  {
    id: 2,
    name: "Training Expert",
    voice: "en-US-DavisNeural",
    pitch: 10,
    rate: 0,
    style: 'hopeful',
  },
  {
    id: 3,
    name: "Social Media Influencer",
    voice: "en-US-AriaNeural",
    pitch: '0',
    rate: '10',
    style: 'excited',
  },
  {
    id: 4,
    name: "Marketing Maven",
    voice: "en-US-AriaNeural",
    pitch: 0,
    rate: 10,
    style: 'customerservice',
  },
  {
    id: 5,
    name: "Executive Leader",
    voice: "en-US-TonyNeural",
    pitch: 0,
    rate: 0,
    style: 'cheerful',
  },
]