import CustomInput from "../../eventcreation/components/CustomInput";
import { makeStyles } from "@mui/styles";
import { customTheme as theme } from "../../../theme/customTheme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FocusTrap from "@mui/base/FocusTrap";
import useValidator from "../../../hooks/useValidator";
import { userResendLink } from "../../../services/api";
import { afterValidate, setInput } from "../../../utils/commonServices";
// import useAuthRequest from "../../../hooks/useAuthRequest";
import movieIcon from '../../../assets/movieIcon.svg';
import { Box, Grid, Paper, Typography } from '@mui/material';
import UnauthLayout from '../components/UnauthLayout'
import CustomButton from "../../../components/CustomButton";
import { LoadingButton } from "@mui/lab";
import "@fontsource/rubik";
import { PRODUCT_ID } from "../../../constants/constants";
import useAuthFlow from "../hooks/useAuthFlow";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    minHeight: "100%",
  },
}));

const ResendLink = () => {

    const location = useLocation()
    const navigate = useNavigate();
    const {pageData, title, icon, btnText, message, signIn} = location.state
  //var pageData, title, icon, btnText, message, signIn ;
    // const title = 'Example'
    // const btnText = 'button'
    // const message = 'message'
    // const [pageData, setPageData] = useState({
    //   name: "",
    //   organisation_name:  "",
    //   email:  '',
    //   password: "",
    //   password_confirmation: "",
    //   product_id: process.env.REACT_APP_PRODUCT_ID
    // });
     const classes = useStyles();
     const {resendVerificationLink} = useAuthFlow()
     const pendingCallReducer = useSelector((state) => state.pendingCallReducer);
    //  const [resendLink, status] = useAuthRequest("resend-link")


  

      const resendLinkClickHandler = (event)=>{
         resendVerificationLink({email: pageData.email, product_id:  process.env.REACT_APP_PRODUCT_ID})
      }

      
      // useEffect(()=> {
      //     if(pendingCallReducer.resendLinkStatus == "verified") {
      //         navigate("/", {replace: true})
      //     }
      // }, [pendingCallReducer.resendLinkStatus])

        
  return (
    <UnauthLayout both>
        <Box className={classes.centerBox} flexDirection='column'>

<Box px={4} pb={3}>
     <Box className={classes.centerBox} mb={7} >
      {/* <CheckRoundedIcon style={{fontSize:'4rem', color:theme.palette.primary.main}}/> */}
       {/* <img src={icon ? icon : movieIcon} className={classes.movieIcon} /> */}
     </Box>

     <Box className={classes.centerBox} flexDirection='column' mb={3}>
        <Typography 
                color="primary"
                style={{
                  fontWeight: 600,
                  color:'#ffffff',
                  textAlign: 'center',
                  textTransform: 'none'
                }}
                sx={{fontSize:{xs:'30px',sm:'25px',md:'35px',lg:'41px',xl:'60px'}, fontFamily:'Rubik'}}>{title? title :''}</Typography>

        <Typography 
                color="primary"
                style={{
                  fontWeight: 300,
                  color:'#ffffff',
                  textAlign: 'center',
                  textTransform: 'none'
                }}
                sx={{fontSize:{xs:'10px',sm:'13px',md:'15px',lg:'17px',xl:'30px'}, fontFamily:'Rubik'}} >{message? message :''}</Typography>

     </Box>
     <Box sx={{width:'100%', textAlign:'center'}}>
         {/* <CustomButton  
         disabled={status == "loading"}
         // btnText="Resend Verification Link"
         btnText={btnText? btnText :'resend link'}
         variant="contained"
         color="primary"
         size="medium"
         onClick={resendLinkClickHandler}
         style={{
             fontSize: 18,
             borderRadius: 50,
              maxWidth: 370,
              minWidth: 90,
              marginTop: 30
         }}/> */}
         <Typography color="primary"
                style={{
                  fontWeight: 300,
                  color:'#ffffff',
                  textAlign: 'center',
                  textTransform: 'none'
                }}
                sx={{fontSize:{xs:'10px',sm:'13px',md:'15px',lg:'17px',xl:'30px'}, fontFamily:'Rubik'}}> If you haven't received our email, please check your spam folder or click 
           <Typography component='span' onClick={resendLinkClickHandler}
                style={{
                  fontWeight: 300,
                  color:'#00D744',
                  textDecoration: 'none', cursor:'pointer',
                  // color: theme.palette.secondary.light,
                  textAlign: 'center',
                  textTransform: 'none'
                }}
                sx={{fontSize:{xs:'10px',sm:'13px',md:'15px',lg:'17px',xl:'30px'}, fontFamily:'Rubik'}} >
             {` ${btnText}`}
          </Typography>
         </Typography>
     </Box>
 </Box>

</Box>
    </UnauthLayout>
  )
}

export default ResendLink