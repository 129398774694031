import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { errorConstant } from "../constants/constants";
import { getErrorMsg } from "../utils/validator";
import useAuthFlow from "../pages/unauth/hooks/useAuthFlow";

export default (data, page) => {
 
  const {checkExistingUser} = useAuthFlow();
  //Callback If user already exists in our database
  const checkExistingUserErrorCallback = ()=> {
    var tempArr = [];
    errorObject.map((object, index) => {
      var tempObj = { ...object };
      if (index === inputFieldIds?.findIndex((key) => key === "email")) {
        tempObj = {
          ...tempObj,
          errorFlag: true,
          error: "User already exists for this email. Please try another email!",
        };
      }
      tempArr = [...tempArr, tempObj];
    });
    setErrorObject(tempArr);
  }

  const inputFieldIds = useMemo(() => {
    return Object.keys(data);
  }, [data]);

  const inputFieldErrorObject = inputFieldIds?.map((item) => {
    return { id: item, errorFlag: false, error: "" };
  });
  const [errorObject, setErrorObject] = useState(inputFieldErrorObject);

  const checkEmptyFields = (event) => {
    var tempArr = [];
    errorObject.map((object, index) => {
      var tempObj = { ...object };
      if (
        (event.type === "click" || event.key === "Enter") &&
        data[inputFieldIds[index]] == ""
      ) {
        if (inputFieldIds[index] === "name" && page === "create_event") {
          tempObj = {
            ...tempObj,
            errorFlag: true,
            error: `Please enter ${errorConstant.event_name}`,
          };
        } else {
          tempObj = {
            ...tempObj,
            errorFlag: true,
            error: `Please enter ${errorConstant[tempObj.id]}`,
          };
        }
      } else {
        if (
          index == inputFieldIds.findIndex((item) => item == event.target.id)
        ) {
          tempObj = { ...tempObj, errorFlag: false, error: "" };
        } else if (
          data[inputFieldIds[index]] == "" &&
          index < inputFieldIds.findIndex((item) => item == event.target.id)
        ) {
          if (inputFieldIds[index] === "name" && page === "create_event") {
            tempObj = {
              ...tempObj,
              errorFlag: true,
              error: `Please enter ${errorConstant.event_name}`,
            };
          } else {
            tempObj = {
              ...tempObj,
              errorFlag: true,
              error: `Please enter ${errorConstant[tempObj.id]}`,
            };
          }
        }
      }
      tempArr = [...tempArr, tempObj];
    });

    setErrorObject(tempArr);
  };

  //Function to check if a the entered value is valid
  const checkValue = (event) => {
    var tempArr = [];
    if (event.type === "click" || event.key === "Enter") {
      errorObject?.map((object, index) => {
        var tempObj = { ...object };

        // console.log("password", getErrorMsg(event.target.id, data[inputFieldIds[index-1]], data[inputFieldIds[index]]))
        if (object.id == "password_confirmation" || object.id == "end_date") {
          if (
            getErrorMsg(
              object.id,
              data[inputFieldIds[index - 1]],
              data[inputFieldIds[index]]
            ) != ""
          ) {
            tempObj = {
              ...tempObj,
              errorFlag: true,
              error: getErrorMsg(
                object.id,
                data[inputFieldIds[index - 1]],
                data[inputFieldIds[index]]
              ),
            };
          }
        } else if (
          (page !== "signin" && page !== "forgot-password") &&
          object.id === "email" &&
          getErrorMsg(object.id, data[inputFieldIds[index]]) == ""
        ) {
            checkExistingUser({email: data[inputFieldIds[index]], product_id: process.env.REACT_APP_PRODUCT_ID}, checkExistingUserErrorCallback)
          //setEmailToCheck(data[inputFieldIds[index]]);
          // setCheckEmailEnable(true);
        } else if (getErrorMsg(object.id, data[inputFieldIds[index]]) != "") {
          tempObj = {
            ...tempObj,
            errorFlag: true,
            error: getErrorMsg(object.id, data[inputFieldIds[index]]),
          };
        }

        tempArr = [...tempArr, tempObj];
      });
    } else {
      // var tempArr = [];
      errorObject?.map((object, index) => {
        var tempObj = { ...object };
        if (
          index == inputFieldIds.findIndex((item) => item == event.target.id)
        ) {
          // console.log("password", getErrorMsg(event.target.id, data[inputFieldIds[index-1]], data[inputFieldIds[index]]))
          if (
            event.target.id == "password_confirmation" ||
            event.target.id == "end_date"
          ) {
            if (
              getErrorMsg(
                event.target.id,
                data[inputFieldIds[index - 1]],
                data[inputFieldIds[index]]
              ) != ""
            ) {
              tempObj = {
                ...tempObj,
                errorFlag: true,
                error: getErrorMsg(
                  event.target.id,
                  data[inputFieldIds[index - 1]],
                  data[inputFieldIds[index]]
                ),
              };
            }
          } else if (
            (page !== "signin" && page !== "forgot-password") &&
            event.target.id === "email" &&
            getErrorMsg(event.target.id, event.target.value) == ""
          ) {
             checkExistingUser({email: data[inputFieldIds[index]], product_id: process.env.REACT_APP_PRODUCT_ID}, checkExistingUserErrorCallback)
          } else if (
            getErrorMsg(event.target.id, data[inputFieldIds[index]]) != ""
          ) {
            tempObj = {
              ...tempObj,
              errorFlag: true,
              error: getErrorMsg(event.target.id, data[inputFieldIds[index]]),
            };
          }
        }
        tempArr = [...tempArr, tempObj];
      });
    }
    setErrorObject(tempArr);
  };

  // console.log(errorObject);

  return [errorObject, checkEmptyFields, checkValue];

  /*1. errorObject contains an array of objects {id: <String>, errorFlag: <Boolean>, error: <String}.
      each such object represents an input field from top to bottom
   2. checkEmptyFields function takes event object that calls it from parent component and checks
      if all the previous inputs are empty or not. if found empty, it will update the respective
      error properties of the input fields.
   3. checkValue function takes event object that calls it from parent component and checks if that
      input value is valid as per client side rules or not. If invalid, it will update the error Object
      properties for that field.
   4. checkExistingUser takes an email address in string form and checks on the server database 
      for existing user. If user is found, it will update the error object for email field
      to ask for another mail address.
   
        
 */
};
