import { Box } from '@mui/material';
import React, { useRef, useEffect } from 'react';

const ScrollToBottom = () => {
  const divRef = useRef(null);

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: 'smooth' });
  });

  return <Box sx={{backgroundColor: '#AA0000', margin: '-8px', padding: '0px'}} height='0px' ref={divRef} />;
}

export default ScrollToBottom