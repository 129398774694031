import { Box, Toolbar, AppBar, Grid, IconButton, Container } from "@mui/material"
import * as React from 'react';
// import luciLogo from '../../assets/Luci-Logo.png';
import luciLogo from '../../assets/luciLogo2.png';


const ErrorHeader = () => {

    
    return(<Box >
        <AppBar position="absolute" sx={{ paddingX:{xs:'1vw',sm:'2vw',md:'4vw',lg:'6vw',xl:'6vw'}, backgroundColor:'transparent', paddingTop:{xs:'20px',sm:'25px',md:'25px',lg:'28px',xl:'48px'}}} elevation={0} > 
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{height:{xs:80 }, display: "flex", justifyContent: { xs: "space-between", sm: "space-between"}}} >
                    <Box mr={2} sx={{height: 80, display: {xs: 'flex', md: 'flex'}, alignItems: 'center'}} >
                        <Box sx={{height:{xs:'20px',md:'25px',lg:'30px',xl:'44px'}}}>
                            <img src={luciLogo} height= '100%' />
                        </Box>
                            
                    </Box>
                    {/* <Box mr={2} sx={{height: 120, display: {xs: 'none', md: 'flex'}}} >
                        <img src={luciLogo} height= '120px' />
                    </Box> */}
                    
                </Toolbar>
            </Container>
        </AppBar>
    </Box>)

    // return(<Box >
    //     <AppBar sx={{px: {xs: '24px', lg: '40px'}}} position="absolute" style={{backgroundColor: "#FFFFFF"}}> 
           
    //             <Toolbar disableGutters sx={{ height:{xs:80, lg: 80 }, display: "flex", justifyContent: 'space-between'}}>
    //                 <Box mr={2} sx={{height: 80, display: {xs: 'flex', lg: 'flex'}, alignItems: 'center' }} >
    //                     <img src={luciLogo} height= '48px' />
    //                 </Box>
    //                 {/* <Box mr={2} sx={{height: 120, display: {xs: 'none', lg: 'flex'}}} >
    //                     <img src={luciLogo} height= '120px' />
    //                 </Box> */}
                    
    //                 <Box>
    //                     <Grid container sx={{ display: "flex"}} spacing={2}>
    //                     <IconButton
    //                         // color="inherit"
    //                             aria-label="open drawer"
    //                             edge="end"
    //                            // onClick={() =>  openDrawer((last)=> !last) }
    //                             sx={{ display: { xs: 'flex', md: 'none' } }}
    //                         >
                                
    //                         </IconButton>
                        
    //                    {/* <CustomUserCard sx = {{display: {xs: 'none', md: 'flex'}}} /> */}
                           
    //                         <Grid item>
    //                             <IconButton >
    //                                 </IconButton>
    //                         </Grid>
                                
    //                     </Grid>
    //                 </Box>
    //             </Toolbar>
           
    //     </AppBar>
    // </Box>)
}


export default ErrorHeader