import { Box, IconButton, Slider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ScriptInputBox from "../ScriptInputBox";
import CustomAutocomplete from "../../../eventcreation/components/CustomAutoComplete";
import TextToSpeech from "../../../eventcreation/components/voiceOverProject/utils/TextToSpeech";
import useTextToSpeech from "./hooks/useTextToSpeech";
import CustomButton from "../../../../components/CustomButton";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CustomTooltip from "../../../../components/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import { updateVoiceState } from "../../../../redux/voiceSlice";
import useTtsSample from "./hooks/useTtsSample";
import { azureLanguageNames, voicePitchRateMarks } from "../../../../constants/constants";
import QuantityInput from "../../../../components/QuantityInput";
import AdvancedSettingView from "./AdvancedSettingView";
import VoiceProfileView from "./VoiceProfileView";
import { reduceWords, removeHtmlTags } from "../../../../utils/commonServices";
import VoiceOverProjectCreation from "../../../eventcreation/components/voiceOverProject/VoiceOverProjectCreation";
import VoiceProfileCreationPopup from "../voiceProfile/components/VoiceProfileCreationPopup";

const VoiceStudio = (props) => {
  // const { audioRef, handleConvertTextToSpeech, pauseSpeech, playSpeech } = useTextToSpeech()
  // const { sampleRef, handleGenerateVoiceSample, pauseSample, playSample } = useTtsSample()
  const {audioRef, handleConvertTextToSpeech, pauseSpeech, playSpeech, 
         sampleRef, handleGenerateVoiceSample, pauseSample, playSample, generateSpeechClickHandler,  
         playSampleHandler, commonChangeHandler } = props
  const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer)
  const voiceReducer = useSelector((state) => state.voiceReducer);
  const contentReducer = useSelector((state) => state.contentReducer);
  const dispatch = useDispatch();

  // const generateSpeechClickHandler = () => {
  //   pauseSample()
  //   dispatch(updateVoiceState({ is_sample_playing: false, is_speech_playing: true, sample_url: '' }))
  //   handleConvertTextToSpeech()
  // }

  // const playSampleHandler = () => {
  //   if (voiceReducer?.sample_url == '') {
  //     handleGenerateVoiceSample()
  //   }
  //   if (voiceReducer?.is_sample_playing) {
  //     pauseSample()
  //   } else if (voiceReducer?.sample_url != '') {
  //     playSample()
  //   }

  //   dispatch(updateVoiceState({ is_sample_playing: !voiceReducer?.is_sample_playing }))

  // }

  // const commonChangeHandler = () => {
  //   pauseSpeech();
  //   pauseSample();
  //   audioRef.current.src = "";
  //   sampleRef.current.src = "";
  //   dispatch(updateVoiceState({
  //     sample_url: "",
  //     audio_url: "",
  //     is_speech_playing: false,
  //     is_sample_playing: false,
  //   }))
  // }
  console.log("from voice studio", voiceReducer?.selected_voice)

  useEffect(()=> {
   // commonChangeHandler()
  }, [contentReducer?.editor_text])

  const voiceProfileViewClickHandler = ()=> {
    commonChangeHandler()
    dispatch(updateVoiceState({
      voice_profile_view: true, 
      sample_text: voiceReducer?.voice_profiles?.find(item => item.id == voiceReducer?.selected_voice_profile && voiceReducer?.is_selected_default == !!item.is_default_profile)?.script, 
      selected_voice_profile: voiceReducer?.selected_voice_profile == 0 ? 1 : voiceReducer?.selected_voice_profile ,
      is_selected_default: voiceReducer?.selected_voice_profile == 0 ? true : voiceReducer?.is_selected_default
    }))
  }
  const advancedSettingsViewClickHandler = ()=> {
    commonChangeHandler()
    dispatch(updateVoiceState({
      profile_name: "", 
      profile_description: "",
      voice_profile_view: false,  
     // sample_text: removeHtmlTags(reduceWords(contentReducer?.editor_text, 30)),
     
    }))
  }

  // return <Box>
  //   {/* <ScriptInputBox/> */}
  //   <Box className='flex row j-between a-center' >
  //   <Box className="flex row j-center a-center" sx={{minWidth: { sm: '230px', md:'270px', lg:'300px'}}} >
  //     <Box className='flex row j-between a-center'  >
  //       <CustomAutocomplete
  //         id="voices"
  //         options={dropdownReducer?.voices}
  //         sx={{ minWidth: { sm: '200px', md:'240px', lg:'270px'}, }}
  //         // onFocus={() => {
  //         //   setScriptLengthValidationFlag(false)
  //         // }}
  //         // onBlur={() => setExportState({enable: false, formatId: ''})}
  //         // required
  //         // errorFlag={scriptLengthValidationFlag && getErrorMsg("script_length", projectLength, exportAs?.id) != ""}
  //         // errorMsg={getErrorMsg("script_length", projectLength, exportAs?.id)}
  //         // boldError
  //         value={voiceReducer?.selected_voice}
  //         disabled= {voiceReducer?.is_generating_speech}
  //         onChange={(event, selection) => {
  //           //!!voiceReducer?.is_sample_playing && pauseSample();
            
  //           commonChangeHandler()
  //           dispatch(updateVoiceState({
  //             selected_style: null,
  //             selected_accent: null,
  //             selected_voice: selection
  //           }))
  //           !!selection?.StyleList ? dispatch(updateVoiceState({ styles: selection?.StyleList })) : dispatch(updateVoiceState({ styles: [] }))
  //           !!selection?.SecondaryLocaleList ? dispatch(updateVoiceState({ accents: selection?.SecondaryLocaleList })) : dispatch(updateVoiceState({ accents: [] }))

  //         }}
  //         label=""
  //         placeholder='Select Voice'
  //         getOptionLabel={(option) =>
  //           `${option.DisplayName} - ${option.LocaleName} - ${option.Gender}`
  //         }
  //         msgColor='red'
  //       />


  //     </Box>
  //     <CustomTooltip title={voiceReducer?.is_sample_playing ? 'Pause sample' : 'Play sample'} > 
  //       <IconButton 
  //         disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample} 
  //         onClick={playSampleHandler} 
  //       >
  //         <VolumeUpIcon style={{ color: voiceReducer?.is_sample_playing ? '#00ceff' : '#ffffff', fontSize: '32px' }} />
  //       </IconButton>
  //     </CustomTooltip>

  //     <audio ref={sampleRef} aria-disabled={!!voiceReducer?.sample_url}></audio>
  //    </Box>

  //     <Box sx={{ minWidth: { sm: '200px', md:'220px', lg:'250px'} }} >
  //       <CustomAutocomplete
  //         id="style_list"
  //         disabled={voiceReducer?.styles?.length == 0}
  //         options={voiceReducer?.styles}
  //         value={voiceReducer?.selected_style}
  //         onChange={(event, selection) => {
  //           commonChangeHandler()
  //           dispatch(updateVoiceState({
  //             selected_accent: null,
  //             selected_style: selection
  //           }))
  //         }}
  //         label=""
  //         placeholder='Select Style'
  //         getOptionLabel={(option) =>
  //           `${option}`
  //         }
  //         msgColor='red'
  //       />
  //     </Box>
  
  //     <Box sx={{ minWidth: { sm: '200px', md:'220px', lg:'250px'} }} >
  //       <CustomAutocomplete
  //         id="accent_list"
  //         disabled={voiceReducer?.accents?.length == 0}
  //         options={voiceReducer?.accents}
  //         value={voiceReducer?.selected_accent}
  //         onChange={(event, selection) => {
  //           commonChangeHandler();
  //           dispatch(updateVoiceState({
  //             selected_accent: selection,
  //           }))
  //         }}
  //         label=""
  //         placeholder='Select Accent'
  //         getOptionLabel={(option) =>
  //           `${!!azureLanguageNames[option] ? azureLanguageNames[option] : ""}`
  //         }
  //         msgColor='red'
  //       />
  //     </Box>
      
      
  //   </Box>
  //   <Box className='flex row j-between a-center' sx={{width: '100%'}} >
  //   <Box className='flex col j-center' sx={{ gap: 2, mt: 2, mb: 1, width: '45%', }} >
  //     <Box width='100%' gap={3} className='flex row j-between a-center' >
  //       <Typography sx={{ color: "#fff" }} >Pitch:</Typography>
  //       <Slider
  //         sx={{ color: "#fff", }}
  //         aria-label="Pitch"
  //         //defaultValue={0}
  //         value={voiceReducer?.pitch}
  //        // getAriaValueText={(value) => `${value}%`}
  //         valueLabelDisplay="auto"
  //         valueLabelFormat={(value)=> `${value}%` }
  //         disabled= {voiceReducer?.is_generating_speech}
  //         // shiftStep={30}
  //         onChange={(e) => {
  //           commonChangeHandler();
  //           dispatch(updateVoiceState({ pitch: e.target.value }))
  //         }}
  //         step={10}
  //         marks
  //         min={-50}
  //         max={50}
  //       />
  //        <QuantityInput 
  //           min={-50} 
  //           max={50} 
  //           disabled= {voiceReducer?.is_generating_speech}
  //           //defaultValue={0}
  //           value={voiceReducer?.pitch} 
  //           onChange={(event, value)=> {
  //               commonChangeHandler()
  //               dispatch(updateVoiceState({pitch: value}))}
  //             } 
  //           shiftMultiplier={10} 
  //         />
  //     </Box>
  //     <Box width='100%' gap={3} className='flex row j-between a-center' >
  //       <Typography sx={{ color: "#fff" }} >Rate:</Typography>
  //       <Slider
  //         sx={{ color: "#fff" }}
  //         aria-label="Rate/Speed"
  //         //defaultValue={0}
  //         //aria-valuetext={`${voiceReducer?.rate} %`}
  //         valueLabelDisplay="auto"
  //         valueLabelFormat={(value)=> `${value}%` }
  //         value={voiceReducer?.rate}
  //         onChange={(e) => {
  //           commonChangeHandler();
  //           dispatch(updateVoiceState({ rate: e.target.value }))
  //         }}
  //         //getAriaValueText={(value) => `${voiceReducer?.rate} %`}
  //         //valueLabelDisplay="auto"
  //         //shiftStep={30}
  //         step={10}
  //         disabled= {voiceReducer?.is_generating_speech}
  //         marks
  //         min={-50}
  //         max={50}
  //       />
  //       <QuantityInput 
  //         min={-50} 
  //         max={50} 
  //         disabled= {voiceReducer?.is_generating_speech}
  //         //defaultValue={0}
  //         value={voiceReducer?.rate} 
  //         onChange={(event, value)=> {
  //           commonChangeHandler()
  //           dispatch(updateVoiceState({rate: value}))
  //         }} 
  //         shiftMultiplier={10} 
  //       />
  //     </Box>
  //   </Box>

  //   <Box className='flex col j-center a-center' sx={{gap: 2, mt: 2, mr: 3}} >
  //     {/* <audio ref={audioRef} aria-disabled={!voiceReducer?.audio_url} style={{ height: '32px' }} controls></audio> */}
  //     <CustomButton
  //       btnText={voiceReducer?.is_generating_speech ? 'Please wait...' : 'Create Voice Clip'}
  //       // disabled = {isDisabled || (userReducer.user_name != userReducer.event_details?.owner_name)}
  //       onClick={generateSpeechClickHandler}
  //       disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || !voiceReducer?.selected_voice || contentReducer?.editor_text == ""}
  //       type='button'
  //       style={{
  //         borderRadius: '5px',
  //         border: "none",
  //         textTransform: 'none',
  //         backgroundColor: '#00CEFF',
  //       }}
  //       sx={{ fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, fontWeight: 600, height: { xs: '18px', sm: '20px', md: '22px', lg: '24px', xl: "32px" }, fontFamily: 'Rubik', width: { xs: '80px', md: '100px', lg: '130px', xl: '160px' } }}
  //     />
  //   </Box>

  //   </Box>
  // </Box>
  return <Box >
    {
      voiceReducer?.voice_profile_view ? <>
      
        <Typography sx={{color: '#ffffff'}} >
          Click here for 
          <CustomTooltip title='Ready-to-use voices with various accents and tones, customizable to fit your needs.' >
          <Typography component='span' sx={{textDecoration: 'underline', cursor: 'pointer', ml:'4px'}} onClick={advancedSettingsViewClickHandler} >
            Voice Library
          </Typography>
          </CustomTooltip>
        </Typography>
        <VoiceProfileView {...props} />
        {
            voiceReducer?.voice_profile_creation_popup && <VoiceProfileCreationPopup {...props} />
          }
      </> : <>
        <Typography sx={{color: '#ffffff'}} >
            Click to choose from
            <CustomTooltip title='Choose from saved voice profiles for consistent voiceover settings across projects.' >
              
            <Typography component='span' sx={{textDecoration: 'underline', cursor: 'pointer', ml:'4px'}} onClick={voiceProfileViewClickHandler} >
              My Voices
            </Typography>
            </CustomTooltip>
          </Typography>
          <AdvancedSettingView {...props} />
          {
            voiceReducer?.voice_profile_creation_popup && <VoiceProfileCreationPopup {...props} />
          }
      </>
    }
   
    
  </Box>
}

export default VoiceStudio;