import React, { useEffect, useState } from "react";
import CustomInput from '../CustomInput';
import CustomAutocomplete from '../CustomAutoComplete';
// import UpIcon from '../icons/UpIcon';
// import DownIcon from '../icons/DownIcon';
import { updateProjectCreationState } from '../../../../redux/projectCreationSlice';
import CheckIcon from '../../../../components/icons/CheckIcon';
import CustomRadioButton from '../CustomRadioButton'
import LeftIcon from '../../icons/LeftIcon';
import RightIcon from '../../icons/RightIcon';
import { useDispatch, useSelector } from 'react-redux';
import { afterValidate } from '../../../../utils/commonServices';
import { getErrorMsg } from '../../../../utils/validator';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { imageStyleList, personaList, videoLength, wordLengthList } from '../../../../constants/staticData';
import { Backdrop, Box, Grid, Slide, Typography, useMediaQuery } from "@mui/material";
import CustomScrollable from "../CustomScrollableX";
import { imageStyleThumbs } from "../../../../constants/constants";

const ImageStyleComponent = (props)=> {
    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex,  closeHandler, totalScreens} = props
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
    const [wordLimit, setWordLimit] = useState()
    const dispatch = useDispatch()
    const [scriptLengthValidationFlag, setScriptLengthValidationFlag] = useState(false)
    const commonReducer = useSelector((state) => state.commonReducer);
    const isListView = commonReducer?.list_view
    const mobileView = useMediaQuery('(max-width:599px)');

     
      const finishedHandler = ()=> {
        validateInputs(true)
        afterValidate(nextPageHandler);
      }
  
      const validateInputs = (flag) => {
        setScriptLengthValidationFlag(flag)
      }
  
    //   const selectedScriptLength = (id) => {
    //     if ((createProjectReducer.imagePageData.projectTypeId) > 3) {
    //       return id;
    //     } else if (!!videoLength?.find((item) => item.value == id)) {
    //       return videoLength?.find((item) => item.value == id);
    //     } else {
    //       return {};
    //     }
    //   };
  
      useEffect(() => {
        const keyDownHandler = (event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            !scriptReducer.open_delete_popup && finishedHandler();
          }
        };
          document.addEventListener("keydown", keyDownHandler)
        return () => {
          document.removeEventListener("keydown", keyDownHandler);
        };
      }, [createProjectReducer.imagePageData.style, scriptReducer.open_delete_popup]);
      const handleRadioChange = (event) => {
        dispatch(updateProjectCreationState({imagePageData: {...createProjectReducer.imagePageData, style: event.target.value}}))
      };
    return  <>
    {/* <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createProjectReducer.open_backdrop_project_creation}
    > */}
     <Box sx={{ width: isListView ? '100%' : '90%', height:'100%', display:'flex', flexDirection:'column'}}>
       {!(!isListView || contentReducer?.create_content_open) && <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3}} onClick={closeHandler}>Close X</Typography>}
    <Box flexGrow={1} className='flex col ' sx={{ paddingLeft:isListView ? '5vw' :'14vw', marginTop: isListView ?{xs:'6%',sm:'4%', md:'3%', xl:'4%'}: {xs:'8%',sm:'6%', md:'4%', xl:'6%'}}}>
        <Box mb={{xs:1, sm:2}}>
          <Typography sx={{fontSize: isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} :{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
          <Typography sx={{fontSize:isListView ? {xs:'20px',sm:'22px',md:'26px',lg:'35px',xl:'52px'} :{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik'}}>Select a style for your image.</Typography>
          <Typography sx={{fontSize: isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} :{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>Select one.</Typography>
        </Box>
        {/* <Box className='flex row j-start'> */}
        {/* <ArrowBackIosNewIcon /> */}
            {/* <Box className='flex j-start' component='div'  gap={4} sx={{ width: '90%', overflowY:'scroll'}}> */}
                
                {/* <Grid container mt={3} ml={{xs: 3, sm: 0, md:1}} gap={{xs:1, sm:0.5, md:0.5}}> */}
                < Box width='90%' className= 'flex row j-start'>
                <CustomScrollable scrollBy={172} >
                    {
                    imageStyleList?.map((type, index)=>{
                        return  <Box>
                        <CustomRadioButton
                        // disabled={(!scriptReducer?.enable_story && type.name == 'storyboard') || type.name == 'avatar' || type.name == 'voice over'}
                        label='image'
                        id={type.id}  
                        name='imageStyle' 
                        handleRadioChange={handleRadioChange}
                        height={isListView ? {xs:'100px',lg:'120px', xl:'180px'} :{xs:'120px',lg:'140px', xl:'200px'}}
                        width={isListView ? {xs:'100px',lg:'120px', xl:'180px'} :{xs:'120px',sm:'140px', xl:'200px'}}
                        radioSize = {0}
                        selectedValue={createProjectReducer.imagePageData?.style}
                    //  setSelectedValue={setPageData}
                        component={
                        <Box className='flex col a-center j-start ' sx={{width: '100%', height: '100%'}} gap={2}>
                           
                        <Box sx={{width:isListView ? {xs:'100px',lg:'120px', xl:'180px'} :{xs:'120px',sm:'140px', xl:'200px'}, height:isListView ? {xs:'100px',lg:'120px', xl:'180px'} :{xs:'120px',lg:'140px', xl:'200px'}}}><img style={{height: '100%', width: '100%', borderRadius: '10px'}} src={imageStyleThumbs[index]}/></Box>
                        </Box>}
                    />
                     <Typography sx={{color:'#FFFFFF', fontSize:'14px', textAlign: 'center', paddingTop: '12px', fontWeight: 600}}>{type.name}</Typography>
                    </Box>
                    })
                    }
                </CustomScrollable>
                </Box>
                     
                {/* </Grid> */}
                
            {/* </Box> */}
            {/* <ArrowForwardIosIcon /> */}
        {/* </Box> */}
       
    </Box>
    <Box className='flex' sx={{justifyContent:'flex-end', mb:3, mr:3}}>
      <LeftIcon sx={{opacity: pageIndex === 1 && 0.5, fontSize:isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}: {xs:'45px', sm:'55px', lg:'65px',xl:'82px'}, cursor:'pointer'}} onClick={pageIndex !== 1 ? prevPageHandler : null}/>
      <RightIcon sx={{ cursor:'pointer', fontSize:isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}}   onClick={pageIndex !== totalScreens ? finishedHandler : null}/>
    </Box>
  </Box>
  {/* </Backdrop> */}
  {/* </Slide> */}
  </>
  
}

export default ImageStyleComponent