import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, IconButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
   // position: 'relative',

  },
  button: {
   // position: 'absolute',
   // top: '50%',
   // transform: 'translateY(-50%)',
   // zIndex: 1,
  },
  backButton: {
   // left: 0,
  },
  forwardButton: {
   // right: 0,
  },
}));

const CustomScrollable = ({ children, scrollBy }) => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(true);
  const [showForwardButton, setShowForwardButton] = useState(false);
  const [disableForwardButton, setDisableForwardButton] = useState(true);

//   const handleScroll = () => {
//     const container = containerRef.current;
//     if (container) {
//       setShowBackButton(container.scrollLeft > 0);
//       setShowForwardButton(
//         container.scrollLeft < container.scrollWidth - container.clientWidth
//       );
//     }
//   };

useEffect(()=> {
    const container = containerRef.current;
    if(container?.scrollWidth > container?.clientWidth) {
        setShowBackButton(true)
        setShowForwardButton(true)
    } else {
        setShowBackButton(false)
        setShowForwardButton(false)
    }
    console.log("container", container?.clientWidth)
    console.log("content", container?.scrollWidth)
}, [containerRef.current])

useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
        console.log("scroll left",container.scrollLeft)
        console.log("scroll right",container.scrollLeft < container.scrollWidth - container.clientWidth - 2)
            if(container.scrollLeft > 0) {
               
                setDisableBackButton(false)
            } else {
                setDisableBackButton(true)
            }
           
            if(container.scrollLeft < container.scrollWidth - container.clientWidth - 2) {
                setDisableForwardButton(false) 
            } else {
                setDisableForwardButton(true)
            }
            
            
        } 
   
    
    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

//   useEffect(() => {
//     const container = containerRef.current;
//     if (container) {
//       container.addEventListener('scroll', handleScroll);
//       handleScroll(); // Initial check
//       return () => {
//         container.removeEventListener('scroll', handleScroll);
//       };
//     }
//   }, []);

  const handleBackButtonClick = () => {
    const container = containerRef.current;
    if (container) {
        container.scrollTo({
            left: container.scrollLeft - scrollBy,
            behavior: 'smooth' // Enable smooth scrolling animation
          });
      //container.scrollLeft -= scrollBy;
    }
  };

  const handleForwardButtonClick = () => {
    const container = containerRef.current;
    if (container) {
        container.scrollTo({
            left: container.scrollLeft + scrollBy,
            behavior: 'smooth' // Enable smooth scrolling animation
          });
     // container.scrollLeft += scrollBy;
    }
  };

  return ( 
//   < Box width='90%' className= 'flex row j-start'>
<>
   {showBackButton && (
        <IconButton
         // className={`${classes.button} ${classes.backButton}`}
         disableRipple
          onClick={handleBackButtonClick}
          disabled = {disableBackButton}
          sx={{color: '#FFFFFF'}} 
        >
          <ArrowBackIosNewIcon sx= {{marginBottom: '32px'}}/>
        </IconButton>
      )}
    <Box component='div' sx={{    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row !important',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '32px', padding: '24px'}}  ref={containerRef}>
      
     
      {children}
      
    </Box>
    {showForwardButton && (
        <IconButton
        disableRipple
          //className={`${classes.button} ${classes.forwardButton}`}
          onClick={handleForwardButtonClick}
          disabled = {disableForwardButton}
          sx={{color: '#FFFFFF'}}
        >
          <ArrowForwardIosIcon  sx= {{marginBottom: '32px'}} />
        </IconButton>
      )}
    {/* // </Box>  */}
    </>
    );
};

export default CustomScrollable;
