import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { fontSize } from "@mui/system";
import { useEffect, useState } from "react";
import { customTheme as theme } from "../../../theme/customTheme";
import "@fontsource/rubik";
import { useSelector } from "react-redux";
import SendIcon from "../icons/SendIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    // border: 2,
    // borderColor: theme.palette.primary.light,
    // fontSize: 20,
    // fontWeight: 400,
    // color: theme.palette.primary.light,
    '& .MuiOutlinedInput-notchedOutline': {
      // borderColor: 'rgba(0, 0, 0, 0.23)',
      outline:'none'
    },
    '&.Mui-error ': {
      // borderColor: 'rgba(0, 0, 0, 0.23)',
      outline:'none'
    },
    // "& .MuiInputBase-input": {
    //   // border: "3px solid red",

    //   fontSize: 13,
    //   fontWeight: 400,
    //   color: theme.palette.inputs.main,
      
    // },
    // "&  .MuiInput-formControl": {
    //   borderBottom: "2px solid rgba(0, 75, 100, 1)",
    // },
  },
}));

const CustomInput = (props) => {
  const {
    type,
    label,
    txtStyle,
    max,
    minValue,
    maxValue,
    shrink,
    errorFlag,
    errorMsg,
    msgColor,
    required,
    bottom = -15,
    light,
    value,
    onButtonClick,
    buttonDisabled,
    medium,
    boldError,
    labelFlag
  } = props;
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const reviewEventReducer = useSelector((state) => state.reviewEventReducer);
  const [showLabel, setShowLabel] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword((value) => !value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(()=> {
    if(!!value) {
      setShowLabel(true)
    } else {
      setShowLabel(false)
    }
  }, [value])

  // console.log({errorFlag,  type, errorMsg}, props.id)
  
  // console.log(medium)
  return (
    <>
     {
      !!label && <Typography sx={{fontSize: '12px', color: '#ffffff', fontWeight: 600}}>{(showLabel || labelFlag )&& label}</Typography>
     }
      
      <FormControl
      id="input-control"
        error={!msgColor && errorFlag}
        variant="outlined"
        fullWidth
        style={{  marginBottom: '12px' ,backgroundColor:'white', borderRadius:'5px'}}
      >
        {/* <InputLabel shrink={shrink && shrink} htmlFor={props.label}>
          {label}
        </InputLabel> */}
        <TextField
          {...props}
          label = ""
          value={value}
          size={medium ? 'medium': 'small'}
          style={{backgroundColor:'white',  }}
          sx={{
            // marginBottom: "1rem",
            // padding:0,
            // backgroundColor:'white',
            borderRadius:'5px',
            border:'1px solid #08231B',
            position:'relative',
            input: {
              fontSize: medium ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'8px',lg:'9px',xl:'13px'},
              fontWeight: 600,
              fontFamily: 'Rubik',
              color:'#08231B',
              // border:'1px solid #08231B',
              "&::placeholder": {    // <----- Add this.
                opacity:light == true ? 0.4 : 1,
            },           
              // backgroundColor:'white'
            //   color: theme.palette.inputs.light,
            },
          }}
          //  id={props.label}
          type={showPassword ? "text" : type}
          fullWidth
          title=""
          // error={errorFlag}
          // aria-describedby="input-error"
          variant="outlined"
          // className={`${classes.root} ${txtStyle}`}
          inputProps={{
            fullWidth: true,
            maxLength: max && max,
            min: minValue && minValue,
            max: maxValue && maxValue,
          }}
          InputProps={{
          endAdornment: (
            type == "password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  tabIndex="-1"
                  size="small"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ): type == 'timestamp' ? (
              <InputAdornment position="end">
                <Typography sx={{ fontSize: {xs:'9px',lg:'10px',xl:'14px'}, fontWeight:600}}>{reviewEventReducer.timestamp}</Typography>
              </InputAdornment>
            )  : type === 'sendInput' ? (
                <InputAdornment position="end">
                            <IconButton
                              onClick={onButtonClick}
                              // onMouseDown={handleMouseDownPassword}
                              disabled={buttonDisabled}
                              tabIndex="-1"
                              size="small"
                            >
                              <SendIcon sx={{ fontSize: medium ? {xs:'30px',lg:'32px',xl:'38px'} : {xs:'18px',lg:'20px',xl:'26px'}, opacity: buttonDisabled === true ? 0.7: 1}}/> 
                            </IconButton>
                        </InputAdornment>  
            ) : <></>
        ),
      }}
          // endAdornment={
          //   type == "password" && (
          //     <InputAdornment position="end">
          //       <IconButton
          //         aria-label="toggle password visibility"
          //         onClick={handleClickShowPassword}
          //         onMouseDown={handleMouseDownPassword}
          //         tabIndex="-1"
          //         size="small"
          //       >
          //         {showPassword ? <VisibilityOff /> : <Visibility />}
          //       </IconButton>
          //     </InputAdornment>
          //   )
          // }
        ></TextField>
        {required && errorFlag && (
          <Typography
            variant="body1"
            className="errorDom"
            component="span"
            sx={{
              fontFamily: 'Rubik',
              color: msgColor ? msgColor : "rgb(211, 47, 47)",
              fontSize: (medium || boldError) ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'6px',lg:'8px',xl:'13px'},
              position:'absolute',
              right:0,
              bottom: {bottom},
              //top: '8px',
              fontWeight : msgColor && 600
               //paddingTop: 20,
            }}
          >
            <Box textAlign='right'>{errorMsg}</Box>
          </Typography>
        )}
        {/* <FormHelperText id="input-error">{errorMsg}</FormHelperText> */}
      </FormControl>
    </>
  );
};

export default CustomInput;

