//import { apiBaseUrl } from "../constants/constants";
export const apiUrlAuth = `${process.env.REACT_APP_AUTH_API_URL}/api`;
export const apiUrlBase = `${process.env.REACT_APP_API_URL}/api`;
//optional layer to add different environments of server
 export const apiUrlPrefix = `${apiUrlAuth}/v1`;
 export const apiUrlPrefix2 = `${apiUrlAuth}/v2`;

////creative director base url
// export const baseUrl = `https://amscdapi.azure-api.net/api`

//main objects for different api urls
export const apiConstant = {
    // testApi: apiUrl + "/getvimeo",
    // session: {
    //     checkSessionValidity: apiUrlPrefix + "/check_session"
    // },
    user: {
        signup: apiUrlPrefix + "/sign_up",
        checkEmail: apiUrlPrefix + "/check_email",
        signin: apiUrlPrefix + "/sign_in",
        forgotPassword: apiUrlPrefix + "/forgot_password_mail",
        checkVerificationToken: apiUrlPrefix + "/check_token_validity",
        verifyUserMail: apiUrlPrefix + "/verify_token",
        resestPassword: apiUrlPrefix + "/reset_password",
        resendLink: apiUrlPrefix + "/resend_link",
        deleteUser: apiUrlPrefix2 + "/users", 
        verifyAppleUser: apiUrlPrefix2 + "/users/verify_user",
        appleSign: apiUrlPrefix2 + "/users",
        userRole: apiUrlPrefix2 + "/users/user_role",
        checkButterflyAccess: apiUrlPrefix + "/butterfly_access",
        checkSession: apiUrlPrefix + "/check_session"
    },

    // event: {
    //     create : apiUrlPrefix2 + "/events",
    //     cancelEvent: apiUrlPrefix + "/events/cancel",
    //     uploadVideo: apiUrlPrefix + "/upload",
    //     uploadList: apiUrlPrefix + "/media_files",
    //     addEvent: apiUrlPrefix2 + "/events/add_collab_event",
    //     downloadMedia: apiUrlPrefix + "/media_files/download",
    //     favoriteMedia: apiUrlPrefix + "/media_files/favourite",  
    //     guestEvent: apiUrlPrefix2 + "/events/guest_detail",
    //     acceptFinalVideo: apiUrlPrefix2 + "/events/accept_final_video",
    //     uploadListToLinkEvent: apiUrlPrefix + "/media_files/event_files",
    //     importFiles: apiUrlPrefix + "/media_files/import_file", 
    //     allMediaFiles: apiUrlPrefix + "/media_files/show_all",
    //     createZippedMedia: apiUrlPrefix + "/media_files/create_zipped_media",
    //     downloadZippedMedia: apiUrlPrefix + "/media_files/show_zipped_media"
    // },

    // options: {
    //     genre: apiUrlPrefix + "/options/genre",
    //     tone: apiUrlPrefix + "/options/tone",
    //     phoneCount: apiUrlPrefix + "/options/phone_count",
    //     qualityOption: apiUrlPrefix + "/options/quality_option",
    //     eventType: apiUrlPrefix + "/options/event_type",
    //     videoLength: apiUrlPrefix + "/options/video_length",
    //     statusType: apiUrlPrefix + "/options/status_type",
    // },

    // consents:{
    //     consentDetail: apiUrlPrefix2 + "/consents/upload_description",
    //     eventVideoUploadConsent: apiUrlPrefix2 + "/consents/event_file_upload"
    // },

    // revision:{
    //     videoUrl: apiUrlPrefix2 + "/revisions/final_video_url",
    //     checkNewVideo: apiUrlPrefix2 + "/revisions/check_new_final_video",
    //     createRevision: apiUrlPrefix2 + "/revisions",
    //     getRevisionLogs: apiUrlPrefix2 + "/logs",
    //    // createRevisionLogs: apiUrlPrefix2 + "/revisions/create_log",
    //     submitRevision: apiUrlPrefix2 + "/revisions/submit_revision"
    // },
    project:{
        getProjectTypeMaster: apiUrlBase + '/projects/types',
        getProjectLengthMaster: apiUrlBase + '/projects/lengths',
        manageProjects: apiUrlBase + '/projects',
        deleteProject: apiUrlBase + '/projects/delete'
        // getProjectDetails: apiUrlBase + '/projects',
        // createProject: apiUrlBase + '/projects',
       // content: apiUrlBase + '/contents/content'
    },
    content :{
        getProjectContents: apiUrlBase + '/contents/project',
        manageContents: apiUrlBase + '/contents',
        updateContents: apiUrlBase + '/contents/update',
        updateVo: apiUrlBase + '/contents/update/vo',
        getVoiceProfiles: apiUrlBase + '/contents/vo/user/profiles',
        createVoiceProfile: apiUrlBase + '/contents/vo/user/profile',
        updateVoiceProfile: apiUrlBase + '/contents/vo/update/user/profile',
        deleteVoiceProfile: apiUrlBase + '/contents/vo/delete/user/profile',
        convertContent: apiUrlBase + '/contents/converto', 
        deleteContent: apiUrlBase + '/contents/delete',
        createImage: apiUrlBase + '/contents/image'
    }

    

};