import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createQueryParams, removeHtmlTags } from '../../../../../utils/commonServices';
import { setDropDownArray } from '../../../../../redux/dropdownSlice';
import { updateVoiceState } from '../../../../../redux/voiceSlice';
import useContentActions from '../../../hooks/useContentActions';
import { updatePendingCallState } from '../../../../../redux/pendingCallSlice';

export default ()=> {
   // const [audioUrl, setAudioUrl] = useState('');
    // const [sampleUrl, setSampleUrl] = useState('');
    // const [isSample, setIsSample] = useState(true)
    // const [voices, setVoices] = useState([]);
    // const [selectedVoice, setSelectedVoice] = useState('');
    // const [isSpeaking, setIsSpeaking] = useState(false);
    // const [styleList, setStyleList] = useState([]);
    // const [style, setStyle] = useState();
    // const [playbackRate, setPlaybackRate] = useState(1.0); // Default playback rate
    const { updateVoContent } = useContentActions();
   // const [tempUrl, setTempUrl] = useState(null)
    const audioRef = useRef(null);
    const sampleRef = useRef(null);
    const contentReducer = useSelector((state) => state.contentReducer)
    const scriptReducer = useSelector((state) => state.scriptReducer)
    const voiceReducer = useSelector((state) => state.voiceReducer)
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer)
    const subscriptionKey = process.env.REACT_APP_SPEECH_KEY;
    const region = process.env.REACT_APP_SPEECH_REGION;
    const dispatch = useDispatch();
  
    useEffect(() => {
      
      const fetchVoices = async () => {
        try {
          const response = await fetch(`https://${region}.tts.speech.microsoft.com/cognitiveservices/voices/list`, {
            headers: {
              'Ocp-Apim-Subscription-Key': subscriptionKey
            }
          });
          const data = await response.json();
          // const filterdList = data?.filter((voice)=>(
          //                       voice?.ShortName == "en-US-AvaMultilingualNeural" || voice?.ShortName == "en-US-AriaNeural" ||
          //                       voice?.ShortName == "en-US-EmmaMultilingualNeural" || voice?.ShortName == "en-US-SaraNeural" ||
          //                         voice?.ShortName == "en-US-SaraNeural" || voice?.ShortName == "en-US-AndrewMultilingualNeural" ||
          //                           voice?.ShortName == "en-US-BrandonNeural" || voice?.ShortName == "en-US-BrianMultilingualNeura" ||
          //                             voice?.ShortName == "en-US-GuyNeural" || voice?.ShortName == "en-US-JasonNeural" || 
          //                               voice?.ShortName == "en-US-DavisNeural" || voice?.ShortName == "en-US-TonyNeural" || 
          //                                 voice?.ShortName == "en-US-JaneNeural"))

          const filterdList = data?.filter((voice)=>(voice?.Locale == "en-US" ))

           dispatch(setDropDownArray({voices:filterdList}))
           dispatch(updateVoiceState({selected_voice: filterdList[5]}))
          // dispatch(setDropDownArray({voices:data}))
          // dispatch(updateVoiceState({selected_voice: data[115]}))
        } catch (error) {
          console.error('Error fetching voices:', error);
        }
      };
     // console.log("before api text", contentReducer?.editor_text)
     if(dropdownReducer?.voices?.length == 0) {
      fetchVoices();
     } else {
      dispatch(updateVoiceState({selected_voice: dropdownReducer?.voices[5]}))
     }
      
    }, [region, subscriptionKey]);
  
    const saveVoiceClip = (audioFile)=> {
      dispatch(updateVoiceState({is_new_audio: true}))
      let payload;
      if(voiceReducer?.voice_profile_view) {
        payload = {
          query: {
            contentId: contentReducer?.selected_content_details?.id,
            projectId: scriptReducer?.selected_project_details?.id,
            voProfileId: voiceReducer?.selected_voice_profile,
            isDefaultProfile: voiceReducer?.is_selected_default ? 1 : 0,
            voice: "",
            style: "",
            accent: "",
           // voice_audio_url: audioFile, 
            pitch: 0,
            speed: 0
          },
          data: {
            audio: audioFile,
            script: contentReducer?.editor_text,
          }
        }
      } else{
        payload = {
          query: {
             contentId: contentReducer?.selected_content_details?.id,
             projectId: scriptReducer?.selected_project_details?.id,
             voProfileId: 0,
             isDefaultProfile: 0,
             voice: voiceReducer?.selected_voice?.ShortName,
             style: voiceReducer?.selected_style,
             accent: voiceReducer?.selected_accent,
            // voice_audio_url: audioFile, 
             pitch: voiceReducer?.pitch,
             speed: voiceReducer?.rate
           },
           data: {
             audio: audioFile,
             script: contentReducer?.editor_text,
           }
         }
      }
   
      //console.log("payload", createQueryParams(payload?.query))
      updateVoContent(payload)
    }

    const handleConvertTextToSpeech = async () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = ''; // Clear the current audio source
        console.log("cleared", audioRef.current.src)
      }
      if (sampleRef.current) {
        sampleRef.current.pause();
        sampleRef.current.src = ''; // Clear the current audio source
      }
      
      dispatch(updateVoiceState({is_generating_speech: true}))
      dispatch(updatePendingCallState({loader_backdrop_open: true}))
     // setIsSpeaking(true);
       const ssml = `
             <speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang='en-US'>
                <voice name='${voiceReducer?.selected_voice?.ShortName}'>
                <mstts:express-as style='${voiceReducer?.selected_style ? voiceReducer?.selected_style : ""}'>
                <prosody pitch='${voiceReducer?.pitch ? voiceReducer?.pitch : 0}%' rate='${voiceReducer?.rate ? voiceReducer?.rate : 0}%'>
                  <lang xml:lang='${voiceReducer?.selected_accent}'>
                        ${removeHtmlTags(contentReducer?.editor_text)}
                      </lang>
                    </prosody>
                  </mstts:express-as>
              </voice>
          </speak>`

    //  const ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang='en-US'>
    //                   <voice name="en-US-BrianMultilingualNeural">
    //                   <mstts:express-as style="">
    //                  <prosody pitch="0%" rate="0%">
    //                   <lang xml:lang="">
    //                         "That’s remarkable! You’re a genius! Mom said to her son."
    //                   </lang>
    //                   </prosody>
    //                    </mstts:express-as>
    //                   </voice>
    //                 </speak>` 

      try {
        
        const response = await fetch(`https://${region}.tts.speech.microsoft.com/cognitiveservices/v1`, {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': subscriptionKey,
            'Content-Type': 'application/ssml+xml',
            'X-Microsoft-OutputFormat': 'audio-48khz-192kbitrate-mono-mp3'
          },
          body: ssml
        });
  
        if (!response.ok) {
          throw new Error(`Error synthesizing speech: ${response.statusText}`);
        }
  
        const audioBlob = await response.blob();
        const url = URL.createObjectURL(audioBlob); 
       // 
       // console.log("audio", audioBlob)
        saveVoiceClip(audioBlob);
        dispatch(updateVoiceState({audio_url: url}))
       // setAudioUrl(url);
      } catch (error) {
        console.error('Error synthesizing speech:', error);
      }
      dispatch(updateVoiceState({is_generating_speech: false}))
    };

    useEffect(()=> {
       dispatch(updateVoiceState({is_speech_playing: false, }))
     //if(contentReducer?.is_voice) { audioRef.current.src = "";}
      // pauseSpeech()
    }, [contentReducer?.editor_text, contentReducer?.is_voice])
  
    useEffect(() => {
      if (!!contentReducer?.selected_content_details?.content?.voice_audio_url && contentReducer?.is_voice) {
         // createAudioUrl(contentReducer?.selected_content_details?.content?.voice_audio_url)
         if(!!audioRef?.current) {
          audioRef.current.src = contentReducer?.selected_content_details?.content?.voice_audio_url
          playSpeech();
        }
        
      }
    }, [contentReducer?.selected_content_details?.content?.voice_audio_url, contentReducer?.is_voice]);
  
    const playSpeech = () => {
      if(!!voiceReducer?.is_new_audio){ 
        audioRef.current.play();
      }
      dispatch(updateVoiceState({is_new_audio: false}))
    };
  
    // const pauseSample = () => {
    //   sampleRef.current.pause();
    // };
    const pauseSpeech = () => {
      if(contentReducer?.is_voice){ audioRef.current.pause();}
    };
  
    const handleSeek = (event) => {
      audioRef.current.currentTime = event.target.value;
    };
  
    const handlePlaybackRateChange = (event) => {
      const rate = parseFloat(event.target.value);
     // setPlaybackRate(rate);
      if (audioRef.current) {
        audioRef.current.playbackRate = rate;
      }
    };
  
    const createAudioUrl = async (url) => {
      try {
        // Fetch the file from the URL
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        // Convert the response to a blob
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        if(!!audioRef?.current) {
          audioRef.current.src = objectURL
          playSpeech();
        }
    
        // Revoke the object URL to free up memory
       // URL.revokeObjectURL(objectURL);
      } catch (error) {
        console.error('There was an error during the download process:', error);
      }
    };

    // const handleDownload = async (url, name) => {
    //  // try {
    //     // Fetch the file from the URL
    //     // const response = await fetch(url);
    //     // if (!response.ok) {
    //     //   throw new Error('Network response was not ok');
    //     // }
    
    //     // Convert the response to a blob
    //    // const blob = await response.blob();
    
    //     // Create a link element
    //     const link = document.createElement('a');
    //     const objectURL = URL.createObjectURL(url);
    
    //     // Set the link's href to the object URL and set the download attribute
    //     link.href = objectURL;
    //     link.download = name;
    
    //     // Append the link to the document, trigger the download, and then remove the link
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    
    //     // Revoke the object URL to free up memory
    //     URL.revokeObjectURL(objectURL);
    //   // } catch (error) {
    //   //   console.error('There was an error during the download process:', error);
    //   // }
    // };

    const handleDownload = (url, name) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

  return {
          audioRef, 
          playSpeech, 
          pauseSpeech,
          handleConvertTextToSpeech, 
          handleDownload
        }
}
