import { ThemeContext } from "@emotion/react";
import backGround from "../../../assets/unAuth_BG.svg";
import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Footer from "./Footer";
import RightComponent from './RightComponent'
import Header from "./Header";
import { useState } from "react";
import { useSelector } from "react-redux";
import CustomSnackbar from "../../../components/CustomSnackbar";
import "@fontsource/rubik";
import DisclaimerPopup from "./DisclaimerPopup";


const useStyles = makeStyles((theme) => ({
    container: {
        // position: 'absolute',
        // padding: '4.5% 4.9% 6% 5.9%',
         fontFamily: 'Rubik',
         height: '100%',
         minHeight: '100vh',
         overflowY: 'auto',
        // width: '100%',
       //  minWidth: '860px',
         // backgroundImage: 'linear-gradient(110deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
         backgroundImage: 'radial-gradient(at bottom right, #039f10, #008d65, #007593, #005998, #003877, #0f2a65, #141c53, #140f41, #140f41, #121633, #121633 , #121633)',
         display: 'flex',
         flexDirection: 'column',
         overflowY: 'auto',
         scrollbarWidth: 'none',
         msOverflowStyle: 'none',
         '&::-webkit-scrollbar': {
           display : 'none'
         },
     },
  root: {
    position: "absolute",

    height: "100%",
    minWidth: "100vw",
    backgroundImage: 'linear-gradient(110deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )', 
    
    // aspectRatio: '1/6'
  },
  content: {
    overflowY: "scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    scrollbarColor: "#FFFFFF00",
    height: "85vh",
    marginTop: "10vh",
  },
}));

const UnauthLayout = (props) => {
  const { signup, both, signin, rightComponent } = props;
  const classes = useStyles();
  const snackbarProps = useSelector((state) => state.messageReducer);
  const userReducer = useSelector((state) => state.persistedReducers.userReducer);
  const appleSignReducer = useSelector((state) => state.appleSignReducer);


  return (
    <>
      {/* <Box className={classes.root} sx={{width:'100%',}}> */}
      <Box className={classes.container} sx={{width:'100%',}}>
        <Header signup={signup} both={both} signin = {signin} />
        <Box
          sx={{
            overflowY: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarColor: "#FFFFFF00",
            height: "calc(100% - 130px)",  
            marginTop: { xs: "80px", lg: "80px" },
            px: { xs: '1vw', sm: "3vw", md: "6vw", lg:'6vw',xl:'6vw' },
            display:'flex',
            width:'100%',
            maxWidth:'1920px',
            margin:'auto'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6 } lg={6} xl={6}  sx={{display:'flex', alignItems:'center', justifyContent:{xs:'center',md:'flex-start'}, }}>
              
                {props.children}
            
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  className='flex j-center a-center'>
             
            {  rightComponent ? rightComponent: <RightComponent/>}
             
            </Grid>
          </Grid>
        </Box>
        {Object.keys(snackbarProps).length > 0 && (
          <CustomSnackbar
         
          />
        )}
        <Footer />
        {userReducer.footer_disclaimer && <DisclaimerPopup />}
      </Box>
    </>
  );
};

export default UnauthLayout;