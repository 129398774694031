import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomCard from "../../../components/CustomCard";
import "@fontsource/rubik";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter, downloadS3File, formatDynamicDate, getInitials, getNamesFromIds, removeWhiteSpaces, timeStampToDayString } from "../../../utils/commonServices";
import CustomTooltip from "../../../components/CustomTooltip";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { updateUserState } from "../../../redux/userSlice";
import downloadButton from "../../../assets/downloadIcon.svg";
import DeleteIcon from '@mui/icons-material/Delete';
import { projectTypeIcons } from "../../../constants/constants";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import { updateSriptState } from "../../../redux/projectScriptSlice";
import { personaList } from "../../../constants/staticData";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { updateContentState } from "../../../redux/contentSlice";

const EventCard = (props) => {
    const { event, setEventName } = props;
    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    const scriptReducer = useSelector((state)=> state.scriptReducer)
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
    const eventId = event?.id;
    const personaId = event?.personaId;
   // const event = scriptReducer.all_projects?.find((event) => event.id == eventId)
   // const projectTypeId = dropdownReducer.project_type?.find((type)=> removeWhiteSpaces(type?.name)?.toLowerCase() === removeWhiteSpaces(event?.projectType)?.toLowerCase())?.id
    const dispatch = useDispatch()
    var collaborators = event?.collaborators?.map((collaborator) => collaborator.name)
    collaborators?.unshift(event?.owner_name);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // const [deleteProject, deleteEventStatus] = useDeleteProject(eventId, refetchList);
    // console.log({event, collaborators})
    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const downloadFinalVideo = (e)=> {
        e.stopPropagation();
        downloadS3File(event?.final_video_download_url)
    }

    const deleteHandler = (e)=> {
        e.stopPropagation();
        //e.preventDefault()
        console.log("clicked")
        dispatch(updateSriptState({open_delete_popup: true, delete_project_id: eventId}))
    }
   

    return <CustomCard
        {...props}
        sx={{
            ...props.sx,
            width: { xs: '100%', md: 'calc(50% - 16px)', lg: 'calc(33% - 16px)', xl: 'calc(33% - 16px)' },
            minWidth: 325,
            // height: '100%',
            //  maxHeight: {md: '12vh', lg: '23%', xl: '21.33%'},
            background: '#FFFFFF40 0% 0% no-repeat padding-box'
        }}
    >
        <Box className="flex col j-between" sx={{height:{xs:'110px',sm:'120px',md:'125px',lg:'132px',xl:'198px'}, borderRadius:'10px', }}>
        {/* <Box sx={{height:'100%'}}> */}
         <Box >
            <Box
                sx={{
                width: '100%',
                padding: '1.3% 1.6% 2.6% 6.8%'

                }}
            >
            <Box className='flex row j-end a-center' mt={{xs:0.2,md:0.3,lg:0.5,xl:0.8}} sx={{ width: '100%', height: { xs: '9px', md: '11px', lg: '12px', xl: '16px' },  }} >
                {/* <MoreHorizIcon onClick={handleClick} sx={{fontSize:{xs:'12px',sm:'14px',md:'16px',lg:'18px',xl:'24px'}}}/> */}
                {/* <p style={{margin: 0}}>icon</p> */}
                {
                    // event?.status_type_order == 5 ? <CustomTooltip title="Download Final Video"   PopperProps={{
                    //     modifiers: [
                    //       {
                    //         name: "offset",
                    //         options: {
                    //           offset: [-35, -10],
                    //         },
                    //       },
                    //     ],
                    //   }} ><IconButton style= {{padding: "0px", marginTop: '3.6%'}} onClick={downloadFinalVideo} height='18%'><img  src={downloadButton}></img></IconButton></CustomTooltip> 
                    //     :( event?.status_type_order == 4 ? <CustomTooltip title="Ready for Review"  PopperProps={{
                    //         modifiers: [
                    //           {
                    //             name: "offset",
                    //             options: {
                    //               offset: [-35, -4],
                    //             },
                    //           },
                    //         ],
                    //       }} ><Box sx={{width: '16px', height: '16px', borderRadius: 50, backgroundColor: '#FF0045'}}></Box></CustomTooltip>  : null)
                    <img style={{width: '24px', marginTop: '12px'}} src={projectTypeIcons[removeWhiteSpaces(event?.projectType)?.toLowerCase()]}></img>
                }
            </Box>
            <Typography sx={{ fontSize: { xs: 6,sm:6, md: 7,lg:'7.33px', xl: 11 }, mt: {xs:0.3,md:0.5,lg:0.6,xl:0.8}, fontWeight: 600, minHeight: '13.5px', fontFamily: 'Rubik' ,  }}>{event?.projectType && `${capitalizeFirstLetter(event?.projectType)} - ${getNamesFromIds(event?.personaId, personaList)?.name}`}</Typography>
            <CustomTooltip title={event?.name?.length > 30 && event?.name} 
              placement= "top"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}  >
                <Typography
                    sx={{
                        fontSize: { xs: '10px', sm:'11px', md: '12px',lg:'13.33px', xl: '20px' },
                        fontWeight: 600, fontFamily: 'Rubik',
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                        mt:{xs:0.4,md:0.5,lg:0.6,xl:1},
                        // backgroundColor:'red',
                        // height:'20p/x',
                        minHeight:{xs:'14px',md:'18px',lg:'20px',xl:'28px'},
                        textTransform:'capitalize'
                    }}
                >{event?.name}</Typography>
            </CustomTooltip>

            <Box width='100%' className='flex row j-start' sx={{ fontSize: 10, fontWeight: 300, fontFamily: 'Rubik', pt: {xs:'4px',sm:'5px', md: '6px', lg: '6px', xl: '13px' }, pb: {xs:'2px',sm:'3px', md: '3px', lg: '3px', xl: '13px', overflowX: 'hidden' }}} >

                <Typography sx={{ fontSize: { xs: 7, md: 8,lg:'9px', xl: 10  }, fontWeight: 300, fontFamily: 'Rubik', mr: 1.5 }}>Created on
                    <Typography component='span' sx={{ fontSize: { xs: 7, md: 8,lg:'9px', xl: 10  }, fontFamily: 'Rubik', fontWeight: 600, ml: 0.5 }} >{formatDynamicDate(event?.startDate)} </Typography>
                </Typography>
                {/* <Typography sx={{ fontSize: {xs: 7, md: 8,lg:'9px', xl: 10 }, fontWeight: 300, fontFamily: 'Rubik' }} >End Date
                    <Typography component='span' sx={{ fontSize: { xs: 7, md: 8,lg:'9px', xl: 10  }, fontFamily: 'Rubik', fontWeight: 600, ml: 0.5 }} >{formatDynamicDate(event?.endDate)} </Typography>
                </Typography> */}
              
            </Box>
                {
                collaborators?.length > 0  && collaborators?.map((member, index) => {
                        return member != null && <Box className="j-center a-center" sx={{ display: 'inline-flex', borderRadius: '5px', backgroundColor: index == 0 ? '#00D744' : '#6C00FF', height: {xs:'12px',sm:'13px',md:'15px',lg:'16.67px',xl:'25px'}, width:  {xs:'12px',sm:'13px',md:'15px',lg:'16.67px',xl:'25px'}, fontFamily: 'Rubik', fontSize: { xs: 6, md: 7,lg:'7.33px', xl: 11 }, fontWeight: 600, color: '#FFFFFF', p: 0.5, marginLeft: '4px', marginRight: '4px' , marginTop:0}} >
                        { getInitials(member)}
                        </Box>
                    })
                }
               
                </Box>
                <Box width='100%' textAlign='right' sx={{paddingRight: '6px'}} ><MoreVertIcon sx={{fontSize: '18px'}} onClick = {handleClick} /></Box>
               {/* <Box width='100%' textAlign='right' sx={{paddingRight: '6px'}} ><DeleteIcon onClick = {deleteHandler} /></Box>  */}
            </Box>
         
        </Box>
        

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                //   vertical: "top",
                //   horizontal: 133,
                vertical: -8,
                horizontal: 12
            }}
        >
          
            <MenuItem
                style={{ height: 24 }}
                onClick={(e) => {
                    // e.stopPropagation();
                    deleteHandler(e);
                     handleClose(e);
                 }}
            >
              
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Delete Project</Typography></ListItemText>
            </MenuItem>
            <MenuItem
                style={{ height: 24 }}
                onClick={(e) => {
                    // e.stopPropagation();
                     dispatch(updateContentState({create_content_open: true}))
                     dispatch(updateSriptState({selected_project_details: {id: eventId, personaId: personaId}}))
                     handleClose(e);
                 }}
            >
              
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' }}}>Create Content</Typography></ListItemText>
            </MenuItem>
            {/* <MenuItem
            
                style={{ height: 24 }}
                onClick={(e) => {
                   // e.stopPropagation();
                    dispatch(updateContentState({create_content_open: true}))
                    handleClose(e);
                }}
            >
               
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Edit Project</Typography></ListItemText>
            </MenuItem> */}
        </Menu>
       
    </CustomCard>
};
export default EventCard;