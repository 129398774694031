import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ImageProject from "./components/ImageProject";
import CommonProject from "./components/CommonProject";
import useProjectActions from "./hooks/useProjectActions";
import BackdropCreateProject from "../eventcreation/components/BackdropCreateProject";

const ProductionEventPage = (props)=> {
  const params = useParams()
  const projectId = params.projectId
  const {getProjectDetails} = useProjectActions();
  const scriptReducer = useSelector((state) => state.scriptReducer);
  const contentReducer = useSelector((state) => state.contentReducer);
  const userReducer = useSelector((state) => state.persistedReducers.userReducer);
  const navigate = useNavigate()
  useEffect(()=> {
    getProjectDetails(projectId) 
  }, [projectId])
  useEffect(()=> {
    if(userReducer.is_logged) {
        if(userReducer.butterfly_access && !userReducer.page_loading) {
             //   navigate(!!window.localStorage.getItem('destination_path') ? window.localStorage.getItem('destination_path') : '/dashboard', {replace: true})
           //  navigate('/dashboard', {replace: true})
        } else {
            navigate('/no-access', {replace: true})
        }
    }
   
}, [userReducer.butterfly_access, userReducer.is_logged])

  return (<>
     { (contentReducer.selected_content_details?.content?.type == 'image' || (scriptReducer?.selected_project_details?.projectType == 'image' && scriptReducer?.selected_project_details?.contents?.length == 0)) ? <ImageProject />
       : <CommonProject />}
     
    </>
  )


}

export default ProductionEventPage