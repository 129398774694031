import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@fontsource/rubik";
import { gaEvents } from "../../../ga/actions";

const ProjectSort = (props)=> {
    const {   selectedSortbyValue, setSelectedSortbyValue, sortByOptions, menuTitle} = props
    //   const [selectedSortbyValue, setSelectedSortbyValue] = useState(null);
    
      const handleClick = (event) => {
       
      }
    
      const handleOptionClick = (event, value) => {
        gaEvents.sortFilter()
        setSelectedSortbyValue(selectedSortbyValue === value ? null : value)
       //  onChange(selectedSortbyValue === value ? null : value)
      }
    
      const handleClose = () => {
      
      }
 
     return (
       <Box width = '100%' className = 'flex col a-start j-start' ml={4} py={4} >
        <Typography mb={2} sx={{fontSize:{xs:'14px',sm:'14px',md:'16px',lg:'16px',xl:'20px'}, fontWeight: 600}} >Sort By</Typography>
           {sortByOptions.map((option, index) => (
             
               <FormControlLabel
                 control={
                   <Checkbox
                     id={`sort-${index + 1}`}
                     checked={selectedSortbyValue === option.value}
                     onChange={(event) => handleOptionClick(event, option.value)}
                     // size="medium"
                    // size='medium'
                     sx={{ '& .MuiSvgIcon-root': { fontSize:{xs:'10px',sm:'10px',md:'12px',lg:'12px',xl:'15px'}, fontWeight: 600}, color:'white !important' }}
                   />
                 }
         
                 label={<Typography sx={{fontSize:{xs:'10px',sm:'10px',md:'12px',lg:'12px',xl:'15px'},  fontWeight: 600}}>{option.label}</Typography>}
               />
             
           ))}
        
       </Box>
     );
}
export default ProjectSort;