import CustomInput from "../../eventcreation/components/CustomInput";
import { makeStyles } from "@mui/styles";
import { customTheme as theme } from "../../../theme/customTheme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FocusTrap from "@mui/base/FocusTrap";
import useValidator from "../../../hooks/useValidator";
import { userResetPassword } from "../../../services/api";
import { afterValidate, setInput } from "../../../utils/commonServices";
import movieIcon from '../../../assets/movieIcon.svg';
import { Box, Grid, Paper, Typography } from '@mui/material';
import UnauthLayout from '../components/UnauthLayout'
import CustomButton from "../../../components/CustomButton";
import { LoadingButton } from "@mui/lab";
import CustomTooltip from "../../../components/CustomTooltip";
import PasswordInstructions from "../../../components/PasswordInstructions";
import ResetSuccessPopup from "./components/ResetSuccessPopup";
import useAuthFlow from "../hooks/useAuthFlow";


const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    minHeight: "100%",
  },
}));

const ResetPassword = () => {

  let tokenStatus = 'error'; //delete it
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('reset_password_token')
  
      const classes = useStyles();
      const [pageData, setPageData] = useState({
        password: "",
        password_confirmation: ""
      });
      const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
      // const [verifyToken, tokenStatus] = useAuthRequest("verify-token")
      const [snackbarProps, setSnackbarProps] = useState({});
      // const [resetPassword, resetPasswordStatus] = useAuthRequest("reset-password", setSnackbarProps)
      const [errorObjectArray, checkEmptyFields, checkValue] = useValidator(pageData);
      const pendingCallReducer = useSelector((state) => state.pendingCallReducer);
      const {resetUserPassword, checkTokenVerificationValidity} = useAuthFlow()
  
      useEffect(()=> {
                checkTokenVerificationValidity({reset_password_token: token});
      }, [])
  
      const submitClickHandler = (event)=> {
       
        checkValue(event);
        checkEmptyFields(event);
         afterValidate(afterValidatCallback);
      }
  
      const afterValidatCallback = async ()=> {
        resetUserPassword({...pageData, "reset_password_token": token})
      //  await resetPassword({...pageData, "reset_password_token": token})
    
      }
  
      useEffect(()=> {
       
        if(pendingCallReducer.resetPasswordStatus == "success") {
          setOpenSuccessPopup(true);
         }
      }, [pendingCallReducer.resetPasswordStatus])
  
        //code to detect enter key for editing Organization Name
    useEffect(() => {
      const keyDownHandler = (event) => {
          if (event.key === 'Enter') {
              event.preventDefault();
              submitClickHandler(event);
          }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
          document.removeEventListener('keydown', keyDownHandler);
      };
  }, [pageData]);
  
  return (
    <UnauthLayout both >
        {
          pendingCallReducer.verifyTokenValidityStatus == "success" ? <Box className={classes.center} square component="Box">

            <Box container justifyContent="flex-start" spacing={3} mt={{xs:1, lg:6, xl:10}}>
              <Box item xs={11} sm={8} md={7}>
                <Box sx={{marginY:{xs:'15px',md:'20px',lg:'25px',xl:'38px'}}}>
                  <Typography
                    variant="h4"
                    color="primary"
                    style={{
                      fontWeight: 600,
                      color:'#ffffff'
                    }}
                    sx={{fontSize:{xs:'30px',sm:'25px',md:'35px',lg:'41px',xl:'60px'}, fontFamily:'Rubik'}}
                  >
                    Reset Password
                  </Typography>
                </Box>
              </Box>
    
              <Box item xs={11} sm={8} md={7} gap={0.8} sx={{display:'flex', flexDirection:'column', width:{ sm:'300px', lg:'400px', xl:'600px'}}}>
              <CustomTooltip 
                    arrow 
                    enterTouchDelay={0} 
                    leaveTouchDelay={5000} 
                    title={<PasswordInstructions />} 
                    placement ={window.innerWidth <=1100 ? 'bottom' : 'right'}
                    PopperProps={{
                      modifiers: [
                          {
                              name: "offset",
                              options: {
                                  offset: window.innerWidth >=1100 && [0, 40],
                              },
                          },
                      ],
                    }}
                  >
                    
                    <CustomInput
                      id={errorObjectArray[0]?.id}
                      onFocus={checkEmptyFields}
                      onBlur={checkValue}
                      required
                     // bottom ={errorObjectArray[0]?.error != "Please enter password" && -22}
                      errorFlag={errorObjectArray[0]?.errorFlag}
                      errorMsg={errorObjectArray[0]?.error}
                      //label="New Password*"
                      type="password"
                      max={40}
                      placeholder="New Password*"
                      onChange={(event)=>setInput(pageData?.password != ""? event.target.value: event.target.value.trim(), "password", pageData, setPageData)}
                      value={pageData?.password}
                      // sx={{ marginBottom: "1rem" }}        
                    />
                    
                    </CustomTooltip>
                    <CustomInput
                      id={errorObjectArray[1]?.id}
                      onFocus={checkEmptyFields}
                      onBlur={checkValue}
                      required
                      errorFlag={errorObjectArray[1]?.errorFlag }
                      errorMsg={errorObjectArray[1]?.error }
                     // label="Confirm Password*"
                      type="password"
                      max={40}
                      placeholder="Confirm Password*"
                      onChange={(event)=>setInput(pageData?.password_confirmation != ""? event.target.value : event.target.value.trim(), "password_confirmation", pageData, setPageData)}
                      value={pageData?.password_confirmation}
                      // sx={{ marginBottom: "1rem" }}  
                    />
                    
              </Box>
           
              <Box item xs={11} sm={8} md={7}>
                <Box
                  container
                  justifyContent="space-between"
                  sx={{
                    flexDirection: { xs: "column-reverse", sm: "row" },
                    alignItems: "center",
                    paddingTop: 0,
                  }}
                >
                
                  <Box item xs={8} sm={4} sx={{display:'flex', justifyContent:'flex-end', marginTop:{xs:'20px',sm:'28px',md:'28px',lg:'33px',xl:'50px'}}}>
                    {/* <CustomButton
                      disabled={ResetPasswordStatus == "loading"}
                      btnText="Login"
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={submitClickHandler}
                      style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#00D744', textTransform:'none', }} 
                      sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,}}
                    /> */}
                     <LoadingButton
                        disabled={pendingCallReducer.resetPasswordStatus == "loading"}
                        onClick={submitClickHandler}
                        style={{borderRadius: '5px', backgroundColor:'#00CEFF', color:'#08231B', textTransform:'none', }} 
                        sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,  minWidth: '94px !important' }}
                        loading={pendingCallReducer.resetPasswordStatus == "loading"}
                        loadingPosition="start"
                        variant="contained"
                        >
                       Submit
                   </LoadingButton> 
                  </Box>
                  
                </Box>
              </Box>
            </Box>
    
    
          </Box> : pendingCallReducer.verifyTokenValidityStatus == "error" ? <Grid container justifyContent="center" spacing={4} >
              <Grid item xs={11} sm={8} md={7} mt={{xs:8, md:0}}>
                <Box>
                  <Typography variant="body1"  style={{ fontFamily: 'Rubik', fontWeight: theme.typography.fontWeightBold, textAlign: 'center', color:'#ffffff'}}>
                    The reset password link seems invalid. Please go to <span ><Link to="/signin?forgot-password=true" style={{color:'#21D644'}} > Forgot Password</Link></span> to generate a new link.
                  </Typography>
                </Box>
              </Grid>
            </Grid> : <></>
        }
      
     { openSuccessPopup && 
          <ResetSuccessPopup 
              openHandler={openSuccessPopup}
              closeHandler={setOpenSuccessPopup}
          />    
      }   
      
    </UnauthLayout>
  )
}

export default ResetPassword