import { Backdrop, Slide } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';

const BackdropWrapper = (props) => {

    const {slideDirection} = props
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const contentReducer = useSelector((state) => state.contentReducer);

  return (
    <>
        <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
            open={createProjectReducer.open_backdrop_project_creation || contentReducer?.create_content_open}
            >
            {props.children}
         </Backdrop>
        </Slide>
    </>
  )
}

export default BackdropWrapper