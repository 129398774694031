import { createSlice } from '@reduxjs/toolkit'

const initialValue = {

    test_value: '',
    all_projects: [],
    selected_project_details:{},
    common_backdrop_open:false,
    is_loading: false,
    enable_story : false,
    open_delete_popup: false,
    delete_project_id: null,
}


export const scriptSlice = createSlice({
    name: 'script',
    initialState: initialValue,
    reducers: {
        updateSriptState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetScriptState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateSriptState, resetScriptState } = scriptSlice.actions
export default scriptSlice.reducer