
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { removeWhiteSpaces } from '../../../utils/commonServices';
import { renderHTML, textToArray } from '../utils/services';
import { useSelector } from 'react-redux';

const ImageContentView = ({data}) => {
    // let item
    //const [imageSrc, setTextToRender] = useState([])
   // const scriptReducer = useSelector((state) => state.scriptReducer);
    // const [type, setType] = useState(!!data.content.type ? removeWhiteSpaces(data.content.type)?.toLowerCase(): 'script')
   // console.log("hakfhgkdfhbdkfbjsdf", scriptReducer.selected_project_details.personaId)
  // const type = removeWhiteSpaces(data.content?.type)?.toLowerCase()

    // useEffect(()=> {
    //   let tempArr = textToArray(data.content?.generated_text);
    //   setTextToRender(tempArr)
    // }, [ data ]);

  return  (
    <Box id='download-content' className = 'flex row a-center j-center' sx={{height: '100%'}} >
        <img src={data.content?.image_url} style={{borderRadius: '8px', maxWidth: '100%', maxHeight: '100%'}}  />
    </Box>
    )
}

export default ImageContentView