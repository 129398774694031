import React from "react";
import ProjectDescriptionPage from "../commonProject/ProjectDescriptionPage";
import ProjectPersonaPage from "../commonProject/ProjectPersonaPage";
import VoiceScriptOption from "./VoiceScriptOption";
import { useSelector } from "react-redux";
import ProjectLengthPage from "../commonProject/ProjectLengthPage";
import VoScriptInput from "./VoScriptInput";
import PreviewCreateProject from "../commonProject/PreviewCreateProject";
import BackdropWrapper from "../BackdropWrapper";

const VoiceOverProjectCreation = (props) => {

    const { slideDirection, pageIndex, prevPageHandler, nextPageHandler, closeHandler, totalScreens } = props;
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const isListView = commonReducer?.list_view

    return <> {
        commonReducer?.list_view ? <>
            {pageIndex === 3 && <VoiceScriptOption {...props} />}
            {
                createProjectReducer.voiceOverPageData.needScript ? <>
                    {pageIndex === 4 && <ProjectLengthPage {...props} />}
                    {pageIndex === 5 && <ProjectPersonaPage {...props} />}
                    {pageIndex === 6 &&
                        <ProjectDescriptionPage
                            {...props}
                            title='Give us a description of your script.'
                            note='What is your script about? Tell us more about what you want to incorporate in your script. (i.e. tone, important information, etc.)'
                        />
                    }
                    {pageIndex === 7 && <PreviewCreateProject {...props} />}
                </> : <>
                    {pageIndex === 4 && <VoScriptInput title='Submit your script.'
                        note='Type or paste here. You can make changes to your script any time in voice studio.'  {...props} />}
                    {pageIndex === 5 && <PreviewCreateProject {...props} />}
                </>
            }

        </> : <>
            {pageIndex === 3 && <BackdropWrapper slideDirection={slideDirection}><VoiceScriptOption {...props} /></BackdropWrapper>}
            {
                createProjectReducer.voiceOverPageData.needScript ? <>
                    {pageIndex === 4 && <BackdropWrapper slideDirection={slideDirection}><ProjectLengthPage {...props} /></BackdropWrapper>}
                    {pageIndex === 5 && <BackdropWrapper slideDirection={slideDirection}><ProjectPersonaPage {...props} /></BackdropWrapper>}
                    {pageIndex === 6 &&
                        <BackdropWrapper slideDirection={slideDirection}><ProjectDescriptionPage
                            {...props}
                            title='Give us a description of your script.'
                            note='What is your script about? Tell us more about what you want to incorporate in your script. (i.e. tone, important information, etc.)'
                        /></BackdropWrapper>
                    }
                    {pageIndex === 7 && <BackdropWrapper slideDirection={slideDirection}><PreviewCreateProject {...props} /></BackdropWrapper>}
                </> : <>
                    {pageIndex === 4 && <BackdropWrapper slideDirection={slideDirection}><VoScriptInput title='Submit your script.'
                        note='Type or paste here. You can make changes to your script any time in voice studio.'  {...props} /></BackdropWrapper>}
                    {pageIndex === 5 && <BackdropWrapper slideDirection={slideDirection}><PreviewCreateProject {...props} /></BackdropWrapper>}
                </>
            }

        </>
    }


        {/*pageIndex === 4 && <ProjectStartDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
       />*/}

        {/*pageIndex === 5 && <ProjectEndDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
     />*/}


    </>

}

export default VoiceOverProjectCreation;