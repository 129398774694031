import { SvgIcon } from "@mui/material";
import React from "react";

const LeftIcon = (props)=> {
    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="127" height="127" viewBox="0 0 100 100">
 <defs xmlns="http://www.w3.org/2000/svg">
    <filter id="Rectangle_29" x="0" y="0" width="127" height="127" filterUnits="userSpaceOnUse">
      <feOffset dy="5" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="7.5" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-path">
      <rect id="Rectangle_48" data-name="Rectangle 48" width="32.025" height="19.382" fill="#00d744"/>
    </clipPath>
  </defs>
  <g xmlns="http://www.w3.org/2000/svg" id="Group_348" data-name="Group 348" transform="translate(-1669.5 -939.5)">
    <g id="Add_Project" data-name="Add Project" transform="translate(1692 957)">
      <g transform="matrix(1, 0, 0, 1, -22.5, -17.5)" filter="url(#Rectangle_29)">
        <path id="Rectangle_29-2" data-name="Rectangle 29" d="M10,0H82a0,0,0,0,1,0,0V82a0,0,0,0,1,0,0H10A10,10,0,0,1,0,72V10A10,10,0,0,1,10,0Z" transform="translate(22.5 17.5)" fill="#08231b"/>
      </g>
    </g>
    <g id="Group_331" data-name="Group 331" transform="translate(1723.309 1014.013) rotate(-90)">
      <g id="Group_81" data-name="Group 81" transform="translate(0)" >
        <path id="Path_42" data-name="Path 42" d="M28.309,19.382a10.271,10.271,0,0,1-2.13-1.342Q21.487,13.5,16.942,8.815c-.794-.817-1.253-.79-2.032.011-3.095,3.179-6.257,6.292-9.4,9.427a3.164,3.164,0,0,1-3.383.935A2.706,2.706,0,0,1,0,16.737a4.441,4.441,0,0,1,.888-2.888c4.156-4.343,8.427-8.575,12.7-12.8a3.06,3.06,0,0,1,4.577-.033q6.478,6.366,12.867,12.82a3.045,3.045,0,0,1,.654,3.655c-.547,1.174-1.534,1.79-3.376,1.894" transform="translate(0 0)" fill="#00d744"/>
      </g>
    </g>
  </g>

      </SvgIcon>
    )
}

export default LeftIcon