import ReactGA from "react-ga4";

const signup = ()=> ReactGA.event({action:'signup', category:'click', label:'Signed up'})
const signupFailed = ()=>{
    ReactGA.event({action:'signup-failed', category:'event', label:'Signup Failed'})
}
const emailVerified = () => ReactGA.event({action:'email-verified', category:'event', label:'Email Verified'})

const login =()=> ReactGA.event( 'login')

const loginFailed =()=>{
    ReactGA.event({action:'login-failed', category:'event', label:'Login Failed'})
}


const clickedOnJoinButton = ()=>{
    ReactGA.event({action:'join', category:'click', label:'Join button clicked'})
}

const projectCreationFormOpened = ()=>{
    ReactGA.event({action:'project-create-form-opened', category:'click', label:'Project Creation Form Opened'})
}


const clickedOnTipsAndTricks = ()=>{
    ReactGA.event({action:'tips and tricks', category:'click', label:'clicked on tips and tricks'})
}

const projectCreated = ()=>{
    ReactGA.event({action:'project-create', category:'event', label:'New Project Created'})
}

const projectCreationFailed = ()=>{
    ReactGA.event({action:'project-create-api-failed', category:'event', label:'Project Creation Failed'})
}

const clickedProjectType = (projectTypeName)=>{
    ReactGA.event({action:`${projectTypeName} type project clicked`, category:'event', label:`${projectTypeName} type project clicked`})
}


const createdProjectType = (projectTypeName)=>{
    ReactGA.event({action:`${projectTypeName} type project created`, category:'event', label:`${projectTypeName} type project created`})
}

const createdPersonaType = (personaTypeName)=>{
    ReactGA.event({action:`${personaTypeName} persona`, category:'event', label:`${personaTypeName} persona`})
}

const sortFilterUsed = ()=>{
    ReactGA.event({action:'Project Sorting', category:'event', label:'Sorting'})
}

const searchUsed = ()=>{
    ReactGA.event({action:'Project Search', category:'event', label:'Project Search'})
}

const contentCreationSuccessful = ()=>{
    ReactGA.event({action:'content creation success', category:'event', label:'content created successfully'})
}

const contentCreationFailed = ()=>{
    ReactGA.event({action:'content creation failed', category:'event', label:'failed content creation'})
}

const convertToSuccessful = ()=>{
    ReactGA.event({action:'convert to success', category:'event', label:'content converted successfully'})
}

const convertToFailed = ()=>{
    ReactGA.event({action:'convert to failed', category:'event', label:' content convert to failed'})
}

const updateContentSuccessful = ()=>{
    ReactGA.event({action:'update content success', category:'event', label:'content updated successfully'})
}

const updateContentFailed = ()=>{
    ReactGA.event({action:'update content failed', category:'event', label:'update content  failed'})
}

const convertToProjectType = (projectTypeName)=>{
    ReactGA.event({action:`Converted to ${projectTypeName} `, category:'event', label:`Converted to ${projectTypeName}`})
}



 const gaEvents ={
    loginEvent: login,
    loginFailed:loginFailed,
    joinClicked: clickedOnJoinButton,
    signupEvent: signup,
    signupFailed: signupFailed,
    emailVerified: emailVerified,
    projectCreated: projectCreated,
    projectFormOpened: projectCreationFormOpened,
    failedProjectCreation: projectCreationFailed,
    clickedProjectType:clickedProjectType,
    sortFilter:sortFilterUsed,
    contentCreationSuccessful:contentCreationSuccessful,
    contentCreationFailed:contentCreationFailed,
    convertToSuccessful:convertToSuccessful,
    convertToFailed:convertToFailed,
    createdProjectType:createdProjectType,
    updateContentSuccessful:updateContentSuccessful,
    updateContentFailed:updateContentFailed,
    convertToProjectType:convertToProjectType,
    clickedOnTipsAndTricks:clickedOnTipsAndTricks,
    createdPersonaType:createdPersonaType
}

export { gaEvents}