import { makeStyles } from "@mui/styles";
import ScriptThumbnail from './ScriptThumbnail'
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { exampleScript, exampleScriptThumbnails } from "../../../constants/staticData";
import { exampleShotlist } from "../../../constants/staticData";
import Shotlist from "./Shotlist";
import Storyboard from "./Storyboard";
import Script from "./Script";
import { useDispatch, useSelector } from "react-redux";
import ScrollToBottom from "../../../components/ScrollToBottom";
import { captureHTML, export2RTF, export2Word, exportAsDocument, exportAsPDF, scriptTextToArray, shotListTextToArray } from "../utils/services";
import useContentActions from "../hooks/useContentActions";
import { copyToClipboardHandler, groupArrayByValue, handleImageDownload, isArray, removeHtmlTags, removeWhiteSpaces } from "../../../utils/commonServices";
import TextContent from "./TextContent";
import ProjectContent from "./ProjectContent";
import BlogContent from "./BlogContent";
import ReactDOMServer from 'react-dom/server';
import { updateContentState } from "../../../redux/contentSlice";
import DeleteConfirmationPopup from "../../dashboard/components/DeleteConfirmationPopup";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomTooltip from "../../../components/CustomTooltip";
import { setSnackbar } from "../../../redux/messageSlice";
import ImageContentView from "./ImageContentView";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import '../styles/scrollbarStyle.css'
import ScriptInputBox from "./ScriptInputBox";
import CustomButton from "../../../components/CustomButton";
import AddIcon from '@mui/icons-material/Add';
import VoiceScriptContent from "./voiceStudio/VoiceScriptContent";
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import MicOffIcon from '@mui/icons-material/MicOff';
import { updateVoiceState } from "../../../redux/voiceSlice";
import CustomSwitch from "../../eventcreation/components/commonProject/CustomSwitch";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const useStyles = makeStyles((theme) => ({

  scroll: {
    overflowY: 'scroll',
    // scrollbarWidth: 'none',
    // msOverflowStyle: 'none',
    // '&::-webkit-scrollbar': {
    //   display: 'none'
    // }

  },
  outerBox: {
    background: '#FFFFFF40 0% 0% no-repeat padding-box',
    boxShadow: '0px 5px 15px #00000029',
    borderRadius: '10px',
    width: '100%',
    height: '68vh',
    padding: '24px 15px 24px 20px'
  },
  flex: {
    display: 'flex'
  },
  leftBox: {
    // width:'25%',
    height: '90%',
  },
  rightBox: {
    // width:'75%',
    height: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    borderRadius: '10px',
    padding: '8px 15px 4px 20px',
  }
}));

const ScriptBox = (props) => {
  const { pdfRef, audioRef, pauseSample, downloadAudio, commonChangeHandler, resetConvertState, exportState, setExportState } = props;
  const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
  const scriptReducer = useSelector((state) => state.scriptReducer);
  const storyboardReducer = useSelector((state) => state.storyboardReducer);
  const contentReducer = useSelector((state) => state.contentReducer);
  const voiceReducer = useSelector((state) => state.voiceReducer);
  const classes = useStyles()
  const [selectedScript, setSelectedScript] = useState()
  const [scriptType, setScriptType] = useState("")
  const { getContentDetails, deleteContent } = useContentActions()
  const dispatch = useDispatch();
  const isImage = contentReducer.selected_content_details?.content?.type == 'image';
  const iconTitle = isImage ? "Save image" : "Copy content";
  

  const captureHTML = (component) => {
    const componentHtml = ReactDOMServer.renderToStaticMarkup(component);
    return componentHtml
  }

  const iconClickHandler = () => {
    if (isImage) {
      handleImageDownload(contentReducer?.selected_content_details?.content?.image_url, `${scriptReducer.selected_project_details.name}.png`)
    } else {
      copyToClipboardHandler(removeHtmlTags(contentReducer?.selected_content_details?.content?.generated_text),
      () => dispatch(setSnackbar({ flag: true, message: "Content copied to clipboard!", type: "success" })))
    }

  }

 
  //console.log("scriptbox", (contentReducer?.selected_content_details))

  // useEffect(()=>{
  //   //setSelectedScript(userReducer.selected_content_id)
  //   setScriptType(userReducer.selected_content_details.type.toLowerCase())
  // }, [userReducer.selected_content_details])
  useEffect(() => {
    if (scriptReducer.selected_project_details?.contents?.length > 0) {
      if (Object.keys(contentReducer.selected_content_details)?.length > 0) {
        setSelectedScript(contentReducer.selected_content_details?.id)
      } else {
        setSelectedScript(scriptReducer.selected_project_details?.contents[0]?.id)
      }
    }
   

    if(contentReducer?.selected_content_details.content?.type?.toLowerCase() === 'voice over' || !!contentReducer?.selected_content_details.content?.voice_audio_url ) {
      dispatch(updateContentState({is_voice: true}))
    } else {
      dispatch(updateContentState({is_voice: false}))
    }
   
  }, [contentReducer.selected_content_details, scriptReducer.selected_project_details])



  useEffect(()=> {
    if(Object.keys(contentReducer?.selected_content_details)?.length > 0 && !!contentReducer?.is_voice) {
      
      console.log("new audio", contentReducer?.selected_content_details.content?.voice, contentReducer?.selected_content_details.content?.voice_audio_url)
      dispatch(updateVoiceState({
        selected_voice: !!dropdownReducer?.voices?.find(voice=> voice.ShortName === contentReducer?.selected_content_details.content?.voice) ? dropdownReducer?.voices?.find(voice=> voice.ShortName === contentReducer?.selected_content_details.content?.voice) : dropdownReducer?.voices[5],
        selected_style: contentReducer?.selected_content_details.content?.voice_style,
        selected_accent: contentReducer?.selected_content_details.content?.voice_accent,
        pitch: !!contentReducer?.selected_content_details.content?.voice_pitch ? contentReducer?.selected_content_details.content?.voice_pitch : 0,
        rate: !!contentReducer?.selected_content_details.content?.voice_speed ? contentReducer?.selected_content_details.content?.voice_speed: 0 ,
        audio_url: contentReducer?.selected_content_details.content?.voice_audio_url
    }))
    }
  }, [contentReducer?.is_voice, contentReducer?.selected_content_details])
  

  useEffect(() => {
    if (scriptReducer?.enable_story) {
      if (!!selectedScript) getContentDetails({ id: selectedScript })
    } else {
      if (!!selectedScript && scriptReducer.selected_project_details.projectTypeId != 3) getContentDetails({ id: selectedScript })
    }
    resetConvertState && resetConvertState()

  }, [selectedScript, scriptReducer?.enable_story])

  const handleExport = (formatId, content) => {
    switch (formatId) {
      case 1: exportAsPDF(content, `${scriptReducer.selected_project_details.name}_${contentReducer?.selected_content_details?.content?.type}`)
        console.log("pdf selected")
        break;
      case 2: export2Word('download-content', `${scriptReducer.selected_project_details.name}_${contentReducer?.selected_content_details?.content?.type}`)
        console.log("doc selected")
        break;
      case 3: export2RTF('download-content', `${scriptReducer.selected_project_details.name}_${contentReducer?.selected_content_details?.content?.type}`)
        console.log("rtf selected")
        break;
      default: break;
    }
    setExportState((last => {
      return { ...last, enable: false }
    }))

  }

  useEffect(() => {
    if (exportState?.enable) {
      const tempData = contentReducer?.selected_content_details;
      // const tempType = contentReducer?.selected_content_details.content?.type;
      handleExport(exportState?.formatId, tempData?.content?.generated_text)

    }
  }, [exportState])

  const contentDeleteCallback = () => {
    // if ((contentReducer.delete_content_id == contentReducer?.selected_content_details?.id || Object.keys(contentReducer?.selected_content_details)?.length == 0)) {
    //   console.log("one deleted: new first", scriptReducer.selected_project_details?.contents[0])
    //   setSelectedScript(scriptReducer.selected_project_details?.contents[0]?.id)
    // }
    dispatch(updateContentState({ open_delete_popup: false, delete_content_id: null }))
  }

  const deleteConfirmHandler = () => {
    deleteContent({ contentId: contentReducer.delete_content_id }, contentDeleteCallback)
  }

  const voiceOnHandler = () => {
    dispatch(updateContentState({ is_voice: true }))

  }

  const voiceOffHandler = () => {
    
    dispatch(updateVoiceState({
      is_sample_playing: false,
      is_speech_playing: false,
      sample_url: "",
      audio_url: "",
      styles: [],
      accents: [],
      pitch: 0,
      rate: 0
    }))
    dispatch(updateContentState({ is_voice: false }))
  }


  return (
    // <Box className={`${classes.outerBox} ${classes.flex}`} gap={1}>
    <>
      <Box className='flex col j-between a-start' height='100%' >
        <Box className={` ${classes.flex} ${classes.leftBox} ${classes.scroll}`} sx={{ flexDirection: 'column', minWidth: { xs: '180px', md: '200px', lg: '220px', xl: '290px' }, width: { xs: '180px', md: '200px', lg: '220px', xl: '290px' }, }} gap={4}>

          {scriptReducer.selected_project_details?.contents?.length > 0 && scriptReducer.selected_project_details?.contents?.map((content, index) => (
            <>
              { /*content.id === scriptReducer.selected_project_details?.id && <ScrollToBottom />*/
              }
              <ScriptThumbnail index={index} selectedScript={selectedScript} content={content} setSelectedScript={setSelectedScript} setScriptType={setScriptType} />
            </>
          ))}

        </Box>
        <Box width='80%' textAlign='right'>
          <CustomTooltip title="Create any new content type tailored to your project's needs." >
            
          <Button
            variant="outlined"
            style={{
              borderRadius: '5px',
              border: "none",
              textTransform: 'none',
              backgroundColor: '#08231B', 
                    color: '#00D744',
            }}
            sx={{ fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, fontWeight: 800, height: { xs: '18px', sm: '20px', md: '22px', lg: '24px', xl: "32px" }, fontFamily: 'Rubik' }}
            onClick={() => dispatch(updateContentState({ create_content_open: true }))}
            // color="secondary"
            endIcon={<AddIcon style={{ fontSize: 24 }} />}
            >Create New</Button>
            </CustomTooltip>
        </Box>
      </Box>
      <Box className={` flex col j-start ${classes.rightBox} `} sx={{ width: { xs: 'calc( 100% - 170px)', md: 'calc( 100% - 190px)', lg: 'calc( 100% - 210px)', xl: 'calc( 100% - 280px)', } }}>
        <Box className='flex row j-end a-center' mb={2} >
          <Box height='20px' textAlign='right' >
            {
              // contentReducer?.is_voice ? <CustomTooltip title="Turn Off Voice Studio" ><IconButton onClick={voiceOffHandler} style={{ padding: 2 }} >
              //   <MicOffIcon />
              // </IconButton>

              // </CustomTooltip> :
              //   <CustomTooltip title="Turn On Voice Studio" ><IconButton onClick={voiceOnHandler} style={{ padding: 2 }} >
              //     <KeyboardVoiceIcon />
              //   </IconButton>

              //   </CustomTooltip>
            }

          </Box>
          <Box height='20px' textAlign='right' >
            <CustomTooltip title={iconTitle} ><IconButton onClick={iconClickHandler} style={{ padding: 2 }} >
              {
                isImage ? <SaveOutlinedIcon style={{ fontSize: '18px' }} /> :
                  <ContentCopyIcon ></ContentCopyIcon>
              }
            </IconButton>

            </CustomTooltip>
          </Box>
        </Box>
        <Box
          // className={` ${classes.scroll}`}  
          sx={{ height: 'calc(100% - 20px)', width: '100%', mb: 1, overflowY: contentReducer?.is_voice ? 'auto' : 'scroll'  }} id='script-file' ref={pdfRef}>
          {/* {      
      </Box>
      <Box className={` flex col j-start ${classes.rightBox} `} sx={{ width: { xs: 'calc( 100% - 170px)', md: 'calc( 100% - 190px)', lg: 'calc( 100% - 210px)', xl: 'calc( 100% - 280px)', } }}>
        <Box height='20px' textAlign='right' > <CustomTooltip title={iconTitle} ><IconButton onClick={iconClickHandler} style={{ padding: 2 }} >
          {
            isImage ? <SaveOutlinedIcon style={{ fontSize: '18px' }} /> :
              <ContentCopyIcon ></ContentCopyIcon>
          }
        </IconButton>

        </CustomTooltip></Box>
        <Box className={` ${classes.scroll}`} sx={{ height: 'calc(100% - 20px)', width: '100%', marginTop: '12px', marginBottom: '12px' }} id='script-file' ref={pdfRef}>
          {/* {      
                isArray(contentReducer?.selected_content_details.content) ? 
                  (removeWhiteSpaces(contentReducer?.selected_content_details.content[0]?.type)?.toLowerCase() === 'storyboards' && <Storyboard data = {storyboardReducer?.storyboard_details}/>) : 
                   <> {removeWhiteSpaces(contentReducer?.selected_content_details.content?.type)?.toLowerCase() === 'blog' ? <BlogContent data = {contentReducer?.selected_content_details} /> :
                      <ProjectContent data = {contentReducer?.selected_content_details} />}
                    </>
                    // <>
                    //   { removeWhiteSpaces(contentReducer?.selected_content_details.content?.type)?.toLowerCase() === 'shotlist' && <Shotlist data = {contentReducer.selected_content_details}/>}
                    //   { removeWhiteSpaces(contentReducer?.selected_content_details?.type)?.toLowerCase() === 'script' && <Script data = {contentReducer?.selected_content_details}/>}
                    // </>
              } */}
          {scriptReducer.selected_project_details?.projectTypeId == 3 ? <Storyboard data={storyboardReducer?.storyboard_details} /> : <>
            {removeWhiteSpaces(contentReducer?.selected_content_details.content?.type)?.toLowerCase() === 'blog' ? <BlogContent data={contentReducer?.selected_content_details} /> :
              contentReducer?.selected_content_details.content?.type?.toLowerCase() === 'image' ? <ImageContentView data={contentReducer?.selected_content_details} /> :
                contentReducer?.is_voice ? <VoiceScriptContent commonChangeHandler = {commonChangeHandler} data={contentReducer?.selected_content_details} /> : <ProjectContent data={contentReducer?.selected_content_details} />}
          </>}
          {contentReducer.open_delete_popup && <DeleteConfirmationPopup
            open={contentReducer.open_delete_popup}
            closeHandler={() => dispatch(updateContentState({ open_delete_popup: false }))}
            deleteConfirmHandler={deleteConfirmHandler}
            message='Are you sure you want to remove this content permanently? Please type "delete" below to confirm.'
          />}
        </Box>
        {(contentReducer.selected_content_details?.content?.type != 'image' && !contentReducer?.is_voice) && <Box>
          <ScriptInputBox />
        </Box>}
        <Box className='flex row a-center' sx={{width: '100%', justifyContent: contentReducer?.is_voice ? 'space-between' : 'flex-start' }} >
          {(contentReducer?.selected_content_details.content?.type?.toLowerCase() === 'script' || contentReducer?.selected_content_details.content?.type?.toLowerCase() === 'voice over') && <CustomSwitch toolTipTitle = {contentReducer?.is_voice ? 'Toggle off to refine your script.' : ''} label = 'Voice Studio' labelPlacement = 'start' value = {contentReducer?.is_voice} onChange = {(e)=> e.target.checked ? voiceOnHandler() : voiceOffHandler()  } />
            }
          {
            contentReducer?.is_voice &&  <Box className='flex row j-start a-center'>
             <CustomTooltip title = 'Play or pause voiceover playback.'>
             <audio ref={audioRef} onPlay={()=> pauseSample()} aria-disabled={!voiceReducer?.audio_url} style={{ height: '32px' }} controlsList="nodownload" nofullscreen controls></audio>
              </CustomTooltip> 
              {
                (contentReducer?.is_voice && !!voiceReducer?.audio_url) && <CustomTooltip title='Download voice clip'  > <IconButton onClick={()=> downloadAudio(voiceReducer?.audio_url,`voice_${scriptReducer.selected_project_details.name}_${voiceReducer?.selected_voice?.DisplayName}_${voiceReducer?.selected_voice?.Gender}.mp3`)} ><FileDownloadIcon style={{ color: '#222222', fontSize: '30px' }} /></IconButton></CustomTooltip>
              }
            </Box>
          }
        </Box>
      </Box>
    </>
    // </Box>
  )
}

export default ScriptBox