import { useDispatch, useSelector } from "react-redux";
import { mockApi } from "../../common/actions";
import { updateSriptState } from "../../redux/projectScriptSlice";
import { setSnackbar } from "../../redux/messageSlice";
import { checkButterflyAccessApi, getAllProjectsListApi, getProjectDetailsApi, userRoleApi } from "../../services/api";
import { updateUserState } from "../../redux/userSlice";
import { getContentDetails } from "../events/actions";
import { removeDuplicateArrayItem, showSnackBar } from "../../utils/commonServices";





const projectTypeArr = [
    {id:1, value:'Script'},
    {id:2, value:'Shot List'},
    {id:3, value: 'Storyboard'},
  ]

//////////////////apis////////////////////////////////////////////////////

export const getAllProjects = (dispatch, reduxState , values) => {
    dispatch(updateUserState({common_backdrop_open: true}))
    getAllProjectsListApi().then(response => {
        dispatch(updateUserState({common_backdrop_open: false}))
        if(response.data?.http_code === 200){
            //console.log("all project",response.data.projects)
            dispatch(updateUserState({all_projects: response?.data?.projects}))
        }else{
            dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
            // dispatch(updateUserState({common_backdrop_open: false}))
        }
    }).catch(error =>{
        dispatch(setSnackbar({type:'error', flag:true, message: 'Please try again later.'}))
        dispatch(updateUserState({common_backdrop_open: false}))
    })
}



export const getProjectDetails = (values, dispatch, navigate  ) => {
    dispatch(updateUserState({common_backdrop_open: true}))
    getProjectDetailsApi({id: values}).then(response => {
        dispatch(updateUserState({common_backdrop_open: false}))
        if(response.data?.http_code === 200){
            if(response.data.projectType.toLowerCase().trim() === "storyboard") {
                let tempContents = removeDuplicateArrayItem(response?.data?.contents, "contentID")
                dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
            } else {
                dispatch(updateUserState({selected_project_details: {...response?.data, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
            }
           
            if(navigate) {
                if(response?.data?.contents?.length > 0) {
                    dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
                    //getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
                }
                navigate && navigate("/productionevent", {replace: true});
            }    
        }else{
            dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        }
    }).catch(error =>{
        //console.log("Error", error)
        dispatch(updateUserState({common_backdrop_open: false}))
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    })
}


//////////////////////////////CHECK BUTTERFLY ACCESS //////////////////////////////////
export const checkUserButterflyAccess = (dispatch ) => {
    dispatch(updateUserState({page_loading:true}))
    checkButterflyAccessApi() 
    .then((response) => {
        dispatch(updateUserState({page_loading:false}))
      if(response?.data?.http_code === 200){
          dispatch(updateUserState({user_name: response?.data?.data?.name , butterfly_access: response?.data?.data?.butterfly_access}));
      }else {
          dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      }
    })
    .catch((error) => {
        dispatch(updateUserState({page_loading:false}))
      if(error) {
      const errorObject = error?.response?.data;
       showSnackBar( errorObject, dispatch);
      }
    });
    }

    