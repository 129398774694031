
import { AppBar, Box, Button, Grid, Typography } from "@mui/material"
import { useState } from "react"
import ErrorHeader from "./ErrorHeader";
import notFoundImage from '../../assets/404.svg';
import CustomButton from "../../components/CustomButton";

const ErrorPage = (props) => {

  const { componentStack, error } = props;
  const [displayError, updateDisplay] = useState(false)



  return (
    <>
      <Box sx={{width:'100%', position: "absolute", height: "100%", minWidth: "100vw", backgroundImage: 'linear-gradient(110deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )'}}>    
        <ErrorHeader />
        <Box
          sx={{
            overflowY: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarColor: "#FFFFFF00",
            height: "calc(100% - 130px)",
            marginTop: { xs: "80px", lg: "80px" },
            px: { xs: '1vw', sm: "3vw", md: "6vw", lg:'6vw',xl:'6vw' },
            display:'flex',
            width:'100%',
            maxWidth:'1920px',
            margin:'auto'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6 } lg={6} xl={6}  sx={{display:'flex', alignItems:'center', justifyContent:{xs:'center',md:'flex-start'}, }}>
                <Box pl={{sm:10}} pb={4}>
                    <Box  flexDirection='column'>
                        <Box sx={{marginY:{xs:'15px',md:'20px',lg:'25px',xl:'38px'}}}>
                            <Typography
                            variant="h4"
                            color="primary"
                            style={{
                                fontWeight: 600,
                                color:'#08231B'
                            }}
                            sx={{fontSize:{xs:'30px',sm:'40px',md:'40px',lg:'50px',xl:'80px'}, fontFamily:'Rubik'}}
                            >Oh Shoot.</Typography>
                        </Box>
                        <Box item xs={11} sm={8} md={7} gap={0.8} sx={{display:'flex', flexDirection:'column',}}>
                        <Typography sx={{color:'#707070', fontSize:{xs:'12px',md:'14px',lg:'16px',xl:'20px'}, fontFamily:'Helvetica'}}> Something went wrong! Please try to signin again</Typography>
                    </Box>

                <Box width='100%' sx={{mt: 3, display: 'flex', flexDirection: {xs: 'column-reverse', sm: 'row'}, justifyContent: { xs: 'flex-start', sm: 'flex-start'}, alignItems: {xs: 'center', sm: 'flex-start'} }} >
                    <CustomButton 
                    variant="contained" 
                    btnText="Retry" 
                    style={{borderRadius: '5px', border:"none",  textTransform:'none', }}  
                    onClick={() => window.location.reload(true)}
                    sx={{fontSize:{xs:'10px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'100px',lg:'120px',xl:'160px'}, fontFamily:'Rubik', backgroundColor:'#08231B', color:'#21D644',  '&:hover': {backgroundColor: '#08231B',},}}
                        />      
                    </Box>           
                </Box>
            </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  className='flex j-center a-center'>
                <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', }}  pl={{xs:2,sm:4,md:8,lg: 10,xl:12}}>
                    <Box sx={{height:{xs:180,sm:200,md:220,lg:'240px',xl:300}, marginBottom:{xs:'15px', sm:'15px',md:'20px',lg:'22.66px',xl:'34px'}}}>
                        <img src={notFoundImage} alt="notFoundImage" height='100%' style={{objectFit:'cover', borderRadius:'10px'}} />
                    </Box>
                </Box>
            </Grid>
          </Grid>
        </Box>
     
      </Box>
    </>
  );
}

export default ErrorPage;
