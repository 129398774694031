import { SvgIcon } from "@mui/material";
import React from "react";

const SendIcon = (props)=> {
    return <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="41" height="39" viewBox="0 0 41 39">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_189" data-name="Rectangle 189" width="23.603" height="23.59" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_276" data-name="Group 276" transform="translate(-1753 -906)">
    <rect id="Rectangle_190" data-name="Rectangle 190" width="41" height="39" rx="10" transform="translate(1753 906)" fill="#08231b"/>
    <g id="Group_275" data-name="Group 275" transform="translate(1763.622 912.616)">
      <g id="Group_274" data-name="Group 274" clip-path="url(#clip-path)">
        <path id="Path_135" data-name="Path 135" d="M1,0a4.566,4.566,0,0,1,.519.2Q12.212,5.544,22.9,10.893a.969.969,0,0,1,0,1.864Q12.2,18.082,1.5,23.414a1.021,1.021,0,0,1-1.193-.033,1,1,0,0,1-.242-1.119q.948-4.1,1.895-8.192c.154-.664.422-.872,1.113-.943Q8.388,12.58,13.7,12.015c.162-.017.309-.178.463-.272-.149-.1-.29-.272-.447-.288-2.957-.3-5.916-.588-8.874-.877-.661-.065-1.322-.139-1.984-.2A.873.873,0,0,1,2,9.652C1.347,6.814.682,3.977.039,1.136A.89.89,0,0,1,1,0" transform="translate(0 0)" fill="#00d644"/>
      </g>
    </g>
  </g>
</SvgIcon>


}

export default SendIcon