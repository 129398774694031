import React from "react";

import { useSelector } from "react-redux";
import { getNamesFromIds } from "../../../utils/commonServices";
import ProjectLengthPage from "../../eventcreation/components/commonProject/ProjectLengthPage";
import ProjectPersonaPage from "../../eventcreation/components/commonProject/ProjectPersonaPage";
import ProjectDescriptionPage from "../../eventcreation/components/commonProject/ProjectDescriptionPage";
import PreviewCreateProject from "../../eventcreation/components/commonProject/PreviewCreateProject";
import BackdropWrapper from "../../eventcreation/components/BackdropWrapper";

const CommonContentCreation = (props)=> {
    const {slideDirection, pageIndex, prevPageHandler, nextPageHandler, closeHandler, totalScreens} = props;
    const commonReducer = useSelector((state) => state.commonReducer);
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const dropdownReducer = useSelector((state)=> state.persistedReducers.dropdownReducer)
    const projectType = getNamesFromIds(createProjectReducer.commonPageData.projectTypeId, dropdownReducer?.project_type)?.name

   return <> 
            
                {pageIndex === 2 &&  <BackdropWrapper slideDirection={slideDirection}><ProjectLengthPage {...props} /></BackdropWrapper>}
            {pageIndex === 3 && 
                <BackdropWrapper slideDirection={slideDirection}><ProjectDescriptionPage 
                    {...props} 
                    title = {`Give us a description of your ${projectType}.`}
                    note = {`What is your ${projectType} about? Tell us more about what you want to incorporate in your ${projectType}. (i.e. tone, important information, etc.)`}   
                /></BackdropWrapper>
            }
            {pageIndex === 4 && <BackdropWrapper slideDirection={slideDirection}><PreviewCreateProject {...props} /></BackdropWrapper>}
           
     
   
       {/*pageIndex === 4 && <ProjectStartDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
       />*/}
   
       {/*pageIndex === 5 && <ProjectEndDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
     />*/} 
    </>
}

export default CommonContentCreation;