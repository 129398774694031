import React from "react";

import { useSelector } from "react-redux";
import ImageStyleComponent from "../../eventcreation/components/imageProject/ImageStyleComponent";
import ImageAspectComponent from "../../eventcreation/components/imageProject/ImageAspectComponent";
import ImageColorScheme from "../../eventcreation/components/imageProject/ImageColorScheme";
import ProjectDescriptionPage from "../../eventcreation/components/commonProject/ProjectDescriptionPage";
import PreviewCreateImage from "../../eventcreation/components/imageProject/PreviewCreateImage";
import BackdropWrapper from "../../eventcreation/components/BackdropWrapper";
import VoiceScriptOption from "../../eventcreation/components/voiceOverProject/VoiceScriptOption";
import ProjectLengthPage from "../../eventcreation/components/commonProject/ProjectLengthPage";
import PreviewCreateProject from "../../eventcreation/components/commonProject/PreviewCreateProject";
import VoScriptInput from "../../eventcreation/components/voiceOverProject/VoScriptInput";

const VoiceOverContentCreation = (props)=> {

    const {slideDirection, pageIndex, prevPageHandler, nextPageHandler, closeHandler, totalScreens} = props;
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const contentReducer = useSelector((state) => state.contentReducer);

    return  <>   {pageIndex === 2 && <BackdropWrapper slideDirection={slideDirection}><VoiceScriptOption {...props} /></BackdropWrapper>}
    {
        createProjectReducer.voiceOverPageData.needScript ? <>
            {pageIndex === 3 && <BackdropWrapper slideDirection={slideDirection}><ProjectLengthPage {...props} /></BackdropWrapper>}
            {/* {pageIndex === 4 && <BackdropWrapper slideDirection={slideDirection}><ProjectPersonaPage {...props} /></BackdropWrapper>} */}
            {pageIndex === 4 &&
                <BackdropWrapper slideDirection={slideDirection}><ProjectDescriptionPage
                    {...props}
                    title='Give us a description of your script.'
                    note='What is your script about? Tell us more about what you want to incorporate in your script. (i.e. tone, important information, etc.)'
                /></BackdropWrapper>
            }
            {pageIndex === 5 && <BackdropWrapper slideDirection={slideDirection}><PreviewCreateProject {...props} /></BackdropWrapper>}
        </> : <>
            {pageIndex === 3 && <BackdropWrapper slideDirection={slideDirection}><VoScriptInput title='Submit your script.'
                note='Type or paste here. You can make changes to your script any time in voice studio.'  {...props} /></BackdropWrapper>}
            {pageIndex === 4 && <BackdropWrapper slideDirection={slideDirection}><PreviewCreateProject {...props} /></BackdropWrapper>}
        </>
    }

   
       {/*pageIndex === 4 && <ProjectStartDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
       />*/}
   
       {/*pageIndex === 5 && <ProjectEndDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
     />*/}
   
       
    </>

}

export default VoiceOverContentCreation;