import React from 'react'
import { SvgIcon } from "@mui/material";

const CheckIcon = (props) => {
  const {disableColor} = props
  return (
    <SvgIcon {...props} width="127" height="127" viewBox="15 15 90 90">

         <defs>
        <filter id="Rectangle_29" x="0" y="0" width="127" height="127" filterUnits="userSpaceOnUse">
      <feOffset dy="5" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="7.5" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
     </filter>
    <clipPath id="clip-path">
      <rect id="Rectangle_52" data-name="Rectangle 52" width="40.658" height="36.361" fill="none"/>
    </clipPath>
    </defs>
     <g id="Add_Project" data-name="Add Project" transform="translate(-196.5 -326.5)">
      <g transform="matrix(1, 0, 0, 1, 196.5, 326.5)" filter="url(#Rectangle_29)">
      <rect id="Rectangle_29-2" data-name="Rectangle 29" width="82" height="82" rx="10" transform="translate(22.5 17.5)" fill="#08231b"/>
     </g>
      <g id="Group_100" data-name="Group 100" transform="translate(240.061 369.437)">
      <g id="Group_99" data-name="Group 99" clip-path="url(#clip-path)">
        <path id="Path_43" data-name="Path 43" d="M15.083,22.132a5.191,5.191,0,0,1,.5-.939q8.376-9.831,16.771-19.641c1.736-2.031,3.275-2.068,5.075-.117q1.017,1.1,2.029,2.213a3.734,3.734,0,0,1,.087,5.407Q29.066,21.32,18.584,33.581c-.494.578-.972,1.176-1.485,1.732a2.685,2.685,0,0,1-4.243.016Q6.841,28.549.878,21.712a3.615,3.615,0,0,1,.056-4.784c.831-.968,1.685-1.911,2.545-2.847a2.759,2.759,0,0,1,4.473.022c2.107,2.368,4.193,4.759,6.29,7.139.193.219.4.424.842.89" transform="translate(0 0)" fill={disableColor ? disableColor: "#00d744"}/>
          </g>
     </g>
      </g>

    </SvgIcon>
  )
}

export default CheckIcon