import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    storyboard_content_list: [],
    storyboard_details:[],
}


export const storyboardSlice = createSlice({
    name: 'storyboard',
    initialState: initialValue,
    reducers: {
        updateStoryboardState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetStoryboardState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateStoryboardState, resetStoryboardState } = storyboardSlice.actions
export default storyboardSlice.reducer