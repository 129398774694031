import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProjectApi, getProjectDetailsApi, getProjectLengthListApi, getProjectTypeListApi } from "../../../services/api";
import { updatePendingCallState } from "../../../redux/pendingCallSlice";
import { setSnackbar } from "../../../redux/messageSlice";
import { updateUserState } from "../../../redux/userSlice";
import { getProjectDetails } from "../../dashboard/actions";
import { updateProjectCreationState } from "../../../redux/projectCreationSlice";
import useSnackMessage from "../../../hooks/useSnackMessage";
import { setDropDownArray } from "../../../redux/dropdownSlice";
import useDashboardActions from "../../dashboard/hooks/useDashboardActions";
import { getNamesFromIds, showSnackBar } from "../../../utils/commonServices";
import { gaEvents } from "../../../ga/actions";

export default ()=> {
    const dispatch = useDispatch();
    const navigate = useNavigate();
   // const [showSnackBar] = useSnackMessage();
    const {getProjectDetails} = useDashboardActions();
    const dropdownReducer = useSelector((state)=>state.persistedReducers.dropdownReducer)

   const createProject = (payload) => { 
        dispatch(updateProjectCreationState({open_backdrop_project_creation: false}))
        
        dispatch(updatePendingCallState({loader_backdrop_open: true, loader_text: {title: `Generating your ${dropdownReducer.project_type?.find((pType)=>pType.id === payload?.query?.projectTypeId)?.name }`, message: "We’re crafting something fantastic for you."}}))
    createProjectApi(payload) 
      .then((response) => {
        //console.log({response})
        dispatch(updatePendingCallState({loader_backdrop_open: false}))
        if(response?.data?.httpCode === 201){
            showSnackBar(response?.data, dispatch)
            navigate(`/productionevent/${response?.data?.projectId}`, {replace:true})
            gaEvents.projectCreated()
            gaEvents.createdProjectType(getNamesFromIds(payload?.query?.projectTypeId, dropdownReducer?.project_type)?.name)
          !!payload?.query?.personaId &&  gaEvents.createdPersonaType(getNamesFromIds(payload?.query?.personaId, dropdownReducer?.persona_id)?.value)
        }else {
            dispatch(setSnackbar({type:'error', flag:true, message: 'Some error occured'}))
        }
      })
      .catch((error) => {
        if(error){
          showSnackBar(error?.response, dispatch)
          gaEvents.failedProjectCreation()
          dispatch(updatePendingCallState({loader_backdrop_open: false}))
        }
        // console.error('error:', error);
       
        // dispatch(setSnackbar({type:'error', flag:true, message: 'error'}))
       
      });
    }

    //Master list get apis
    
    const getProjectTypeList = (payload, callBack ) => {
    
      dispatch(updatePendingCallState({projectTypeList: true}))
    
      getProjectTypeListApi() 
      .then((response) => {
        dispatch(updatePendingCallState({projectTypeList: false}))
        //if(response && response?.status === 200){
          const listWithoutUserScript = response?.data?.filter((item)=> item.id != 10)
            dispatch(setDropDownArray({project_type: listWithoutUserScript}))
       // }
      })
      .catch((error) => {
        if(error) {
          dispatch(updatePendingCallState({projectTypeList: false}))
          showSnackBar(error?.response, dispatch)
        //  const errorObject = error?.response?.data;
         // showSnackBar(errorObject, dispatch)
         
        }
        
       // dispatch(setSnackbar({type:'error', flag:true, message: 'error'}))
      });
      }

      const getProjectLengthList = (payload, callBack ) => {
    
        dispatch(updatePendingCallState({projectLengthList: true}))
      
        getProjectLengthListApi() 
        .then((response) => {
          dispatch(updatePendingCallState({projectLengthList: false}))
        //  if(response && response?.status === 200){
              dispatch(setDropDownArray({project_length: response?.data}))
        //  }
        })
        .catch((error) => {
          if(error) {
            dispatch(updatePendingCallState({projectLengthList: false}))
            showSnackBar(error?.response, dispatch)
            //const errorObject = error?.response?.data;
           // showSnackBar(errorObject, dispatch)
           
          }
          
         // dispatch(setSnackbar({type:'error', flag:true, message: 'error'}))
        });
        }
    return {
        createProject, 
        getProjectTypeList, 
        getProjectLengthList
    }
}