import { Backdrop, Box, Slide, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateProjectCreationState } from '../../../../redux/projectCreationSlice';
import { afterValidate, calculateSpeakingTime } from '../../../../utils/commonServices';
import CustomInput from '../CustomInput';
import { getErrorMsg } from '../../../../utils/validator';
import LeftIcon from '../../icons/LeftIcon';
import RightIcon from '../../icons/RightIcon';


const VoScriptInput = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex,  closeHandler, totalScreens, title, note} = props
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const isListView = commonReducer?.list_view
    const dispatch = useDispatch()
    const [descriptionValidationflag, setDescriptionvalidationFlag] = useState(false)
    const charLimit = 25000;
    const [charLeft, setCharLeft] = useState(25000)

    const finishedHandler = ()=> {
      validateInputs(true)
      afterValidate(nextPageHandler);
  }

  const validateInputs = (flag) => {
    setDescriptionvalidationFlag(flag)
  }
  const onChangeHandler = (event)=> {
    const length = calculateSpeakingTime(event.target.value)
    setCharLeft(charLimit - event.target.value?.length)
    dispatch(updateProjectCreationState({commonPageData: {...createProjectReducer.commonPageData, lengthId: length?.speakingTime, userScript: createProjectReducer.commonPageData.userScript != "" ? event.target.value : event.target.value.trim() }}))
  }

  console.log("char left", charLeft)

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        finishedHandler();
      }
    };
      document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [createProjectReducer.commonPageData.userScript]);

  return (
    <>
    {/* <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createProjectReducer.open_backdrop_project_creation}
    > */}
        <Box sx={{ width:isListView ? '100%' : '90%', height:'100%', display:'flex', flexDirection:'column'}}>
         {(!isListView || contentReducer?.create_content_open) && <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3}} onClick={closeHandler}>Close X</Typography>}
          <Box flexGrow={1} className='flex col ' sx={{ paddingLeft: isListView ? '5vw' :'14vw', marginTop:isListView ?{xs:'6%',sm:'4%', md:'3%', xl:'4%'}: {xs:'8%',sm:'6%', md:'4%', xl:'6%'}}}>
              <Box mb={6}>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography sx={{fontSize:{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik'}}>{title}</Typography>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{note}</Typography>
              </Box>
              <Box className='flex col j-center' sx={{width:'80%'}}>
              <CustomInput
                id='user-script'
                onFocus={() => setDescriptionvalidationFlag(false) }
                onBlur={() => setDescriptionvalidationFlag(true)}
               // type='sendInput'
                required
                errorFlag={descriptionValidationflag && getErrorMsg("description", createProjectReducer.commonPageData.userScript) != ""}
                errorMsg={getErrorMsg("description", createProjectReducer.commonPageData.userScript)}
                fullWidth
                medium
                multiline
                maxRows = {8}
                minRows = {6}
                max={25000}
                bottom={-20}
                msgColor='red'
                placeholder="Type/paste your text here..."
                onChange={onChangeHandler } 
                value={createProjectReducer.commonPageData.userScript}
                onButtonClick={pageIndex !== totalScreens ? finishedHandler : null}
               />
                {charLeft < 25000 && <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Rubik',
                      color: "#BBBBBB",
                      fontSize:{xs:'12px',lg:'13px',xl:'18px'} ,
                     // position:'absolute',
                      
                      //top: '8px',
                      fontWeight : 600
                      //paddingTop: 20,
                    }}
                  > 
                   
                    <Box textAlign='left'>{`Characters left: ${charLeft}`}</Box>
                  </Typography>}
              </Box>
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', mb:3, mr:3}}>
            <LeftIcon sx={{opacity: pageIndex === 1 && 0.5, fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}, cursor:'pointer'}}  onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <RightIcon sx={{ cursor:'pointer', fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== totalScreens ? finishedHandler : null}/>
          </Box>
        </Box>
    {/* </Backdrop>
    </Slide> */}
    </>
  )
}

export default VoScriptInput