

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem,} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetUserState, updateUserState } from "../../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import "@fontsource/rubik";
import { resetSnackbar } from "../../../redux/messageSlice";
import { logout } from "../../../utils/commonServices";

const useStyles = makeStyles((theme) => ({
  center: {
    //  display:'flex',
    //   alignItems: 'center',
    //   justifyContent:'center'
  },
  text: {
    color: 'white',
    fontSize: theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightBold,
  
  },
}));

const CustomUserCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { avatarImg, userName, size = 'small' } = props;
  const [anchorEl, setAnchorEl] = useState(null);
 

  const userReducer = useSelector((state) => state.persistedReducers.userReducer);
  const open = Boolean(anchorEl);
  const [reqTokenDelete, setReqTokenDelete] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const logout = () => {
  //   window.localStorage.removeItem("token");
  //   dispatch(resetSnackbar())
  //   dispatch(resetUserState());
  //   //   dispatch(updateUserState({
  //   //     is_logged: false,
  //   // }));
  //   //navigate('/signin');
  // };


 

  function getFirstLetters(str) {
    if(str === null || str === undefined){
      return 'NA'
    }
      var names = str?.split(' '),
          initials = names[0]?.substring(0, 1)?.toUpperCase();
      
      if (names.length > 1) {
          initials += names[names.length - 1]?.substring(0, 1)?.toUpperCase();
      }
      return initials;
    // const firstLetters = str
    //   .split(' ')
    //   .map(word => word.charAt(0))
    //   .join('');
    // return firstLetters;
  }



//   useEffect(() => {
//     // Function to request the token from localhost:3000

   
//     if(reqTokenDelete== true){
//     const requestToken =  () => {
//       const targetOrigin = 'http://localhost:3000';
//       const iframe = document.createElement('iframe');
//       iframe.style.display = 'none';
//       iframe.src = targetOrigin;
//       document.body.appendChild(iframe);

//       iframe.onload = () => {
//         iframe.contentWindow.postMessage('DELETE_TOKEN', targetOrigin);
       
//       };
  

  

//       return () => {
//         if (iframe.parentNode) {
//           document.body.removeChild(iframe);
//         }
//       };
//     };


//   requestToken();
//   setTimeout(() => {
//     logout(dispatch)
//   }, 2000);
// }
//   }, [reqTokenDelete]);


  // useEffect(() => {

  //   if(reqTokenDelete == true){
  //   dispatch(updateUserState({page_loading: true}))
  //   const requestToken = () => {
  //     const targetOrigin = process.env.REACT_APP_LUCIHUB_URL;
  //     const iframe = document.createElement('iframe');
  //     iframe.style.display = 'none';
  //     iframe.src = targetOrigin;
  //     document.body.appendChild(iframe);

  //     iframe.onload = () => {
  //       iframe.contentWindow.postMessage('DELETE_TOKEN', targetOrigin);
       
  //     };
  

  //     const handleMessage =  (event) => {
  //       if (event.origin === targetOrigin ) {
          
  //         if(event.data == 'TOKEN_DELETED'){
  //             dispatch(updateUserState({page_loading: false}))
  //             logout(dispatch)     
  //         }
  //       } 
  //     };

  //     window.addEventListener('message', handleMessage);

  //     return () => {
  //       window.removeEventListener('message', handleMessage);
  //       if (iframe.parentNode) {
  //         document.body.removeChild(iframe);
  //       }
  //     };
  //   };

  //   requestToken();
  // }
  // }, [reqTokenDelete]);

  return (
    <Box
      {...props}
      className='flex row a-center j-center'
    //   sx={{
    //     display: { xs: "none", md: "flex" },
    //     alignItems: "center",
    //     justifyContent: "center",
      
    //   }}
      spacing={2}
    >

    <Box 
      sx={{display:'flex', cursor:'pointer', justifyContent:'center', alignItems:'center'}}  
      onClick={userReducer.is_logged == true ? handleClick : null} 
      gap={2}>

        { 
          size == "small" ?<Avatar sx={{width:{xs:'20px',sm:'24px' ,md:'27px' , lg:'26px' ,xl:'35px'}, height:{xs:'20px',sm:'24px' ,md:'27px' , lg:'26px' ,xl:'35px'},backgroundColor:'#00D744', borderRadius:'10px', fontSize:{xs:'8px',md:'10px',lg:'12px',xl:'16px'}, fontWeight: 600, textTransform:'uppercase'}}> {userReducer.is_logged == false
            ? getFirstLetters("Lucihub")
            : getFirstLetters(userReducer?.user_name)}</Avatar>
        :  <Avatar sx={{width:{xs:'20px',sm:'22px' ,md:'24px' , lg:'25px' ,xl:'35px'}, height:{xs:'20px',sm:'22px' ,md:'24px' , lg:'25px' ,xl:'35px'},backgroundColor:'#00D744', borderRadius:'10px', fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, fontWeight: 600, textTransform:'uppercase'}}> {userReducer.is_logged == false
            ? getFirstLetters("Lucihub")
            : getFirstLetters(userReducer?.user_name)}</Avatar>}
        <Typography
          className={classes.text}
          variant="body2"
          sx={{
            fontFamily: 'Rubik',
            fontSize: {xs:'10px',md:'12px',lg:'14px',xl:'20px'},
            fontWeight: 600,
            color: 'white',
            textTransform:'capitalize'
          }}
        >
          {userReducer.is_logged == false
            ? "Lucihub"
            : userReducer.user_name}
        </Typography>

        {/* <IconButton onClick={userReducer.is_logged == true && handleClick}>
          <ExpandMoreIcon style={{ color: theme.palette.secondary.main }} />
        </IconButton> */}

    </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        // transformOrigin={{
        // //   vertical: "top",
        // //   horizontal: 133,
        // vertical:-8,
        // horizontal:87
        // }}
        transformOrigin={{
              vertical: -8,
              horizontal: 'right',
             }}
      >
        {/* <MenuItem
          style={{ height: 25 }}
          onClick={() => {
            navigate('/myfiles', {replace: true})
            handleClose();
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4}}
        >
         My Files
        </MenuItem> */}

        {/* <MenuItem
          style={{ height: 25 ,}}
          onClick={() => {
            dispatch(updateUserState({account_delete_popup : true}))
            setAnchorEl(null)
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4}}
        >
          Delete Account
        </MenuItem> */}
         <MenuItem
          style={{ height: 25 ,}}
          onClick={() => {
            navigate('/voiceprofiles');
           // setReqTokenDelete(true)
            handleClose();
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4}}
        >
          {/* <ListItemIcon>
            <LogoutIcon fontSize="small" color="primary" />
          </ListItemIcon> */}
          {/* <ListItemText sx={{fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}} color="primary">Log out</ListItemText> */}
          My Voices
        </MenuItem>

        <MenuItem
          style={{ height: 25 ,}}
          onClick={() => {
             logout(dispatch, navigate);
           // setReqTokenDelete(true)
            handleClose();
          }}
          sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}, py:0.4}}
        >
          {/* <ListItemIcon>
            <LogoutIcon fontSize="small" color="primary" />
          </ListItemIcon> */}
          {/* <ListItemText sx={{fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}} color="primary">Log out</ListItemText> */}
          Log out
        </MenuItem>
       
      </Menu>
    </Box>
  );
};

export default CustomUserCard;