import { Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Input, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomCard from "../../components/CustomCard";
import AuthLayout from "../layouts/AuthLayout";
import EventCard from "./components/EventCard";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "./icons/AddIcon";
import { updateUserState } from "../../redux/userSlice";
import { formatDynamicDate, timeStampToString } from "../../utils/commonServices";
import { useNavigate, useParams } from "react-router-dom";
import EventCreationDrawer from "../eventcreation";
import CustomUserCard from "../events/components/CustomUserCard";
import "@fontsource/rubik";
import CustomSortOptions from "../events/components/CustomSortOptions";
import { eventSortOptions, sortByOptions } from "../../constants/staticData";
import useEventSort from "./hooks/useEventSort";
import PopupVideoPlayer from "../../components/PopupVideoPlayer";
import DeleteDeclinePopup from "./components/DeleteDeclinePopup";
import useFilter from "./hooks/useFilter";
import { gaEvents } from "../../ga/actions";
import MainLogoCard from "./components/MainLogoCard";
import { updateSriptState } from "../../redux/projectScriptSlice";
import { resetProjectCreationState, updateProjectCreationState } from "../../redux/projectCreationSlice";
import lucihub from '../../assets/lucihub_navigation.png'
import arrow from '../../assets/arrow.svg';
import BackdropCreateProject from "../eventcreation/components/BackdropCreateProject";
import useAuthFlow from "../unauth/hooks/useAuthFlow";
import useDashboardActions from "./hooks/useDashboardActions";
import useEventCreationActions from "../eventcreation/hooks/useEventCreationActions";
import { resetContentState } from "../../redux/contentSlice";
import ProjectSort from "./components/ProjectSort";
import { resetStoryboardState } from "../../redux/storyboardSlice";
import DeleteConfirmationPopup from "./components/DeleteConfirmationPopup";
import FilmingTutorialCard from "../events/components/FilmingTutorialCard";
import { updateCommonState } from "../../redux/commonSlice";
import ListViewDashboard from "./listview/ListViewDashboard";
import rightArrowLong from '../../assets/long_arrow_right.svg';
import BackdropCreateContent from "../contentcreation/components/BackdropCreateContent";
import luciNavigation from '../../assets/butterfly_navigation.png';



const Dashboard = () => {
    // const [refetchEvents] = useEventList();
    const commonReducer = useSelector((state) => state.commonReducer);
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    // const snackbarProps = useSelector((state) => state.messageReducer);
    // const [eventType, selectedEventType] = useEventTypeList()
    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    const dropdownReducer = useSelector((state)=>state.persistedReducers.dropdownReducer)
    // const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
    const [selectedSortbyValue, setSelectedSortbyValue] = useState("date_added");
    const [searchedArray, setSearchedArray] = useState(scriptReducer.all_projects);
    const [searchString, setSearchString] = useState("");
    const isMobileView = useMediaQuery('(max-width:599px)');

    // const [deleteProject, deleteEventStatus] = useDeleteProject(userReducer.production_event_id, refetchEvents);
   // const [searchFilterArray] = useArraySearch(searchedArray);
    const [sortedEvents] = useEventSort(searchedArray, selectedSortbyValue);
    // const [refetchStatus] = useStatusType();
    //const [selectedStatus, setSelectedStatus] = useState(statusTypes);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {getProjectTypeList, getProjectLengthList} = useEventCreationActions();
    const {eventCountByStatus, statusCheckHandler} = useFilter("status_type")
   
    const {getAllProjects, deleteProject } = useDashboardActions();

    //***********Rerenders the dashboard as soon as all the events are successfully fetched************ */
    // useEffect(()=> {
    //     setSearchedArray(userReducer.combined_filter)
    // }, [userReducer.combined_filter])


    useEffect(()=> {
        if(userReducer.is_logged) {
            if(userReducer.butterfly_access && !userReducer.page_loading) {
                 //   navigate(!!window.localStorage.getItem('destination_path') ? window.localStorage.getItem('destination_path') : '/dashboard', {replace: true})
                 navigate('/dashboard', {replace: true})
            } else {
                navigate('/no-access', {replace: true})
            }
        }
       
    }, [userReducer.butterfly_access, userReducer.is_logged])


    useEffect(()=> {
        setSearchedArray(scriptReducer.all_projects)
    }, [scriptReducer.all_projects])

    useEffect(()=> {
        isMobileView && dispatch(updateCommonState({list_view: true}))

        dropdownReducer?.project_type?.length < 1 && getProjectLengthList()
        dropdownReducer?.project_length?.length < 1 && getProjectTypeList();
        dispatch(updateUserState({storyboard_item_number: null}))
        dispatch(updateSriptState({selected_project_details: {}}))
        dispatch(resetProjectCreationState())
        dispatch(resetContentState());
        dispatch(resetStoryboardState())
        getAllProjects()
    }, [window.localStorage.getItem('token')])



    const onSearch = (event) => {
        setSearchString(event.target.value);
        if (scriptReducer.all_projects?.length > 0) {
            const temp = scriptReducer.all_projects?.filter((searchedEvent) => {
                if (event.target.value == "") {
                    return searchedEvent;
                } else if (
                    searchedEvent?.name
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                ) {
                    return searchedEvent;
                } else if (
                    searchedEvent?.projectType
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                ) {
                    return searchedEvent;
                } else if (
                    timeStampToString(searchedEvent?.startDate)
                        ?.toLowerCase()
                        ?.includes(event.target.value.toLocaleLowerCase())
                ) {
                    return searchedEvent;
                } 
                // else if (
                //     timeStampToString(searchedEvent?.end_date)
                //         ?.toLowerCase()
                //         ?.includes(event.target.value.toLocaleLowerCase())
                // )
                    return searchedEvent;
            });
            setSearchedArray(temp);
        }
        // setFilteredArray(filterArray(event));
    }

    const handleSearch = (e) => {
        const searchedInput = e.target.value.toLowerCase();
        setSearchString(searchedInput);
    
        const projects = [...scriptReducer.all_projects];
    
        const results = projects?.filter(item =>
          item.name?.toLowerCase().includes(searchedInput) ||
          item.projectType?.toLowerCase().includes(searchedInput)||
          formatDynamicDate(item.startDate)?.toLowerCase().includes(searchedInput)
        );
        setSearchedArray(results);
      };

    //***********************LOGIC FOR CLICKS ON EVENT CARDS******************************************** */
    const onEventClick = (event) => {
       // dispatch(updateUserState({project_id: event.id}))
        navigate(`/productionevent/${event.id}`, {replace: true})
      //  getProjectDetails( event.id, ()=> navigate("/productionevent", {replace: true}) ) //for testing only
        // dispatch(updateUserState({ production_event_id: event.id}));
        // const tempEvent =  userReducer.events?.find((e) => e.id == event.id)
        // if(tempEvent?.status_type_order <= 3) {
           
        // } else {
        //     navigate('/completedevent', {replace: true})
        // }

        // setSelectedEventId(id);
        // const tempEvent =  userReducer.events?.find((e) => e.id == event.id)
        // console.log({tempEvent})
        // if(tempEvent != null) {
        //     if(tempEvent.revision?.id != null && tempEvent.revision?.submitted == true) {
        //         dispatch(updateUserState({under_revision: true}))
        //     } else {
        //         dispatch(updateUserState({under_revision: false}))
        //     }
        //     dispatch(updateUserState({ production_event_id: event.id, selected_event : tempEvent}));
        //     if(tempEvent.status_type == 'Review'|| tempEvent.status_type == 'Complete' ){
        //         navigate('/completedevent')
        //     }else{

        //         navigate("/productionevent");
        //     }
        // }
    

        // setOpenDrawer(false);
    };

    const deleteConfirmHandler = ()=> {
        deleteProject({projectId: scriptReducer.delete_project_id}, ()=> dispatch(updateSriptState({open_delete_popup: false})))
    }
    const goToLuciVideos = ()=> {
       
        window.open(`${process.env.REACT_APP_LUCIHUB_URL}`, '_blank')
    }

    return (
        <>{commonReducer?.list_view ? <>
        <ListViewDashboard/>
        
    </> : <><AuthLayout sx={{ padding: '2% 3.2% 2.1% 2.2%' }}>
    <Box gap={3} className='flex row a-center j-end' sx={{ pr: {xs:1, sm:3} }} >
        <img onClick={()=> window.location.href=`${process.env.REACT_APP_LUCIHUB_URL}`} src={luciNavigation} style={{ borderRadius: '5px', width: '280px', cursor: 'pointer'}} />
            <CustomUserCard />
        </Box>
        <Box className='flex row justify-center align-center' >

            <Box id="left" className='flex col' sx={{ width: { xs: '38%', md: '30%', lg: '28%', xl: '25%' }, }} >
                <MainLogoCard/>
                <CustomCard>
                    <ProjectSort sortByOptions={eventSortOptions}
                         selectedSortbyValue={selectedSortbyValue}
                         setSelectedSortbyValue={setSelectedSortbyValue}
                         menuTitle = "Sort By" />
                </CustomCard>

                {/* <CustomCard
                    margin='20px 0px 0px 0px'
                    // height={{ xs: '15vh', sm: '230px', md: '18vh', lg: '18vh', xl: '18vh' }}
                    //  width= {{xs:'200px', sm: '230px', md: '260px', lg: '320px', xl: '18.8vw' }} 
                    sx={{ flex: 1, cursor: 'pointer' }}
                    onClick={()=> {dispatch(updateUserState({play_tutorial: true})) ; gaEvents.clickedOnTipsAndTricks()}}
                >
                     <img src={filmingTutorialImage} style={{objectFit:'cover', height: '100%', borderRadius:'5px', width: '100%'}} />
                </CustomCard> */}
                 <Box margin='20px 0px 0px 0px' >
                <FilmingTutorialCard onClick= {()=> {dispatch(updateCommonState({play_tips_tricks_video: true})); gaEvents.clickedOnTipsAndTricks()} } clickable = {true} type = 'voice_studio'  />
              </Box>
            </Box>
            <Box id="right" width='100%' minWidth= '300px' ml={4.5}>
                <Box width='100%' className= 'flex row j-between' sx={{ marginTop: {xs: '30px', md: '38px', lg:'46px', xl:'54px'}, marginBottom: {xs: '12px', md: '15px', lg:'20px', xl:'30px'}}}>

                <Box id="search" 
                    sx={{flex: 1}}
                    maxWidth='300px'
                    //sx={{ marginBottom: { xs: '12px', md: '15px', lg: '18px', xl: '30px' } }} 
                >
                    <Input
                        id="search"
                        value={searchString}
                        onChange={handleSearch}
                        inputProps={{ maxLength: 30 }}
                        sx={{
                          //  minWidth: '160px',
                          width: '90%',
                          maxWidth:'300px',
                            borderBottom: 1.5,
                            borderColor: "#fff",
                            input: {
                                fontSize: { xs: '12px', md: '13px', lg: '14px', xl: '20px' },
                                fontWeight: 400,
                                color: "#fff",
                            },
                        }}
                        placeholder="Search"
                        startAdornment={
                            <IconButton sx={{paddingBottom:1, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'17px',xl:'24px'}}, paddingRight:2}} >
                                <SearchIcon style={{ color: "#fff" }} />
                            </IconButton>
                        }
                        />
                </Box>
                {/* <Box sx={{display:'flex', justifyContent:'end', alignItems:'center', 
                            //marginBottom:{xs:'10px',sm:'14px',md:'16px',lg:'18px',xl:'25px'}, 
                           // marginTop:{xs:'5px',sm:'5px',md:'5px',lg:'10px',xl:'20px'}, 
                            cursor:'pointer'}} gap={{xs:0.3,sm:0.5, md:0.7, lg:1, xl:1.2}} pr={1}>

                    <CustomPopoverFilter listType= 'event_type_filter'  />
                </Box> */}
                <Box sx={{display:'flex', justifyContent:'end', alignItems:'center', 
                            //marginBottom:{xs:'10px',sm:'14px',md:'16px',lg:'18px',xl:'25px'}, 
                           // marginTop:{xs:'5px',sm:'5px',md:'5px',lg:'10px',xl:'20px'}, 
                            cursor:'pointer'}} gap={{xs:0.3,sm:0.5, md:0.7, lg:1, xl:1.2}} pr={1}>
                       
                       {/* <CustomSortOptions
                         sortByOptions={eventSortOptions}
                         selectedSortbyValue={selectedSortbyValue}
                         setSelectedSortbyValue={setSelectedSortbyValue}
                         menuTitle = "Sort By"
                         // onChange={(newValue) => console.log(newValue)}
                         /> */}

                   </Box>
                </Box>
                {/* <Box id="events" className='flex row f-wrap j-start a-start' sx={{ height: { xs: '78vh', sm: '66vh', md: '62vh', lg: '54vh', xl: '68vh' }, overflowY: "scroll", whiteSpace: 'nowrap', alignContent: 'flex-start' }} > */}
                <Box id="events" className='flex row f-wrap f-start a-start' sx={{ height: { xs: '71vh', sm: '67vh', md: '64vh', lg: '61vh', xl: '66vh' }, overflowY: "scroll", whiteSpace: 'nowrap', alignContent: 'flex-start', backgroundColor:'transparent',scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': {display : 'none'}, }} >
                    {/* {
                        sortedEvents?.length > 0 ? sortedEvents?.map((event) => {
                            return <EventCard onClick={() => onEventClick(event)} eventId={event.id} sx={{ mt: 2, mr: 2, cursor: 'pointer' }} />
                        }) :
                        <Box className= 'flex col j-center a-center' sx={{overflowY: "initial", whiteSpace: 'normal'}} height='100%' width='100%'><Typography sx={{fontSize: 30, color: '#878887', fontWeight: 300, textAlign: 'center', textTransform: 'Uppercase'}} >Create your new project by clicking the Add button.</Typography></Box>
                    } */}
                    {
                        sortedEvents?.length > 0 ? sortedEvents?.map((event) => {
                            return <EventCard onClick={() => onEventClick(event)} event={event} sx={{ mt: 2, mr: 2, cursor: 'pointer' }} />
                        }) :
                        <Box className= 'flex col j-center a-center' sx={{overflowY: "initial", whiteSpace: 'normal'}} height='100%' width='100%'><Typography sx={{fontSize: 30, color: '#fff', fontWeight: 300, textAlign: 'center', mb:1}} >Welcome to Lucihub's</Typography>
                        <Typography sx={{fontSize: 30, color: '#fff', fontWeight: 600, textAlign: 'center', textTransform: 'Uppercase'}} >Assistant Creative Director!</Typography>
                        <Typography sx={{fontSize: 30, color: '#fff', fontWeight: 300, textAlign: 'center', mt:3}} >Start your first project by clicking <br/> on the    <AddIcon sx={{ fontSize: { xs: '25px', sm: '25px', md: '30px', lg: '40px', xl: '40px' }, boxShadow:'0px 5px 15px #FFFFFF', borderRadius:'10px', }} />   button here.</Typography></Box>
                    }
                 
                   
                </Box>
                <Box className='flex row j-end a-center' width='100%' sx={{ mt: 2 }} >
                        {/* <img src={arrow} style={{width: '30%',  position: 'absolute'}} /> */}
                        <Typography sx={{fontSize: '21px', color: '#fff', fontWeight: 300}} >CREATE NEW PROJECT</Typography>
                        <img src={rightArrowLong} style={{height: '12px', marginRight: 12, marginLeft: 12}} />
                        <Box onClick={() =>{ 
                            dispatch(updateProjectCreationState({open_backdrop_project_creation: true}))
                            gaEvents.projectFormOpened()
                        }} 
                        sx={{ cursor: 'pointer', mr: {sm: '12px', md: '16px', lg: '20px', xl: 3} }} >
                        <AddIcon sx={{ fontSize: { xs: '40px', sm: '44px', md: '50px', lg: '56px', xl: '82px' }, }} />
                        </Box>
                    </Box>

            </Box>
            <Box>

            </Box>
        </Box>
        {
            createProjectReducer.open_form == true && <EventCreationDrawer/>
        }
       {
            commonReducer.play_tips_tricks_video == true && <PopupVideoPlayer tutorial type= "voice_studio" />
       }

       {createProjectReducer?.open_backdrop_project_creation === true && <BackdropCreateProject />}
       {contentReducer?.create_content_open === true && <BackdropCreateContent />}
       {scriptReducer.open_delete_popup && <DeleteConfirmationPopup 
            open={scriptReducer.open_delete_popup} 
            closeHandler = {()=> dispatch(updateSriptState({open_delete_popup: false}))} 
            deleteConfirmHandler = {deleteConfirmHandler}
            message = 'Are you sure you want to remove this project permanently? All the content related to this project will be erased. Please type "delete" below to confirm.'
        />} 
      
    </AuthLayout>
    </>
    
}
    </>
    )
}
export default Dashboard;