import React, { useEffect, useState } from 'react'
import CustomInput from '../../eventcreation/components/CustomInput'
import { IconButton, InputAdornment } from '@mui/material'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserState } from '../../../redux/userSlice';
import useContentActions from '../hooks/useContentActions';
import CustomTooltip from '../../../components/CustomTooltip';
import { removeWhiteSpaces } from '../../../utils/commonServices';

const ScriptInputBox = () => {

  const [text, setText] = useState('')
  const userReducer = useSelector((state) => state.persistedReducers.userReducer);
  const scriptReducer = useSelector((state)=>state.scriptReducer)
  const contentReducer = useSelector((state)=>state.contentReducer)
  const dropdownReducer = useSelector((state)=>state.persistedReducers.dropdownReducer)
  const [storyboardPrompt, setStoryboardPrompt] = useState(scriptReducer.selected_project_details.description);
  const contentTypeId = dropdownReducer.project_type?.find(type=> removeWhiteSpaces(type.name)?.toLowerCase() == removeWhiteSpaces(contentReducer?.selected_content_details.content?.type)?.toLowerCase())?.id
  const dispatch = useDispatch();
  const {createContent, updateContent} = useContentActions();

  //code to detect enter key for editing Organization Name
  // useEffect(() => {
  //   const keyDownHandler = (event) => {
  //     // console.log("key Parent", event.key)
  //     if (event.key === "Enter") {
  //       event.preventDefault();

  //       handleSendPrompt();
  //     }
  //   };
  //     document.addEventListener("keydown", keyDownHandler)
    

  //   return () => {
  //     document.removeEventListener("keydown", keyDownHandler);
  //   };
  // }, []);

  const handleSendPrompt=()=>{
  
    
    // if(scriptReducer.selected_project_details.project_type_id === 3) {
    //   setStoryboardPrompt(scriptReducer.selected_project_details?.contents?.length === 0 ? scriptReducer.selected_project_details?.description : text)
    //   //dispatch(updateUserState({storyboard_item_number: 1}))
    // } else {
      // const payload = {
      //   "contentTypeId": scriptReducer.selected_project_details.projectTypeId,
      //   "projectLength": `${scriptReducer.selected_project_details.length} seconds`,
      //  // "storyboardCount": parseInt(scriptReducer.selected_project_details.length)/5,    
      //   "projectId": scriptReducer.selected_project_details.id,
      //   "personaId": scriptReducer.selected_project_details.personaId,
      //   "prompt": scriptReducer.selected_project_details?.contents?.length === 0 ? scriptReducer.selected_project_details?.description : text

      // }
      // createContent(payload)
      const payload = {
        "projectTypeId": contentTypeId,
        "contentId": contentReducer.selected_content_details.id,
       // "projectLength": `${scriptReducer.selected_project_details.length} seconds`,
        // "storyboardCount": scriptReducer.selected_project_details.projectTypeId === 3 ?   (parseInt(scriptReducer.selected_project_details.length) > 4 ? 4 : parseInt(scriptReducer.selected_project_details.length)): 0,    
        "projectId": scriptReducer.selected_project_details.id,
        "personaId": contentTypeId == 9 ? 1 : scriptReducer.selected_project_details.personaId,
        "prompt": text

       }
       const createPayload = {
       query: {
        "contentTypeId": scriptReducer.selected_project_details.projectTypeId,
        "projectLength": scriptReducer.selected_project_details.length,
        "storyboardCount": scriptReducer.selected_project_details.projectTypeId === 3 ? (parseInt(scriptReducer.selected_project_details.length) > 3 ? 3 : parseInt(scriptReducer.selected_project_details.length)) : 0,    
        "projectId": scriptReducer.selected_project_details.id,
        "personaId": scriptReducer.selected_project_details.personaId,
      },
      data: {
         "prompt": text
        }
      


      }
      // const payload = {
      //   "projectTypeId": 1,
      //   "contentId": 23,
      //  // "projectLength": `${scriptReducer.selected_project_details.length} seconds`,
      //   "storyboardCount": parseInt(scriptReducer.selected_project_details.length)/5,    
      //   "projectId": 16,
      //   "personaId": 1,
      //   "prompt": scriptReducer.selected_project_details?.contents?.length === 0 ? scriptReducer.selected_project_details?.description : text

      // }
   scriptReducer.selected_project_details?.contents?.length > 0 ? updateContent(payload) : createContent(createPayload)

    setText('')
   // }
    
  }
 
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
       if ((contentReducer?.create_content_open === false && contentReducer?.open_delete_popup === false) && text?.length > 5) { handleSendPrompt();}
      }
    };
      document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [text, contentReducer?.create_content_open, contentReducer?.open_delete_popup]);

  return (
    <>
              
      <CustomInput 
        type='sendInput' 
        disabled = {scriptReducer.selected_project_details.projectTypeId === 3}
        buttonDisabled = {text?.length < 5} 
        onButtonClick={handleSendPrompt}
        onChange={(e)=> setText(e.target.value)} 
        value= {text} 
        size='small' 
        max={25000} 
        style={{backgroundColor:'white',}}  
        sx={{width:'100%', 
        input:{
          paddingTop:'5px',
          paddingBotttom:'5px'
        },
        paddingTop:'5px',
          paddingBotttom:'5px'
          }} 
        placeholder='Type your refinements here…'           
        /> 
              
    </>
  )
}

export default ScriptInputBox