import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
  voices:[],
  genre: [],
  phone_count_range: [],
  tones: [],
  capture_quality_list: [],
  event_type:[],
  event_type_filter: [],
  video_length:[],
  status_type: [],
  selected_status: [],
  project_type:[],
  project_length: [],
  script_length:[
    {id:1, value: '15 seconds'},
    {id:2, value: '30 seconds'},
    {id:3, value: '60 seconds'},
    {id:4, value: '90 seconds'},
    {id:5, value: '120 seconds'},
    {id:6, value: '3 minutes'},
    {id:7, value: '4 minutes'},
  ],
  persona_id:[
    {id:1, value:'Novice'},
    {id:2, value: 'Creative'},
    {id:3, value: 'Expert'}
  ]
};

export const dropdownSlice = createSlice({
  name: "dropdown",
  initialState: initialValue,
  reducers: {
    setDropDownArray: (state, action) => {
      assignValueInState(action.payload, state);
    },
    emptyDropdownArray : (state) => {
      assignValueInState(initialValue, state);
    },
  },
});

const assignValueInState = (obj, state) => {
  for (const key in obj) {
    state[key] = obj[key];
  }
};

export const { setDropDownArray, emptyDropdownArray } = dropdownSlice.actions;
export default dropdownSlice.reducer;