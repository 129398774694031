import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    user_id: "",
    user_email: "",
    user_name: "",
    token: "",
    is_logged: false,
    /////////////////////////creative director//////////////////////////
    all_projects: [],
    selected_project_details: {},
    selected_content_id: null,
    selected_content_details: {},
    storyboard_item_number: null,
    project_id: null,
    isLoading: false,
    account_delete_popup:false,
    footer_disclaimer: false,
    signup_disclaimer: false,
    butterfly_access: false,
    page_loading: false,
    destination_path: "/",

}
export const userSlice = createSlice({
    name: 'user',
    initialState: initialValue,
    reducers: {
        updateUserState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetUserState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateUserState, resetUserState } = userSlice.actions
export default userSlice.reducer