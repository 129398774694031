import { createQueryParams } from "../utils/commonServices"
import { apiConstant } from "./apiConstants"
import { Request } from "./service"
import axios from 'axios'

// export const testApi = () => {
   
//     return Request(apiConstant.testApi, 'Get')
// }


// export const userLogin = (params) => {
//     return Request(apiConstant.user.login, 'Get', params, false)
// }

//************************SESSIONS API SERVICES**************************************** */

// export const checkSession= () => {
//     return Request(apiConstant.session.checkSessionValidity, 'Get')
// }

//********************USER API SERVICES************************************** */

export const userSignup= (params) => {
    return Request(apiConstant.user.signup, 'Post', params, true)
}
export const appleSign= (params) => {
    return Request(apiConstant.user.appleSign, 'Post', params, true)
}
export const verifyAppleUser= (params) => {
    return Request(apiConstant.user.verifyAppleUser, 'Get', params, false)
}
export const userSignin= (params) => {
    return Request(apiConstant.user.signin, 'Get', params, false)
}
export const checkEmail = (params) => {
    return Request(apiConstant.user.checkEmail, 'Get', params, false)
}
export const resendLink = (params) => {
    return Request(apiConstant.user.resendLink, 'Get', params, false )
}


export const forgotPassword = (params) => {
    return Request(apiConstant.user.forgotPassword, 'Get', params, false)
}

export const checkVerificationToken = (params) => {
    return Request(apiConstant.user.checkVerificationToken, 'Get', params, false)
}

export const verifyUserMail = (params) => {
    return Request(apiConstant.user.verifyUserMail, 'Get', params, false)
}

export const resetPassword = (params) => {
    return Request(apiConstant.user.resestPassword, 'Put', params, true)
}

export const deleteUser= (params) => {
    return Request(`${apiConstant.user.deleteUser}/${params.id}`, 'Delete', params.param, true)
}

export const userRoleApi = (params) => {
    return Request(apiConstant.user.userRole, 'Get', params, false)
}

export const checkButterflyAccessApi = (params) => {
    return Request(apiConstant.user.checkButterflyAccess, 'Get', params, false, {"product": process.env.REACT_APP_PRODUCT_ID})
}

//************************EVENTS API SERVICES**************************************** */

// export const createEvent= (params) => {
//     return Request(apiConstant.event.create, 'Post', params, true)
// }

// export const editEvent= (params) => {
//     return Request(`${apiConstant.event.create}/${params.id}`, 'Put', params.param, true)
// }
// export const cancelEvent= (params) => {
//     return Request(apiConstant.event.cancelEvent, 'Put', params, false)
// }

// export const addEvent = (params) => {
//     return Request(apiConstant.event.addEvent, "Post", params, true);
//   };

// export const eventsList= () => {
//     return Request(apiConstant.event.create, 'Get')
// }

// export const eventDetails= (params) => {
//     return Request(`${apiConstant.event.create}/${params.id}`, 'Get')
// }

// export const uploadVideo= (params) => {
//     return Request(apiConstant.event.uploadVideo, 'Post', params.data, true, params.progress, params.header, params.controller )
// }

// export const uploadList= (params) => {
//     return Request(apiConstant.event.uploadList, 'Get', params, false)
// }

// export const uploadListToLinkEvent = (params) => {
//     return Request(apiConstant.event.uploadListToLinkEvent, 'Get', params, false)
// }

// export const importFiles= (params) => {
//     return Request(apiConstant.event.importFiles, 'Post', params, true)
// }

// export const allMediaFiles = () => {
//     return Request(apiConstant.event.allMediaFiles, 'Get')
// }

// export const createZippedMedia= (params) => {
//     return Request(apiConstant.event.createZippedMedia, 'Post', params, true)
// }

// export const downloadZippedMedia = (params) => {
//     return Request(apiConstant.event.downloadZippedMedia, 'Get', params, false, )
// }

// export const downloadMedia = (params) => {
//     return Request(apiConstant.event.downloadMedia, 'Get', params, false, )
// }

// export const favoriteMedia = (params) => {
//     return Request(apiConstant.event.favoriteMedia, 'Post', params, false, )
// }

// export const deleteMedia= (params) => {
//     return Request(`${apiConstant.event.uploadList}/${params.id}`, 'Delete', params.param, true)
// }

// export const guestEventDetails= (params) => {
//     return Request(apiConstant.event.guestEvent, 'Get', params, false)
// }

// export const acceptFinalVideo= (params) => {
//     return Request(apiConstant.event.acceptFinalVideo, 'Put', params, false)
// }

//************************OPTIONS API SERVICES**************************************** */

// export const genreList = (params) => {
//     return Request(apiConstant.options.genre, 'Get', params, false)
// }

// export const toneList = (params) => {
//     return Request(apiConstant.options.tone, 'Get', params, false)
// }

// export const phoneCountRanges = (params) => {
//     return Request(apiConstant.options.phoneCount, 'Get', params, false)
// }
// export const qualityOptionList = (params) => {
//     return Request(apiConstant.options.qualityOption, "Get", params, false);
//   };
//   export const eventTypeList = (params) => {
//     return Request(apiConstant.options.eventType, 'Get', params, false)
// }

// export const videoLengthList = (params) => {
//     return Request(apiConstant.options.videoLength, 'Get', params, false)
// }
//   export const eventStatusType = (params) => {
//     return Request(apiConstant.options.statusType, 'Get', params, false)
// }
// ////////////////////////////////consent api//////////////////////////////////

// export const consentDetail = (params) => {
//     return Request(apiConstant.consents.consentDetail, 'Get', params, false )
// }

// export const eventVideoUploadConsent= (params) => {
//     return Request(apiConstant.consents.eventVideoUploadConsent, 'Post', params, true)
// }

////////////////////////////////revision////////////////////////////////

// export const videoUrl = (params) => {
//     return Request(apiConstant.revision.videoUrl, 'Get', params, false )
// }
// export const checkNewVideo = (params) => {
//     return Request(apiConstant.revision.checkNewVideo, 'Get', params, false )
// }
// export const createRevision = (params) => {
//     return Request(apiConstant.revision.createRevision, 'Post', params, true )
// }
// export const getRevisionLogs = (params) => {
//     return Request(apiConstant.revision.getRevisionLogs, 'Get', params, false )
// }
// export const createRevisionLogs = (params) => {
//     return Request(`${apiConstant.revision.getRevisionLogs}?user_id=${params.id}`, 'Post', params.param, true )
// }
// export const submitRevision = (params) => {
//     return Request(`${apiConstant.revision.submitRevision}?revision_id=${params.id}`, 'Put' )
// }

///////////////////Project API Services//////////////////////
export const getAllProjectsListApi = () => {
    return Request(apiConstant.project.manageProjects, 'Get')
}

export const getProjectDetailsApi = (params) => {
    return Request(`${apiConstant.project.manageProjects}/${params}`, 'Get')
}

export const createProjectApi = (params) => {
    return Request(`${apiConstant.project.manageProjects}?${createQueryParams(params.query)}` , 
                  'Post', 
                  params.data , 
                  true, 
                  {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                  }
                 )
}

export const deleteProjectApi = (params) => {
    return Request(apiConstant.project.deleteProject , 
                  'Post', 
                  params , 
                  true, 
                  {
                    'Content-Type': 'application/json',
                    "Accept": "application/json-patch+json",
                  }
                 )
}
export const getProjectTypeListApi = (params) => {
   // console.log("url", apiConstant.project.getProjectTypeMaster)
    return Request(apiConstant.project.getProjectTypeMaster , 
                  'Get'
                )
}
export const getProjectLengthListApi = (params) => {
    return Request(apiConstant.project.getProjectLengthMaster , 
                  'Get'
                )
}

//******************* Content API Services **************************
export const createContentApi = (params) => {
    return Request(`${apiConstant.content.manageContents}?${createQueryParams(params.query)}` , 
                  'Post', 
                  params.data , 
                  true, 
                 {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                  })
}

export const createImageApi = (params) => {
    let formData = JSON.stringify(params)
    const token = window.localStorage.token
    return axios({
        url: apiConstant.content.createImage ,
        method: 'Post', 
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
          },
        data: formData,
      })
}

export const convertContentApi = (params) => {
    return Request(apiConstant.content.convertContent , 
                  'Post', 
                  params , 
                  true, 
                 {
                    'Content-Type': 'application/json',
                    "Accept": "application/json-patch+json"
                  })
}


export const getProjectContentsApi = (params) => {
    return Request(`${apiConstant.content.getProjectContents}/${params.id}`, 'Get')
}
export const getContentDetailApi = (params) => {
    //console.log({params})
    return Request(`${apiConstant.content.manageContents}/${params.id}`, 'Get')
}
export const updateContentDetailApi = (params) => {
    //return Request(`${apiConstant.content.manageContents}/${params.id}`, 'Put')
    return Request(apiConstant.content.updateContents , 
        'Post', 
        params , 
        true, 
       {
          'Content-Type': 'application/json',
          "Accept": "application/json-patch+json"
        })
}

export const deleteContentApi = (params) => {
    //return Request(`${apiConstant.content.manageContents}/${params.id}`, 'Put')
    return Request(apiConstant.content.deleteContent , 
        'Post', 
        params , 
        true, 
       {
          'Content-Type': 'application/json',
          "Accept": "application/json-patch+json"
        })
}

/*******************************VOICE OVER API*************************************************** */

export const updateVoContentApi = (params) => {
    //return Request(`${apiConstant.content.manageContents}/${params.id}`, 'Put')
    return Request(`${apiConstant.content.updateVo}?${createQueryParams(params.query)}` , 
        'Post', 
        params.data , 
        true, 
       {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
         // "Accept": "application/json-patch+json"
        })
}

/*************************VOICE PROFILE API****************************************************** */

export const updateVoiceProfileApi = (params) => {
    //return Request(`${apiConstant.content.manageContents}/${params.id}`, 'Put')
    return Request(apiConstant.content.updateVoiceProfile, 
        'Post', 
        params , 
        true, 
       {
            'Content-Type': 'application/json',
            "Accept": "application/json-patch+json"
         // "Accept": "application/json-patch+json"
        })
}
export const createVoiceProfileApi = (params) => {
    //return Request(`${apiConstant.content.manageContents}/${params.id}`, 'Put')
    return Request(apiConstant.content.createVoiceProfile, 
        'Post', 
        params , 
        true, 
       {
            'Content-Type': 'application/json',
            "Accept": "application/json-patch+json"
         // "Accept": "application/json-patch+json"
        })
}

export const deleteVoiceProfileApi = (params) => {
    //return Request(`${apiConstant.content.manageContents}/${params.id}`, 'Put')
    return Request(`${apiConstant.content.deleteVoiceProfile}` , 
        'Post', 
        params , 
        false, 
       {
          'Content-Type': 'application/json',
          "Accept": "application/json-patch+json"
        })
}

export const getVoiceProfileApi = () => {
    //return Request(`${apiConstant.content.manageContents}/${params.id}`, 'Put')
    return Request(apiConstant.content.getVoiceProfiles, 'Get')
}

export const checkSessionApi = (params) => {
    return Request(apiConstant.user.checkSession, 'Get', params, false, {"product": process.env.REACT_APP_PRODUCT_ID})
}
