import React, { useEffect } from 'react'
import CustomDrawer from './components/CustomDrawer'
import '../../App.css'
import { Box, TextField, Typography } from '@mui/material'
import { makeStyles } from "@mui/styles";
import CustomInput from './components/CustomInput';
import { useState } from 'react';
// import useEventList from '../../hooks/useEventList';
import { useDispatch, useSelector } from 'react-redux';
import "@fontsource/rubik";
import CreateProject from './components/CreateProject';
import { createContent, tempContentCreate } from './actions';
// import CustomInput from '../../components/CustomIn
// import CreateEvent from '../auth/dashboard/events/EventCreationV2';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight:'100vh',
    // maxWidth:'100vw',
    backgroundImage: 'linear-gradient(140deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
    display:'flex',
    width:'100vw',
    minWidth:'860px'
},

  scroll: {
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }

  }
}));


const EventCreationDrawer = (props) => {

    const classes = useStyles()
    const {openDrawer, setOpenDrawer} = props
    const initialData = {
      name : "",
      start_date: new Date().toISOString(),
      end_date: new Date().toISOString(),
      genre_id: "",
      event_tones: [],
      video_orientation: "landscape", // landscape | portrait
      phone_count_id: "",
      quality_options: [],
      description: ""

  }
const [pageData, setPageData] = useState(initialData);
const [snackbarProps, setSnackbarProps] = useState({});
// const [refetchEvents] = useEventList();
// const [createEvent, createEventStatus, eventId] = useEventRequest("create", refetchEvents)
// const [editEvent, editEventStatus] = useEventRequest("edit", refetchEvents)
//const [enableEvents, setEnableEvents] = useState(false);

const [pageIndex, setPageIndex] = useState(0);

// useEffect(()=> {
//   tempContentCreate();
// },[])
  return (
    <CustomDrawer >

          <Box textAlign='left'  sx={{marginBottom:{xs:'10px',sm:'10px',md:'15px',lg:'20px',xl:'40px'}}}>
            <Typography sx={{fontFamily: 'Rubik', fontSize:{xs:'18px',sm:'18px',md:'16px',lg:'20px',xl:'30px'}, fontWeight:600}} >Create your new Project</Typography>
          </Box>
          {/* <CreateEvent submitRequest={createEventReducer.edit == true ? editEvent : createEvent} requestStatus = {createEventReducer.edit == true ? editEventStatus : createEventStatus}/> */}
          <CreateProject />
        
    </CustomDrawer>
  )
}

export default EventCreationDrawer