import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUserState } from "../../../redux/userSlice";
import { getProjectDetailsApi } from "../../../services/api";
import { updateSriptState } from "../../../redux/projectScriptSlice";
import { removeWhiteSpaces, showSnackBar } from "../../../utils/commonServices";
import { resetPendingCallState, updatePendingCallState } from "../../../redux/pendingCallSlice";
import { resetProjectCreationState } from "../../../redux/projectCreationSlice";
export default ()=> {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const dropdownReducer = useSelector((state)=>state.persistedReducers.dropdownReducer)
    // APi to get project details by project Id
const getProjectDetails = (payload, callBack ) => {
    dispatch(updatePendingCallState({loader_backdrop_open: true,
        loader_text: {title: "Please wait", message: "Loading your projects..."}}))
    getProjectDetailsApi(payload).then(response => {
        dispatch(resetPendingCallState())
        if(response.data?.httpCode === 200){
            //console.log("here")
            dispatch(updateSriptState({
                selected_project_details: {
                    contents: response.data?.contents, 
                    description: response.data?.description, 
                    endDate: response.data?.endDate ,
                    id: response.data?.id,
                    length: response.data?.length,
                    message: response.data?.message, 
                    name:response.data?.name , 
                    personaId: response.data?.personaId,
                    projectType: response.data?.projectType,
                    projectTypeId: dropdownReducer.project_type?.find((type)=> removeWhiteSpaces(type.name)?.toLowerCase() === removeWhiteSpaces(response.data?.projectType)?.toLowerCase())?.id,
                    startDate: response.data?.startDate
                }
            }))
            // if(response.data.projectType.toLowerCase().trim() === "storyboard") {
            //    // let tempContents = removeDuplicateArrayItem(response?.data?.contents, "contentID")
            //    // dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
            // } else {
            //    // dispatch(updateUserState({selected_project_details: {...response?.data, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
            // }
           
            // if(navigate) {
            //     if(response?.data?.contents?.length > 0) {
            //         dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
            //        // getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
            //     }
                
            // }    
            //callBack()
        }else{
           // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        }
    }).catch(error =>{
       // console.log("Error", error.response.status)
       if(error) {
        dispatch(resetPendingCallState())
        showSnackBar(error?.response, dispatch)
       }
        
       // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    })
}

    return {getProjectDetails}

}