import React, { useEffect, useState } from 'react'
import { Backdrop } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { resetProjectCreationState } from '../../../redux/projectCreationSlice';
import { updateContentState } from '../../../redux/contentSlice';

import ProjectNamePage from '../../eventcreation/components/commonProject/ProjectNamePage';
import useEventCreationActions from '../../eventcreation/hooks/useEventCreationActions';
import ProjectTypePage from '../../eventcreation/components/commonProject/ProjectTypePage';
import ImageProjectCreation from '../../eventcreation/components/imageProject/ImageProjectCreation';
import CommonContentCreation from './CommonContentCreation';
import BackdropWrapper from '../../eventcreation/components/BackdropWrapper';
import ImageContentCreation from './ImageContentCreation';
import VoiceOverContentCreation from './VoiceOverContentCreation';




const BackdropCreateContent = () => {

    const [pageIndex, setPageIndex] = useState(1)
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const userReducer = useSelector((state)=>state.persistedReducers.userReducer)
    const dropdownReducer = useSelector((state)=> state.persistedReducers.dropdownReducer )
   // const [pageData, setPageData] = useState(createProjectReducer.pageData)
    const [slideDirection, setSlideDirection] = useState('up')
    const dispatch = useDispatch()
    const [totalScreens, setTotalScreens] = useState(5);
    const {getProjectTypeList, getProjectLengthList, createProject} = useEventCreationActions();

    useEffect(()=>{
      if(createProjectReducer.open_backdrop_project_creation === true || contentReducer?.create_content_open){
        document.body.style.overflow = 'hidden';
      }
    },[createProjectReducer.open_backdrop_project_creation, contentReducer?.create_content_open])

    useEffect(()=> {
      switch(parseInt(createProjectReducer.commonPageData.projectTypeId)) {
        case 8: createProjectReducer.voiceOverPageData.needScript ? setTotalScreens(5) : setTotalScreens(4)
                break;
        case 9: setTotalScreens(6);
                break;
          default: setTotalScreens(4) ;
      }
      // if(parseInt(createProjectReducer.commonPageData.projectTypeId) == 9) {
      //   setTotalScreens(6);
      // } else {
      //   setTotalScreens(4)
      // }
     // dropdownReducer?.project_type?.length < 1 && getProjectTypeList()
      //dropdownReducer?.project_length?.length < 1 && getProjectLengthList();
      // setPageData((last)=>{
      //   return {...last, user_id: userReducer.user_id, organisation_id: userReducer.organisation_id ?? 0 }
      // })
    },[createProjectReducer.commonPageData.projectTypeId])

    const prevPageHandler =()=>{
        if(pageIndex >1){
            setPageIndex(prev => prev - 1)
            setSlideDirection('right')
        }
    }

    const nextPageHandler =()=>{
        if(pageIndex < totalScreens ){
            setPageIndex(prev => prev + 1)
            setSlideDirection('left')
        }
    }


    const closeHandler = () => {
      dispatch(resetProjectCreationState())
      dispatch(updateContentState({create_content_open :  false}))
    };


  return (
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(15px)', display:'flex', flexDirection:'column', background:'transparent'}}
    open={createProjectReducer.open_backdrop_project_creation || contentReducer?.create_content_open}
    >


    {/* {pageIndex === 1 && 
      <ProjectTypePage 
      slideDirection={slideDirection}
      pageIndex={pageIndex}
      prevPageHandler={prevPageHandler}
      nextPageHandler={nextPageHandler}
      closeHandler={closeHandler}
      totalScreens={totalScreens}
    />
  
    } */}

    {pageIndex === 1 && 
    <BackdropWrapper slideDirection={slideDirection}>
      <ProjectTypePage 
      slideDirection={slideDirection}
      pageIndex={pageIndex}
      prevPageHandler={prevPageHandler}
      nextPageHandler={nextPageHandler}
      closeHandler={closeHandler}
      totalScreens={totalScreens}
    />
    </BackdropWrapper>
    }


      { parseInt(createProjectReducer.commonPageData.projectTypeId) == 9 ? 
            <ImageContentCreation 
                slideDirection={slideDirection}
                pageIndex={pageIndex}
                prevPageHandler={prevPageHandler}
                nextPageHandler={nextPageHandler}
                closeHandler={closeHandler}
                totalScreens={totalScreens}
            />  : parseInt(createProjectReducer.commonPageData.projectTypeId) == 8 ? 
              <VoiceOverContentCreation 
              slideDirection={slideDirection}
              pageIndex={pageIndex}
              prevPageHandler={prevPageHandler}
              nextPageHandler={nextPageHandler}
              closeHandler={closeHandler}
              totalScreens={totalScreens}
              setTotalScreens={setTotalScreens}
              totalAIScreens = {5} 
              totalUserScriptScreens = {4}
          /> 
            :<CommonContentCreation 
                    slideDirection={slideDirection}
                    pageIndex={pageIndex}
                    prevPageHandler={prevPageHandler}
                    nextPageHandler={nextPageHandler}
                    closeHandler={closeHandler}
                    totalScreens={totalScreens}
                  />
      }
   


    </Backdrop>
    </>
  )
}

export default BackdropCreateContent