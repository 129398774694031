import { makeStyles } from "@mui/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Dialog, DialogContent, IconButton, Box, Typography, DialogActions } from "@mui/material";
import { useState } from "react";
import { customTheme as theme } from '../../../theme/customTheme'
import '../../../App.css'

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function CustomAlert(props) {
  //   const [open, setOpen] = useState(true);
  const classes = useStyles();
  const { maxWidth, open, handleClose, rightComponent , width, height} = props;



  return (
    <>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth ? maxWidth : "lg"}
      slotProps={{
        backdrop: {
            sx: {
                background:'#DDDDDD20 0% 0% no-repeat padding-box',
                // backdropFilter: 'blur(1px)',
                backdropFilter: 'blur(8px) !important',
            }
        }
        
    }}
      PaperProps={{ 
        sx: { borderRadius: '5px', 
        height:height ? height : {sm:'330px' ,md:'430px' ,lg:'350px' ,xl:'555px'},
        width: width ?width :{lg:'820px',xl:'1400px'}, 
        backgroundColor:'transparent',
        boxShadow:'none'
        }}}
      fullWidth='lg'
      {...props}
    >

      <DialogContent
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" , margin:0, padding:0, backgroundColor:'transparent'}}
        id="alert-dialog-description"
      >
        <Box className='flex col' width='100%' height='90%' style={{borderRadius:'5px'}}>
            <Box className='flex row j-end' sx={{backgroundColor: 'transparent'}} >    
                <Typography onClick={handleClose} 
                    sx={{fontFamily: 'Rubik', textAlign: 'right', 
                         fontSize:{xs:'8px',md:'11px', xl:'14px'}, 
                         color: '#ffffff',
                         width: '60px', fontWeight:700, marginTop:{xs:'10px',sm:'15px',md:'20px',lg:'20px',xl:'44px'}, 
                         marginBottom:{xs:'10px',md:'14px',lg:'12px',xl:'24px'}, 
                         cursor:'pointer',
                         backgroundColor: 'transparent'
                        }}>Close X</Typography>
            </Box>

            <Box className='flex' width='100%' height='90%' style={{backgroundColor:'white', borderRadius:'5px', overflowX:'auto'}}>
                <Box  style={{  backgroundImage: 'radial-gradient(at bottom right, #039f10, #008d65, #007593, #005998, #003877, #0f2a65, #141c53, #140f41, #140f41, #121633, #121633 , #121633)', height:'100%', borderRadius:'5px'}} flex={3}>
                    {props.children}
                </Box>
                {rightComponent && <Box style={{backgroundColor:'white', height:'90%',  borderRadius:'5px'}} flex={1.2}>
                 {rightComponent}
                </Box>}
            </Box>
        </Box>
        
      </DialogContent>
    </Dialog>
  
    </>
  );
}
