import { Box, Button, Grid, Typography } from "@mui/material"
import { useEffect, useReducer, useState } from "react"
import { customTheme as theme } from "../../theme/customTheme";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import notFoundImage from '../../assets/404.svg';
import CustomButton from "../../components/CustomButton";
import AuthLayout from "../layouts/AuthLayout";
import UnauthLayout from "../unauth/components/UnauthLayout";
import { useDispatch, useSelector } from "react-redux";

const NoAccessPage = (props) => {

  const { componentStack, error } = props;
  const [displayError, updateDisplay] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {token} = useParams()
  const userReducer = useSelector((state) => state.persistedReducers.userReducer);

  // useEffect(()=> {
  //   if(!!token) {
  //     window.localStorage.setItem('token', token)
  //   } 
  // }, [token])

  // useEffect(()=>{
  //   if( userReducer?.is_logged == true){
  //     navigate('/dashboard', {replace:true});
  //   }else{
  //     navigate('/no-access', {replace:true});
  //   }
  

  //  },[userReducer?.is_logged])

  useEffect(()=> {
    if(userReducer.is_logged) {
        if(userReducer.butterfly_access) {
            navigate('/dashboard', {replace: true})
        } 
    }
   
}, [userReducer.butterfly_access, userReducer.is_logged])

  
  return (
    <UnauthLayout  rightComponent={
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', mt:10}}  pl={{xs:2,sm:4,md:8,lg: 10,xl:12}}>
        <Box sx={{height:{xs:180,sm:200,md:220,lg:'240px',xl:300}, marginBottom:{xs:'15px', sm:'15px',md:'20px',lg:'22.66px',xl:'34px'}}}>
             <img src={notFoundImage} alt="notFoundImage" height='100%' style={{objectFit:'cover', borderRadius:'10px'}} />
        </Box>
    </Box>
    }>
      
        <Box  flexDirection='column'>
          
         <Box sx={{marginY:{xs:'15px',md:'20px',lg:'25px',xl:'38px'}}}>
            <Typography
              variant="h4"
              color="primary"
              style={{
                fontWeight: 600,
                color:'#ffffff'
              }}
              sx={{fontSize:{xs:'30px',sm:'40px',md:'40px',lg:'50px',xl:'80px'}, fontFamily:'Rubik', color:'#ffffff'}}
            >Oh Shoot.</Typography>
          </Box>
          <Box item xs={11} sm={8} md={7} gap={0.8} sx={{display:'flex', flexDirection:'column',}}>
              <Typography sx={{color:'#ffffff', fontSize:{xs:'14px',md:'16px',lg:'18px',xl:'24px'}, fontFamily:'Rubik'}}>You do not have permission to access Creative Copilot. Please contact your organisation admin for Lucihub</Typography>
          </Box>

          {/* <Box width='100%' sx={{mt: 3, display: 'flex', flexDirection: {xs: 'column-reverse', sm: 'row'}, justifyContent: { xs: 'flex-start', sm: 'flex-start'}, alignItems: {xs: 'center', sm: 'flex-start'} }} >

               <CustomButton variant="contained" 
                  btnText="Go Home" 
                  style={{borderRadius: '5px', border:"none",  textTransform:'none', }}  
                  onClick= {()=> navigate('/dashboard', {replace: true})}
                  sx={{fontSize:{xs:'10px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'100px',lg:'120px',xl:'160px'}, fontFamily:'Rubik', backgroundColor:'#00CEFF', color:'#08231B'}}/>
       
          </Box> */}
                
         </Box>

       
       
    </UnauthLayout>
 

  )
}

export default NoAccessPage;