import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { updateUserState } from "../../../redux/userSlice";
// import { getProjectDetailsApi } from "../../../services/api";
// import { updateSriptState } from "../../../redux/projectScriptSlice";
// import { removeWhiteSpaces, showSnackBar } from "../../../utils/commonServices";
// import { resetPendingCallState, updatePendingCallState } from "../../../redux/pendingCallSlice";
// import { resetProjectCreationState } from "../../../redux/projectCreationSlice";
import { createVoiceProfileApi, deleteVoiceProfileApi, getVoiceProfileApi, updateVoiceProfileApi } from "../../../../../services/api";
import { updateVoiceState } from "../../../../../redux/voiceSlice";
import { resetPendingCallState, updatePendingCallState } from "../../../../../redux/pendingCallSlice";
import { gaEvents } from "../../../../../ga/actions";
import { showSnackBar } from "../../../../../utils/commonServices";
import { updateUserState } from "../../../../../redux/userSlice";
import { updateContentState } from "../../../../../redux/contentSlice";
export default ()=> {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const dropdownReducer = useSelector((state)=>state.persistedReducers.dropdownReducer)
    // APi to get list of voice profiles
const getVoiceProfiles = (callBack ) => {
   // dispatch(updatePendingCallState({loader_backdrop_open: true,
     //   loader_text: {title: "Please wait", message: "Loading your projects..."}}))
    getVoiceProfileApi().then(response => {
      //  console.log("profiles",response )
       // dispatch(resetPendingCallState())
        if(response.status === 200){
           // console.log("profiles",response?.data )
            //console.log("here")
          //  dispatch(resetProjectCreationState())  
          let customProfiles = [] 
          let defaultProfiles = []
          response?.data?.map((item) => {
            if(!!item.is_default_profile) {
               defaultProfiles.push(item)
            } else {
               customProfiles.push(item)
            }
          })
            dispatch(updateVoiceState({voice_profiles: [...customProfiles?.reverse(), ...defaultProfiles] }))
            !!callBack && callBack();
            // if(response.data.projectType.toLowerCase().trim() === "storyboard") {
            //    // let tempContents = removeDuplicateArrayItem(response?.data?.contents, "contentID")
            //    // dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
            // } else {
            //    // dispatch(updateUserState({selected_project_details: {...response?.data, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
            // }
           
            // if(navigate) {
            //     if(response?.data?.contents?.length > 0) {
            //         dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
            //        // getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
            //     }
                
            // }    
            //callBack()
        }else{
           // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        }
    }).catch(error =>{
       // console.log("Error", error.response.status)
       if(error) {
      //  dispatch(resetPendingCallState())
       // showSnackBar(error?.response, dispatch)
       }
        
       // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    })
}

//API to create new voice profile

const createVoiceProfile = (payload, callBack ) => {
 // dispatch(updatePendingCallState({loader_backdrop_open: true}))
 dispatch(updatePendingCallState({loader_backdrop_open: true}))
   createVoiceProfileApi(payload).then(response => {
      dispatch(updatePendingCallState({loader_backdrop_open: false}))
      // dispatch(resetPendingCallState())
      // if(response.data?.httpCode === 201){
          
          console.log("image response", response.data)
        //  gaEvents.contentCreationSuccessful()
         // navigate(`/productionevent/${payload?.projectId}`, {replace:true})
         // dispatch(updateContentState({create_content_open: false}))
        // getProjectDetails(scriptReducer.selected_project_details.id)
         getVoiceProfiles(callBack);
         //!!callBack && callBack();
      //    if(isArray(response?.data?.data) && response.data?.data[0]?.type === 'Storyboard') {
      //     getContentDetails({id:response?.data?.id})
      //    } else {
      //     getContentDetails({id:response?.data?.data?.id})
      //    }
        
         // console.log("content details", response?.data?.content)
         // dispatch(updateContentState({selected_content_details:{...response?.data?.content, id: response?.data?.id} }))
           // if(response.data.projectType.toLowerCase().trim() === "storyboard") {
           //    // let tempContents = removeDuplicateArrayItem(response?.data?.contents, "contentID")
           //    // dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
           // } else {
           //    // dispatch(updateUserState({selected_project_details: {...response?.data, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
           // }
          
           // if(navigate) {
           //     if(response?.data?.contents?.length > 0) {
           //         dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
           //        // getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
           //     }
               
           //}    
     //  }else{
          // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      // }
   }).catch(error =>{
       //console.log("Error", error)
       dispatch(updatePendingCallState({loader_backdrop_open: false}))
       if(error){
       gaEvents.createVoiceProfileFailure()
      // dispatch(resetPendingCallState())
       showSnackBar(error?.response, dispatch)
      }
      // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
   })
}

//API to edit an existing voice profile

const updateVoiceProfile = (payload, callBack ) => {
  // dispatch(updatePendingCallState({loader_backdrop_open: true}))
  dispatch(updatePendingCallState({loader_backdrop_open: true}))
    updateVoiceProfileApi(payload).then(response => {
      dispatch(updatePendingCallState({loader_backdrop_open: false}))
       // dispatch(resetPendingCallState())
       // if(response.data?.httpCode === 201){
           
           console.log("image response", response.data)
         //  gaEvents.contentCreationSuccessful()
          // navigate(`/productionevent/${payload?.projectId}`, {replace:true})
          // dispatch(updateContentState({create_content_open: false}))
         // getProjectDetails(scriptReducer.selected_project_details.id)
          getVoiceProfiles(callBack);
         // !!callBack && callBack();
       //    if(isArray(response?.data?.data) && response.data?.data[0]?.type === 'Storyboard') {
       //     getContentDetails({id:response?.data?.id})
       //    } else {
       //     getContentDetails({id:response?.data?.data?.id})
       //    }
         
          // console.log("content details", response?.data?.content)
          // dispatch(updateContentState({selected_content_details:{...response?.data?.content, id: response?.data?.id} }))
            // if(response.data.projectType.toLowerCase().trim() === "storyboard") {
            //    // let tempContents = removeDuplicateArrayItem(response?.data?.contents, "contentID")
            //    // dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
            // } else {
            //    // dispatch(updateUserState({selected_project_details: {...response?.data, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
            // }
           
            // if(navigate) {
            //     if(response?.data?.contents?.length > 0) {
            //         dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
            //        // getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
            //     }
                
            //}    
      //  }else{
           // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
       // }
    }).catch(error =>{
        //console.log("Error", error)
        dispatch(updatePendingCallState({loader_backdrop_open: false}))
        if(error){
        gaEvents.createVoiceProfileFailure()
       // dispatch(resetPendingCallState())
        showSnackBar(error?.response, dispatch)
       }
       // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    })
 }

 const deleteVoiceProfile = (payload, callBack ) => {
   dispatch(updatePendingCallState({loader_backdrop_open: true}))
     deleteVoiceProfileApi(payload).then(response => {  
      dispatch(updatePendingCallState({loader_backdrop_open: false}))
           console.log("delete voice profile", response.data)
           dispatch(updateContentState({ open_delete_popup: false }))
           getVoiceProfiles(callBack);
     }).catch(error =>{
         dispatch(updatePendingCallState({loader_backdrop_open: false}))
         if(error){
        // gaEvents.createVoiceProfileFailure()
         showSnackBar(error?.response, dispatch)
        }
     })
  }

    return {getVoiceProfiles, createVoiceProfile, updateVoiceProfile, deleteVoiceProfile}

}