import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
   play_tips_tricks_video: false,
   list_view: true,
   open_drawer_project_list: false,
   logout_all: false,
   logout_from_luci: false
}


export const commonSlice = createSlice({
    name: 'common',
    initialState: initialValue,
    reducers: {
        updateCommonState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetCommonState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateCommonState, resetCommonState } = commonSlice.actions
export default commonSlice.reducer