import React, { useEffect, useState } from 'react'
import CustomCard from '../../../components/CustomCard'
import { Avatar, Box, Button, IconButton, Typography, Input, Menu, MenuItem, ListItemText } from '@mui/material'
import { formatDynamicDate, getInitials, hasPermission } from '../../../utils/commonServices'
import CustomButton from '../../../components/CustomButton'
import luciLogo from "../../../assets/copilot_logo_dark.png";
import { useDispatch, useSelector } from 'react-redux'
import FeedbackIcon from '@mui/icons-material/Feedback';
import ListIcon from '@mui/icons-material/List';
import { updateCommonState } from '../../../redux/commonSlice'
import AppsIcon from '@mui/icons-material/Apps';
import useEventSort from '../hooks/useEventSort'
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateSriptState } from '../../../redux/projectScriptSlice'
import { resetProjectCreationState } from '../../../redux/projectCreationSlice'
import CustomTooltip from '../../../components/CustomTooltip'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { updateContentState } from '../../../redux/contentSlice'


const ProjectList = () => {

    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    // const userPermissions = useSelector((state) => state.permissionsReducer.permissions)
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedSortbyValue, setSelectedSortbyValue] = useState("date_added");
    const [selectedProject, setSelectedProject] = useState()
    const [searchedArray, setSearchedArray] = useState(scriptReducer.all_projects);
    const [sortedEvents] = useEventSort(searchedArray, selectedSortbyValue);
    const [searchString, setSearchString] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // const [deleteProject, deleteEventStatus] = useDeleteProject(eventId, refetchList);
    // console.log({event, collaborators})
    const handleClick = (e, event) => {
        e.stopPropagation()
        setSelectedProject(event)
        setAnchorEl(e.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };


    const dashboardViewToggleHandler = () => {
        dispatch(updateCommonState({ list_view: false }))
        dispatch(resetProjectCreationState())
    }

    useEffect(() => {
        setSearchedArray(scriptReducer.all_projects)
    }, [scriptReducer.all_projects])



    const handleSearch = (e) => {
        const searchedInput = e.target.value.toLowerCase();
        setSearchString(searchedInput);

        const projects = [...scriptReducer.all_projects];

        const results = projects?.filter(item =>
            item.name?.toLowerCase().includes(searchedInput) ||
            item.projectType?.toLowerCase().includes(searchedInput) ||
            formatDynamicDate(item.startDate)?.toLowerCase().includes(searchedInput)
        );
        setSearchedArray(results);
    };

    const onEventClick = (event) => {
        navigate(`/productionevent/${event.id}`, { replace: true })
    };
    const deleteHandler = (e) => {
        e.stopPropagation();
        //e.preventDefault()

        dispatch(updateSriptState({ open_delete_popup: true, delete_project_id: selectedProject.id }))
    }


    return (
        <>
            <CustomCard
                margin='0px 0px 0px 0px'
                padding='6% 2% 6% 6%'
                sx={{ position: 'relative', height: '100%', maxHeight: { xs: '71vh', sm: '68vh', md: '68vh', lg: '70vh', xl: '72vh' } }}
            >
                <Box height='100%' className='flex col  a-center' gap={3} >

                    <Box sx={{ width: '100%', }} className='flex j-between a-center' gap={6}>

                        <Box id="search"
                            sx={{ display: 'flex', justifyContent: 'flex-start' }}
                            maxWidth='300px'
                        //sx={{ marginBottom: { xs: '12px', md: '15px', lg: '18px', xl: '30px' } }} 
                        >
                            <Input
                                id="search"
                                value={searchString}
                                onChange={handleSearch}
                                inputProps={{ maxLength: 30 }}
                                sx={{
                                    //  minWidth: '160px',
                                    // width: '100%',
                                    maxWidth: '300px',
                                    borderBottom: 1.5,
                                    borderColor: "#fff",
                                    input: {
                                        fontSize: { xs: '12px', md: '13px', lg: '14px', xl: '20px' },
                                        fontWeight: 400,
                                        color: "#fff",
                                    },
                                }}
                                placeholder="Search"
                                startAdornment={
                                    <IconButton sx={{ paddingBottom: 1, fontSize: { xs: '12px', md: '14px', lg: '17px', xl: '24px' }, '& .MuiSvgIcon-root': { fontSize: { xs: '12px', md: '14px', lg: '17px', xl: '24px' } }, paddingRight: 2 }} >
                                        <SearchIcon style={{ color: "#fff" }} />
                                    </IconButton>
                                }
                            />
                        </Box>

                        <CustomTooltip title='Grid View' > <AppsIcon onClick={dashboardViewToggleHandler} sx={{ cursor: 'pointer' }} /></CustomTooltip>

                    </Box>



                    <Box width="100%" sx={{ overflowY: 'scroll', height: '100%' }} >
                        {
                            sortedEvents?.length > 0 ? sortedEvents?.map((event) => {
                                return <Box className='flex row j-between a-center' sx={{ mt: { xs: 1, md: 1.5, lg: 2 } }} >
                                    <Typography onClick={() => onEventClick(event)} sx={{ mr: 2, cursor: 'pointer', overflowX: 'hidden', textTransform: 'capitalize', textWrap: 'nowrap', fontSize: { xs: '12px', sm: '14px', lg: '16px', xl: '22px' } }} >{event?.name}</Typography>
                                    <Box width='100%' textAlign='right' sx={{ paddingRight: '6px', flex: 1 }} ><MoreVertIcon sx={{ fontSize: '18px', cursor: 'pointer' }} onClick={(e) => handleClick(e, event)} /></Box>
                                    {/* <DeleteIcon style={{fontSize: 16, marginRight: 4, marginLeft: 8, cursor: 'pointer'}} onClick = {(e)=>deleteHandler(e, event)} /> */}
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            "aria-labelledby": "basic-button",
                                        }}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            //   vertical: "top",
                                            //   horizontal: 133,
                                            vertical: -8,
                                            horizontal: 12
                                        }}
                                    >

                                        <MenuItem
                                            style={{ height: 24 }}
                                            onClick={(e) => {
                                                // e.stopPropagation();
                                                // console.log("clicked", event)
                                                deleteHandler(e)
                                                handleClose(e);
                                            }}
                                        >

                                            <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Delete Project</Typography></ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            style={{ height: 24 }}
                                            onClick={(e) => {
                                                // e.stopPropagation();
                                                dispatch(updateContentState({ create_content_open: true }))
                                                dispatch(updateSriptState({ selected_project_details: { id: selectedProject?.id, personaId: selectedProject?.personaId } }))
                                                handleClose(e);
                                            }}
                                        >

                                            <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Create Content</Typography></ListItemText>
                                        </MenuItem>
                                        {/* <MenuItem
            
                style={{ height: 24 }}
                onClick={(e) => {
                   // e.stopPropagation();
                    dispatch(updateContentState({create_content_open: true}))
                    handleClose(e);
                }}
            >
               
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: '#000000' } }}>Edit Project</Typography></ListItemText>
            </MenuItem> */}
                                    </Menu>
                                </Box>
                            }) :
                                <Box className='flex col j-center a-center' sx={{ overflowY: "initial", whiteSpace: 'normal' }} height='100%' width='100%'><Typography sx={{ fontSize: { xs: 14, sm: 16, md: 22, lg: 24, xl: 30 }, color: '#fff', fontWeight: 800, textAlign: 'center', mb: 1 }} >Welcome to Lucihub‘s Assistant Creative Director!</Typography>
                                    {/* <Typography sx={{fontSize: 30, color: '#fff', fontWeight: 600, textAlign: 'center', textTransform: 'Uppercase'}} >Creative Director Copilot</Typography> */}
                                    <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 22, lg: 24, xl: 30 }, color: '#fff', fontWeight: 300, textAlign: 'center', mt: 3 }} >Choose a content type to get started on your first project.</Typography></Box>
                        }

                    </Box>



                </Box>
            </CustomCard>
        </>
    )
}

export default ProjectList