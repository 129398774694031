import scriptIcon from '../assets/scriptIcon.svg';
import shotlistIcon from '../assets/shotListIcon_new.svg';
import storyboardIcon from '../assets/storyboardIcon.svg';
import blogIcon from '../assets/blogIcon.svg';
import imageIcon from '../assets/imageIcon.svg';
import pressReleaseIcon from '../assets/pressReleaseIcon.svg';
import socialMedia from '../assets/socialMediaIcon.svg';
import avatarIcon from  '../assets/avatarIcon.svg';
import voiceoverIcon from '../assets/voiceoverIcon.svg';
import scriptThumb from '../assets/scriptThumb.svg';
import shotlistThumb from '../assets/shotlistThumb.svg';
import storyboardThumb from '../assets/storyboardThumb.svg';
import realistic from '../assets/realistic.png';
import illustration from '../assets/illustration.png';
import impression from '../assets/impression.png';
import abstract from '../assets/abstract.png';
import sketch from '../assets/sketch.png';
import cartoon from '../assets/cartoon.png';

export const apiBaseUrl = {
    devServer: process.env.REACT_APP_API_URL
}

export const iosAppId = {
    LUCI_CAM : '6446018575',
    REAL_ESTATE: '6450928480',
}



export const errorConstant= {
    name: "name",
    full_name: "name",
    company_name: "company name",
    phone_number: "phone number",
    email: "email",
    password: "password",
    password_confirmation: "confirmation password",
    event_name: "event name",
    start_date: "start date",
    end_date: "end date",
}

export const ROLE = {
    ADMIN : 'admin',
    MANAGER: 'manager',
    ASSOCIATE: 'associate'
}

export const PERMISSIONS = {
    'user.create': [ROLE.ADMIN, ROLE.MANAGER],
    'user.delete':[ROLE.ADMIN],
    'user.edit': [ROLE.ADMIN, ROLE.MANAGER],
    'user.read':[ROLE.ASSOCIATE, ROLE.ADMIN, ROLE.MANAGER]
}

export const COMMON_MSG = {
    GENERIC_ERROR_MSG: 'Please try again. some error occured.',
    PAASWORD_CHANGED_MSG: 'Your account password has been reset successfully.',
    ACCOUNT_CREATION: 'Your account has been created succesfully.',
    TOKEN_EXPIRED: 'Your token has been expired, please login again.',
    PROJECT_DELETED: 'Project deleted succesfully.',
    EDITOR_ERROR: 'Please fill all the fields and make sure the editor name is unique.',
    TEXT_EDITOR_LENGTH: 'Text length should be 2000 characters or less.',
    DATA_CREATED: 'Data submitted successfully.',
    RECORD_DELETED: 'Data deleted successfully.',
  }

  export const httpMethod = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
  };

  
export const PRODUCT_ID = {
    lucihub: 1,
    butterfly:'example'
}

export const projectTypeIcons = {
    script: scriptIcon,
   shotlist:  shotlistIcon,
   storyboard: storyboardIcon ,
   blog: blogIcon,
   pressrelease: pressReleaseIcon,
   socialmedia: socialMedia,
   avatar: avatarIcon,
   voiceover: voiceoverIcon,
   image: imageIcon
}
export const projectTypeThumbnails = [
    scriptThumb,
    shotlistThumb,
    storyboardThumb ,
    scriptThumb,
    scriptThumb,
    scriptThumb,
    scriptThumb,
    scriptThumb
]

export const imageStyleThumbs= [
    realistic,
    illustration,
    impression,
    abstract,
    cartoon,
    sketch,
]

export const azureLanguageNames = {
    'af-ZA': 'Afrikaans (South Africa)',
    'am-ET': 'Amharic (Ethiopia)',
    'ar-AE': 'Arabic (United Arab Emirates)',
    'ar-BH': 'Arabic (Bahrain)',
    'ar-DZ': 'Arabic (Algeria)',
    'ar-EG': 'Arabic (Egypt)',
    'ar-IQ': 'Arabic (Iraq)',
    'ar-JO': 'Arabic (Jordan)',
    'ar-KW': 'Arabic (Kuwait)',
    'ar-LB': 'Arabic (Lebanon)',
    'ar-LY': 'Arabic (Libya)',
    'ar-MA': 'Arabic (Morocco)',
    'ar-OM': 'Arabic (Oman)',
    'ar-QA': 'Arabic (Qatar)',
    'ar-SA': 'Arabic (Saudi Arabia)',
    'ar-SY': 'Arabic (Syria)',
    'ar-TN': 'Arabic (Tunisia)',
    'ar-YE': 'Arabic (Yemen)',
    'az-AZ': 'Azerbaijani (Latin, Azerbaijan)',
    'bn-BD': 'Bengali (Bangladesh)',
    'bn-IN': 'Bengali (India)',
    'bg-BG': 'Bulgarian (Bulgaria)',
    'bs-BA': 'Bosnian (Bosnia and Herzegovina)',
    'ca-ES': 'Catalan (Spain)',
    'cs-CZ': 'Czech (Czech Republic)',
    'cy-GB': 'Welsh (United Kingdom)',
    'da-DK': 'Danish (Denmark)',
    'de-AT': 'German (Austria)',
    'de-CH': 'German (Switzerland)',
    'de-DE': 'German (Germany)',
    'el-GR': 'Greek (Greece)',
    'en-AU': 'English (Australia)',
    'en-CA': 'English (Canada)',
    'en-GB': 'English (United Kingdom)',
    'en-HK': 'English (Hong Kong)',
    'en-IE': 'English (Ireland)',
    'en-IN': 'English (India)',
    'en-KE': 'English (Kenya)',
    'en-NG': 'English (Nigeria)',
    'en-NZ': 'English (New Zealand)',
    'en-PH': 'English (Philippines)',
    'en-SG': 'English (Singapore)',
    'en-TZ': 'English (Tanzania)',
    'en-US': 'English (United States)',
    'en-ZA': 'English (South Africa)',
    'es-AR': 'Spanish (Argentina)',
    'es-BO': 'Spanish (Bolivia)',
    'es-CL': 'Spanish (Chile)',
    'es-CO': 'Spanish (Colombia)',
    'es-CR': 'Spanish (Costa Rica)',
    'es-DO': 'Spanish (Dominican Republic)',
    'es-EC': 'Spanish (Ecuador)',
    'es-ES': 'Spanish (Spain)',
    'es-GT': 'Spanish (Guatemala)',
    'es-HN': 'Spanish (Honduras)',
    'es-MX': 'Spanish (Mexico)',
    'es-NI': 'Spanish (Nicaragua)',
    'es-PA': 'Spanish (Panama)',
    'es-PE': 'Spanish (Peru)',
    'es-PR': 'Spanish (Puerto Rico)',
    'es-PY': 'Spanish (Paraguay)',
    'es-SV': 'Spanish (El Salvador)',
    'es-US': 'Spanish (United States)',
    'es-UY': 'Spanish (Uruguay)',
    'es-VE': 'Spanish (Venezuela)',
    'et-EE': 'Estonian (Estonia)',
    'eu-ES': 'Basque',
    'fa-IR': 'Persian (Iran)',
    'fi-FI': 'Finnish (Finland)',
    'fil-PH': 'Filipino (Philippines)',
    'fr-BE': 'French (Belgium)',
    'fr-CA': 'French (Canada)',
    'fr-CH': 'French (Switzerland)',
    'fr-FR': 'French (France)',
    'ga-IE': 'Irish (Ireland)',
    'gl-ES': 'Galician (Spain)',
    'gu-IN': 'Gujarati (India)',
    'he-IL': 'Hebrew (Israel)',
    'hi-IN': 'Hindi (India)',
    'hr-HR': 'Croatian (Croatia)',
    'hu-HU': 'Hungarian (Hungary)',
    'hy-AM': 'Armenian (Armenia)',
    'id-ID': 'Indonesian (Indonesia)',
    'it-IT': 'Italian (Italy)',
    'is-IS': 'Icelandic (Iceland)',
    'ja-JP': 'Japanese (Japan)',
    'jv-ID': 'Javanese (Indonesia)',
    'ka-GE': 'Georgian (Georgia)',
    'mn-MN': 'Mongolian (Mongolia)',
    'kn-IN': 'Kannada (India)',
    'kk-KZ': 'Kazakh (Kazakhstan)',
    'km-KH': 'Khmer (Cambodia)',
    'ko-KR': 'Korean (Korea)',
    'lo-LA': 'Lao (Laos)',
    'lt-LT': 'Lithuanian (Lithuania)',
    'lv-LV': 'Latvian (Latvia)',
    'mk-MK': 'Macedonian (North Macedonia)',
    'ml-IN': 'Malayalam (India)',
    'mr-IN': 'Marathi (India)',
    'ms-MY': 'Malay (Malaysia)',
    'mt-MT': 'Maltese (Malta)',
    'my-MM': 'Burmese (Myanmar)',
    'nb-NO': 'Norwegian (Norway)',
    'ne-NP': 'Nepali (Nepal)',
    'nl-BE': 'Dutch (Belgium)',
    'nl-NL': 'Dutch (Netherlands)',
    'pl-PL': 'Polish (Poland)',
    'ps-AF': 'Pashto (Afghanistan)',
    'pt-BR': 'Portuguese (Brazil)',
    'pt-PT': 'Portuguese (Portugal)',
    'ro-RO': 'Romanian (Romania)',
    'ru-RU': 'Russian (Russia)',
    'si-LK': 'Sinhala (Sri Lanka)',
    'sk-SK': 'Slovak (Slovakia)',
    'sl-SI': 'Slovenian (Slovenia)',
    'so-SO': 'Somali (Somalia)',
     'sq-AL': 'Albanian (Albania)',
    'sr-RS': 'Serbian (Serbia)',
    'su-ID': 'Sundanese (Indonesia)',
    'sv-SE': 'Swedish (Sweden)',
    'sw-KE': 'Swahili (Kenya)',
    'ta-IN': 'Tamil (India)',
    'te-IN': 'Telugu (India)',
    'th-TH': 'Thai (Thailand)',
    'tr-TR': 'Turkish (Turkey)',
    'uk-UA': 'Ukrainian (Ukraine)',
    'ur-IN': 'Urdu (India)',
    'ur-PK': 'Urdu (Pakistan)',
    'uz-UZ': 'Uzbek (Uzbekistan)',
    'vi-VN': 'Vietnamese (Vietnam)',
    'zh-CN': 'Chinese (Simplified)',
    'zh-HK': 'Chinese (Traditional, Hong Kong)',
    'zh-TW': 'Chinese (Traditional, Taiwan)',
    'zu-ZA': 'Zulu (South Africa)'
  };
  
 export const voicePitchRateMarks = [
    {
      value: -50,
      label: '-50%',
    },
    {
      value: -40,
      label: '-40%',
    },
    {
      value: -30,
      label: '-30%',
    },
    {
      value: -20,
      label: '-20%',
    },
    {
        value: -10,
        label: '-10%',
      },
      {
        value: 0,
        label: '0%',
      },
      {
        value: 10,
        label: '10%',
      },
      {
        value: 20,
        label: '20%',
      },
      {
        value: 30,
        label: '30%',
      },
      {
        value: 40,
        label: '40%',
      },
      {
        value: 50,
        label: '50%',
      },
  ];