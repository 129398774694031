import React from "react";

import { useSelector } from "react-redux";
import ImageStyleComponent from "../../eventcreation/components/imageProject/ImageStyleComponent";
import ImageAspectComponent from "../../eventcreation/components/imageProject/ImageAspectComponent";
import ImageColorScheme from "../../eventcreation/components/imageProject/ImageColorScheme";
import ProjectDescriptionPage from "../../eventcreation/components/commonProject/ProjectDescriptionPage";
import PreviewCreateImage from "../../eventcreation/components/imageProject/PreviewCreateImage";
import BackdropWrapper from "../../eventcreation/components/BackdropWrapper";

const ImageContentCreation = (props)=> {

    const {slideDirection, pageIndex, prevPageHandler, nextPageHandler, closeHandler, totalScreens} = props;
    const commonReducer = useSelector((state) => state.commonReducer);
    const contentReducer = useSelector((state) => state.contentReducer);

    return  <> 
            
              {pageIndex === 2 && <BackdropWrapper slideDirection={slideDirection}><ImageStyleComponent {...props} /></BackdropWrapper>}
                {pageIndex === 3 && <BackdropWrapper slideDirection={slideDirection}><ImageAspectComponent {...props} /></BackdropWrapper>}
                {pageIndex === 4 && <BackdropWrapper slideDirection={slideDirection}><ImageColorScheme {...props} /></BackdropWrapper>}
                {pageIndex === 5 && 
                   <BackdropWrapper slideDirection={slideDirection}><ProjectDescriptionPage 
                        {...props} 
                        title = 'Describe the image you would like to create in detail.'
                        note = '(ie. What is the main subject? Are there any specific details you want to include? What mood do you want to convey?)'  
                    /></BackdropWrapper>
                }
                {pageIndex === 6 && <BackdropWrapper slideDirection={slideDirection}><PreviewCreateImage {...props} /></BackdropWrapper>}
          
               
   
       {/*pageIndex === 4 && <ProjectStartDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
       />*/}
   
       {/*pageIndex === 5 && <ProjectEndDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
     />*/}
   
       
    </>

}

export default ImageContentCreation;