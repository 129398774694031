import { Backdrop, Box, Slide, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomRadioButton from '../CustomRadioButton'
import { useDispatch, useSelector } from 'react-redux';
import { updateProjectCreationState } from '../../../../redux/projectCreationSlice';
import CustomInput from '../CustomInput';
import CustomAutocomplete from '../CustomAutoComplete';
// import UpIcon from '../icons/UpIcon';
// import DownIcon from '../icons/DownIcon';
import CheckIcon from '../../../../components/icons/CheckIcon';
import { afterValidate, getNamesFromIds } from '../../../../utils/commonServices';
import { getErrorMsg } from '../../../../utils/validator';
import { videoLength, wordLengthList } from '../../../../constants/staticData';
import LeftIcon from '../../icons/LeftIcon';
import RightIcon from '../../icons/RightIcon';

const ProjectLengthPage = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex,  closeHandler, totalScreens} = props
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const dropdownReducer = useSelector((state)=> state.persistedReducers.dropdownReducer)
    const projectType = getNamesFromIds(createProjectReducer.commonPageData.projectTypeId, dropdownReducer?.project_type)?.name
    const [wordLimit, setWordLimit] = useState()
    const dispatch = useDispatch()
    const [scriptLengthValidationFlag, setScriptLengthValidationFlag] = useState(false)
    const isListView = commonReducer?.list_view
    const isMobileView = useMediaQuery('(max-width:599px)');

    useEffect(()=> {
      let upper, lower;
      switch(createProjectReducer.commonPageData.projectTypeId) {
        case 4 : lower = 0;
                 upper = 700;
                 break;
        case 5 : lower = 0;
                 upper = 800;
                 break;
        case 6 : lower = 0;
                 upper = 150;
                 break;
        default : lower = 0;
                  upper = 800;
                  break;
      }

      setWordLimit({lower, upper})
    }, [createProjectReducer.commonPageData.projectTypeId])

    const finishedHandler = ()=> {
      validateInputs(true)
      afterValidate(nextPageHandler);
    }

    const validateInputs = (flag) => {
      setScriptLengthValidationFlag(flag)
    }

    const selectedScriptLength = (id) => {
      if ((createProjectReducer.commonPageData.projectTypeId) > 3 && (createProjectReducer.commonPageData.projectTypeId) != 8) {
        return id;
      } else if (!!videoLength?.find((item) => item.value == id)) {
        return videoLength?.find((item) => item.value == id);
      } else {
        return {};
      }
    };

    useEffect(() => {
      const keyDownHandler = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          !scriptReducer.open_delete_popup && finishedHandler();
        }
      };
        document.addEventListener("keydown", keyDownHandler)
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [createProjectReducer.commonPageData.lengthId, scriptReducer.open_delete_popup]);

  return (
    <>
    {/* <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createProjectReducer.open_backdrop_project_creation}
    > */}
        <Box sx={{ width: isListView ? '100%' : '90%', height:'100%', display:'flex', flexDirection:'column'}}>
          { (!isListView || contentReducer?.create_content_open) && <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3}} onClick={closeHandler}>Close X</Typography>}
          <Box flexGrow={1} className='flex col ' sx={{ paddingLeft: isListView ? '5vw' :'14vw', marginTop:isListView ?{xs:'6%',sm:'4%', md:'3%', xl:'4%'}: {xs:'8%',sm:'6%', md:'4%', xl:'6%'}}}>
              <Box mb={6}>
                <Typography sx={{fontSize:isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} :{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography sx={{fontSize:isListView ? {xs:'20px',sm:'22px',md:'26px',lg:'35px',xl:'52px'} :{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik'}}>{`How long will your ${projectType} be?`}</Typography>
                <Typography sx={{fontSize: isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} :{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>Select the length of your project.</Typography>
              </Box>
          
              <Box className='flex' sx={{width: isMobileView ? '100%' : '80%'}} gap={1}>
                    <Box  sx={{width: isMobileView ? '93%' : '80%' , textAlign:'center',}}>              
                        {
                          parseInt(createProjectReducer.commonPageData.projectTypeId) < 4 || parseInt(createProjectReducer.commonPageData.projectTypeId) == 8 ? <CustomAutocomplete
                          id="script_length"
                          options={ videoLength}
                          onFocus={() => {
                            setScriptLengthValidationFlag(false)
                          }}
                          onBlur={() => setScriptLengthValidationFlag(true)}
                          required
                          errorFlag={scriptLengthValidationFlag && getErrorMsg("script_length", createProjectReducer.commonPageData.lengthId, createProjectReducer.commonPageData?.projectTypeId) != ""}
                          errorMsg={getErrorMsg("script_length", createProjectReducer.commonPageData.lengthId, createProjectReducer.commonPageData?.projectTypeId)}
                          value={selectedScriptLength(createProjectReducer.commonPageData.lengthId)}
                          onChange={(event, newValue) => {
                            dispatch(updateProjectCreationState({commonPageData: {...createProjectReducer.commonPageData, lengthId: newValue.value }}))
                              }}
                          label=""
                          placeholder={`Select the length of your ${projectType}`}
                          getOptionLabel={(option) =>
                              Object.keys(option).length > 0 ? `${option.name}` : ""
                          }
                          medium
                          msgColor='red'
                      /> :  <CustomInput
                      onFocus={() =>{ setScriptLengthValidationFlag(false) }}
                      onBlur={() => setScriptLengthValidationFlag(true)}
                      type='sendInput'
                      required
                      medium
                      bottom={-20}
                      msgColor='red'
                      errorFlag={scriptLengthValidationFlag && getErrorMsg("word_length", createProjectReducer.commonPageData.lengthId, wordLimit?.lower) != ""}
                          errorMsg={getErrorMsg("word_length", createProjectReducer.commonPageData.lengthId, wordLimit?.lower)}
                      fullWidth
                      max={100}
                      placeholder={`Upto ${wordLimit?.upper} words`}
                      onChange={(e) => {
                        (!isNaN(e.target.value.trim()) && e.target.value <= wordLimit?.upper) && dispatch(updateProjectCreationState({commonPageData: {...createProjectReducer.commonPageData, lengthId:  e.target.value.trim() }}))
                          }}
                      value={createProjectReducer.commonPageData.lengthId}
                      onButtonClick={pageIndex !== totalScreens ? finishedHandler : null}
                     />
                        }
                          
                      </Box> 
                     {//createProjectReducer.pageData.projectTypeId < 3 && <Box sx={{ pt:'2px'}}>
                        //<CheckIcon   onClick={pageIndex !== totalScreens ? finishedHandler : null}  sx={{fontSize:{xs:'50px',xl:'60px'}, cursor: 'pointer'}}/>
                      //</Box>
                      }
              </Box>
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', mb:3, mr:3}}>
            <LeftIcon sx={{opacity: pageIndex === 1 && 0.5, fontSize:isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}, cursor:'pointer'}} onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <RightIcon sx={{ cursor:'pointer', fontSize:isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}}   onClick={pageIndex !== totalScreens ? finishedHandler : null}/>
          </Box>
        </Box>
    {/* </Backdrop> */}
    {/* </Slide> */}
    </>
  )
}

export default ProjectLengthPage