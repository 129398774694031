import { IconButton, Input, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import CustomCard from "../../components/CustomCard";
import AuthLayout from "../../layouts/AuthLayout";
import EventCard from ".././components/EventCard";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from ".././icons/AddIcon";
import { updateUserState } from "../../../redux/userSlice";
import { formatDynamicDate, timeStampToString } from "../../../utils/commonServices";
import { useNavigate } from "react-router-dom";
import EventCreationDrawer from "../../eventcreation";
import CustomUserCard from "../../events/components/CustomUserCard";
import "@fontsource/rubik";
import { eventSortOptions, sortByOptions } from "../../../constants/staticData";
import useEventSort from "../hooks/useEventSort";
import PopupVideoPlayer from "../../../components/PopupVideoPlayer";
import useFilter from "../hooks/useFilter";
import { gaEvents } from "../../../ga/actions";
import MainLogoCard from "../components/MainLogoCard";
import { updateSriptState } from "../../../redux/projectScriptSlice";
import { resetProjectCreationState, updateProjectCreationState } from "../../../redux/projectCreationSlice";
import BackdropCreateProject from "../../eventcreation/components/BackdropCreateProject";
import useDashboardActions from "../hooks/useDashboardActions";
import useEventCreationActions from "../../eventcreation/hooks/useEventCreationActions";
import { resetContentState } from "../../../redux/contentSlice";
import { resetStoryboardState } from "../../../redux/storyboardSlice";
import DeleteConfirmationPopup from "../components/DeleteConfirmationPopup";
import { updateCommonState } from "../../../redux/commonSlice";
import CustomCard from "../../../components/CustomCard";
import FilmingTutorialCard from "../../events/components/FilmingTutorialCard";
import ProjectSort from "../components/ProjectSort";
import luciLogo from "../../../assets/copilot_logo_dark.png";
import lucihub from "../../../assets/butterfly_navigation.png";
import ProjectList from "./ProjectList";
import ProjectTypePage from "../../eventcreation/components/commonProject/ProjectTypePage";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import DrawerProjectList from "./DrawerProjectList";
import ProjectNamePage from "../../eventcreation/components/commonProject/ProjectNamePage";
import ImageProjectCreation from "../../eventcreation/components/imageProject/ImageProjectCreation";
import CommonProjectCreation from "../../eventcreation/components/commonProject/CommonProjectCreation";
import CustomTooltip from "../../../components/CustomTooltip";
import BackdropCreateContent from "../../contentcreation/components/BackdropCreateContent";
import VoiceOverProjectCreation from "../../eventcreation/components/voiceOverProject/VoiceOverProjectCreation";


const ListViewDashboard = () => {
    const commonReducer = useSelector((state) => state.commonReducer);
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer)
    const [selectedSortbyValue, setSelectedSortbyValue] = useState("date_added");
    const [searchedArray, setSearchedArray] = useState(scriptReducer.all_projects);
    const [searchString, setSearchString] = useState("");
    const [sortedEvents] = useEventSort(searchedArray, selectedSortbyValue);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getProjectTypeList, getProjectLengthList } = useEventCreationActions();
    const { eventCountByStatus, statusCheckHandler } = useFilter("status_type")
    const nonMobileView = useMediaQuery('(min-width:600px)');
    const mobileView = useMediaQuery('(max-width:599px)');
    const [slideDirection, setSlideDirection] = useState('up')
    const [totalScreens, setTotalScreens] = useState(5);
    const [pageIndex, setPageIndex] = useState(1)

    const { getAllProjects, deleteProject } = useDashboardActions();

    useEffect(() => {
        setSearchedArray(scriptReducer.all_projects)
    }, [scriptReducer.all_projects])

    useEffect(() => {
        dropdownReducer?.project_type?.length < 1 && getProjectLengthList()
        dropdownReducer?.project_length?.length < 1 && getProjectTypeList();
        dispatch(updateUserState({ storyboard_item_number: null }))
        dispatch(updateSriptState({ selected_project_details: {} }))
        dispatch(resetProjectCreationState())
        dispatch(resetContentState());
        dispatch(resetStoryboardState())
        getAllProjects()
    }, [])



    const handleSearch = (e) => {
        const searchedInput = e.target.value.toLowerCase();
        setSearchString(searchedInput);

        const projects = [...scriptReducer.all_projects];

        const results = projects?.filter(item =>
            item.name?.toLowerCase().includes(searchedInput) ||
            item.projectType?.toLowerCase().includes(searchedInput) ||
            formatDynamicDate(item.startDate)?.toLowerCase().includes(searchedInput)
        );
        setSearchedArray(results);
    };

    //***********************LOGIC FOR CLICKS ON EVENT CARDS******************************************** */
    const onEventClick = (event) => {
        navigate(`/productionevent/${event.id}`, { replace: true })
    };

    const deleteConfirmHandler = () => {
        deleteProject({ projectId: scriptReducer.delete_project_id }, () => dispatch(updateSriptState({ open_delete_popup: false })))
    }

    useEffect(()=> {
        switch(parseInt(createProjectReducer.commonPageData.projectTypeId)) {
          case 8 :  createProjectReducer.voiceOverPageData.needScript ? setTotalScreens(7) : setTotalScreens(5)
                    break;
          case 9 : setTotalScreens(7)
                    break;
          default : setTotalScreens(6)
        }
       // dropdownReducer?.project_type?.length < 1 && getProjectTypeList()
        //dropdownReducer?.project_length?.length < 1 && getProjectLengthList();
        // setPageData((last)=>{
        //   return {...last, user_id: userReducer.user_id, organisation_id: userReducer.organisation_id ?? 0 }
        // })
      },[createProjectReducer.commonPageData.projectTypeId])

    const prevPageHandler = () => {
        if (pageIndex > 1) {
            setPageIndex(prev => prev - 1)
            setSlideDirection('right')
        }
    }

    const nextPageHandler = () => {
        if (pageIndex < totalScreens) {
            setPageIndex(prev => prev + 1)
            setSlideDirection('left')
        }
    }

    return <AuthLayout sx={{ padding: mobileView ? '5% 3.2% 2.1% 3.2%' : '2% 3.2% 2.1% 3.2%' }}>
        <Box gap={3} className='flex row a-center j-between' sx={{ pr: { xs: 1, sm: 3 } }} >
            <Box className='flex a-center' gap={2}>
                <Box sx={{ width: { xs: '70px', md: '95px', lg: '120px', xl: '150px' }, mb: 2 }}>
                    <img src={luciLogo} width='100%' />
                </Box>
                {mobileView && <CustomTooltip title='Project List' ><MenuOutlinedIcon sx={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => dispatch(updateCommonState({ open_drawer_project_list: true }))} /></CustomTooltip>}
            </Box>
            <Box gap={3} className='flex row a-center j-end' sx={{ pr: {xs:1, sm:3} }} >
        <img onClick={()=> window.location.href=`${process.env.REACT_APP_LUCIHUB_URL}`} src={lucihub} style={{ borderRadius: '5px', width: '280px', cursor: 'pointer'}} />
            <CustomUserCard />
        </Box>
        </Box>
        <Box className='flex row justify-center align-center' >

            {!mobileView ? <Box id="left" className='flex col' sx={{ width: { xs: '38%', md: '30%', lg: '28%', xl: '25%' }, }} >
                <ProjectList />
            </Box> : <DrawerProjectList />}

            <Box id="right" width='100%' minWidth='300px' ml={nonMobileView && 4.5}>

                <Box id="events" className='flex row f-wrap f-start a-start' sx={{ height: { xs: '71vh', sm: '68vh', md: '68vh', lg: '70vh', xl: '72vh' }, overflowY: "scroll", whiteSpace: 'nowrap', alignContent: 'flex-start', backgroundColor: 'transparent', scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': { display: 'none' }, }} >

                    {contentReducer?.create_content_open === false && <CustomCard sx={{ height: '100%', width: '100%' }}>
                        {/* hello */}
                        {/* <ProjectTypePage/> */}
                        {pageIndex === 1 &&

                            <ProjectTypePage
                                slideDirection={slideDirection}
                                pageIndex={pageIndex}
                                prevPageHandler={prevPageHandler}
                                nextPageHandler={nextPageHandler}
                                //   closeHandler={closeHandler}
                                totalScreens={totalScreens}
                            />

                        }


                        {pageIndex === 2 && <ProjectNamePage
                            // pageData={pageData}
                            //setPageData={setPageData}
                            slideDirection={slideDirection}
                            pageIndex={pageIndex}
                            prevPageHandler={prevPageHandler}
                            nextPageHandler={nextPageHandler}
                            //   closeHandler={closeHandler}
                            totalScreens={totalScreens}
                        />}

                        {parseInt(createProjectReducer.commonPageData.projectTypeId) == 9 ?
                            <ImageProjectCreation
                                slideDirection={slideDirection}
                                pageIndex={pageIndex}
                                prevPageHandler={prevPageHandler}
                                nextPageHandler={nextPageHandler}
                                // closeHandler={closeHandler}
                                totalScreens={totalScreens}
                            /> : parseInt(createProjectReducer.commonPageData.projectTypeId) == 8 ?
                                <VoiceOverProjectCreation
                                    slideDirection={slideDirection}
                                    pageIndex={pageIndex}
                                    prevPageHandler={prevPageHandler}
                                    nextPageHandler={nextPageHandler}
                                   // closeHandler={closeHandler}
                                    totalScreens={totalScreens}
                                    setTotalScreens={setTotalScreens}
                                    totalAIScreens = {7} 
                                    totalUserScriptScreens = {5}
                                /> : <CommonProjectCreation
                                    slideDirection={slideDirection}
                                    pageIndex={pageIndex}
                                    prevPageHandler={prevPageHandler}
                                    nextPageHandler={nextPageHandler}
                                    // closeHandler={closeHandler}
                                    totalScreens={totalScreens}
                                />
                        }


                    </CustomCard>}


                </Box>
            </Box>
            <Box>

            </Box>
        </Box>
        {
            createProjectReducer.open_form == true && <EventCreationDrawer />
        }
        {
            commonReducer.play_tips_tricks_video == true && <PopupVideoPlayer tutorial type ="voice_studio" />
        }

        {createProjectReducer?.open_backdrop_project_creation === true && <BackdropCreateProject />}
        {contentReducer?.create_content_open === true && <BackdropCreateContent />}
        {scriptReducer.open_delete_popup && <DeleteConfirmationPopup
            open={scriptReducer.open_delete_popup}
            closeHandler={() => dispatch(updateSriptState({ open_delete_popup: false }))}
            deleteConfirmHandler={deleteConfirmHandler}
            message='Are you sure you want to remove this project permanently? All the content related to this project will be erased. Please type "delete" below to confirm.'
        />}

    </AuthLayout>
}
export default ListViewDashboard;