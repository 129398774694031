import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    commonPageData: {
        projectTypeId: 1,
        name : "",
        personaId: 1,
       // startDate: new Date().toISOString(),
        //endDate: new Date().toISOString(),
        description: "",
        lengthId: 0,
        userScript:""
    },
    imagePageData: {
        grayscale: false,
        primaryColor: '',
        secondaryColor: '',
        style: 1,
        aspectRatio: 2,
        prompt: ""
    },
    voiceOverPageData: {
        needScript: true,
    },
    open_form: false,
    open_backdrop_project_creation: false
}
export const projectCreationSlice = createSlice({
    name: 'createProject',
    initialState: initialValue,
    reducers: {
        updateProjectCreationState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetProjectCreationState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateProjectCreationState, resetProjectCreationState } = projectCreationSlice.actions
export default projectCreationSlice.reducer