import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkVerificationToken,
  forgotPassword,
  resetPassword,
  userSignin,
  userSignup,
  verifyUserMail,
  resendLink,
  checkEmail,
  checkButterflyAccessApi
} from "../../../services/api";
import useAllHooks from "../../../hooks/useAllHooks";
import { updatePendingCallState } from "../../../redux/pendingCallSlice";
import { setSnackbar } from "../../../redux/messageSlice";
import { updateUserState } from "../../../redux/userSlice";
import { showAuthSnackBar } from "../../../utils/commonServices";

export default () => { 
  //const {dispatch, navigate, state} = useAllHooks();
  const dispatch = useDispatch();
  const navigate = useNavigate();

// ***********************AUTH FLOW API REQ FUNCTIONS

//NEW USER SIGNUP
const newUserSignup = (payload, callBack) => {
  dispatch(updatePendingCallState({signup: true}))
  userSignup(payload) 
  .then((response) => { 
    dispatch(updatePendingCallState({signup: false}))
    dispatch(updateUserState({signup_disclaimer: false}))
    if(response) {
      const responseObject = response?.data;
      const responseData = responseObject?.data
      if (responseObject?.http_code == 200) {
        navigate('/resendlink', {state: {pageData: payload, title: 'Your account has been created', icon: "", btnText: 'Resend Email', message:'Please check your email and click on the verify now button to continue.', signIn: false}, replace:true})
       // gaEvents.signupEvent();
      }else {
         // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      }
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
    
  })
  .catch((error) => {
    if(error) {
      dispatch(updatePendingCallState({signup: false}))
      dispatch(updateUserState({signup_disclaimer: false}))
      const errorObject = error?.response?.data;
      //.signupFailed();
      showAuthSnackBar(errorObject, dispatch);
    }
  });
  }

  //USER SIGN IN

    const userLogin = (payload, callBack) => {
      dispatch(updatePendingCallState({signin: true}))
      userSignin(payload) 
    .then((response) => {  
      dispatch(updatePendingCallState({signin: false}))
      if(response) {
            const responseObject = response?.data;
            const responseData = responseObject?.data
            if (responseObject?.http_code == 200) {
             // gaEvents.loginEvent()
              //getPermissions(dispatch)
              window.localStorage.setItem("token", responseData?.token);
              dispatch(
                updateUserState({
                  is_logged: true,
                  user_name: responseData?.name,
                  user_id: responseData?.user_id,
                  show_revision_tutorial: responseData?.show_revision_tutorial,
                })
              );
            
              navigate("/", {replace: true});
            }
      }
    })
    .catch((error) => {
      if(error) {
        dispatch(updatePendingCallState({signin: false}))
        const errorObject = error?.response?.data;
        if(errorObject.data && errorObject.data.email_verified == false){
          navigate('/resendlink', {state: {pageData: payload, title: 'Your email verification is pending', icon: "", btnText: 'Resend Email', message:'please check your email and click on the verify now button to continue.', signIn: true}, replace:true})
        } else{
         dispatch(updatePendingCallState({signinStatus: 'error'}))
          //gaEvents.loginFailed()
          showAuthSnackBar(errorObject, dispatch)
        }
        
      }
    });
    }

  //RESET PASSWORD

    const resetUserPassword = (payload, callBack) => {
      dispatch(updatePendingCallState({resetPassword: true, resetPasswordStatus:'loading'}))
      resetPassword(payload) 
    .then((response) => {   
      dispatch(updatePendingCallState({resetPassword: false}))
      if(response) {
        const responseObject = response?.data;
        const responseData = responseObject?.data;
        if (responseObject?.http_code == 200) {
          //setResetPasswordStatus("success");
          dispatch(updatePendingCallState({ resetPasswordStatus:'success'}))
           showAuthSnackBar(responseObject, dispatch);
          //dispatch(setSnackbar({ ...setSnackMessageType(responseObject) }));
        }
      } else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
      }
    })
    .catch((error) => {
      if(error) {
      dispatch(updatePendingCallState({resetPassword: false, resetPasswordStatus:'error'}))
      const errorObject = error?.response?.data;
      showAuthSnackBar(errorObject, dispatch);
   
      }
    });
    }

    //FORGOT PASSWORD
    const forgotUserPassword = (payload, callBack) => {
      dispatch(updatePendingCallState({forgotPassword: true}))
      forgotPassword(payload) 
        .then((response) => {   
          dispatch(updatePendingCallState({forgotPassword: false}))
          if(response) {
            const responseObject = response?.data;
            const responseData = responseObject?.data
            if (responseObject?.http_code == 200) {
              showAuthSnackBar(responseObject, dispatch)
              setTimeout(()=> {
                navigate('/signin', {replace: true})
              }, 6000)
             // dispatch(setSnackbar({ ...setSnackMessageType(forgotPasswordData?.data) }));
            //  setForgotPasswordStatus("success");
           // dispatch(updatePendingCallState({ forgotPasswordStatus:'success'}))
            }
          } else {
            dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
          }
         
        })
        .catch((error) => {
          if(error) {
            dispatch(updatePendingCallState({forgotPassword: false}))
            const errorObject = error?.response?.data;
            showAuthSnackBar(errorObject, dispatch);
       
          }
        });
    }

    //RESEND LINK

    const resendVerificationLink = (payload, callBack) => {
      dispatch(updatePendingCallState({resendLink: true}))
      resendLink(payload) 
        .then((response) => {  
          dispatch(updatePendingCallState({resendLink: false}))
          if(response) {
            const responseObject = response?.data;
            const responseData = responseObject?.data;
            if(responseObject?.http_code === 200){
              showAuthSnackBar(responseObject, dispatch)
            }else {
               // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
            }
          } else {
             dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
          }
         
        })
        .catch((error) => {
          if(error) {
          dispatch(updatePendingCallState({resendLink: false}))
          const errorObject = error?.response?.data;
          if(errorObject?.data?.email_verified == true) {
            navigate("/", {replace: true})
          } else {
           // dispatch(updatePendingCallState({resendLinkStatus: 'error'}))
          }
          showAuthSnackBar(errorObject, dispatch)
       
          }
        });
    }

    //TOKEN VALIDITY

    const checkTokenVerificationValidity = (payload) => {
      dispatch(updatePendingCallState({checkVerificationToken: true, verifyTokenValidityStatus:'loading'}))
      checkVerificationToken(payload) 
        .then((response) => {   
          dispatch(updatePendingCallState({checkVerificationToken: false}))
          if(response) {
            const responseObject = response?.data;
            const responseData = responseObject?.data;
            if(responseObject?.http_code === 200){
             // showAuthSnackBar(responseObject, dispatch)
              dispatch(updatePendingCallState({verifyTokenValidityStatus:'success'}))
            }else {
                dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
            }
          }
          
        })
        .catch((error) => {
          if(error) {
          dispatch(updatePendingCallState({checkVerificationToken: false, verifyTokenValidityStatus:'error'}))
          const errorObject = error?.response?.data;
          showAuthSnackBar(errorObject, dispatch);
       
          }
        });
    }

    //VERIFY USER EMAIL
    
    const verifyEmail = (payload, callBack) => {
      dispatch(updatePendingCallState({verifyUserMail: true}))
      verifyUserMail(payload) 
        .then((response) => {   
          dispatch(updatePendingCallState({verifyUserMail: false}))
          if(response) {
            const responseObject = response?.data;
            const responseData = responseObject?.data;
            if(responseObject?.http_code === 200){
              showAuthSnackBar(responseObject, dispatch)
             // callBack(responseData?.already_verified)

              if (responseData?.already_verified == true) {
                //setCheckVerifyUserEmailStatus("already_verified");
                callBack("This email has been verified earlier. Please proceed to Sign In", "EMAIL VERIFIED!" )
               // dispatch(updatePendingCallState({verifyUserMailStatus:'already_verified'}))
              } else {
                callBack("Congratulations! Your email has been verified successfully. Please proceed to Sign In", "EMAIL VERIFIED!" )
                //setCheckVerifyUserEmailStatus("success");
                //dispatch(updatePendingCallState({verifyUserMailStatus:'success'}))
              }
            }else {
               // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
            }
          } else {
            dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
          }
          
        })
        .catch((error) => {
          if(error) {
            dispatch(updatePendingCallState({verifyUserMail: false}))
            callBack("The verification link seems invalid. Please proceed to Forgot Password and get new link", "VERIFICATION FAILED!")
          const errorObject = error?.response?.data;
          showAuthSnackBar(errorObject, dispatch);
       
          }
        });
    }
//////////////////////////////CHECK BUTTERFLY ACCESS //////////////////////////////////
     const checkUserButterflyAccess = ( ) => {
      checkButterflyAccessApi() 
      .then((response) => {
        if(response?.data?.http_code === 200){
          if(response.data.data.butterfly_access == true){
              dispatch(updateUserState({ is_logged: true }));

          }else{
              dispatch(updateUserState({ is_logged: false }));
          }
          // navigate("/", {replace: true});
        }else {
            dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        }
      })
      .catch((error) => {
        if(error) {
        const errorObject = error?.response?.data;
      //   showSnackBar(dispatch, errorObject);
        }
      });
      }

     //VERIFY USER EMAIL
    
     const checkExistingUser = (payload, callBack) => {
      dispatch(updatePendingCallState({checkExistingUser: true}))
      checkEmail(payload) 
        .then((response) => {   
          dispatch(updatePendingCallState({checkExistingUser: false}))
          if(response) {
            const responseObject = response?.data;
            const responseData = responseObject?.data;
            if(responseObject?.http_code === 200){
              //success
            }else {
               // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
            }
          }
          
        })
        .catch((error) => {
          if(error) {
            dispatch(updatePendingCallState({checkExistingUser: false}))
            callBack()
          const errorObject = error?.response?.data;
          //showAuthSnackBar(errorObject, dispatch);
          }
        });
    }

  return {
    newUserSignup,
    userLogin,
    resetUserPassword,
    forgotUserPassword,
    resendVerificationLink,
    checkTokenVerificationValidity,
    verifyEmail,
    checkExistingUser,
    checkUserButterflyAccess
  }
};