import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customTheme as theme } from "../../../theme/customTheme";
import luciLogo from '../../../assets/luci_dark_logo.svg'
import "@fontsource/rubik";
import { updateUserState } from "../../../redux/userSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    // maxHeight: "60px",
    // minHeight: "40px",
    // height: "50px",
    display: "flex",
    backgroundColor: 'black',
    alignItems: "center",
    justifyContent: "center",
    // boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
    zIndex: 1150,
    border:'1px solid #707070'
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <Box className={classes.footer} sx={{height:{xs:'30px',md:'40px',lg:'40px',xl:'65px'}}}>
        <Box sx={{height:{xs:'17px',lg:'18px',xl:'26.5px'}}}>
            <img src={luciLogo} alt="Luci Logo" height='100%'/>
        </Box>
        <Typography
          variant="body1"
          sx={{ml: 2, mt: '1px', fontFamily: 'Rubik', fontSize:{xs:'8px', sm:'12px',lg:'12px',xl:'12px'}}}
          style={{
            color: 'white',
            fontWeight: 600,
            textAlign: 'center'
          }}
        >
          © {new Date().getFullYear()} All Rights Reserved. |<Typography component='span' sx={{fontSize:{xs: '8px', sm: '12px'}, ml: '4px', color:'#FFFFFF', width: '95%', textAlign: 'center', fontWeight:600,}}>{"View our "} <Typography  component='span' onClick={()=> dispatch(updateUserState({footer_disclaimer: true}))} sx={{fontSize:{xs: '8px', sm: '12px'}, color:'#FFFFFF', fontWeight:900, cursor: 'pointer', textDecoration: 'underline #FFFFFF'}}>Disclaimer </Typography></Typography>
        </Typography>
      </Box>
    </>
  );
};

export default Footer;