import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from './redux/store';
import { Provider } from 'react-redux';

import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { customTheme } from './theme/customTheme';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './pages/shared/ErrorPage';


let persistor = persistStore(store)
const queryClient = new QueryClient({
  refetchOnWindowFocus: false
})


const container = document.getElementById('root');
const root = createRoot(container)

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


root.render(
  <>
    <ErrorBoundary FallbackComponent={ErrorPage} >
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={customTheme} >
              <CssBaseline />
              <App />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  </>)
