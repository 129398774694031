import { Backdrop, Box,  Slide, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import UpIcon from '../icons/UpIcon';
// import DownIcon from '../icons/DownIcon';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/base';
import { LoadingButton } from '@mui/lab';
import { getNamesFromIds } from '../../../utils/commonServices';
import useEventCreationActions from '../hooks/useEventCreationActions';
import { updateProjectCreationState } from '../../../redux/projectCreationSlice';
import LeftIcon from '../icons/LeftIcon';
import useContentActions from '../../events/hooks/useContentActions';
import { imageAspectList, imageStyleList } from '../../../constants/staticData';

const FinalScreen = (props) => {

    const {children, slideDirection,  prevPageHandler, pageIndex,  closeHandler, totalScreens} = props
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const dropdownReducer = useSelector((state)=> state.persistedReducers.dropdownReducer);
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const pendingCallReducer = useSelector((state) => state.pendingCallReducer)
    const { createContent, createImage } = useContentActions();
    const projectType = getNamesFromIds(createProjectReducer?.commonPageData?.projectTypeId, dropdownReducer?.project_type)?.name
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const commonReducer = useSelector((state) => state.commonReducer);
    const isListView = commonReducer?.list_view
    const mobileView = useMediaQuery('(max-width:599px)');
  const {createProject} = useEventCreationActions();
    const createProjectHandler = ()=> {
        let payload;
        // if(parseInt(createProjectReducer.commonPageData?.projectTypeId) == 9) {
        //    // payload = {...createProjectReducer.commonPageData, personaId: 1, lengthId: 1}
        //    payload = {
        //    query: {
        //     projectTypeId: createProjectReducer.commonPageData.projectTypeId,
        //     name : createProjectReducer.commonPageData.name,
        //     personaId: 1,
        //    // startDate: new Date().toISOString(),
        //     //endDate: new Date().toISOString(),
        //     description: createProjectReducer.commonPageData.description,
        //     lengthId: 1
        //   },
        //     data: {
        //       userScript:createProjectReducer.commonPageData.userScript
        //     }
            
        //    }
        // } else {
          payload = {
            query: {
             projectTypeId: createProjectReducer.commonPageData.projectTypeId,
             name : createProjectReducer.commonPageData.name,
             personaId: parseInt(createProjectReducer.commonPageData?.projectTypeId) == 9 ? 1 : createProjectReducer.commonPageData.personaId,
            // startDate: new Date().toISOString(),
             //endDate: new Date().toISOString(),
             lengthId: parseInt(createProjectReducer.commonPageData?.projectTypeId) == 9 ? 1 : createProjectReducer.commonPageData.lengthId,
           },
             data: {
               userScript: parseInt(createProjectReducer.commonPageData?.projectTypeId) == 9 ? "" : createProjectReducer.commonPageData.userScript,
               description: createProjectReducer.commonPageData.description,
             }
             
            }
        // }
        // const query = {}
      createProject(payload)
      //console.log("create clicked", payload )
    }
   
    

    const createContentHandler = (data) => {
      if(parseInt(createProjectReducer.commonPageData?.projectTypeId) == 9) {
        createImageHandler()
      } else {
        const payload = {
         query: {
            contentTypeId: createProjectReducer.commonPageData.projectTypeId,
          projectLength: createProjectReducer.commonPageData.lengthId,
          storyboardCount: 3,    
          projectId: scriptReducer.selected_project_details.id,
          personaId: scriptReducer.selected_project_details.personaId,
        },
          data: {
            prompt: createProjectReducer.commonPageData?.description
          }
  
        }
      createContent(payload)
      }
     
    }

   const createImageHandler = ()=> {
    const payload = {
      "projectId": parseInt(scriptReducer.selected_project_details.id),
      "contentTypeId": "9",
      "prompt": createProjectReducer?.commonPageData?.description,
      "style": getNamesFromIds(createProjectReducer.imagePageData?.style, imageStyleList)?.name?.toLowerCase(), 
      "aspectRatio": getNamesFromIds(createProjectReducer.imagePageData?.aspectRatio, imageAspectList)?.name?.toLowerCase(),
      "grayscale": createProjectReducer.imagePageData?.grayscale,
      "primaryColor": createProjectReducer.imagePageData?.grayscale ? "" : createProjectReducer.imagePageData?.primaryColor ,
      "secondaryColor": createProjectReducer.imagePageData?.grayscale ? "" : createProjectReducer.imagePageData?.secondaryColor ,
     // "colors": []
    }
  createImage(payload)
   }


    useEffect(() => {
      const keyDownHandler = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          if(!pendingCallReducer?.loader_backdrop_open){
          (!scriptReducer.open_delete_popup && contentReducer?.create_content_open )? createContentHandler() : createProjectHandler()
          }
        }
      };
        document.addEventListener("keydown", keyDownHandler)
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [contentReducer?.create_content_open, scriptReducer.open_delete_popup, pendingCallReducer?.loader_backdrop_open]);


    const handleRadioChange = (event) => {
      dispatch(updateProjectCreationState({commonPageData: {...createProjectReducer.commonPageData, personaId: parseInt(event.target.value)}}))
    };
  

  return (
    <>
    {/* <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createProjectReducer.open_backdrop_project_creation}
    > */}
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
         {(!isListView || contentReducer?.create_content_open) && <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr: 6}}  onClick={closeHandler}>Close X</Typography>}
          <Box flexGrow={1} className='flex col 'sx={{ paddingLeft:isListView ? {xs:'3vw',md:'5vw'} : {xs: '4vw' , md:'14vw'}, marginTop: isListView ? {xs:'6%',sm:'4%', md:'3%', xl:'4%'}: {xs:'8%',sm:'6%', md:'4%', xl:'6%'}, height:'100%', overflowY:'scroll'}}>
              <Box mb={{xs:1, sm:2}}>
                <Typography sx={{fontSize: isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} : {xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography sx={{fontSize: isListView ? {xs:'20px',sm:'22px',md:'26px',lg:'35px',xl:'52px'} : {xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik',  whiteSpace:'break-spaces'}}>{`Are you ready to generate your ${projectType}?`} </Typography>
                {/* <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{note}</Typography> */}
                <Box className='flex '  pt={isListView ? 2 : 3} pl={{xs:3, sm:5, md:3}}  sx={{width:'90%', overflowY:'scroll', justifyContent: 'center', flexDirection:{xs:'column', md:'row', }}} gap={{xs:2, lg:3, xl:4}}>
                { children
                  // personaList?.map((persona)=> {
                  //   return <CustomRadioButton
                  //   label='novice'
                  //   id={persona.id}   
                  //   name='personaType' 
                  //   handleRadioChange={handleRadioChange}
                  //   height={{ xs:'95px',lg:'114px', xl:'155px'}}
                  //   width={{xs:'230px',lg:'250px', xl:'381px'}}
                  //   selectedValue={createProjectReducer.commonPageData.personaId}
                  //  // setSelectedValue={setPageData}
                  //   component={
                  //     <Box px={2} className='flex col a-center j-center' gap={1} sx={{width:'100%'}}>
                  //         <Typography sx={{color:'#08231B', fontSize:{xs:'15px',md:'20px',lg:'26px',xl:'40px'}, fontWeight:600,}}>{persona.name}</Typography>
                  //         <Typography textAlign={'center'} sx={{color:'#08231B', fontSize:{xs:'8px',md:'9px',lg:'11px',xl:'15px'}, }}>{persona.description}</Typography>
                  //     </Box>}
                  // />
               
                  // })
                }
              </Box>
              </Box>
          
            
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', alignItems: 'flex-end',mb:3, mr:3}}>
            <LeftIcon sx={{opacity: pageIndex === 1 && 0.5, fontSize:isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}: {xs:'45px', sm:'55px', lg:'65px',xl:'82px'}, cursor:'pointer'}}  onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <LoadingButton
                 // size="small"
                 // disabled={userReducer.common_backdrop_open || text !== 'delete'}
                  onClick={contentReducer?.create_content_open ? createContentHandler : createProjectHandler}
                  style={{borderRadius: '0px 5px 5px 0px', backgroundColor:'#08231B', color:'#00D744', textTransform:'none' }} 
                  sx={{ fontSize:{xs:'12px',sm:'13px',md:'14px',lg:'16px',xl:'20px', marginLeft: '10px' }, height:isListView ? {xs:'33px !important', sm:'30px !important', md: '37px !important', lg:'46px !important',xl:'67.2px !important'} : {xs:'36.9px', sm:'45.1px', lg:'53.3px',xl:'67.2px'} , fontWeight:600, width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'}, minWidth: '110px !important'}}
                 // loading={userReducer.common_backdrop_open}
                  loadingPosition="start"
                  variant="contained"
                  >
                 Create
             </LoadingButton> 
          </Box>
        </Box>
    {/* </Backdrop> */}
    {/* </Slide> */}
    </>
  )
}

export default FinalScreen;