import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CustomTooltip from './CustomTooltip';

//const titles = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

const CustomButtonlist = (props) => {
   const {titles, actions, color = 'primary', tooltips} = props
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  
  const fontColor = color == 'primary' ? '#00D744' : '#000000';
  const bColor = color == 'primary' ? '#08231B' : '#00CEFF'

  const handleClick = () => {
    console.info(`You clicked ${titles[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <CustomTooltip title= {tooltips[selectedIndex]} >
        <Button 
            onClick={actions[selectedIndex]}
            style={{
                borderRadius: '5px 0px 0px 5px',
               // border: "none",
               color: fontColor,
         //  border: `1px solid ${bColor}`,
            textTransform: 'none',
            backgroundColor: bColor,  
            
            }}
            sx={{ minWidth: '142px', fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, fontWeight: 600, height: 29, fontFamily: 'Rubik', width: { xs: '80px', md: '100px', lg: '130px', xl: '160px' } }}

        >
            {titles[selectedIndex]}
        </Button>
        </CustomTooltip>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          style={{
            borderRadius: '0px 5px 5px 0px',
           // border: "none",
           color: fontColor,
         //  border: `1px solid ${bColor}`,
            textTransform: 'none',
            backgroundColor: bColor, 
        
        }}
        sx={{ 
            minWidth: '142px', 
            //fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, 
            //fontWeight: 600, 
            height: 29, 
            //fontFamily: 'Rubik', width: { xs: '80px', md: '100px', lg: '130px', xl: '160px' } 
        }}

          onClick={handleToggle}
        >
          |<ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
                 sx={{  width: { xs: '130px', md: '150px', lg: '180px', xl: '210px' } }}

            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList 
                    id="split-button-menu" 
                    autoFocusItem
                      >
                  {titles?.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      sx={{fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, fontWeight: 600, fontFamily: 'Rubik'}}
             
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

export default CustomButtonlist