import { Backdrop, Box, Slide, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FinalScreen from '../FinalScreen';
import { getNamesFromIds } from '../../../../utils/commonServices';
import { personaList } from '../../../../constants/staticData';

const PreviewCreateProject = (props) => {

    const { pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex, closeHandler, totalScreens } = props
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const projectDetails = {
        type : getNamesFromIds(createProjectReducer?.commonPageData?.projectTypeId, dropdownReducer?.project_type)?.name,
        length: `${createProjectReducer?.commonPageData?.lengthId} ${parseInt(createProjectReducer?.commonPageData?.projectTypeId) > 3 && parseInt(createProjectReducer?.commonPageData?.projectTypeId) != 8 ? "Words" : "Seconds"}`,
        persona: getNamesFromIds(createProjectReducer?.commonPageData?.personaId, personaList)
    }
    const [nameValidationFlag, setNameValidationFlag] = useState(false);
    const dispatch = useDispatch()

    // const createHandler = () => {
    //     console.log("create Project")
    // }

    return (
        <>
            <FinalScreen
                {...props}
              // createHandler={createHandler}
            >
                <Box className='flex col a-start j-start' gap={2} sx={{ width: '100%' }}>
                   { !contentReducer?.create_content_open && <Box className='flex row j-center a-start' >
                        <Typography sx={{ minWidth: '120px' }}>{`Name: `} </Typography>
                        <Typography sx={{ textAlign: 'justify' }}><Box component='div' sx={{ whiteSpace: 'wrap', maxHeight: '150px', overflowY: 'scroll' }} >{createProjectReducer?.commonPageData?.name}</Box></Typography>
                    </Box>}

                    <Box className='flex row j-center a-start' >
                        <Typography sx={{ minWidth: '120px' }}>Length: </Typography>
                        <Typography sx={{ textAlign: 'justify' }}><Box component='div' sx={{ whiteSpace: 'wrap', maxHeight: '150px', overflowY: 'scroll' }} >{projectDetails?.length}</Box></Typography>
                    </Box>

                    {
                       createProjectReducer.voiceOverPageData.needScript && <Box className='flex row j-center a-start' >
                        <Typography sx={{ minWidth: '120px' }}>Description: </Typography>
                        <Typography sx={{ textAlign: 'justify' }}><Box component='div' sx={{ whiteSpace: 'wrap', wordBreak: 'break-all', maxHeight: '150px', overflowY: 'scroll' }} >{createProjectReducer?.commonPageData?.description}</Box></Typography>
                    </Box>
                    }
                    <Box className='flex row j-between a-start' >
                        <Typography sx={{ minWidth: '120px' }}>Persona: </Typography>
                        <Typography sx={{ textAlign: 'justify' }}><Box component='div' sx={{ whiteSpace: 'wrap', maxHeight: '150px', overflowY: 'scroll' }} >{`${projectDetails?.persona?.name} - ${projectDetails?.persona?.description}`}</Box></Typography>
                    </Box>

                </Box>
            </FinalScreen>
        </>
    )
}

export default PreviewCreateProject;