import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { decryptString } from "../../utils/encryptionServices";
import { updateCommonState } from "../../redux/commonSlice";

const AuthRedirect = (props) => {
    const [searchParams] = useSearchParams();
    const requestType = searchParams.get('request');
    const  token  = searchParams.get('k')
    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
   console.log({token})
    useEffect(() => {
        if (requestType == 'logout') {
            if (!!window.localStorage.getItem('token')) {
                dispatch(updateCommonState({ logout_from_luci: true }))
                window.localStorage.removeItem('token')
               
            }
        }
    }, [requestType])

    useEffect(()=> {
        if (!!token) {
            const decodedString = decodeURIComponent(token)
            const decryptedToken = decryptString(decodedString)
            console.log({ decryptedToken })
            window.localStorage.setItem('token', decryptedToken)
        }
    }, [token])

    useEffect(()=> {
        if(userReducer.is_logged) {
            if(userReducer.butterfly_access && !userReducer.page_loading) {
                 //   navigate(!!window.localStorage.getItem('destination_path') ? window.localStorage.getItem('destination_path') : '/dashboard', {replace: true})
                 navigate('/dashboard', {replace: true})
            } else {
                navigate('/no-access', {replace: true})
            }
        }
       
    }, [userReducer.butterfly_access, userReducer.is_logged])

    return <Box sx={{ display: 'flex', color: '#000000', backgroundColor: '#ffffff', width: '100%', height: '100vh', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography fontSize={38} p={8}>Please wait while we redirect for authentication...</Typography>
    </Box>

}

export default AuthRedirect