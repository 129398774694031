import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, BrowserRouter as Router, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import ProductionEventPage from './pages/events';
import PageNotFound from './pages/shared/PageNotFound';
import { isIos, logout, resetAllState } from './utils/commonServices';
import ReactGA from "react-ga4";
import SignUp from './pages/unauth/signup';
import SignIn from './pages/unauth/signin';
import ForgotPassword from './pages/unauth/forgotpassword';
import ResetPassword from './pages/unauth/resetpassword';
import ResendLink from './pages/unauth/resendlink';
import { updateUserState } from './redux/userSlice';
import VoiceProfileManager from './pages/events/components/voiceProfile/components/VoiceProfileManager';
import { checkUserButterflyAccess, getUserRoles } from './pages/dashboard/actions';
import NoAccessPage from './pages/shared/NoAccessPage';
import useAuthFlow from './pages/unauth/hooks/useAuthFlow';
import { redirect } from 'react-router-dom';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import CommonLoader from './pages/eventcreation/components/CommonLoader';
import Logout from './pages/shared/AuthRedirect';
import AuthRedirect from './pages/shared/AuthRedirect';
import { updateCommonState } from './redux/commonSlice';

// import { useSelector } from 'react-redux';

//lucimoments -----------------------
// const GOOGLE_ANALYTICS_ID="G-WT87Y5QVPK"

//localhost ----------------------------
// const GOOGLE_ANALYTICS_ID="G-L29SNM4Y46"

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)
// ReactGA.initialize([
//   {
//     trackingId: process.env.GOOGLE_ANALYTICS_ID
//   }
// ])

function App() {

   
   const userReducer = useSelector((state) => state.persistedReducers.userReducer);
   const commonReducer = useSelector((state) => state.commonReducer);
   
    const dispatch = useDispatch()
    // const {checkUserButterflyAccess} = useAuthFlow()
    


   useEffect(()=> {
    setTimeout(()=> {
      if(!!window.localStorage.getItem('token')) {
        dispatch(updateUserState({ is_logged: true }));
        checkUserButterflyAccess(dispatch)
      } else {
        
        if(commonReducer.logout_all) {
          dispatch(updateCommonState({ logout_all: false }));
          console.log("logout butetrfly", commonReducer.logout_all)
        //  dispatch(updateUserState({ is_logged: false }));
          window.location.href=`${process.env.REACT_APP_LUCIHUB_URL}/auth-redirect?request=logout`;
          resetAllState(dispatch);
        } else {
          if(commonReducer.logout_from_luci){
            dispatch(updateCommonState({logout_from_luci: false}))
            window.location.href = `${process.env.REACT_APP_LUCIHUB_URL}`
          } else {
                window.location.href=`${process.env.REACT_APP_LUCIHUB_URL}/auth-redirect?request=login`;
          }
          //console.log("login butetrfly", `${process.env.REACT_APP_LUCIHUB_URL}/auth-redirect?request=login`)
         
        }
        
      }
    }, 1500)
   
   },[window.localStorage.getItem('token')])


   const [token, setToken] = useState(window.localStorage.getItem('token'));
   const [loading, setLoading] = useState(false)

//  useEffect(()=>{
//   if( userReducer?.butterfly_access == true){
//     dispatch(updateUserState({ is_logged: true }));
//     window.history.pushState({}, '', '/dashboard');
    
//   }else{
//     dispatch(updateUserState({ is_logged: false }));
//     window.history.pushState({}, '', '/no-access');
//   }

//  },[userReducer?.butterfly_access])



  

  //  useEffect(() => {
  //   dispatch(updateUserState({page_loading: true}))
  //   const requestToken =  () => {
  //     const targetOrigin = process.env.REACT_APP_LUCIHUB_URL;
  //     const iframe = document.createElement('iframe');
  //     iframe.style.display = 'none';
  //     iframe.src = targetOrigin;
  //     document.body.appendChild(iframe);

  //      iframe.onload =  () => {
  //       window.top.postMessage('REQUEST_TOKEN', targetOrigin);
       
  //     };
  

  //     const handleMessage =  (event) => {
  //       // console.log(event.origin, 'event origin')
  //       // console.log(targetOrigin, 'target origin')
  //       if (event.origin === 'http://localhost:3000' ) {
  //         console.log('origin matched')
  //         if(event.data){
  //           console.log('data from iframe', event.data)
  //           // window.localStorage.setItem('token', event.data.token);
  //           // checkUserButterflyAccess(dispatch)
  //           // setToken(event.data.token);
  //           // // console.log('postMessageApi', event.data.token);
  //           // dispatch(
  //           //   updateUserState({
  //           //     user_name: event.data.user?.user_name,
  //           //     user_id: event.data.user?.user_id,
  //           //   })
  //           // );
      
  //          // console.log(' butterfly  token', event.data.token)
  //         }else{
  //           console.log("No data from iframe")
  //         //   setTimeout(() => {
  //         //     console.log(' butterfly else block')
  //         //     window.localStorage.removeItem("token");
  //         //     setToken(null)
  //         //     const params = new URLSearchParams({  redirect: true }).toString();
  //         //     window.location.href = `${process.env.REACT_APP_LUCIHUB_URL}/signin?${params}`;
  //         //     dispatch(updateUserState({page_loading:false}))
  //         // }, 2000);
  //         }
  //       } 
  //     };

  //     window.addEventListener('message', handleMessage);
  //     requestToken();
  //     return () => {
  //       window.removeEventListener('message', handleMessage);
  //       if (iframe.parentNode) {
  //         document.body.removeChild(iframe);
  //       }
  //     };
  //   };
  // }, []);

//   useEffect(()=> {
//     const iframe = document.createElement('iframe');
//     iframe.style.display = 'none';
//     iframe.src = 'http://localhost:3000';
//     document.body.appendChild(iframe);
//     // Function to request JWT from main app
//     function requestJwtFromMainApp() {
//       // iframe.onload = () => {
//         iframe.contentWindow.postMessage('requestJwt', 'http://localhost:3000');
//       // };
//     }
// // Listener for messages from main app
// const eventCallback = (event) => {
//   console.log("event triggered from queenbee" )
//   if (event.origin === 'http://localhost:3000' && event.data.jwt) {
//     console.log("butterfly token", event.data.jwt )
//     window.localStorage.setItem('token', event.data.jwt);
//     document.body.removeChild(iframe);
//   }
// }
// window.addEventListener('message', eventCallback);

// const jwt = window.localStorage.getItem('token');
//   if (!jwt) {
//     requestJwtFromMainApp();
//   }

//   return () => {
//     window.removeEventListener('message', eventCallback);
//   };

//   }, [])


  // useEffect(() => {
  //   dispatch(updateUserState({page_loading: true}))
  //   const targetWindow = document.getElementById('domain-a-iframe').contentWindow;

  //   const handleMessage = (event) => {
  //     // if (event.origin !== 'http://localhost:3000') {
  //     //   return; // Ensure the message is from the expected origin
  //     // }

  //     const receivedData = event.data;
  //     // Handle the received data (e.g., store it in local storage)
  //     localStorage.setItem('receivedData', receivedData);
  //     console.log('Received data in butterfly:', receivedData);
  //     dispatch(updateUserState({page_loading: false}))
  //   };

  //   window.addEventListener('message', handleMessage);

  //   // Request data from Domain A
  //   targetWindow.postMessage('requestLocalStorageData', 'http://localhost:3000');

  //   return () => {
  //     window.removeEventListener('message', handleMessage);
  //   };
  // }, []);


  return (<>
      <Router >
          {
            userReducer?.page_loading == true  ? <></> :
          !userReducer.is_logged ?  <Routes>
          <Route path= '/' element={<Navigate to='/redirect' />} />  
          <Route path='/redirect' element={ <AuthRedirect/> } />
          {/* <Route path='/signup' element={ <SignUp/> } />
         
          <Route path='/signin' element={ <SignIn/> } />
          <Route path='/forgotpassword' element={ <ForgotPassword/> } />
          <Route path='/resendlink' element={<ResendLink/>} />
          <Route path='/resetpassword' element={ <ResetPassword/> } /> */}
          <Route path='*' element={ <PageNotFound/> } />
        </Routes> :
        
          <Routes>
             <Route path= '/' element={<Navigate to='/dashboard' />} /> 
            <Route path='/dashboard' element={ <Dashboard/> } />
            <Route path='/voiceprofiles' element={ <VoiceProfileManager/> } />
            <Route path='/no-access' element={ <NoAccessPage/> } />
            {/* <Route path='/guestuploads' element={isIos() ? <RedirectToAppStore /> : <GuestUserPage />} />  
            <Route path='/invite-collaborator' element = {<RedirectToAppStore /> } />
            <Route path='/realestate-verify' element = {<RedirectToAppStore /> } />
            <Route path='/realestate-resetpassword' element = {<RedirectToAppStore /> } /> */}
            {/* <Route path='/guestuploads' element = {<RedirectToAppStore /> } /> */}
            {/* <Route path='/create-event' element={ <CreateEvent /> } /> 
            <Route path='/edit-event' element={ <EditEvent /> } />
            <Route path='/hdsbvjdsdfdsfsfhbv' element={<CustomIntermediateNavigate />} />
            <Route path='/completedevent' element={ <CompletedEventPage/> } /> */}
            <Route path='/redirect' element={ <AuthRedirect/> } />
            <Route path='/productionevent/:projectId' element={ <ProductionEventPage/> } />
            <Route path='*' element={ <PageNotFound/> } />
            {/* <Route path= '*' element={<Navigate to='/error-notfound-redirect' />} />  */}
          </Routes>  
        
        }
      </Router>
       
        
    </>);
}

export default App;
