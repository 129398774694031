import CustomCard from "./CustomCard"
import tutorial_projects_thumb from "../../../assets/tutorial_projects.png"
import tutorial_voice_studio_thumb from "../../../assets/tutorial_voice_studio.png"
import { Box } from "@mui/material"

const FilmingTutorialCard = (props) => {
  const {clickable, type} = props;
  const thumbSrc = {
    voice_studio : tutorial_voice_studio_thumb,
    projects: tutorial_projects_thumb
  }
  return (
    <CustomCard {...props} sx={{height: '100%' }}>
    <Box  textAlign='center' height='100%' m={0} p={0} sx={{cursor: clickable && 'pointer' }}>
       <img src={thumbSrc[type]} height = '100%'   style={{objectFit:'cover', borderRadius:'5px'}} width='100%'/>
    </Box>
</CustomCard>
  )
}

export default FilmingTutorialCard
