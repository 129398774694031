import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUserState } from "../../../redux/userSlice";
import { convertContentApi, createContentApi, createImageApi, deleteContentApi, getContentDetailApi, getProjectDetailsApi, updateContentDetailApi, updateVoContentApi } from "../../../services/api";
import { updateSriptState } from "../../../redux/projectScriptSlice";
import { updateContentState } from "../../../redux/contentSlice";
import useProjectActions from "./useProjectActions";
import { getNamesFromIds, isArray, removeDuplicateArrayItem, showSnackBar } from "../../../utils/commonServices";
import { resetPendingCallState, updatePendingCallState } from "../../../redux/pendingCallSlice";
import { updateStoryboardState } from "../../../redux/storyboardSlice";
import { gaEvents } from "../../../ga/actions";
import { setSnackbar } from "../../../redux/messageSlice";
import { updateVoiceState } from "../../../redux/voiceSlice";
import { resetProjectCreationState } from "../../../redux/projectCreationSlice";
export default ()=> {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {getProjectDetails} = useProjectActions()
    const scriptReducer = useSelector((state)=>state.scriptReducer)
    const dropdownReducer = useSelector((state)=>state.persistedReducers.dropdownReducer)
    const voiceReducer = useSelector((state) => state.voiceReducer);
//API  to create new content for a project

const createContent = (payload, callBack ) => {
    dispatch(updatePendingCallState({loader_backdrop_open: true}))
     createContentApi(payload).then(response => {
         dispatch(resetPendingCallState())
         if(response.data?.httpCode === 201){
            gaEvents.contentCreationSuccessful()
            dispatch(resetProjectCreationState())  
            dispatch(updateContentState({create_content_open: false}))
           getProjectDetails(scriptReducer.selected_project_details.id)
           getContentDetails({id: response?.data?.data?.id })
           navigate(`/productionevent/${payload?.query?.projectId}`, {replace:true})
        //    if(isArray(response?.data?.data) && response.data?.data[0]?.type === 'Storyboard') {
        //     getContentDetails({id:response?.data?.id})
        //    } else {
        //     getContentDetails({id:response?.data?.data?.id})
        //    }
          
           // console.log("content details", response?.data?.content)
           // dispatch(updateContentState({selected_content_details:{...response?.data?.content, id: response?.data?.id} }))
             // if(response.data.projectType.toLowerCase().trim() === "storyboard") {
             //    // let tempContents = removeDuplicateArrayItem(response?.data?.contents, "contentID")
             //    // dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
             // } else {
             //    // dispatch(updateUserState({selected_project_details: {...response?.data, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
             // }
            
             // if(navigate) {
             //     if(response?.data?.contents?.length > 0) {
             //         dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
             //        // getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
             //     }
                 
             //}    
         }else{
            // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
         }
     }).catch(error =>{
         //console.log("Error", error)
         if(error){
         gaEvents.contentCreationFailed()
         dispatch(resetPendingCallState())
         showSnackBar(error?.response, dispatch)
        }
        // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
     })
 }
 
//API to create Image

const createImage = (payload, callBack ) => {
    dispatch(updatePendingCallState({loader_backdrop_open: true}))
     createImageApi(payload).then(response => {
         dispatch(resetPendingCallState())
         if(response.data?.httpCode === 201){
            
            console.log("image response", response.data)
            dispatch(resetProjectCreationState())  
            gaEvents.contentCreationSuccessful()
            navigate(`/productionevent/${payload?.projectId}`, {replace:true})
            dispatch(updateContentState({create_content_open: false}))
           getProjectDetails(scriptReducer.selected_project_details.id)
           getContentDetails({id: response?.data?.data?.id })
           !!callBack && callBack();
        //    if(isArray(response?.data?.data) && response.data?.data[0]?.type === 'Storyboard') {
        //     getContentDetails({id:response?.data?.id})
        //    } else {
        //     getContentDetails({id:response?.data?.data?.id})
        //    }
          
           // console.log("content details", response?.data?.content)
           // dispatch(updateContentState({selected_content_details:{...response?.data?.content, id: response?.data?.id} }))
             // if(response.data.projectType.toLowerCase().trim() === "storyboard") {
             //    // let tempContents = removeDuplicateArrayItem(response?.data?.contents, "contentID")
             //    // dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
             // } else {
             //    // dispatch(updateUserState({selected_project_details: {...response?.data, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
             // }
            
             // if(navigate) {
             //     if(response?.data?.contents?.length > 0) {
             //         dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
             //        // getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
             //     }
                 
             //}    
         }else{
            // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
         }
     }).catch(error =>{
         //console.log("Error", error)
         if(error){
         gaEvents.contentCreationFailed()
         dispatch(resetPendingCallState())
         showSnackBar(error?.response, dispatch)
        }
        // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
     })
 }
 

    // APi to get project details by project Id
const getContentDetails = (payload, callBack ) => {
   // dispatch(updateUserState({common_backdrop_open: true}))
   dispatch(updatePendingCallState({loader_backdrop_open: true, loader_text: {title: `Please wait...`, message: "Loading content"}}))
    getContentDetailApi(payload).then(response => {
        dispatch(resetPendingCallState())
        if(response.data?.httpCode === 200){
        //    dispatch(updateContentState({selected_content_details:{content : response?.data?.content, id: response?.data?.id} }))
            console.log(response?.data?.content?.type?.toLowerCase(), 'script')
            if(response?.data?.content[0]?.type?.toLowerCase() === "storyboards") {
               
                console.log(response.data, 'storyboard')
               let tempContents = removeDuplicateArrayItem(response?.data?.content, "contentID")
            //    dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
                dispatch(updateStoryboardState({storyboard_details : response?.data?.content}))
            } 
            else if(response?.data?.content?.type?.toLowerCase() === "voice over" || response?.data?.content?.type?.toLowerCase() === "script"){
               
                const voiceTemp = voiceReducer?.voice_profiles?.find((profile => profile.id == response?.data?.content?.vo_profile_id && profile.is_default_profile == response?.data?.content?.is_default_profile))
                const isVoiceProfile = !!response?.data?.content?.vo_profile_id
                dispatch(updateVoiceState({
                    profile_name: voiceTemp?.name,
                    profile_description: voiceTemp?.description,
                    selected_voice: !!dropdownReducer?.voices?.find(voice=> voice.ShortName === response?.data?.content?.voice) ? dropdownReducer?.voices?.find(voice=> voice.ShortName === response?.data?.content?.voice)?.ShortName : dropdownReducer?.voices[5],
                    selected_style: !!response?.data?.content?.voice_style ? response?.data?.content?.voice_style : voiceTemp?.voice,
                    selected_accent: !!response?.data?.content?.voice_accent ? response?.data?.content?.voice_accent : voiceTemp?.voice_accent,
                    pitch: !!response?.data?.content?.voice_pitch ? response?.data?.content?.voice_pitch : 0,
                    rate: !!response?.data?.content?.voice_speed ? response?.data?.content?.voice_speed: 0 ,
                    audio_url: response?.data?.content?.voice_audio_url,
                    selected_voice_profile: response?.data?.content?.vo_profile_id,
                    is_selected_default: !!response?.data?.content?.is_default_profile,
                    voice_profile_view: !(!isVoiceProfile && !!response?.data?.content?.voice_audio_url) 
                }))
                dispatch(updateContentState({selected_content_details:{content : response?.data?.content, id: response?.data?.id}, is_voice: !!response?.data?.content?.voice_audio_url }))
                
            } 
            else {
                console.log('else')
                dispatch(updateContentState({selected_content_details:{content : response?.data?.content, id: response?.data?.id} }))
            }
           
            if(navigate) {
                if(response?.data?.contents?.length > 0) {
                    dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
                   // getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
                }
                
            }    
        }else{
           // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        }
    }).catch(error =>{
        //console.log("Error", error)
        if(error){
        dispatch(resetPendingCallState())
        showSnackBar(error?.response, dispatch)
       }// dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    })
}

//API  to Refine existing content for a project

const updateContent = (payload, callBack ) => {
    dispatch(updatePendingCallState({loader_backdrop_open: true}))
     updateContentDetailApi(payload).then(response => {
         dispatch(resetPendingCallState())
         if(response.data?.httpCode === 201){
          getProjectDetails(scriptReducer.selected_project_details.id)
        //   getContentDetails({id:response?.data?.data?.id})
         getContentDetails({id: payload?.projectTypeId == 3 ? response?.data?.id : response?.data?.data?.id })
           // console.log("content details", response?.data?.content)
           // dispatch(updateContentState({selected_content_details:{...response?.data?.content, id: response?.data?.id} }))
             // if(response.data.projectType.toLowerCase().trim() === "storyboard") {
             //    // let tempContents = removeDuplicateArrayItem(response?.data?.contents, "contentID")
             //    // dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
             // } else {
             //    // dispatch(updateUserState({selected_project_details: {...response?.data, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
             // }
            
             // if(navigate) {
             //     if(response?.data?.contents?.length > 0) {
             //         dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
             //        // getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
             //     }
                 
             //}    
             gaEvents.updateContentSuccessful()
         }else{
            // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
         }
     }).catch(error =>{
       //  console.log("Error", error)
        if(error) {
            dispatch(resetPendingCallState())
            gaEvents.updateContentFailed()
            showSnackBar(error?.response, dispatch)
        }
        // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
     })
 }

//API  to Refine existing content for a project

const updateVoContent = (payload ) => {
    dispatch(updatePendingCallState({loader_backdrop_open: true}))
     updateVoContentApi(payload).then(response => {
        // dispatch(resetPendingCallState())
         if(response.data?.httpCode === 201){
          getProjectDetails(scriptReducer.selected_project_details.id)
        //   getContentDetails({id:response?.data?.data?.id})
         getContentDetails({id: payload?.query?.contentId })
           // console.log("content details", response?.data?.content)
           // dispatch(updateContentState({selected_content_details:{...response?.data?.content, id: response?.data?.id} }))
             // if(response.data.projectType.toLowerCase().trim() === "storyboard") {
             //    // let tempContents = removeDuplicateArrayItem(response?.data?.contents, "contentID")
             //    // dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
             // } else {
             //    // dispatch(updateUserState({selected_project_details: {...response?.data, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
             // }
            
             // if(navigate) {
             //     if(response?.data?.contents?.length > 0) {
             //         dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
             //        // getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
             //     }
                 
             //}    
             gaEvents.updateContentSuccessful()
         }else{
            // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
         }
     }).catch(error =>{
       //  console.log("Error", error)
        if(error) {
            dispatch(resetPendingCallState())
            gaEvents.updateContentFailed()
            showSnackBar(error?.response, dispatch)
        }
        // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
     })
 }


 //Api to convert a content to different type of content

 const convertContent = (payload, callBack ) => {
    dispatch(updatePendingCallState({loader_backdrop_open: true}))
    convertContentApi(payload).then(response => {
        dispatch(resetPendingCallState())
        if(response.data?.httpCode === 201){
          getProjectDetails(scriptReducer.selected_project_details.id)
          getContentDetails({id: payload.convertoProjectTypeId === 3 ? response?.data?.id :  response?.data?.data?.id})
          callBack()
          const projectType = getNamesFromIds(payload.convertoProjectTypeId, dropdownReducer?.project_type)?.name
          gaEvents.convertToProjectType(projectType)
          // console.log("content details", response?.data?.content)
          // dispatch(updateContentState({selected_content_details:{...response?.data?.content, id: response?.data?.id} }))
            // if(response.data.projectType.toLowerCase().trim() === "storyboard") {
            //    // let tempContents = removeDuplicateArrayItem(response?.data?.contents, "contentID")
            //    // dispatch(updateUserState({selected_project_details: {...response?.data, contents: tempContents, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
            // } else {
            //    // dispatch(updateUserState({selected_project_details: {...response?.data, project_type_id: projectTypeArr.find((item)=> item.value.toLowerCase().trim() === response.data.projectType.toLowerCase().trim()).id}}))
            // }
           
            // if(navigate) {
            //     if(response?.data?.contents?.length > 0) {
            //         dispatch(updateUserState({selected_content_id: response?.data?.contents[0]?.contentID }))
            //        // getContentDetails(response?.data?.contents[0]?.contentID, dispatch)
            //     }
                
            //}    
        }else{
           // dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
        }
    }).catch(error =>{
       // console.log("Error", error)
       if(error){
        dispatch(resetPendingCallState())
        showSnackBar(error?.response, dispatch)
       }// dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    })
}

//APi  to delete a projects    
const deleteContent = (payload, callBack) => {
    dispatch(updateUserState({common_backdrop_open: true}))
    deleteContentApi(payload).then(response => {
        dispatch(updateUserState({common_backdrop_open: false}))
        console.log("delete content res", response?.data)
       
        if(response.data?.httpCode === 200){
           // console.log("all project",response.data.projects)
           // dispatch(updateSriptState({all_projects: response?.data?.projects}))
           dispatch(setSnackbar({type:'success', flag:true, message: response.data?.message }))
           dispatch(updateContentState({selected_content_details:{}}))
           callBack()
           getProjectDetails(scriptReducer.selected_project_details.id)
           
        }else{
          //  dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
            // dispatch(updateUserState({common_backdrop_open: false}))
        }
    }).catch(error =>{
      if(error) { //console.log("error", error.response)
        showSnackBar(error?.response, dispatch)
       // dispatch(setSnackbar({type:'error', flag:true, message: 'Please try again later.'}))
        dispatch(updateUserState({common_backdrop_open: false}))}
    })
}


    return {getContentDetails, createContent, updateContent, convertContent, deleteContent, createImage, updateVoContent}

}