import { Backdrop, Box, Slide, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomRadioButton from '../CustomRadioButton'
import { useDispatch, useSelector } from 'react-redux';
import { updateProjectCreationState } from '../../../../redux/projectCreationSlice';
import CustomInput from '../CustomInput';
import UpIcon from '../../icons/UpIcon';
import DownIcon from '../../icons/DownIcon';
import { getErrorMsg } from '../../../../utils/validator';
import { afterValidate, getNamesFromIds, setInput } from '../../../../utils/commonServices';
import LeftIcon from '../../icons/LeftIcon';
import RightIcon from '../../icons/RightIcon';

const ProjectNamePage = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex,  closeHandler, totalScreens} = props
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const dropdownReducer = useSelector((state)=> state.persistedReducers.dropdownReducer)
    const projectType = getNamesFromIds(createProjectReducer.commonPageData.projectTypeId, dropdownReducer?.project_type)?.name
    const commonReducer = useSelector((state) => state.commonReducer);
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const [nameValidationFlag, setNameValidationFlag] = useState(false);
    const dispatch = useDispatch()
    const isListView = commonReducer?.list_view
    const isMobileView = useMediaQuery('(max-width:599px)');

    const finishedHandler = ()=> {
      validateInputs(true)
      afterValidate(nextPageHandler);
  }

  const validateInputs = (flag) => {
      setNameValidationFlag(flag)
  }

  const onChangeHandler = (e)=> {
    dispatch(updateProjectCreationState({commonPageData: {...createProjectReducer.commonPageData, name :  createProjectReducer.commonPageData.name != ""
    ? e.target.value.replace(/[<>:"/\\|?*]/g, '')
    : e.target.value.trim() }}))
   
  }

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        !scriptReducer.open_delete_popup && finishedHandler();
      }
    };
      document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [createProjectReducer.commonPageData.name, scriptReducer.open_delete_popup]);

  return (
    <>
    {/* <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(18px) !important', display:'flex', flexDirection:'column', background: '#08231B88 0% 0% no-repeat padding-box', }}
    open={createProjectReducer.open_backdrop_project_creation}
    > */}
        <Box sx={{ width: isListView ? '100%' : '90%', height:'100%', display:'flex', flexDirection:'column'}}>
          {(!isListView || contentReducer?.create_content_open) && <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3}} onClick={closeHandler}>Close X</Typography>}
          <Box flexGrow={1} className='flex col ' sx={{ paddingLeft:isListView ? '5vw' :'14vw', marginTop:isListView ?{xs:'6%',sm:'4%', md:'3%', xl:'4%'}: {xs:'8%',sm:'6%', md:'4%', xl:'6%'}}}>
              <Box mb={6}>
                <Typography sx={{fontSize:isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} :{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography sx={{fontSize:isListView ? {xs:'20px',sm:'22px',md:'26px',lg:'35px',xl:'52px'} :{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'},  color:'white', fontFamily:'Rubik',  whiteSpace:'break-spaces'}}>{`What is the name of your ${projectType} project?`}</Typography>
                <Typography sx={{fontSize:isListView ? {xs:'15px',sm:'14px',md:'16px',lg:'19px',xl:'30px'} :{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik',  whiteSpace:'break-spaces'}}>Note: The title of your project cannot be changed once you’ve created it.</Typography>
              </Box>
              <Box className='flex' sx={{width: isMobileView ? '93%' : '80%'}}>
              <CustomInput
                onFocus={() =>{ setNameValidationFlag(false) }}
                onBlur={() => setNameValidationFlag(true)}
               // type='sendInput'
                required
                medium
                bottom={-20}
                msgColor='red'
                errorFlag={
                  nameValidationFlag &&
                  getErrorMsg("project_name", createProjectReducer.commonPageData.name) != ""
                }
                errorMsg={getErrorMsg("project_name", createProjectReducer.commonPageData.name)}
                fullWidth
                max={100}
                placeholder="Project Name (Max Character 100)"
                onChange={onChangeHandler}
                value={createProjectReducer.commonPageData.name}
                onButtonClick={pageIndex !== totalScreens ? finishedHandler : null}
               />
              </Box>
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', mb:3, mr:3}}>
            <LeftIcon sx={{opacity: pageIndex === 1 && 0.5, fontSize: isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}: {xs:'45px', sm:'55px', lg:'65px',xl:'82px'}, cursor:'pointer'}} onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <RightIcon sx={{ cursor:'pointer', fontSize: isListView ? {xs:'40px', sm:'36px', md:'45px',lg:'55px',xl:'80px'}: {xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}}  onClick={pageIndex !== totalScreens ? finishedHandler : null}/>
          </Box>
        </Box>
    {/* </Backdrop> */}
    {/* </Slide> */}
    </>
  )
}

export default ProjectNamePage