import { Box, Card, CardContent, CardActions, Grid, Typography, Avatar, IconButton} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import "@fontsource/rubik";
import useProjectActions from "../hooks/useProjectActions";
import useContentActions from "../hooks/useContentActions";
import { exportOptions, videoLength } from "../../../constants/staticData";
import { afterValidate } from "../../../utils/commonServices";
import AuthLayout from "../../layouts/AuthLayout";
import lucihub from '../../../assets/butterfly_navigation.png';
import CustomUserCard from "./CustomUserCard";
import ProjectDetailCard from "./ProjectDetailCard";
import FilmingTutorialCard from "./FilmingTutorialCard";
import { updateCommonState } from "../../../redux/commonSlice";
import { gaEvents } from "../../../ga/actions";
import ScriptBox from "./ScriptBox";
import ScriptInputBox from "./ScriptInputBox";
import CustomAutocomplete from "../../eventcreation/components/CustomAutoComplete";
import { getErrorMsg } from "../../../utils/validator";
import CustomInput from "../../eventcreation/components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import PopupVideoPlayer from "../../../components/PopupVideoPlayer";
import BackdropCreateProject from "../../eventcreation/components/BackdropCreateProject";
import BackdropCreateContent from "../../contentcreation/components/BackdropCreateContent";
import VoiceStudio from "./voiceStudio/VoiceStudio";
import useTextToSpeech from "./voiceStudio/hooks/useTextToSpeech";
import useTtsSample from "./voiceStudio/hooks/useTtsSample";
import { updateVoiceState } from "../../../redux/voiceSlice";



const useStyles = makeStyles((theme) => ({
    container: {
     // minHeight:'100vh',
      // maxWidth:'100vw',
     // backgroundImage: 'linear-gradient(140deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
      display:'flex',
      width:'100%',
      minWidth:'860px',
       height:'85vh',
     // height:'100%',  
  },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    },
    leftContainer: {
         width:'21.1%',
          display:'flex',
          flexDirection:'column',
          justifyContent: 'flex-start',
          marginBottom:'41px',  
         // paddingLeft:'2.24%',
          height:'100%',
        
    },
    rightContainer:{
      width:'77.2%',
      display:'flex',
      flexDirection:'column',
     // marginRight:'4.9%',
      marginBottom:'51px',
      marginLeft:'1.7%',
      height:'100%',
     
  },
    scroll: {
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
  
    },
    outerBox:{
      background:'#FFFFFF40 0% 0% no-repeat padding-box',
      boxShadow:'0px 5px 15px #00000029',
      borderRadius:'10px',
      width:'100%',
      height:'60vh',
      padding:'24px 15px 24px 20px'
  },
  flex:{
    display:'flex'
},
  }));
  
  
  const CommonProject = (props) => {
    const {projectId} = props
    // const params = useParams()
    // const projectId = params.projectId
    const classes = useStyles()
    // const { videoTitle, videoSrc, } = props
    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    const commonReducer = useSelector((state)=> state.commonReducer);
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const dropdownReducer = useSelector((state)=> state.persistedReducers.dropdownReducer)
    const voiceReducer = useSelector((state) => state.voiceReducer);
    const [projectTypeList, setProjectTypeList] = useState(dropdownReducer?.project_type)
    const { audioRef, handleConvertTextToSpeech, pauseSpeech, playSpeech, handleDownload } = useTextToSpeech()
    const { sampleRef, handleGenerateVoiceSample, pauseSample, playSample } = useTtsSample()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {getProjectDetails} = useProjectActions();
    const { createContent, convertContent } = useContentActions();
   // const initialExportAsValue = ''
    const [exportAs, setExportAs] = useState({})
    const [exportState, setExportState] = useState({enable: false, formatId: ''})
    const [wordLimit, setWordLimit] = useState()
    const [scriptLengthValidationFlag, setScriptLengthValidationFlag] = useState(false)
    const projectTypesToExclude =  ['storyboard' ,'avatar', 'voice over', 'image', 'press release' ]
    const [projectLength, setProjectLength] = useState('')
    // useEffect(()=> {
    //   getProjectDetails(projectId) 
    // }, [projectId])


  //Functions for Voice Studio ---------------------------------------------------------------------------------------
    const generateSpeechClickHandler = () => {
      pauseSample()
      dispatch(updateVoiceState({ is_sample_playing: false, is_speech_playing: true, sample_url: '' }))
      handleConvertTextToSpeech()
    }
  
    const playSampleHandler = () => {
      if (voiceReducer?.sample_url == '') {
        if(!!audioRef.current) {
          // audioRef.current.src = "";
           pauseSpeech();
         }
         dispatch(updateVoiceState({ is_speech_playing: false, }))
        handleGenerateVoiceSample()
      }
      if (voiceReducer?.is_sample_playing) {
        pauseSample()
      } else if (voiceReducer?.sample_url != '') {
        if(!!audioRef.current) {
          // audioRef.current.src = "";
           pauseSpeech();
         }
         dispatch(updateVoiceState({ is_speech_playing: false, }))
        playSample()
      }
  
    //  dispatch(updateVoiceState({ is_sample_playing: !voiceReducer?.is_sample_playing }))
  
    }
  
    const commonChangeHandler = (sRef = sampleRef) => {
      if(!!audioRef.current) {
       // audioRef.current.src = "";
        pauseSpeech();
      }
      if(!!sRef.current) {
        pauseSample();
        sRef.current.src = "";
      }
      dispatch(updateVoiceState({
        sample_url: "",
      //  audio_url: "",
        is_speech_playing: false,
        is_sample_playing: false,
      }))
    }

    //--------------------------------------------------------------------------------------------------------------


    useEffect(()=> {
      let upper, lower;
      switch(exportAs.id) {
        case 4 : lower = 0;
                 upper = 700;
                 break;
        case 5 : lower = 0;
                 upper = 800;
                 break;
        case 6 : lower = 0;
                 upper = 150;
                 break;
        default : lower = 0;
                  upper = 800;
                  break;
      }

      setWordLimit({lower, upper})
      setProjectLength('')
      setScriptLengthValidationFlag(false)
    }, [exportAs.id])

    useEffect(()=> {
      if(Object.keys(scriptReducer.selected_project_details)?.length > 0) {
        if(scriptReducer?.selected_project_details?.contents?.length === 0) {
          const payload = {
           query: {
            "contentTypeId": scriptReducer.selected_project_details.projectTypeId,
            "projectLength": scriptReducer.selected_project_details.length,
            "storyboardCount": 3,    
            "projectId": scriptReducer.selected_project_details.id,
            "personaId": scriptReducer.selected_project_details.personaId,
          },
          data: { 
            "prompt": scriptReducer.selected_project_details?.description
          }
          }
        createContent(payload)
        } 
      }
        //else{
      //     dispatch(updateUserState({common_backdrop_open: false}))
      //   }
        
      // } else {
      //   dispatch(updateUserState({common_backdrop_open: true}))
      // }
     
    }, [scriptReducer.selected_project_details])
  
  
   
    // const [sortBy, setSortBy] = useState('')
    // const [selectedSortbyValue, setSelectedSortbyValue] = useState(null);
    const [eventData, setEventData] = useState({})
    // const [uploadedVideosArray, setUploadedVideosArray] = useState([]);
    // const [uploadedVideos, refetchList] = useUploadedList(true);
    // const [checkVideoArrival, recheckVideoArrival] = useCheckVideoArrival();
    // const [getConsentDetails] = useConsentDetail();
    // const statusRef = useRef();
    // const underRevisionRef = useRef(false)
    // const { files, 
    //   setFiles,
    //   progress, 
    //   uploadStatus,
    //   setUploadStatus, 
    //   setProgress,
    //   isLoading,
    //   requestController, 
    //   setRequestController,
    //   stopUpload, 
    //   stopList } = useSequentialVideoUpload(userReducer.production_event_id, false, uploadedVideos)  // useSequentialVideoUpload(eventId: , isGuestUser, uploadedVideos) 
      // const [importFiles, importFilesStatus] = useImportMediaFiles("import-files")
      const pdfRef = useRef(null)
      // useEffect(()=>{
      //   getContentDetails(userReducer.selected_project_details?.contents[0].contentID, dispatch)
      // }, [])
     
  //  console.log(userReducer.production_events)

  // const sortByOptions = [
  //   { value: 'date_added', label: 'Date Added' },
  //   { value: 'file_type', label: 'File Type' },
  //   { value: 'favourites', label: 'Favourites' },
  //   {value: 'file_size', label:'File Size'}
  // ];

  // useEffect(() => {
   
  //     statusRef.current = userReducer.event_details?.status_type_order;
  //   underRevisionRef.current = userReducer.event_details?.final_video_id == null || userReducer.event_details?.revision.submitted == true;
    
  // }, [userReducer.event_details]);

  // useEffect(()=> {
  //  const intervalId = setInterval(async () => {
  //     if((statusRef.current == 3 || statusRef.current == 4) && underRevisionRef.current == true) {
  //       await checkVideoArrival()
  //     }
  //   }, 15000);
   
  //   return () => clearInterval(intervalId);
  

  // },[])

// useEffect(()=> {
//   if(userReducer.new_video == true) {
//     refetchEventDetail()
//   }
// }, [userReducer.new_video])

    // useEffect(() => {
     
    //   setUploadedVideosArray(uploadedVideos) 
    // }, [uploadedVideos])
    
  
    // useEffect(() => {
    //   refetchList();
    // }, [userReducer.production_event_id])


  

    // useEffect(() => {
    //   window.addEventListener('beforeunload', alertUser)
    //   return () => {
    //     window.removeEventListener('beforeunload', alertUser)
    //   }
    // }, [isLoading])

    // useEffect(() => {
    //   window.addEventListener('popstate', alertUser);
    //   return () => {
    //     window.removeEventListener('popstate', alertUser);
    //   }
    // }, [isLoading])

    // const alertUser = e => {
    //   if (isLoading) {
    //     e.preventDefault()
    //     window.history.forward();
    //     e.returnValue = 'Upload will be cancelled if you leave this page!!'
    //   }
  
    // }

    // useEffect(() => {
    //   setFiles([])
    //   const selectedEvent = userReducer.event_details
    // //   if (selectedEvent == null) {
    // //     setEventData({ name: userReducer.production_events[0]?.name, start: userReducer.production_events[0]?.start_date, end: userReducer.production_events[0]?.end_date, genre: userReducer.production_events[0]?.genre, code: userReducer.production_events[0]?.collab_code })
    // //   } else 
    //   if (Object.keys(selectedEvent)?.length > 0) {
    //     if(selectedEvent?.status_type_order < 3 && selectedEvent.consent_accepted == false) {
    //       getConsentDetails(userReducer.production_event_id)
    //     }
    //     setEventData({ name: selectedEvent.name, editor_start_date: selectedEvent.editor_start_date, start: selectedEvent?.start_date, end: selectedEvent.end_date, eventType: selectedEvent.event_type, code: selectedEvent.collab_code, status: selectedEvent.status_type , consentStatus: selectedEvent.consent_accepted})
    //   }
     
    // }, [userReducer.production_event_id, userReducer.event_details])
  
   

    const selectedScriptLength = (id) => {
      if ((createProjectReducer.commonPageData.projectTypeId) > 3) {
        return id;
      } else if (!!videoLength?.find((item) => item.value == id)) {
        return videoLength?.find((item) => item.value == id);
      } else {
        return {};
      }
    };
    const selectedExportFormat = (id) => {
      if (!!id) {
        return exportOptions?.find(option => option.id == id);
      } else {
        return {};
      }
    };
  //  const handleExport = (formatId)=> {
  //     switch(formatId) {
  //       case '1': exportAsPDF(content, "Pdf_sample")
  //     }
  //  }
  
    // const handlePdfDownload = ()=>{
    //   var quotes = document.getElementById('story-board');
    //   html2canvas(quotes).then((canvas) => {
    //        //! MAKE YOUR PDF
    //        var pdf = new jsPDF('p', 'pt', 'letter');
   
    //        for (var i = 0; i <= quotes.clientHeight/1180; i++) {
    //            //! This is all just html2canvas stuff
    //            var srcImg  = canvas;
    //            var sX      = 0;
    //            var sY      = 1180*i; // start 980 pixels down for every new page
    //            var sWidth  = 900;
    //            var sHeight = 1180;
    //            var dX      = 0;
    //            var dY      = 0;
    //            var dWidth  = 900;
    //            var dHeight = 1180;
   
    //            var onePageCanvas = document.createElement("canvas");
    //            onePageCanvas.setAttribute('width', 900);
    //            onePageCanvas.setAttribute('height', 1180);
    //            var ctx = onePageCanvas.getContext('2d');
    //            // details on this usage of this function: 
    //            // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
    //            ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);
   
    //            // document.body.appendChild(canvas);
    //            var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);
   
    //            var width         = onePageCanvas.width;
    //            var height        = onePageCanvas.clientHeight;
   
    //            //! If we're on anything other than the first page,
    //            // add another page
    //            if (i > 0) {
    //                pdf.addPage(612, 791); //8.5" x 11" in pts (in*72)
    //            }
    //            //! now we declare that we're working on that page
    //            pdf.setPage(i+1);
    //            //! now we add content to that page!
    //            pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width*.62), (height*.62));
   
    //        }
    //        //! after the for loop is finished running, we save the pdf.
    //        pdf.save('Test.pdf');
    //  });
    // }

    // const handleDocDownload =()=>{

    // }

    // const handleRtfDownload =()=>{

    // }

    // const options =[
    //   {id:1, name: 'RTF'},
    //   {id:2, name: 'PDF'},
    //   {id:3, name: 'DOCX'},
    // ]

    const handleAutocompleteChange = (event, value) => {
      if(value){
          console.log(value)
          setExportAs(value)
      }
    };

    const convertContentClickHandler = (id) => {
     // handleMenuClose();
      const payload = {
          "sourceContentId": contentReducer.selected_content_details?.id,
          "convertoProjectTypeId": exportAs.id,
          "convertoPersonaId": scriptReducer.selected_project_details?.personaId,
          "storyboardCount":  3,
          "contentLength": projectLength
        }
        // console.log("convert payload", payload)
          //resetConvertState()
         convertContent(payload, resetConvertState)  
    }

    const resetConvertState =()=>{
      setProjectLength('')
      setExportAs({})
    }

    // const downloadFileHandler =()=>{
    //         switch (exportAs) {
    //         case "RTF":
    //           handleRtfDownload()
    //           break;
    //         case "PDF":
    //           handlePdfDownload()
    //           break;
    //         case "DOCX":
    //           handleDocDownload()
    //           break;
    //         default:
    //         // func to download as rtf
    //           break;
    //       }
    // }
    const finishedHandler = ()=> {
      validateInputs(true)
      afterValidate(convertContentClickHandler);
  }

  const validateInputs = (flag) => {
      setScriptLengthValidationFlag(flag)
  }
  
  
    return (
      
    <AuthLayout sx={{ padding: '2.5% 3.9% 2.1% 2.2%'}}>
       <Box gap={3} className='flex row a-center j-end' sx={{ pr: {xs:1, sm:3} }} >
        <img onClick={()=> window.location.href=`${process.env.REACT_APP_LUCIHUB_URL}`} src={lucihub} style={{ borderRadius: '5px', width: '280px', cursor: 'pointer'}} />
            <CustomUserCard />
        </Box>
                <Box container className={classes.container}>
        <Box className={classes.leftContainer}>
              <Box sx={{height:{xs:'400px',sm:'400px',md:'58vh',lg:'62vh',xl:'70vh'}, marginBottom:{xs:'15px',md:'20px',lg:'23px',xl:'35px'}}}>
                 {/* <EventDetailCard eventData={eventData}/> */}
                 <ProjectDetailCard eventData={eventData}/>
              </Box>
              <Box  >
                <FilmingTutorialCard onClick= {()=> {dispatch(updateCommonState({play_tips_tricks_video: true})); gaEvents.clickedOnTipsAndTricks()} } clickable = {true} type = 'voice_studio' />
              </Box>
             
        </Box>
  
        <Box className={classes.rightContainer}>
              <Box sx={{width:'100%', }}>
                <Box gap={1} sx={{display:'flex', justifyContent:'start', alignItems:'center', color:'black' }}>
                    <IconButton disabled={userReducer.isLoading} onClick={()=>{navigate('/dashboard', {replace: true})}}>
                      <KeyboardBackspaceOutlinedIcon sx={{color:'#fff',}}/>
                    </IconButton>
                    <Typography sx={{fontFamily: 'Rubik', fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'14px',xl:'20px'}, color:'#fff'}}>Back</Typography>
                </Box>
              </Box>
  
              <Box style={{display:'flex', gap:'2.34%'}} sx={{ marginBottom:{xs:'15px',sm:'15px',md:'15px',lg:'20px',xl:'30px'},  marginTop:{xs:'8px', sm:'10px', md:'12px',lg:'14px',xl:'23px'}, width:'100%',}}>
              <Box className={`${classes.outerBox} ${classes.flex}`} gap={1} sx={{alignItems:'center', justifyContent:'center'}}>
            {scriptReducer?.selected_project_details?.contents?.length > 0 ? (
              <ScriptBox pdfRef={pdfRef} pauseSample = {pauseSample} audioRef={audioRef} commonChangeHandler = {commonChangeHandler} downloadAudio = {handleDownload} exportState={exportState} setExportState = {setExportState} resetConvertState={resetConvertState}/>
            ):(
              <Typography sx={{fontSize: 24, color: '#fff', fontWeight: 300, textAlign: 'center', textTransform: 'Uppercase'}} >Send prompt to generate content</Typography>
            )}
                </Box>
              </Box>

            {
              contentReducer?.is_voice ? 
                <VoiceStudio 
                  generateSpeechClickHandler = {generateSpeechClickHandler}
                  playSampleHandler = {playSampleHandler}
                  commonChangeHandler = {commonChangeHandler}
                  audioRef = {audioRef}
                  handleConvertTextToSpeech = {handleConvertTextToSpeech}
                  pauseSpeech = {pauseSpeech}
                  playSpeech = {playSpeech}
                  sampleRef = {sampleRef}
                  handleGenerateVoiceSample = {handleGenerateVoiceSample}
                  pauseSample = {pauseSample}
                  playSample = {playSample}
                /> : 
                <Box sx={{display:'flex', height:{xs:'60%',sm:'60%',md:'62%',lg:'64%',xl:'60%'}, flexDirection:'column'}} gap={{xs:0.2,sm:0.5,md:2,lg:2.5,xl:3}}>
                  <Box>
                    {/* <ScriptInputBox/> */}
                  </Box>
                 {scriptReducer.selected_project_details?.projectTypeId !== 3 && 
                 <Box gap={2} sx={{display:'flex', justifyContent:'space-evenly', alignItems:"baseline", }}>
                    <Box sx={{display:'flex', justifyContent:'flex-start', alignItems:'baseline', width:'30%'}} gap={2}>
                       <Typography sx={{fontSize:{xs:'9px',md:'12px',xl:'16px'}, color:'#fff', width:{xs:'60px',md:'80px'}, }}>Convert to a:</Typography>
                      {/* <CustomButton 
                        btnText='Shotlist'
                        disabled = {(scriptReducer?.selected_project_details?.contents?.length === 0 || (isArray(contentReducer.selected_content_details.content) && contentReducer.selected_content_details?.content[0]?.type === 'Storyboards'))}
                        onClick={()=>convertContentClickHandler(2)}
                        type='button'    
                        style={{
                          borderRadius: '5px',
                          border: "none",
                          textTransform:'none',
                          backgroundColor:'#00CEFF', 
                        }}
                        sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},  fontFamily:'Rubik', width:{xs:'100px', md:'120px', lg:'150px',xl:'180px'}}}
                      /> */}
                      <Box  sx={{width:'60%'}}>
                            <CustomAutocomplete
                                options={projectTypeList.filter(type => !projectTypesToExclude.includes(type.name) && type.name !== contentReducer.selected_content_details?.content?.type?.toLowerCase())}
                                // options={projectTypeList.filter(type =>  type.name !== contentReducer.selected_content_details?.content?.type?.toLowerCase() && type.name !== 'storyboard' && type.name !== 'avatar' && type.name !== 'voice over')}
                                placeholder='Content type'
                                onChange={handleAutocompleteChange}
                                getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.name : ""
                                }
                                value={exportAs}
                            />
                      </Box>
                           
                     { /*!(isArray(contentReducer.selected_content_details.content) && contentReducer.selected_content_details?.content[0]?.type === 'Storyboards') &&  <CustomButton 
                        btnText='Storyboard'
                        // disabled = {isDisabled || (userReducer.user_name != userReducer.event_details?.owner_name)}
                        onClick={()=>convertContentClickHandler(3)}
                        disabled = {(scriptReducer?.selected_project_details?.contents?.length === 0 )}
                        type='button'    
                        style={{
                          borderRadius: '5px',
                          border: "none",
                          textTransform:'none',
                          backgroundColor:'#00CEFF',  
                        }}
                        sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},fontFamily:'Rubik', width:{xs:'100px', md:'120px',lg:'150px' ,xl:'180px'}}}
                      />*/}
                    </Box>

                    {!!Object.keys(exportAs)?.length &&  <Box sx={{display:'flex', justifyContent:'flex-start', alignItems:'baseline', width:'60%', }} >
                       <Typography sx={{fontSize:{xs:'9px',md:'12px',xl:'16px'}, color:'#fff', width:{xs:'50px',md:'70px'}, }}>Length:</Typography>
                    
                      <Box  sx={{width:'55%'}} mr={2}>
                      {
                    exportAs?.id < 3 ? <CustomAutocomplete
                          id="script_length"
                          options={ videoLength}
                          onFocus={() => {
                            setScriptLengthValidationFlag(false)
                          }}
                          onBlur={() => setScriptLengthValidationFlag(true)}
                          required
                          errorFlag={scriptLengthValidationFlag && getErrorMsg("script_length", projectLength, exportAs?.id) != ""}
                          errorMsg={getErrorMsg("script_length", projectLength, exportAs?.id)}
                          boldError
                          value={selectedScriptLength(projectLength)}
                          onChange={(event, newValue) => {
                            setProjectLength(newValue.value)
                              }}
                          label=""
                          placeholder='Select the length of your project'
                          getOptionLabel={(option) =>
                              Object.keys(option).length > 0 ? `${option.name}` : ""
                          }
                          msgColor='red'
                      /> :  <CustomInput
                      onFocus={() =>{ setScriptLengthValidationFlag(false) }}
                      onBlur={() => setScriptLengthValidationFlag(true)}
                      required
                      boldError
                      bottom={-20}
                      msgColor='red'
                      errorFlag={scriptLengthValidationFlag && getErrorMsg("word_length", projectLength, wordLimit?.lower) != ""}
                          errorMsg={getErrorMsg("word_length", projectLength, wordLimit?.lower)}
                      fullWidth
                      max={100}
                      placeholder={`Upto ${wordLimit?.upper} words`}
                      onChange={(e) => {
                        (!isNaN(e.target.value.trim()) && e.target.value <= wordLimit?.upper) && setProjectLength(e.target.value.trim())
                          }}
                      value={projectLength}
                     />
                        }
                      </Box>
                            
                     <CustomButton 
                        btnText='Convert'
                        // disabled = {isDisabled || (userReducer.user_name != userReducer.event_details?.owner_name)}
                        onClick={()=>finishedHandler()}
                        disabled = {!projectLength}
                        type='button'    
                        style={{
                          borderRadius: '5px',
                          border: "none",
                          textTransform:'none',
                          backgroundColor:'#00CEFF',  
                        }}
                        sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},fontFamily:'Rubik', width:{xs:'80px', md:'100px',lg:'130px' ,xl:'160px'}}}
                      />
                  
                    </Box>}

                    <Box  sx={{minWidth: '18%'}} gap={2}>
                    <CustomAutocomplete
                          id="export_as"
                          options={exportOptions}
                          // onFocus={() => {
                          //   setScriptLengthValidationFlag(false)
                          // }}
                          onBlur={() => setExportState({enable: false, formatId: ''})}
                          // required
                          // errorFlag={scriptLengthValidationFlag && getErrorMsg("script_length", projectLength, exportAs?.id) != ""}
                          // errorMsg={getErrorMsg("script_length", projectLength, exportAs?.id)}
                          // boldError
                          value={selectedExportFormat(exportState.formatId)}
                          onChange={(event, selection) => {
                            setExportState({enable: true, formatId: selection.id})
                              }}
                          label=""
                          placeholder='Export As'
                          getOptionLabel={(option) =>
                              Object.keys(option).length > 0 ? `${option.value}` : ""
                          }
                          msgColor='red'
                      /> 
                      {/* <Typography  sx={{fontSize:{xs:'9px',md:'12px',xl:'16px'}, color:'#fff', width:{xs:'45px',md:'65px'}, }}>Export as:</Typography> */}
                      {/* <CustomButton 
                        btnText='RTF'
                        // disabled = {isDisabled || (userReducer.user_name != userReducer.event_details?.owner_name)}
                        // onClick={extendEditingDateClickHandler}
                        type='button'    
                        style={{
                          borderRadius: '5px',
                          border: "none",
                          textTransform:'none',
                          backgroundColor:'#00CEFF',  
                        }}
                        sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},fontFamily:'Rubik', width:{xs:'100px', md:'150px', xl:'180px'}}}
                      /> */}
                      {/* <CustomButton 
                        btnText='PDF'
                        // disabled = {isDisabled || (userReducer.user_name != userReducer.event_details?.owner_name)}
                        onClick={handlePdfDownload}
                        type='button'    
                        style={{
                          borderRadius: '5px',
                          border: "none",
                          textTransform:'none',
                          backgroundColor:'#00CEFF',  
                        }}
                        sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},fontFamily:'Rubik', width:{xs:'100px', md:'150px', xl:'180px'}}}
                      /> */}
                      {/* <CustomButton 
                        btnText='DOCX'
                        // disabled = {isDisabled || (userReducer.user_name != userReducer.event_details?.owner_name)}
                        // onClick={extendEditingDateClickHandler}
                        type='button'    
                        style={{
                          borderRadius: '5px',
                          border: "none",
                          textTransform:'none',
                          backgroundColor:'#00CEFF',  
                        }}
                        sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},fontFamily:'Rubik', width:{xs:'100px', md:'150px', xl:'180px'}}}
                      /> */}
                          {/* <Box width={`30%`}>
                                <CustomAutocomplete
                                options={options}
                                placeholder='Exoort as...'
                                onChange={handleAutocompleteChange}
                                // sx={{width:{xs:'76px',sm:'76px',md:'76px',lg:'86px',xl:'129px'},}}
                                getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.name : ""
                                }
                                />
                          </Box>
                        <CustomButton 
                        btnText='Download'
                        disabled = {exportAs === ''}
                        onClick={handlePdfDownload}
                        type='button'    
                        style={{
                          borderRadius: '5px',
                          border: "none",
                          textTransform:'none',
                          backgroundColor:'#00CEFF',  
                        }}
                        sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},fontFamily:'Rubik', width:{xs:'100px', md:'120px', lg:'150px', xl:'180px'}}}
                      />  */}
                    </Box>
                   {/* {exportAs !== '' && <Box  sx={{width:'50%',display:'flex', justifyContent:'flex-start', alignItems:'center'}} gap={2}>
                   <Typography sx={{fontSize:{xs:'9px',md:'12px',xl:'16px'}, color:'#fff', width:{xs:'60px',md:'80px'}, }}>Convert to a:</Typography>
                   <Box  sx={{width:'50%'}} >
                   {
                    exportAs?.id < 3 ? <CustomAutocomplete
                          id="script_length"
                          options={ videoLength}
                          onFocus={() => {
                            setScriptLengthValidationFlag(false)
                          }}
                          onBlur={() => setScriptLengthValidationFlag(true)}
                          required
                          errorFlag={scriptLengthValidationFlag && getErrorMsg("script_length", createProjectReducer.pageData.lengthId) != ""}
                          errorMsg={getErrorMsg("script_length", createProjectReducer.pageData.lengthId)}
                          value={selectedScriptLength(createProjectReducer.pageData.lengthId)}
                          onChange={(event, newValue) => {
                            dispatch(updateProjectCreationState({pageData: {...createProjectReducer.pageData, lengthId: newValue.value }}))
                              }}
                          label=""
                          placeholder='Select the length of your project'
                          getOptionLabel={(option) =>
                              Object.keys(option).length > 0 ? `${option.name}` : ""
                          }
                          
                          msgColor='red'
                      /> :  <CustomInput
                      onFocus={() =>{ setScriptLengthValidationFlag(false) }}
                      onBlur={() => setScriptLengthValidationFlag(true)}
                      type='sendInput'
                      required
                      
                      bottom={-20}
                      msgColor='red'
                      errorFlag={scriptLengthValidationFlag && getErrorMsg("word_length", createProjectReducer.pageData.lengthId, wordLimit?.lower) != ""}
                          errorMsg={getErrorMsg("word_length", createProjectReducer.pageData.lengthId, wordLimit?.lower)}
                      fullWidth
                      max={100}
                      placeholder={`Word limit (${wordLimit?.lower} - ${wordLimit?.upper} words)`}
                      onChange={(e) => {
                        (!isNaN(e.target.value.trim()) && e.target.value <= wordLimit?.upper) && dispatch(updateProjectCreationState({pageData: {...createProjectReducer.pageData, lengthId:  e.target.value.trim() }}))
                          }}
                      value={createProjectReducer.pageData.lengthId}
                      // onButtonClick={pageIndex !== totalScreens ? finishedHandler : null}
                     />
                        }
                          
                    </Box>
                    </Box>
} */}

                    
                  </Box>}
              </Box>
             }
  
              
        
        </Box>  
        </Box> 
        {
            commonReducer.play_tips_tricks_video == true && <PopupVideoPlayer type ="voice_studio" />
       }
        {
      contentReducer?.create_content_open === true && <BackdropCreateContent />
     }

     </AuthLayout> 
     
  
    )
  }
  
  export default CommonProject;














// import { Box, Card, CardContent, CardActions, Grid, Typography, Avatar, IconButton} from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import { useRef, useState } from "react";
// import CollaboratorCard from "./components/CollaboratorCard";
// //import CustomVideoTable from "./components/CustomVideoTable";
// // import CustomDropBox from "./components/CustomUploadZone";
// import EventDetailCard from "./components/EventDetailCard";
// import FilmingTutorialCard from "./components/FilmingTutorialCard";
// import ShareLinkCard from "./components/ShareLinkCard";
// // import CustomVideoTable from "../../components/CustomVideoTable";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import useUploadedList from "../../hooks/useUploadedList";
// import useSequentialVideoUpload from "../../hooks/useSequentialVideoUpload";
// import { isEditStarted, isEventDate } from "../../utils/commonServices";
// import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
// import { useEffect } from "react";
// import CustomDropBox from "../../components/CustomUploadZone";
// import CustomUserCard from "./components/CustomUserCard";
// import "@fontsource/rubik";
// import CustomSortOptions from "./components/CustomSortOptions";
// //import CheckboxSelect from "./components/CheckboxSelect";
// import CustomVideoTable from "../../components/CustomVideoTable";
// import AuthLayout from "../layouts/AuthLayout";
// import EditingStartedCard from "./components/EditingStartedCard";
//  import UpgradePlanCard from "./components/UpgradePlanCard";
// import useConsentDetail from "./hooks/useConsentDetail";
// import filmingTutorialImage from "../../assets/filmingTutorialCard.png"
// import CustomCard from "../../components/CustomCard";
// import { updateUserState } from "../../redux/commonSlice";
// import PopupVideoPlayer from "../../components/PopupVideoPlayer";
// import FinalVideoCompletedCard from "../completedvideo/components/FinalVideoCompletedCard";
// import useCheckVideoArrival from "../completedvideo/hooks/useCheckVideoArrival";
// import useEventDetails from "./hooks/useEventDetails";
// import VideoEditedEventCard from "./components/VideoEditedEventCard";
// import UploadFromProjectPopup from "./components/UploadFromProjectPopup";
// import { sortByOptions } from "../../constants/staticData";
// import useImportMediaFiles from "./hooks/useImportMediaFiles";
// import DuplicatingFilesPopup from "./components/DuplicatingFilesPopup";


// const useStyles = makeStyles((theme) => ({
//     container: {
//      // minHeight:'100vh',
//       // maxWidth:'100vw',
//      // backgroundImage: 'linear-gradient(140deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
//       display:'flex',
//       width:'100%',
//       minWidth:'860px',
//        height:'85vh',
//      // height:'100%',  
//   },
//     center: {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'space-evenly'
//     },
//     leftContainer: {
//          width:'21.1%',
//           display:'flex',
//           flexDirection:'column',
//           justifyContent: 'flex-start',
//           marginBottom:'41px',  
//          // paddingLeft:'2.24%',
//           height:'100%',
        
//     },
//     rightContainer:{
//       width:'77.2%',
//       display:'flex',
//       flexDirection:'column',
//      // marginRight:'4.9%',
//       marginBottom:'51px',
//       marginLeft:'1.7%',
//       height:'100%',
     
//   },
//     scroll: {
//       overflowY: 'scroll',
//       scrollbarWidth: 'none',
//       msOverflowStyle: 'none',
//       '&::-webkit-scrollbar': {
//         display: 'none'
//       }
  
//     }
//   }));
  
  
//   const CommonProject = (props) => {
  
//     const classes = useStyles()
//     const { videoTitle, videoSrc, } = props
//     const userReducer = useSelector((state) => state.persistedReducers.userReducer);
//     const reviewEventReducer = useSelector((state) => state.reviewEventReducer);
//     const mediaFilesReducer = useSelector((state) => state.mediaFilesReducer);
//     const [refetchEventDetail] = useEventDetails(userReducer.production_event_id)
//     const [copyButtonText, setCopyButtonText] = useState("COPY LINK");
//     const [shareButtonText, setShareButtonText] = useState("Copy event code");
//     const navigate = useNavigate()
  
  
  
   
//     const [sortBy, setSortBy] = useState('')
//     const [selectedSortbyValue, setSelectedSortbyValue] = useState(null);
//     const [eventData, setEventData] = useState({})
//     const [uploadedVideosArray, setUploadedVideosArray] = useState([]);
//     const [uploadedVideos, refetchList] = useUploadedList(true);
//     const [checkVideoArrival, recheckVideoArrival] = useCheckVideoArrival();
//     const [getConsentDetails] = useConsentDetail();
//     const statusRef = useRef();
//     const underRevisionRef = useRef(false)
//     const dispatch = useDispatch();
//     const { files, 
//       setFiles,
//       progress, 
//       uploadStatus,
//       setUploadStatus, 
//       setProgress,
//       isLoading,
//       requestController, 
//       setRequestController,
//       stopUpload, 
//       stopList } = useSequentialVideoUpload(userReducer.production_event_id, false, uploadedVideos)  // useSequentialVideoUpload(eventId: , isGuestUser, uploadedVideos) 
//       const [importFiles, importFilesStatus] = useImportMediaFiles("import-files")
     
//   //  console.log(userReducer.production_events)

//   // const sortByOptions = [
//   //   { value: 'date_added', label: 'Date Added' },
//   //   { value: 'file_type', label: 'File Type' },
//   //   { value: 'favourites', label: 'Favourites' },
//   //   {value: 'file_size', label:'File Size'}
//   // ];

//   useEffect(() => {
   
//       statusRef.current = userReducer.event_details?.status_type_order;
//     underRevisionRef.current = userReducer.event_details?.final_video_id == null || userReducer.event_details?.revision.submitted == true;
    
//   }, [userReducer.event_details]);

//   useEffect(()=> {
//    const intervalId = setInterval(async () => {
//       if((statusRef.current == 3 || statusRef.current == 4) && underRevisionRef.current == true) {
//         await checkVideoArrival()
//       }
//     }, 15000);
   
//     return () => clearInterval(intervalId);
  

//   },[])

// useEffect(()=> {
//   if(userReducer.new_video == true) {
//     refetchEventDetail()
//   }
// }, [userReducer.new_video])

//     useEffect(() => {
     
//       setUploadedVideosArray(uploadedVideos) 
//     }, [uploadedVideos])
    
  
//     // useEffect(() => {
//     //   refetchList();
//     // }, [userReducer.production_event_id])


  

//     useEffect(() => {
//       window.addEventListener('beforeunload', alertUser)
//       return () => {
//         window.removeEventListener('beforeunload', alertUser)
//       }
//     }, [isLoading])

//     useEffect(() => {
//       window.addEventListener('popstate', alertUser);
//       return () => {
//         window.removeEventListener('popstate', alertUser);
//       }
//     }, [isLoading])

//     const alertUser = e => {
//       if (isLoading) {
//         e.preventDefault()
//         window.history.forward();
//         e.returnValue = 'Upload will be cancelled if you leave this page!!'
//       }
  
//     }
//     useEffect(() => {
//       setFiles([])
//       const selectedEvent = userReducer.event_details
//     //   if (selectedEvent == null) {
//     //     setEventData({ name: userReducer.production_events[0]?.name, start: userReducer.production_events[0]?.start_date, end: userReducer.production_events[0]?.end_date, genre: userReducer.production_events[0]?.genre, code: userReducer.production_events[0]?.collab_code })
//     //   } else 
//       if (Object.keys(selectedEvent)?.length > 0) {
//         if(selectedEvent?.status_type_order < 3 && selectedEvent.consent_accepted == false) {
//           getConsentDetails(userReducer.production_event_id)
//         }
//         setEventData({ name: selectedEvent.name, editor_start_date: selectedEvent.editor_start_date, start: selectedEvent?.start_date, end: selectedEvent.end_date, eventType: selectedEvent.event_type, code: selectedEvent.collab_code, status: selectedEvent.status_type , consentStatus: selectedEvent.consent_accepted})
//       }
     
//     }, [userReducer.production_event_id, userReducer.event_details])
  


//     const copyInvite = ()=> {
//       setShareButtonText("COPIED!")
//       navigator.clipboard.writeText(`Hi! ${userReducer.user_name} has invited you to be a contributor to their event. Please visit the given link and use following invitation code to collaborate to this event\n\nInvitation Code:  ${eventData.code}\n\n\n ${window.location.host}/invite-collaborator?c=${eventData.code}`)
//       setTimeout(() => {
//         setShareButtonText("Copy event code")
//       }, 1500)
//     }
  
//     const shareClickHandler = () => {
//       const dataToBeShared = {
//        // title: "This title is for sharing",
//         text: `Hi! ${userReducer.user_name} has invited you to be a contributor to their event. Please visit the given link and use following invitation code to collaborate to this event\n\nInvitation Code:  ${eventData.code}\n\n\n`,
//        // url: `/guestuploads?e=${userReducer.production_event_id}&n=${eventData?.name?.replace(/ /g, "%20")}&s=${eventData?.start}&end=${eventData?.end}`,
//         url: `/`
//       };
//       // Check if navigator.share is supported by the browser
 
//       if (navigator.share) {
//         navigator
//           .share(dataToBeShared)
//           .then(() => {
//             console.log("Sharing successfull");
//           })
//           .catch(() => {
//             console.log("Sharing failed");
//           });
//       } else {
//         copyInvite()
//        // console.log("Sorry! Your browser does not support Web Share API");
//       }
//     }
  
//     const copyClickHandler = () => {
//       setCopyButtonText("COPIED!")
//       navigator.clipboard.writeText(`${window.location.host}/guestuploads?c=${eventData?.code}`)
//       setTimeout(() => {
//         setCopyButtonText("COPY LINK")
//       }, 1500)
  
//       // setSnackbarProps({ flag: true, message: `Copied to Clipboard: ${link} `, type: "success" })
//     }
   
  
//     function getFirstLetters(str) {
//       const firstLetters = str
//         .split(' ')
//         .map(word => word.charAt(0))
//         .join('');
//       return firstLetters;
//     }
  
//     return (
      
//     <AuthLayout sx={{ padding: '2.5% 3.9% 2.1% 2.2%'}}>
//         <Box gap={2} className='flex row a-start j-end'>
//                   <CustomUserCard/>
//                 </Box>
//                 <Box container className={classes.container}>
//         <Box className={classes.leftContainer}>
//               <Box sx={{height:{xs:'400px',sm:'400px',md:'58vh',lg:'62vh',xl:'70vh'}, marginBottom:{xs:'15px',md:'20px',lg:'23px',xl:'35px'}}}>
//                  <EventDetailCard eventData={eventData}/>
//               </Box>
//               <Box  sx={{height:{xs:'60px',sm:'80px',md:'16.1vh',lg:'18.1vh',xl:'16.1vh'}}} >
//                 <FilmingTutorialCard onClick= {()=> dispatch(updateUserState({play_tutorial: true})) } clickable = {true}/>
//               </Box>
             
//         </Box>
  
//         <Box className={classes.rightContainer}>
//               <Box sx={{width:'100%', }}>
//                 {/* <Box gap={2} sx={{marginTop:{xs:'20px',sm:'24px', md:'28px', lg:'35px', xl:'49px',}, display:'flex', alignItems:'center', justifyContent:'end' }}>
//                   <CustomUserCard/>
//                 </Box> */}
//                 <Box gap={1} sx={{display:'flex', justifyContent:'start', alignItems:'center', color:'black' }}>
//                     <IconButton disabled = {isLoading} onClick={()=>{navigate('/dashboard', {replace: true})}}>
//                       <KeyboardBackspaceOutlinedIcon sx={{color:'black',}}/>
//                     </IconButton>
//                     <Typography sx={{fontFamily: 'Rubik', fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'14px',xl:'20px'}}}>Back to Main Dashboard</Typography>
//                 </Box>
//               </Box>
  
//               <Box style={{display:'flex', gap:'2.34%'}} sx={{ marginBottom:{xs:'15px',sm:'15px',md:'15px',lg:'20px',xl:'30px'},  marginTop:{xs:'8px', sm:'10px', md:'12px',lg:'14px',xl:'23px'}, width:'100%', }}>

//                   <Box sx={{height:{xs:'100px',sm:'100px', md:'120px',lg:'19.5vh',xl:'20vh'}, width:'26%'}} container  >
//                       <CollaboratorCard getFirstLetters={getFirstLetters} shareClickHandler={shareClickHandler} copyInvite={copyInvite} buttonState={shareButtonText} isDisabled = {userReducer?.event_details?.status_type_order > 2 }/>
//                   </Box>
//                   <Box sx={{height:{xs:'100px',sm:'100px', md:'120px',lg:'19.5vh',xl:'20vh'}, width:'74%'}} container>
//                   { userReducer.event_details.status_type == "Review" ? (userReducer.event_details?.revision?.submitted == true && userReducer.event_details?.final_video_id == userReducer.event_details?.revision?.revision_video_id ? <EditingStartedCard message = "Requested changes are being made to your Lucivideo!"  note = "Your current Lucivideo will be available until a newer version arrives." />  : < VideoEditedEventCard message = {(userReducer?.event_details?.revision?.id == null || userReducer?.event_details?.revision?.submitted != true ) ? "Your Lucivideo is ready!" : "Updates have been made to your Lucivideo."} /> )
//                       : userReducer.event_details.status_type == "Complete" ? <FinalVideoCompletedCard eventPage /> 
//                         : userReducer.event_details.status_type == "Post Production" ? <EditingStartedCard message =  "Woohoo! Your Lucivideo is currently being edited!"  note = "Video submissions are no longer accepted." /> 
//                           : <ShareLinkCard 
//                             isDisabled={ isLoading }
//                             shareClickHandler={shareClickHandler}
//                             refetchEvent = {refetchEventDetail}
//                             eventData={eventData}
//                             copyClickHandler={copyClickHandler}
//                             guestUploadsLink={ <Typography width='100%' sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'13px',xl:'16px'}}}>{`${window.location.host}/guestuploads?c=${eventData?.code}`}</Typography>}
//                           />
//                   }
//                   </Box>
//               </Box>
  
//               <Box sx={{display:'flex', height:{xs:'60%',sm:'60%',md:'62%',lg:'64%',xl:'60%'}, }} gap={{xs:0.2,sm:0.5,md:2,lg:2.5,xl:3}}>
//                 <Box flex={3.7} sx={{paddingLeft:{xs:'5px',sm:'10px',md:'15px',lg:'20px',xl:'30px'}, }}>
//                     <Box sx={{display:'flex', justifyContent:'end', alignItems:'center', marginBottom:{xs:'10px',sm:'14px',md:'16px',lg:'18px',xl:'25px'}, marginTop:{xs:'5px',sm:'5px',md:'5px',lg:'10px',xl:'20px'}, cursor:'pointer'}} gap={{xs:0.3,sm:0.5, md:0.7, lg:1, xl:1.2}} pr={0.5}>
                       
//                         <CustomSortOptions
//                           sortByOptions={sortByOptions}
//                           selectedSortbyValue={selectedSortbyValue}
//                           setSelectedSortbyValue={setSelectedSortbyValue}
//                           // onChange={(newValue) => console.log(newValue)}
//                         />

//                     </Box>
//                        <CustomVideoTable
//                               sortBy={selectedSortbyValue}
//                               setSortBy={setSelectedSortbyValue}
//                               progress= {progress} 
//                               stopUpload = {stopUpload}
//                               uploadStatus = {uploadStatus}
//                               files = {files}
//                               uploadedVideosArray={uploadedVideosArray}
//                               stopList={stopList}
//                               isUploading={isLoading}
//                               refetchList={refetchList}
//                               importFiles={importFiles}
//                               importFilesStatus={importFilesStatus}
//                               setUploadedVideosArray={setUploadedVideosArray}
//                          />
//                 </Box>
//                 <Box flex={0.9} sx={{height:'90%', width: '100%' }}>
//                 { isEditStarted(userReducer?.event_details?.editor_start_date) ? <Box flex={0.9} sx={{height:{xs:'55vh',md: '55vh',lg: '55vh' , xl: '56vh'}, width: '100%',  }}> 
//                      <UpgradePlanCard /> 
//                      </Box>
//                    :<Box flex={0.9} sx={{height:'90%', width: '100%' }}>
//                          <CustomDropBox
//                          isDisabled={
//                          !isEventDate(eventData?.start, eventData?.editor_start_date) || isLoading
//                            }
//                          style={{ display: { xs: "none", md: "flex" } }}
//                          // height={{xs: '50vh', md: '62vh', lg: '65vh'}}
//                          // width="auto"
//                          files={files}
//                         // eventData={eventData}
//                          setFiles={setFiles}
//                          setUploadStatus={setUploadStatus}
//                          setProgress={setProgress}
//                          setRequestController={setRequestController}
//                          refetch={refetchList}
//                          refetchEvent = {refetchEventDetail}
//                          // productionFiles={productionFiles}
//                        />
//                      </Box>
//                      }
//                 </Box>
//               </Box>
        
//         </Box>  
//         </Box> 
//         {
//             userReducer.play_tutorial == true && <PopupVideoPlayer />
//        }
//        {/* {userReducer.import_files_confirmation_popup && <DuplicatingFilesPopup importFilesStatus={importFilesStatus} />} */}
//        {/* {userReducer.open_previous_project_media_popup && <UploadFromProjectPopup importFiles={importFiles}/>} */}
//        {mediaFilesReducer.import_files_confirmation_popup && <DuplicatingFilesPopup importFilesStatus={importFilesStatus} />}
//        {mediaFilesReducer.open_previous_project_media_popup && <UploadFromProjectPopup importFiles={importFiles}/>}

//      </AuthLayout> 
     
  
//     )
//   }
  
//   export default CommonProject;