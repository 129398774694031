import { createSlice } from '@reduxjs/toolkit'
import { voiceProfiles } from '../constants/staticData'

const initialValue = {
    selected_voice: {},
    is_speech_playing: false,
    is_sample_playing: false,
    is_generating_speech: false,
    is_generating_sample: false,
    audio_url: '',
    sample_url: '',
    styles:[],
    selected_style: null,
    accents: [],
    selected_accent: null,
    pitch: 0,
    rate: 0,
    sample_text: "Experience the future with our cutting-edge technology – faster, smarter, and more connected than ever. Innovate with us today and watch your ideas come to life effortlessly. ",
    is_new_audio: false,
   // voiceProfiles: [],
    profile_name: "",
    profile_description: "",
    voice_profile_view: true,
    voice_profile_manager_popup: false,
    voice_profile_creation_popup: false,
    selected_voice_profile: 1,
    is_selected_default: false,
    voice_profiles : [],
    is_profile_edit: false,
    selected_profile_id: '',
    selected_profile_action_id: 1
}


export const voiceSlice = createSlice({
    name: 'voice',
    initialState: initialValue,
    reducers: {
        updateVoiceState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetVoiceState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateVoiceState, resetVoiceState } = voiceSlice.actions
export default voiceSlice.reducer