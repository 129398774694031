import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { Typography } from "@mui/material";
import "@fontsource/rubik";
import { useDispatch, useSelector } from "react-redux";
import { updateSriptState } from "../../../redux/projectScriptSlice";
import { updateProjectCreationState } from "../../../redux/projectCreationSlice";

const drawerWidth = 350;

const CustomDrawer = (props) => {
  const { openDrawer, setOpenDrawer, bgColor } = props;
  const createProjectReducer = useSelector((state) => state.createProjectReducer);
  const dispatch = useDispatch()

  const handleDrawerToggle = () => {
    //setOpenDrawer((last) => !last);
    // dispatch(resetCreationState())
    dispatch(updateProjectCreationState({open_form:false}))
  };

  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }} >
      <CssBaseline />
      <Box
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}  
       
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // {...props}
          anchor="right"
          variant="temporary"
         // open={openDrawer}
         open = {createProjectReducer.open_form}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
         classes={'linear-gradient-cd-drawer'}
         slotProps={{
        backdrop: {
            sx: {
                backdropFilter: 'blur(2px)'
            }
        }
        
    }}
          sx={{
            // display: { xs: "block", sm: "block", md: "none" },
            // width: "90vw",
            // minWidth: 250,
            // maxWidth: 400,
            "& .MuiDrawer-paper": {
              width: {xs:'100vw',sm:'80vw',md:'70vw',lg:'60vw'},
              // backgroundColor: bgColor,
              height:'100%'
            },
          }}
          transitionDuration={1000}
        >
          <Box className='linear-gradient-cd-drawer ' sx={{height:'100%', width:'100%', paddingLeft:{xs:'20px',sm:'30px',md:'50px',lg:'70px', xl:'82px'},paddingRight:{xs:'20px',sm:'20px',md:'35px',lg:'45px', xl:'57px', display:'flex', flexDirection:'column'}, overflowY:'auto',  scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': {display: 'none'}}} textAlign='right'>
            <Box className='flex row j-end' >    
                <Typography onClick={handleDrawerToggle} sx={{fontFamily: 'Rubik', fontSize:{xs:'8px',md:'11px', xl:'14px'}, width: '60px', fontWeight:600, marginTop:{xs:'10px',sm:'15px',md:'20px',lg:'20px',xl:'44px'}, marginBottom:{xs:'10px',md:'14px',lg:'12px',xl:'24px'}, cursor:'pointer'}}>Close X</Typography>
            </Box>

            {props.children}
            
          </Box>
        </Drawer>
       
      </Box>
    </Box>
  );
};

// CustomDrawer.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

export default CustomDrawer;