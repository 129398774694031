import * as React from "react";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip classes={{ popper: className }}
  {...props}
  leaveDelay={10}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F4F4F4",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 10,
    textAlign: 'justify'
  },
}));

export default CustomTooltip;
