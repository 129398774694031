import { Box, Button, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { customTheme as theme } from "../../theme/customTheme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import notFoundImage from '../../assets/404.svg';
import CustomButton from "../../components/CustomButton";
import AuthLayout from "../layouts/AuthLayout";
import UnauthLayout from "../unauth/components/UnauthLayout";
import { useDispatch, useSelector } from "react-redux";
import { updateUserState } from "../../redux/userSlice";

const PageNotFound = (props) => {

  const { componentStack, error } = props;
  const [displayError, updateDisplay] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const userReducer = useSelector((state) => state.persistedReducers.userReducer);
  const [renderPage, setRenderPage] = useState(false)

  useEffect(()=>{
    if( !!window.localStorage.getItem('token')){
     setRenderPage(true)
    }else{
     // window.localStorage.setItem('destination_path', window.location.path )
      navigate('/redirect', {replace:true});
    }
  
   },[window.localStorage.getItem('token')])

  
  return (
   renderPage && <UnauthLayout  rightComponent={
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', mt:10}}  pl={{xs:2,sm:4,md:8,lg: 10,xl:12}}>
        <Box sx={{height:{xs:180,sm:200,md:220,lg:'240px',xl:300}, marginBottom:{xs:'15px', sm:'15px',md:'20px',lg:'22.66px',xl:'34px'}}}>
             <img src={notFoundImage} alt="notFoundImage" height='100%' style={{objectFit:'cover', borderRadius:'10px'}} />
        </Box>
    </Box>
    }>
        <Box pl={{sm:10}} pb={4} mt={10}>
          <Box  flexDirection='column'>
            
           <Box sx={{marginY:{xs:'15px',md:'20px',lg:'25px',xl:'38px'}}}>
              <Typography
                variant="h4"
                color="primary"
                style={{
                  fontWeight: 600,
                  color:'#ffffff'
                }}
                sx={{fontSize:{xs:'30px',sm:'40px',md:'40px',lg:'50px',xl:'80px'}, fontFamily:'Rubik', color:'#ffffff'}}
              >Oh Shoot.</Typography>
            </Box>
            <Box item xs={11} sm={8} md={7} gap={0.8} sx={{display:'flex', flexDirection:'column',}}>
                <Typography sx={{color:'#ffffff', fontSize:{xs:'12px',md:'14px',lg:'16px',xl:'20px'}, fontFamily:'Helvetica'}}>The page you were looking for does not exist.</Typography>
            </Box>

            <Box width='100%' sx={{mt: 3, display: 'flex', flexDirection: {xs: 'column-reverse', sm: 'row'}, justifyContent: { xs: 'flex-start', sm: 'flex-start'}, alignItems: {xs: 'center', sm: 'flex-start'} }} >

                 <CustomButton variant="contained" 
                    btnText="Go Home" 
                    style={{borderRadius: '5px', border:"none",  textTransform:'none', }}  
                    onClick= {()=> navigate('/', {replace: true})}
                    sx={{fontSize:{xs:'10px',sm:'10px',md:'11px',lg:'12px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'100px',lg:'120px',xl:'160px'}, fontFamily:'Rubik', backgroundColor:'#00CEFF', color:'#08231B'}}/>
         
            </Box>
                  
           </Box>

         </Box>
    </UnauthLayout>
 

  )
}

export default PageNotFound;