import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useValidator from "../../../hooks/useValidator";
import { useEffect } from "react";
import { Box,  TextField, Typography } from "@mui/material";
import CustomInput from "./CustomInput";
import { getErrorMsg } from "../../../utils/validator";
import { afterValidate, isEventPending, setInput, timeStampToDateString, timeStampToDateTime, timeStampToDayString } from "../../../utils/commonServices";
import CustomAutocomplete from "./CustomAutoComplete";
import CheckIcon from "../../../components/icons/CheckIcon";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import "@fontsource/rubik";
import CustomTooltip from "../../../components/CustomTooltip";
import CustomTextbox from "./CustomTextbox";
import { createProject } from "../actions";



const CreateProject = (props)=> {

    // const {submitRequest, requestStatus} = props;
    const stateReducer = useSelector((state) => state);
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    // const [pageData, setPageData] = useState(createEventReducer.pageData)
    const [pageData, setPageData] = useState(createProjectReducer.pageData)
    const [checked, setChecked] = useState(false)
    const [enableEvents, setEnableEvents] = useState(false);
    // const [refetchEvents] = useEventList(enableEvents);
    // const [createEvent, createEventStatus, eventId] = useEventRequest("create", refetchEvents)
    const [nameTooltip, setNametooltip] = useState(false)
  
  const [pageIndex, setPageIndex] = useState(0);
  const userReducer = useSelector((state) => state.persistedReducers.userReducer);
  const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch()
//   const { next, pageData, setPageData , requestStatus} = props;
  const [errorObjectArray, checkEmptyFields, checkValue] = useValidator(
    pageData,
    "create_event"
  );
  // const [startDateValidationFlag, setStartDateValidationFlag] = useState(false);

  const [nameValidationFlag, setNameValidationFlag] = useState(false);
  // const [videoLengthValidationFlag, setVideoLengthValidationFlag] = useState(false)
  // const [eventTypeValidationFlag, setEventTypeValidationFlag] = useState(false)
  const [projectTypeValidationFlag, setProjectTypeValidationFlag] = useState(false)
  const [scriptLengthValidationFlag, setScriptLengthValidationFlag] = useState(false)
  const [descriptionValidationflag, setDescriptionvalidationFlag] = useState(false)
  const [personaIdValidationFlag, setPersonaIdValidationFlag] = useState(false)

  //  const [videoLength, selectedVideoLength] = useVideoLengthList()
  //  const [eventType, selectedEventType] = useEventTypeList()


   const selectedProjectType = (value) => {
    if (dropdownReducer.project_type.length > 0 && dropdownReducer.project_type.find((item)=> item.value === value) ) {
        return dropdownReducer.project_type.find((item)=> item.value == value)
    } else {
        return {}
    }
};

const selectedScriptLength = (value) => {
  if (dropdownReducer?.script_length?.length > 0 && dropdownReducer?.script_length?.find((item) => item.value === value)) {
    return dropdownReducer?.script_length?.find((item) => item.value == value);
  } else {
    return {};
  }
};



    const finishedHandler = ()=> {
        validateInputs(true)
        afterValidate(requestCallback);      
    }

    const requestCallback= async ()=>{
      //  if(createEventReducer.edit == true && createEventReducer.event_id != null) {
      //   await submitRequest({id: createEventReducer.event_id, param: pageData})
      //  } else{
      //   await submitRequest(pageData);
      //  }

      // createProject(pageData, dispatch, navigate)
    }

    const validateInputs = (flag) => {
        setNameValidationFlag(flag)
        setProjectTypeValidationFlag(flag)
        setScriptLengthValidationFlag(flag)
        setDescriptionvalidationFlag(flag)
        setPersonaIdValidationFlag(flag)
        // setStartDateValidationFlag(flag)
        // setEndDateValidationFlag(flag)
        // setEditorStartDateValidationFlag(flag)
    }


  // useEffect(()=> {
  //     refetchEvents();
  // },[])

    // useEffect(()=> {
    //     if( requestStatus === 'success' ) {
    //         dispatch(updateUserState({selected_event: userReducer.events.find((e)=> e.id == userReducer.production_event_id) }))
           
    //         dispatch(resetCreationState());
    //         navigate('/productionevent', {replace: true})
    //     }
    // },[requestStatus])


    //   const selectLandscape = () => {
    //     setPageData((last) => {
    //         return { ...last, video_orientation: 'landscape' }
    //     })
    // }
    
    // const selectPortrait = () => {
    //     setPageData((last) => {
    //         return { ...last, video_orientation: "portrait" }
    //     })
    // }

    // const handleChange = (event)=>{
    //   setChecked(event.target.checked)
    // }


    return <>
 
      <Box sx={{display:'flex', flexDirection:'column', width:'100%', height:'100%', }}>
           {/* /////////////////////////////////////////////////////////////////////////////////////////////////// */}


      <Box sx={{width:'100%', marginBottom:{xs:'7px',sm:'12px',md:'15px',lg:'20px',xl:'33px'}}} textAlign='left'>

     <CustomTooltip arrow  PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}  
               open={nameTooltip} onClose={()=>setNametooltip(false)} disableHoverListener title={'Please enter new project name'} >
        <CustomInput
          id= {errorObjectArray[0]?.id}
          onFocus={() =>{ setNameValidationFlag(false); setTimeout(()=>{setNametooltip(true)},[1500]) }}
          onBlur={() => setNameValidationFlag(true)}
          // autoFocus={createEventReducer.duplicate}
          required
          errorFlag={
            nameValidationFlag &&
            getErrorMsg("project_name", pageData?.name) != ""
          }
          errorMsg={getErrorMsg("project_name", pageData?.name)}
          fullWidth
        //   label="Event Name*"
          type="text"
          max={100}
          placeholder="Lucihub Project Name (Max Character 100)"
          onChange={(event) =>
           {
            setNametooltip(false)
             setInput(
              pageData?.name != ""
                ? event.target.value.replace(/[<>:"/\\|?*]/g, '')
                : event.target.value.trim(),
              "name",
              pageData,
              setPageData
            )}
          }
          value={pageData?.name}
        />
        </CustomTooltip>
         <Typography sx={{fontFamily: 'Rubik',fontSize:{xs:'6px',md:'7px',lg:'8px',xl:'11px'}, paddingLeft:'15px'}}><span style={{fontWeight:600}}>Note:</span> The title of you video <span style={{fontWeight:600}}>cannot</span> be changed once you’ve created it.</Typography>
         </Box>

{/* //////////////////////////////event type and video length /////////////////////////////////////////// */}

        <Box sx={{width:'100%', display:'flex', marginBottom:{xs:'25px',sm:'25px',md:'25px',lg:'25px',xl:'33px'}}} gap={4}>
            <Box sx={{width:'50%'}}>

                        <CustomAutocomplete
                            id="project_type"
                            options={dropdownReducer?.project_type}
                            onFocus={() => {
                              setNameValidationFlag(true)
                              setProjectTypeValidationFlag(false)
                              }}
                            onBlur={() => setProjectTypeValidationFlag(true)}
                            required
                            errorFlag= {projectTypeValidationFlag && getErrorMsg("project_type", pageData?.project_type_id) != ""}
                            errorMsg= {getErrorMsg("project_type", pageData?.project_type_id)}
                            // value={selectedProjectType(pageData?.project_type)}

                            onChange={(event, newValue) => {
                                setPageData((last) => {
                                    return { ...last, project_type_id: newValue.id };
                                });
                            }}
                            label=""
                            placeholder='Project Type'
                            // id="Department"
                            //  required
                            //  options={departmentdata?.data?.department}
                            getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.value : ""
                            }
                
                        />
   
           
          </Box>
        { pageData?.project_type_id && <Box sx={{width:'50%'}}>
                       
                         <CustomAutocomplete
                            id="script_length"
                            options={dropdownReducer?.script_length}
                            onFocus={() => {
                            setNameValidationFlag(true)
                            setProjectTypeValidationFlag(true)
                            setScriptLengthValidationFlag(false)
                            }}
                            onBlur={() => setScriptLengthValidationFlag(true)}
                            required
                            errorFlag={scriptLengthValidationFlag && getErrorMsg("script_length", pageData?.length) != ""}
                            errorMsg={getErrorMsg("script_length", pageData?.length)}
                            // value={selectedScriptLength(pageData?.script_length)}

                            onChange={(event, newValue) => {
                                setPageData((last) => {
                                    return { ...last, length: newValue.value };
                                });
                            }}
                            label=""
                            placeholder='Select the length of your project'
                            getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? `${option.value}` : ""
                            }
                        />
             </Box>}
        </Box>

        <Box sx={{width:'100%', display:'flex', marginBottom:{xs:'25px',sm:'25px',md:'25px',lg:'25px',xl:'33px'}}} gap={4}>
            <Box sx={{width:'47.5%'}}>

                        <CustomAutocomplete
                            id="persona_id"
                            options={dropdownReducer?.persona_id}
                            onFocus={() => {
                              setNameValidationFlag(true)
                              setProjectTypeValidationFlag(true)
                              setPersonaIdValidationFlag(false)
                              }}
                            onBlur={() => setPersonaIdValidationFlag(true)}
                            required
                            errorFlag= {personaIdValidationFlag && getErrorMsg("persona_id", pageData?.persona_id) != ""}
                            errorMsg= {getErrorMsg("persona_id", pageData?.persona_id)}
                            // value={selectedProjectType(pageData?.project_type)}

                            onChange={(event, newValue) => {
                                setPageData((last) => {
                                    return { ...last, persona_id: newValue.id };
                                });
                            }}
                            label=""
                            placeholder='Select Persona'
                            getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.value : ""
                            }
                
                        />
   
           
          </Box>

        </Box>


     

    {/* ///////////////////////////////////////video description////////////////////////////////////////////// */}


          <Box sx={{marginBottom:{xs:'10px',sm:'15px',md:'15px',lg:'20px',xl:'57px'}}}>
            {/* <TextField 
              onFocus={() => {
                    setDescriptionvalidationFlag(false)
                    setNameValidationFlag(true)
                    setProjectTypeValidationFlag(true)
                    setScriptLengthValidationFlag(true)
                  }}
              onBlur={() => setDescriptionvalidationFlag(true)}
              required
              errorFlag={descriptionValidationflag && getErrorMsg("description", pageData?.description) != ""}
              errorMsg={getErrorMsg("description", pageData?.description)}
            fullWidth
            multiline
            variant='outlined'
            rows={10}
            rowsMax={10}
            size="small"
            style={{backgroundColor:'white'}}
           sx={{
            color:'#08231B',
                border:'1px solid #08231B',
                borderRadius:'5px',
                backgroundColor:'white',
                fontFamily: 'Rubik',
            '& .MuiInputBase-input': {
                        padding:0,
                        paddingX: '4px',
                        fontSize: {xs:'8px',lg:'10px',xl:'13px'},
                        fontWeight: 600,
                        // color: theme.palette.inputs.main
                        color:'#08231B',
                        "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            fontWeight: 600,
                    },
              },
           }}
        
            type="text"
            inputProps={{
              maxLength: 600
            }}
            shrink
            placeholder='What is your script about? Tell us more about what you want to incorporate in your script. (i.e. tone, important information, etc.)'
            onChange={(event) => setInput(pageData?.description != "" ? event.target.value : event.target.value.trim() , "description", pageData, setPageData)}
            value={pageData?.description}
            />           */}
            <CustomTextbox
              onFocus={() => {
                setDescriptionvalidationFlag(false)
                setNameValidationFlag(true)
                setProjectTypeValidationFlag(true)
                setScriptLengthValidationFlag(true)
                setPersonaIdValidationFlag(true)
              }}
              onBlur={() => setDescriptionvalidationFlag(true)}
              required
              maxLength ={400}
              errorFlag={descriptionValidationflag && getErrorMsg("description", pageData?.description) != ""}
              errorMsg={getErrorMsg("description", pageData?.description)}
              placeholder='What is your project about? Tell us more about what you want to incorporate in your project. (i.e. tone, important information, etc.)'
              onChange={(event) => setInput(pageData?.description != "" ? event.target.value : event.target.value.trim() , "description", pageData, setPageData)}
              value={pageData?.description}
            />
          </Box>

    {/* /////////////////////////////////////create event Button//////////////////////////////////////////// */}
         
    
            <Box flexGrow={1} sx={{ display:'flex', justifyContent:'flex-end' , alignItems:'flex-end', }} >
           
              <Box >
          
            {/* { requestStatus == 'loading' || requestStatus == 'success' ? <LoadingButton
                    sx={{
                        '& .MuiLoadingButton-loadingIndicator': {
                            color: '#00D744',
                        },
                        height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
                       minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                        backgroundColor: '#08231B'
                    }}
                    loading={requestStatus == 'loading'}
                    variant="outlined"
                    disabled
                    >
                   
               </LoadingButton> : */}
               <CheckIcon onClick= {finishedHandler}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: 'pointer'}}/>
               {/* } */}
              </Box>
             

                {/* <CustomButton  
                style={{
                    width: 100,
                    borderWidth: 2,
                    fontSize: 14,
                    fontWeight: 600,
                    boxShadow:  '0px 4px 10px rgba(0, 0, 0, 0.25)',
                    borderRadius: 10}}
                    // disabled={requestStatus === "loading" }
                     disabled={!checked}
                     onClick= {finishedHandler} 
                    btnText = "FINISH"  
                    variant="contained"
                    /> */}
            </Box>
      
    

           {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}

        </Box>
    </>
}

export default CreateProject;