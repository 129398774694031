import React from "react";
import ProjectLengthPage from "./ProjectLengthPage";
import ProjectDescriptionPage from "./ProjectDescriptionPage";
import ProjectPersonaPage from "./ProjectPersonaPage";
import PreviewCreateProject from "./PreviewCreateProject";
import BackdropWrapper from "../BackdropWrapper";
import { useSelector } from "react-redux";
import { getNamesFromIds } from "../../../../utils/commonServices";

const CommonProjectCreation = (props)=> {
    const {slideDirection, pageIndex, prevPageHandler, nextPageHandler, closeHandler, totalScreens} = props;
    const commonReducer = useSelector((state) => state.commonReducer);
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const dropdownReducer = useSelector((state)=> state.persistedReducers.dropdownReducer)
    const projectType = getNamesFromIds(createProjectReducer.commonPageData.projectTypeId, dropdownReducer?.project_type)?.name

   return <> 
            {commonReducer?.list_view ? (<>
                {pageIndex === 3 &&  <ProjectLengthPage {...props} />}
            {pageIndex === 4 && <ProjectPersonaPage {...props} />}
            {pageIndex === 5 && 
                <ProjectDescriptionPage 
                    {...props} 
                    title = {`Give us a description of your ${projectType}.`}
                    note = {`What is your ${projectType} about? Tell us more about what you want to incorporate in your ${projectType}. (i.e. tone, important information, etc.)`}   
                />
            }
            {pageIndex === 6 && <PreviewCreateProject {...props} />}
            </>):(<>
                {pageIndex === 3 &&  <BackdropWrapper slideDirection={slideDirection}><ProjectLengthPage {...props} /></BackdropWrapper>}
            {pageIndex === 4 && <BackdropWrapper slideDirection={slideDirection}><ProjectPersonaPage {...props} /></BackdropWrapper>}
            {pageIndex === 5 && 
                <BackdropWrapper slideDirection={slideDirection}><ProjectDescriptionPage 
                    {...props} 
                    title = {`Give us a description of your ${projectType}.`}
                    note = {`What is your ${projectType} about? Tell us more about what you want to incorporate in your ${projectType}. (i.e. tone, important information, etc.)`}   
                /></BackdropWrapper>
            }
            {pageIndex === 6 && <BackdropWrapper slideDirection={slideDirection}><PreviewCreateProject {...props} /></BackdropWrapper>}
            </>)}
     
   
       {/*pageIndex === 4 && <ProjectStartDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
       />*/}
   
       {/*pageIndex === 5 && <ProjectEndDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
     />*/} 
    </>
}

export default CommonProjectCreation;