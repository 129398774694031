import jsPDF from "jspdf";
// import html2pdf from 'html2pdf.js';
// import ReactDOMServer from 'react-dom/server';
//import { htmlToRtf } from 'html-to-rtf';
import Docxtemplater from 'docxtemplater';


  export const textToArray = (inputString) => {
    console.log("formatted array", inputString?.split('\n\n'))
    return inputString?.split('\n');
  }
  export const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };
  export const replaceAllOccurrences = (inputString, targetCharacter, replacementCharacter)=> {
    const escapedTarget = targetCharacter.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    const regex = new RegExp(escapedTarget, 'g');
    return inputString.replace(regex, replacementCharacter);
  }

  // export const captureHTML = (component)=> {
  //   const componentHtml = ReactDOMServer.renderToStaticMarkup(component);
  //   return componentHtml
  // }

 export const extractBoldText = (inputText)=> {
  const regex = /<b>(.*?)<\/b>/;
  let match;
  
  while ((match = inputText.match(regex))) {
    const boldContent = match[1];
    const startIndex = inputText.indexOf('<b>');
    const endIndex = startIndex + match[0].length;
  
    const beforeBold = inputText.substring(0, startIndex);
    const afterBold = inputText.substring(endIndex);
  
    console.log('Text before <b>:', beforeBold);
    console.log('Bold Content:', boldContent);
    
    //console.log('Text after </b>:', afterBold);
    console.log('-------------------------');
  
    // Update inputText for the next iteration
    inputText = afterBold;
  }
  
  console.log('Remaining Text:', inputText);

 }

  // export const exportAsPDF = (inputText, name) => {
  //   const regex = /<b>(.*?)<\/b>/;
  // let match;
  // const pdf = new jsPDF("p", "pt", "a4");

  // var currentY = 30;
  // const setCurrentY = (pos) => {
  //   currentY = pos
  // }
  
  // while ((match = inputText.match(regex))) {
    
  //   const boldContent = match[1];
  //   const startIndex = inputText.indexOf('<b>');
  //   const endIndex = startIndex + match[0].length;
  
  //   const beforeBold = inputText.substring(0, startIndex);
  //   const afterBold = inputText.substring(endIndex);

  //   addWrappedText(setCurrentY, currentY, beforeBold, 520, pdf, 12, 'normal')
  //   addWrappedText(setCurrentY, currentY, boldContent, 520, pdf, 12, 'bold')
  //   inputText = afterBold;
  // }
  //   addWrappedText(setCurrentY, currentY, inputText, 520, pdf, 12, 'normal')

  //   // // Save the PDF
  //    pdf.save(`${name}.pdf`);
  // };

  export const exportAsPDF = (inputText, name) => {
    const regex = /<(\/?[a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    let match;
    const pdf = new jsPDF("p", "pt", "a4");
  
    var currentY = 30;
    const setCurrentY = (pos) => {
      currentY = pos;
    };
  
    while ((match = inputText.match(regex))) {
     
      const fullTag = match[0];
      const tagType = match[1];
      const tagContent = match[2];

      if (tagType && tagType.toLowerCase() === 'br') {
        // Handle line break
        setCurrentY(currentY + 12); // Adjust the value as needed
      }else {
      const startIndex = inputText.indexOf(fullTag);
      const endIndex = startIndex + fullTag.length;
  
      const beforeTag = inputText.substring(0, startIndex);
      const afterTag = inputText.substring(endIndex);
  
      addWrappedText(setCurrentY, currentY, beforeTag.replace('<br />', ""), 520, pdf, 12, 'normal');
  
      // Handle different tag types
      switch (tagType.toLowerCase()) {
        case 'b':
          addWrappedText(setCurrentY, currentY, tagContent, 520, pdf, 12, 'bold');
          break;
     
        case 'i':
          addWrappedText(setCurrentY, currentY, tagContent, 520, pdf, 12, 'italic');
          break;
        case 'h1':
          addWrappedText(setCurrentY, currentY, tagContent, 520, pdf, 34, 'bold', 35);
          break;
        case 'h2':
          addWrappedText(setCurrentY, currentY, tagContent, 520, pdf, 30, 'bold', 32);
          break;
        case 'h3':
          addWrappedText(setCurrentY, currentY, tagContent, 520, pdf, 24, 'bold', 26);
          break;
        case 'h4':
          addWrappedText(setCurrentY, currentY, tagContent, 520, pdf, 20, 'bold', 22);
          break;
        case 'h5':
          addWrappedText(setCurrentY, currentY, tagContent, 520, pdf, 18, 'bold', 20);
          break;
        case 'h6':
          addWrappedText(setCurrentY, currentY, tagContent, 520, pdf, 16, 'bold', 18);
          break;
        // Add more cases for other tag types as needed
        default:
          // Handle unknown tags or treat them as normal text
          addWrappedText(setCurrentY, currentY, tagContent, 520, pdf, 12, 'normal');
          break;
      }
      console.log({afterTag})
      inputText = afterTag;
    }
    }
  
    addWrappedText(setCurrentY, currentY, inputText.replace('<br />', ""), 520, pdf, 12, 'normal');
  
    // Save the PDF
    pdf.save(`${name}.pdf`);
  };

  const addWrappedText = (setCurrentY,  initialYPosition = 10, text, textWidth, doc, fontSize = 10, fontType = 'normal', lineSpacing = 13, xPosition = 30, pageWrapInitialYPosition = 30)=> {
    doc.setFont('times', fontType);
    doc.setFontSize(fontSize);
    var textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
    var pageHeight = doc.internal.pageSize.height;        // Get page height, we'll use this for auto-paging. TRANSLATE this line if using units other than `pt`
    var cursorY = initialYPosition;
  
    textLines.forEach(lineText => {
      if (cursorY > pageHeight - 30) { // Auto-paging
        doc.addPage();
        cursorY = pageWrapInitialYPosition;
      }
      doc.text(xPosition, cursorY, lineText);
      cursorY += lineSpacing;
    })

    setCurrentY(cursorY);
  }
  

// export const exportAsRTF = (content) => {
//  // const yourComponentHTML = '<div>Your component content here</div>';
//   const rtfContent = htmlToRtf(content);
//   // Save or process the RTF content as needed
// };



export function export2Word(element, filename = ''){
  var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
 
  var postHtml = "</body></html>";
  let doc = document.getElementById(element)
  var html = preHtml + doc.innerHTML + postHtml ;

  
  console.log(document.getElementById(element).innerHTML, 'html------------')
  var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
       //type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  });
  
  // Specify link url
  var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
   //var url = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8,' + encodeURIComponent(html);
  // Specify file name
  filename = filename?filename+'.doc':'document.doc';
  
  // Create download link element
  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);
  
  if(navigator.msSaveOrOpenBlob ){
      navigator.msSaveOrOpenBlob(blob, filename);
  }else{
      // Create a link to the file
      downloadLink.href = url;
      
      // Setting the file name
      downloadLink.download = filename;
      
      //triggering the function
      downloadLink.click();
  }
  
  document.body.removeChild(downloadLink);
}

export function export2RTF(element, filename = '') {
  var preRtf = "{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033";
  var postRtf = "}";

  var doc = document.getElementById(element);
  var rtfContent = preRtf + doc.innerText + postRtf;

  var blob = new Blob(['\ufeff', rtfContent], {
      type: 'application/rtf'
  });

  var url = 'data:application/rtf;charset=utf-8,' + encodeURIComponent(rtfContent);
  filename = filename ? filename + '.rtf' : 'document.rtf';

  var downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
  } else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
  }

  document.body.removeChild(downloadLink);
}

function convertHtmlToRtf(html) {
  const tagMap = {
      'b': '\\b ',
      'i': '\\i ',
      // Add more mappings as needed
  };

  function convertTags(node) {
      if (node.nodeType === 3) {
          return node.nodeValue.replace(/[\\{}]/g, ''); // Remove characters reserved in RTF
      }

      if (node.nodeType === 1) { // Element node
          const tag = node.tagName.toLowerCase();
          const tagMapping = tagMap[tag] || '';
          const openingTag = tagMapping ? `{${tagMapping}` : '';
          const closingTag = tagMapping ? '}' : '';

          const children = Array.from(node.childNodes).map(convertTags).join('');

          return openingTag + children + closingTag;
      }

      return '';
  }

  const rtfContent = `{\rtf1\ansi\ansicpg1252\deff0\deflang1033${convertTags(document.createElement('div').innerHTML = html)}}`;

  return rtfContent;
}
// export function Export2Word(element, filename = ''){
//   var header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' "+
//        "xmlns:w='urn:schemas-microsoft-com:office:word' "+
//        "xmlns='http://www.w3.org/TR/REC-html40'>"+
//        "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
//   var footer = "</body></html>";
//   var sourceHTML = header+document.getElementById(element).innerHTML+footer;
  
//   var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
//   var fileDownload = document.createElement("a");
//   document.body.appendChild(fileDownload);
//   fileDownload.href = source;
//   fileDownload.download = 'document.docx';
//   fileDownload.click();
//   document.body.removeChild(fileDownload);
// }

// export const shotListTextToArray = (inputString, persona)=> {
//   if(persona == 3) {
//    return expertShotlistToArray(inputString)
//   } else {

// // Split the script into an array of smaller strings using newline character
// const sectionArray = inputString.split('\n\n');
// const resultArray = sectionArray?.map((section)=> {
//   return section?.split('\n')
// })
// if(resultArray?.length > 0) {
//   console.log({resultArray})
//   return resultArray
// }
  
//     return [];
//   }
//   }

  // export const expertShotlistToArray = (longString)=> {
  // const scenes = longString.split('\n\n');
  // const jsonScenes = [];

  // scenes.forEach(scene => {
  //   const lines = scene.split('\n');
  //   const sceneObject = {};

  //   lines.forEach(line => {
  //     const [key, value] = line.split(': ');
  //     const trimmedKey = key.replace(/^Scene \d+: /, ''); // Remove the numbers following "Scene"
  //     sceneObject[trimmedKey.trim()] = value.trim();
  //   });

  //   jsonScenes.push(sceneObject);
  // });
  //  const tempArr = jsonScenes?.map(item=>{
  //   var temp = {}
  //   const keys = Object.keys(item)
  //   const values = Object.values(item)
  //   keys?.map((key, i)=> {
  //     if(key.includes('Scene')) {
  //       temp = {...temp, Scene : values[i] }
  //     } else {
  //       temp = {...temp, [key] : values[i]}
  //     }
  //   })
  //   return temp
  //  })
  // console.log({tempArr})
  // return tempArr;
  // }



  // export const scriptTextToArray = (longString)=> {
  //   // Use regular expression to split the string based on uppercase words in square brackets
  //   const result = longString.split(/\[([A-Z\s]+)\]/);

  //   // Remove '\n' and numbers from each element
  //   const processedResult = result
  //       .filter(element => element.trim() !== "")
  //       .map(element => element.replace(/\n|\d+/g, '').trim());

  //   return processedResult;
  //  }

  //  export const blogTextArray = (longString, persona) => {
  //   if(persona == 3) {
  //     let temp
  //     try {
  //       temp = JSON.parse(longString)
  //     } catch (e) {
  //       console.log("corrupt JSON")
  //     }
      
  //     // const Script = temp?.Script?.map((item, index)=> {
  //     //   return {...item, }
  //     // })
  //     const Script =  !!temp ? temp?.Script : []
  //     return Script
  //    // console.log({Script})
  //   } else {
  //     return (!!longString && longString?.split("\n"));
  //   }
  //  // 
  //  }
