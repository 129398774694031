import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCommonState } from '../../../redux/commonSlice'
import ProjectList from './ProjectList'
import { Box, Drawer, IconButton, Input, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CustomCard from '../../../components/CustomCard'
import SearchIcon from "@mui/icons-material/Search";
import { formatDynamicDate } from '../../../utils/commonServices'
import luciLogo from "../../../assets/copilot_logo_dark.png";
import useEventSort from '../hooks/useEventSort'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import { updateSriptState } from '../../../redux/projectScriptSlice'

const DrawerProjectList = () => {

  const commonReducer = useSelector(state=> state.commonReducer)
  const dispatch = useDispatch()
  const classes = useStyles();
  const navigate = useNavigate()
  const scriptReducer = useSelector((state) => state.scriptReducer);
  const [searchString, setSearchString] = useState("");
  const [selectedSortbyValue, setSelectedSortbyValue] = useState("date_added");
  const [searchedArray, setSearchedArray] = useState(scriptReducer.all_projects);
  const [sortedEvents] = useEventSort(searchedArray, selectedSortbyValue);
 
  


  useEffect(()=> {
      setSearchedArray(scriptReducer.all_projects)
  }, [scriptReducer.all_projects])

  const closeDrawerHandler = ()=>{
    dispatch(updateCommonState({open_drawer_project_list: false}))
  }

  const handleSearch = (e) => {
    const searchedInput = e.target.value.toLowerCase();
    setSearchString(searchedInput);

    const projects = [...scriptReducer.all_projects];

    const results = projects?.filter(item =>
      item.name?.toLowerCase().includes(searchedInput) ||
      item.projectType?.toLowerCase().includes(searchedInput)||
      formatDynamicDate(item.startDate)?.toLowerCase().includes(searchedInput)
    );
    setSearchedArray(results);
  };

  const onEventClick = (event) => {
    closeDrawerHandler()
    navigate(`/productionevent/${event.id}`, {replace: true})
};

const deleteHandler = (e, event)=> {
  e.stopPropagation();
  //e.preventDefault()
  // console.log("clicked")
  dispatch(updateCommonState({open_drawer_project_list: false}))
  dispatch(updateSriptState({open_delete_popup: true, delete_project_id: event.id}))
}

  return (
    <Drawer PaperProps={{ style: {borderRadius: '0px 10px 10px 0px' }}} open={commonReducer?.open_drawer_project_list} onClose={closeDrawerHandler} sx={{backgroundColor:'transparent', borderRadius: '0px 10px 10px 0px' }}>
      <Box sx={{width:'80vw', height:'100%', color:'#ffffff' }}  className={classes.container} >
        <CustomCard sx={{height:"100%", width:'100%'}} customStyle={{borderRadius: '0px 10px 10px 0px'}} 
                    padding='5% 5% 5% 5%'>

              <Box sx={{width:'100%', }} className='flex j-between a-center' gap={6}>

                    <Box sx={{width:{xs:'70px', md:'95px',lg:'120px',xl:'150px'}, mb: 2}}>
                            <img src={luciLogo} width= '100%' />
                        </Box>

                <Box id="search" 
                sx={{display:'flex', justifyContent:'flex-start'}}
                maxWidth='300px'
                //sx={{ marginBottom: { xs: '12px', md: '15px', lg: '18px', xl: '30px' } }} 
                >
                  <Input
                  id="search"
                  value={searchString}
                  onChange={handleSearch}
                  inputProps={{ maxLength: 30 }}
                  sx={{
                  //  minWidth: '160px',
                  // width: '100%',
                  maxWidth:'300px',
                  borderBottom: 1.5,
                  borderColor: "#fff",
                  input: {
                  fontSize: { xs: '12px', md: '13px', lg: '14px', xl: '20px' },
                  fontWeight: 400,
                  color: "#fff",
                  },
                }}
                  placeholder="Search"
                  startAdornment={
                <IconButton sx={{paddingBottom:1, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'17px',xl:'24px'}}, paddingRight:2}} >
                    <SearchIcon style={{ color: "#fff" }} />
                </IconButton>
                }
                />
            </Box>



              </Box>

              
              <Box width="100%"   sx={{overflowY:'scroll', height:'85%'}} pl={2}>
                        {
                        sortedEvents?.length > 0 ? sortedEvents?.map((event) => {
                            {/* return <Typography onClick={() => onEventClick(event)}  sx={{ mt: 2, mr: 1, cursor: 'pointer', textTransform:'capitalize', wordBreak:'break-all', '&:hover':{ opacity:'0.8'}}} >{event?.name}</Typography> */}
                            return <Box className='flex row j-between a-center' sx={{mt: {xs:2, md:1.5,lg:2}}} >
                                <Typography onClick={() => onEventClick(event)}  sx={{  mr: 1, cursor: 'pointer', textTransform:'capitalize', wordBreak:'break-all', fontSize: {xs:'12px',sm:'14px',lg:'16px',xl:'22px'} }} >{event?.name}</Typography>
                                <DeleteIcon style={{fontSize: 13, marginRight: 12, cursor: 'pointer'}} onClick = {(e)=>deleteHandler(e, event)} />
                                </Box>
                        }) :
                        <Box className= 'flex col j-center a-center' sx={{overflowY: "initial", whiteSpace: 'normal'}} height='100%' width='100%'><Typography sx={{fontSize: 30, color: '#fff', fontWeight: 800, textAlign: 'center', mb:1}} >Welcome to Lucihub‘s Assistant Creative Director!</Typography>
                        {/* <Typography sx={{fontSize: 30, color: '#fff', fontWeight: 600, textAlign: 'center', textTransform: 'Uppercase'}} >Creative Director Copilot</Typography> */}
                        <Typography sx={{fontSize: 30, color: '#fff', fontWeight: 300, textAlign: 'center', mt:3}} >Choose a content type to get started on your first project.</Typography></Box>
                    }
                           
                        </Box>

        </CustomCard>

      </Box>
    </Drawer>
  )
}

export default DrawerProjectList

const useStyles = makeStyles((theme) => ({
  container: {
      fontFamily: 'Rubik',
      height: '100%',
      minHeight: '100vh',
      overflowY: 'auto',
      backgroundImage: 'radial-gradient(at top right,  #005998, #003877, #0f2a65, #141c53, #140f41, #140f41, #121633, #121633 , #121633)',
      borderRadius: '0px 10px 10px 0px',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display : 'none'
      },
  }
}));