import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { makeStyles } from "@mui/styles";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import scriptIcon from '../../../assets/scriptIcon.png';
import shotlistIcon from '../../../assets/shotlist.png';
import storyboardIcon from '../../../assets/storyboard.png';
import { updateUserState } from '../../../redux/userSlice';
import useContentActions from '../hooks/useContentActions';
import { updateContentState } from '../../../redux/contentSlice';
import { projectTypeIcons, projectTypeThumbnails } from '../../../constants/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateSriptState } from '../../../redux/projectScriptSlice';
import { groupArrayByValue, removeWhiteSpaces } from '../../../utils/commonServices';
import ScrollToBottom from '../../../components/ScrollToBottom';


const useStyles = makeStyles((theme) => ({
    outerBox:{
        borderRadius:'10px',
        // width:'100%',
        // border:'2px solid #08231B',
        // height:'160px',
        // minHeight:'16px',
        display:'flex', 
        
        alignItems:'center'
    },
    topContainer:{
        background:'#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius:'10px 10px 0px 0px',
        height:'100%'
    },
    bottomContainer:{
        // background: '#08231B 0% 0% no-repeat padding-box',
        borderRadius:' 0px 0px 10px 10px',
        opacity:1,
        // color:'#fff',
        height:'20%'
    },
    selected:{
        border:'2px solid #00D744',
        color:'#08231B',
        background: '#00D744 0% 0% no-repeat padding-box',
    },
    notSelected:{
        border:'2px solid #08231B',
        color:'#FFFFFF',
        background: '#08231B 0% 0% no-repeat padding-box',
    },
    flex:{
        display:'flex'
    },
 
  }));

const ScriptThumbnail = (props) => {

   const {selectedScript, content, index, setSelectedScript, setScriptType} = props
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null);
    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
    const scriptReducer = useSelector((state) => state.scriptReducer);
    const contentReducer = useSelector((state)=> state.contentReducer);
    const dispatch = useDispatch();
    const isSelected = content.id === selectedScript
    const {convertContent} = useContentActions();
    const contentType = dropdownReducer.project_type?.find(type=> type.id == content.content_type_id)?.name
    const [selectedTypeIndex, setSelectedTypeIndex] = useState()
    // const thumbsrc = content.content_type_id == 9 ? contentReducer?.selected_content_details?.content?.image_url 
    //                     : projectTypeThumbnails[content.content_type_id -1] 
   
    useEffect(()=> {
        const tempArr = groupArrayByValue(scriptReducer.selected_project_details?.contents, 'content_type_id')
        const index = tempArr?.find((item)=> item[0]?.content_type_id === content.content_type_id)?.findIndex((item1, i) => item1.id === content.id)
        setSelectedTypeIndex(index)
       // setGroupedArray(tempArr)
    }, [selectedScript])
   
    //const truncatedThumbDesc = content.thumbDesc.slice(0, 400);

    const setSelectedScriptHandler = ()=>{
       // if( userReducer.isLoading === false) {
            dispatch(updateContentState({is_voice: false}))
            setSelectedScript(content.id)
     
          
           // dispatch(updateUserState({selected_content_id: content.contentID, storyboard_item_number: null}))
       // }
       
       // setScriptType('script')
    } 
const convertContentClickHandler = (id) => {
    handleMenuClose();
    const payload = {
        "sourceContentId": content?.id,
        "convertoProjectTypeId": id,
        "convertoPersonaId": scriptReducer.selected_project_details?.personaId,
        "storyboardCount":  (parseInt(scriptReducer.selected_project_details.length) > 3 ? 3 : parseInt(scriptReducer.selected_project_details.length))
      }
      //console.log("convert payload", payload)
      convertContent(payload)
    }


    const handleMenuClick = (event) => {
        setMenuAnchor(event.currentTarget);
      };
    
      const handleMenuClose = () => {
        setMenuAnchor(null);
      };
      const deleteHandler = (e)=> {
        e.stopPropagation();
        dispatch(updateContentState({open_delete_popup: true, delete_content_id: content.id}))
    }

  return (<>
     {isSelected && <ScrollToBottom/>}
    <Box className={classes.outerBox} sx={{maxHeight:{xs:'90px',md:'100px',lg:'110px',xl:'160px'}, minHeight:{xs:'90px',md:'100px',lg:'110px',xl:'160px'},  minWidth:{xs:'170px',md:'190px',lg:'210px',xl:'280px'}, width:{xs:'170px',md:'190px',lg:'210px',xl:'280px'}, mb: '12px'}} >
        
        <Box sx={{width:'240px', display:'flex', flexDirection:'column', height:'100%', minWidth:{xs:'150px',md:'160px',lg:'180px',xl:'250px'}, width:{xs:'150px',md:'160px',lg:'180px',xl:'250px'},}}>
            <Box flex={5} className={classes.topContainer} sx={{position:'relative', border:isSelected ? '2px solid #00D744' :'2px solid #08231B', cursor: !userReducer.isLoading && 'pointer'}} onClick={setSelectedScriptHandler}>
                {/* <Box p={0.5} sx={{position:'absolute', backgroundColor: scriptReducer?.selected_project_details?.projectType?.toLowerCase() === 'storyboard' ? '#59DFFF' : scriptReducer?.selected_project_details?.projectType?.toLowerCase() === 'shot list' ? '#00D744':'black', display:'flex', alignItems:"center", justifyContent:'center', borderRadius:'50%', top:'5px', left:'5px'}}>
                    <ArticleOutlinedIcon style={{color:'#fff'}} sx={{ fontSize:{xs:'12px',md:'14px',lg:'16px', xl:'20px'}}}/>
                </Box> */}
                <Box sx={{width:'20px', height:'28px', ml: '6px', mt:'6px', position: 'absolute', zIndex: 4}}><img style={{height: '28px'}} src={projectTypeIcons[removeWhiteSpaces(contentType)?.toLowerCase()]}/></Box>
                <Box textAlign='center'  sx={{zIndex: 5, pb: '4px', width:{xs:'150px',md:'160px',lg:'180px',xl:'250px'}, maxHeight:{xs:'90px',md:'100px',lg:'110px',xl:'160px'}, height:{xs:'90px',md:'100px',lg:'110px',xl:'160px'}}} >
                    {/* <img width='50%' style={{opacity: 0.7}}  src={scriptReducer?.selected_project_details?.projectType?.toLowerCase() === 'script' ? scriptIcon : (scriptReducer?.selected_project_details?.projectType?.toLowerCase() === 'shot list' || scriptReducer?.selected_project_details?.projectType?.toLowerCase() === 'shotlist') ? shotlistIcon : storyboardIcon } /> */}
                    {
                        content.content_type_id == 9 ? <img height='100%'  style={{maxWidth: '98%', marginRight: '4px', borderRadius: '10px 10px 0 0', opacity: 0.7}}  src={scriptReducer?.selected_project_details?.contents[index]?.image_url } />
                            : <img width='73%' style={{opacity: 0.7, paddingTop: '8px'}}  src={projectTypeThumbnails[content.content_type_id -1] } />
                    }
                    {/* <Typography sx={{fontSize:'5px'}}>{truncatedThumbDesc}</Typography> */}
                </Box>
               
              
            </Box>
            <Box flex={1.2} className={`${classes.bottomContainer} ${classes.flex} j-between a-center`} paddingX={1} paddingY={0.5} sx={{border:isSelected ? '2px solid #00D744' :'2px solid #08231B',background: isSelected ? '#00D744 0% 0% no-repeat padding-box' : '#08231B 0% 0% no-repeat padding-box', color:isSelected ?"#08231B":'#fff' }}>
                <Box className = 'flex row j-start a-center' >
                    <Typography sx={{fontSize:{xs:'9px',lg:'11px', xl:'14px'}, fontWeight:600}}>{`${dropdownReducer.project_type?.find((pType)=> pType.id === content.content_type_id)?.name} ${selectedTypeIndex+1}`}</Typography>
                    {/*<Box sx={{width:'20px', height:'28px', ml: '6px', mt:'6px', position: 'absolute', zIndex: 4}}><img style={{height: '28px'}} src={projectTypeIcons[removeWhiteSpaces(contentType)?.toLowerCase()]}/></Box>*/}
                </Box>
                <Box className={`flex`} gap={1}>
                   {scriptReducer.selected_project_details?.contents?.length > 1 && <Box width='100%' textAlign='right' sx={{paddingRight: '6px'}} ><DeleteIcon onClick = {deleteHandler}  style={{fontSize: '16px', paddingTop: '3px', color:isSelected ?"#08231B":'#fff', cursor: 'pointer'}} /></Box> }
                    {/* <FileDownloadOutlinedIcon onClick={()=>console.log('download')}  sx={{fontSize:{xs:'14px',lg:'16px', xl:'20px'}, cursor:'pointer'}}/> */}
                    {/* <MoreHorizOutlinedIcon onClick={handleMenuClick} sx={{fontSize:{xs:'14px',lg:'16px', xl:'20px'}, cursor:'pointer'}}/> */}
                    <Menu
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={handleMenuClose}
                    >
        
                        {content.content_type_id !== 2 && <MenuItem   sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'10px',xl:'14px'}, py:0.4}} onClick={() => convertContentClickHandler(2)}>Convert to a Shotlist</MenuItem>}
                        {content.content_type_id !== 3 && <MenuItem   sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'10px',xl:'14px'}, py:0.4}} onClick={()=>convertContentClickHandler(3)}>Convert to a Storyboard</MenuItem>}
                        {/* <MenuItem  
                        sx={{color:'#000',fontWeight:600, fontFamily:'Rubik', letterSpacing:0.8, fontSize:{xs:'8px',md:'10px',lg:'10px',xl:'14px'}, py:0.4}}
                        onClick={() => {console.log(content.contentID); handleMenuClose()}}>Delete</MenuItem> */}
                    </Menu>
                </Box>
            </Box>
        </Box>
       {isSelected === true && <Box mt={3} >
            <PlayArrowRoundedIcon style={{color: '#00D744'}} sx={{fontSize:{xs:'22px', md:'26px', lg:'28px', xl:'32px'}}}/>
        </Box>}
    </Box> </>
  )
}

export default ScriptThumbnail