import React from "react";
import ImageStyleComponent from "./ImageStyleComponent";
import ProjectDescriptionPage from "../commonProject/ProjectDescriptionPage";
import ProjectPersonaPage from "../commonProject/ProjectPersonaPage";
import ImageAspectComponent from "./ImageAspectComponent";
import ImageColorScheme from "./ImageColorScheme";
import PreviewCreateImage from "./PreviewCreateImage";
import BackdropWrapper from "../BackdropWrapper";
import { useSelector } from "react-redux";

const ImageProjectCreation = (props)=> {

    const {slideDirection, pageIndex, prevPageHandler, nextPageHandler, closeHandler, totalScreens} = props;
    const commonReducer = useSelector((state) => state.commonReducer);
    const contentReducer = useSelector((state) => state.contentReducer);

    return  <> 
            {commonReducer?.list_view ? (<>
              {pageIndex === 3 && <ImageStyleComponent {...props} />}
                {pageIndex === 4 && <ImageAspectComponent {...props} />}
                {pageIndex === 5 && <ImageColorScheme {...props} />}
                {pageIndex === 6 && 
                 <ProjectDescriptionPage 
                        {...props} 
                        title = 'Describe the image you would like to create in detail.'
                        note = '(ie. What is the main subject? Are there any specific details you want to include? What mood do you want to convey?)'  
                    />
                }
                {pageIndex === 7 && <PreviewCreateImage {...props} />}
            </>):(<>
              {pageIndex === (3) && <BackdropWrapper slideDirection={slideDirection}><ImageStyleComponent {...props} /></BackdropWrapper>}
                {pageIndex === 4 && <BackdropWrapper slideDirection={slideDirection}><ImageAspectComponent {...props} /></BackdropWrapper>}
                {pageIndex === 5 && <BackdropWrapper slideDirection={slideDirection}><ImageColorScheme {...props} /></BackdropWrapper>}
                {pageIndex === 6 && 
                   <BackdropWrapper slideDirection={slideDirection}><ProjectDescriptionPage 
                        {...props} 
                        title = 'Describe the image you would like to create in detail.'
                        note = '(ie. What is the main subject? Are there any specific details you want to include? What mood do you want to convey?)'  
                    /></BackdropWrapper>
                }
                {pageIndex === 7 && <BackdropWrapper slideDirection={slideDirection}><PreviewCreateImage {...props} /></BackdropWrapper>}
            </>)}
               
   
       {/*pageIndex === 4 && <ProjectStartDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
       />*/}
   
       {/*pageIndex === 5 && <ProjectEndDatePage
         pageData={pageData}
         setPageData={setPageData}
         slideDirection={slideDirection}
         pageIndex={pageIndex}
         prevPageHandler={prevPageHandler}
         nextPageHandler={nextPageHandler}
         totalScreens={totalScreens}
         closeHandler={closeHandler}
     />*/}
   
       
    </>

}

export default ImageProjectCreation;