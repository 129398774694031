import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkSessionApi, deleteProjectApi, getAllProjectsListApi, getProjectDetailsApi } from "../../../services/api";
import { updateUserState } from "../../../redux/userSlice";
import { setSnackbar } from "../../../redux/messageSlice";
import { logout, removeDuplicateArrayItem, showSnackBar } from "../../../utils/commonServices";
import { getContentDetails } from "../../events/actions";
import { updateSriptState } from "../../../redux/projectScriptSlice";
import { resetPendingCallState, updatePendingCallState } from "../../../redux/pendingCallSlice";

export default ()=> {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
//APi  to get all the projects    
const getAllProjects = (payload, callBack) => {
    dispatch(updatePendingCallState({loader_backdrop_open: true, loader_text: {title: `Please wait...`, message: "We’re loading your projects."}}))
    getAllProjectsListApi().then(response => {
        dispatch(resetPendingCallState())
        if(response.data?.httpCode === 200){
           // console.log("all project",response.data.projects)
            dispatch(updateSriptState({all_projects: response?.data?.projects}))
        }else {
            dispatch(updateSriptState({all_projects:[]}))
          //  dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
            // dispatch(updateUserState({common_backdrop_open: false}))
        }
    }).catch(error =>{
      if(error) { //console.log("error", error.response)
        showSnackBar(error?.response, dispatch)
       // dispatch(setSnackbar({type:'error', flag:true, message: 'Please try again later.'}))
        dispatch(resetPendingCallState())}
    })
}

//APi  to delete a projects    
const deleteProject = (payload, callBack) => {
    dispatch(updateUserState({common_backdrop_open: true}))
    deleteProjectApi(payload).then(response => {
        dispatch(updateUserState({common_backdrop_open: false}))
        console.log("delete proj res", response?.data)
       
        if(response.data?.httpCode === 200){
           // console.log("all project",response.data.projects)
           // dispatch(updateSriptState({all_projects: response?.data?.projects}))
           callBack()
           dispatch(setSnackbar({type:'success', flag:true, message: response.data?.message }))
           getAllProjects()
        }else{
          //  dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
            // dispatch(updateUserState({common_backdrop_open: false}))
        }
    }).catch(error =>{
      if(error) { //console.log("error", error.response)
        showSnackBar(error?.response, dispatch)
       // dispatch(setSnackbar({type:'error', flag:true, message: 'Please try again later.'}))
        dispatch(updateUserState({common_backdrop_open: false}))}
    })
}

 const checkTokenSession = (dispatch , callback) => {
    checkSessionApi() 
    .then((response) => {
      if(response?.data?.http_code === 200){
        callback && callback()
      }
    })
    .catch((error) => {
        const errorObject = error?.response?.data;
        // showSnackBar(errorObject)
        dispatch(setSnackbar({type:'error', flag:true, message: 'Your session has expired. Please login again.'}))
        setTimeout(() => {
          logout(dispatch)
        }, 3000);
    });
  }


return {
    getAllProjects,
    deleteProject,
    checkTokenSession
}
}