

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { customTheme as theme } from "../../../theme/customTheme";
import { Box } from "@mui/system";
import { InputAdornment, Typography } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";
import "@fontsource/rubik";
import { styled, lighten, darken } from '@mui/system';
import { capitalizeFirstLetter } from "../../../utils/commonServices";
import CustomTooltip from "../../../components/CustomTooltip";

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: '#B3F0FF'
  }));
  
  const GroupItems = styled('ul')({
    padding: '0px',
    "& :hover": {
              // color: theme.palette.primary.main,
              backgroundColor:'#00D744 !important',
              color:'black !important',
              fontWeight:600
            },
            '& .MuiAutocomplete-option[aria-selected="true"]': {
              // works
              // backgroundColor: theme.palette.secondary.light,
              backgroundColor:'#00D744',
              // color: theme.palette.secondary.contrastText,
              color:'black',
              fontWeight:600
            },
            '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
              // works
              // backgroundColor: theme.palette.secondary.light,
              backgroundColor:'#00D744',
              // color: theme.palette.secondary.contrastText,
              color:'black',
              fontWeight:600
            },
  });

const CustomGroupedAutoComplete = (props) => {
  const { required, options, value, errorFlag, errorMsg, boldError, ref, medium , headerTooltip, msgColor, label, labelFlag} = props;

   const [highlightedIndex, setHighlightedIndex] = useState(0)
  //const {options, getOptionLabel} = props;
  // options: top100Films,
  // getOptionLabel: (option) => option.title,

  //   const flatProps = {
  //     options: top100Films.map((option) => option.title),
  //   };

  //   const [value, setValue] = React.useState(null);

  return (
    <>
      
    <Box sx={{height: {xs:'20px',md:'25px',lg:'30px',xl:'44px'}}}>
      {
      !!label && <Typography sx={{fontSize: '12px', color: '#ffffff', fontWeight: 600}}>{ labelFlag && label}</Typography>
      }
      <Autocomplete
        ref={ref}
        popupIcon={<KeyboardArrowDownIcon  sx={{color:'black', fontSize: medium ? '24px !important': '14px !important'}} />}
        size={medium ? 'medium': 'small'}
        id="disable-close-on-select"
        options={options}
        disableClearable
        value = {value}
        style={{backgroundColor:'white', borderRadius:'5px'}}
        // onHighlightChange={(event, selection)=> {
        //   const index = options?.findIndex(item => item.ShortName == selection.ShortName)
        //   setHighlightedIndex(index)
        //   console.log({index})
        // }}
    
        // ListboxProps={{
        //   sx: {
        //     backgroundColor:'white',
        //     color: theme.palette.primary.light,
        //     fontSize:  medium ?  {xs:'10px',lg:'12px',xl:'15px'} :  {xs:'8px',lg:'10px',xl:'13px'},
        //     "& :hover": {
        //       // color: theme.palette.primary.main,
        //       backgroundColor:'#00D744 !important',
        //       color:'black !important',
        //       fontWeight:600
        //     },
        //     '& .MuiAutocomplete-option[aria-selected="true"]': {
        //       // works
        //       // backgroundColor: theme.palette.secondary.light,
        //       backgroundColor:'#00D744',
        //       // color: theme.palette.secondary.contrastText,
        //       color:'black',
        //       fontWeight:600
        //     },
        //     '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
        //       // works
        //       // backgroundColor: theme.palette.secondary.light,
        //       backgroundColor:'#00D744',
        //       // color: theme.palette.secondary.contrastText,
        //       color:'black',
        //       fontWeight:600
        //     },
        //   },
        // }}
       
        sx={{
          "& .MuiAutocomplete-root": {
            backgroundColor:'white'
          },
          "& .MuiAutocomplete-inputRoot": {
            color: theme.palette.primary.light,
            fontSize:{xs:'8px',lg:'10px',xl:'13px'},
            border:'1px solid black',
            backgroundColor:'white'
          },
          "& .MuiAutocomplete-option": {
            color: theme.palette.primary.light,
            fontSize: {xs:'8px',lg:'10px',xl:'13px'},
          },
          "& .MuiAutocomplete-endAdornment": {
           // position: 'relative',
            transform: 'none',
            top:'calc(40% - 8px) !important'
          },
         
        }}
        renderGroup={(params) => (
            <li key={params.key}>
              <CustomTooltip followCursor placement ="top" title={headerTooltip[params.group]} > <GroupHeader>{params.group}</GroupHeader></CustomTooltip>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        renderInput={(params) => (
          <TextField
            size="small"
            value={props.value}
            {...params}
            // label={props.label}
            placeholder={props.placeholder}
            variant="outlined"
   
            InputProps={{
              ...params.InputProps,
      
          //     endAdornment: (
          //   <InputAdornment position="end">
          //     <AccountCircle />
          //   </InputAdornment>
          // ),
            }}
            sx={{ fontSize: {xs:'8px',lg:'9px',xl:'13px'}, 
              
             input: {
              fontFamily: 'Rubik',
              fontSize: medium ?  {xs:'12px',lg:'13px',xl:'18px'} :  {xs:'8px',lg:'9px',xl:'13px'},
              fontWeight: 600,
              color:'#08231B',
              "&::placeholder": {    // <----- Add this.
                opacity: 1,
            },
            
            //   color: theme.palette.inputs.light,
            },}}
          />
        )}
        {...props}
      />
      {required && errorFlag && (
        <Typography
          textAlign={'right'}
          variant="body1"
          className="errorDom"
          component="span"
          sx={{fontFamily: 'Rubik', color:msgColor? msgColor : "rgb(211, 47, 47)",  fontSize: medium || boldError ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'6px',md:'7px',lg:'8px',xl:'13px'}, fontWeight: (medium || boldError) && 600 }}
        >
          <Box  mt={0.5} >{errorMsg}</Box>
        </Typography>
      )}
    </Box>
    </>
  );
};
export default CustomGroupedAutoComplete;