import { Box, TextField, Typography } from '@mui/material'
import React from 'react'

const CustomTextbox = (props) => {

    const { required, errorFlag, errorMsg, maxLength } = props;
  return (
    <div>
           <TextField 
            //   onFocus={() => {
            //         setDescriptionvalidationFlag(false)
            //         setNameValidationFlag(true)
            //         setProjectTypeValidationFlag(true)
            //         setScriptLengthValidationFlag(true)
            //       }}
            //   onBlur={() => setDescriptionvalidationFlag(true)}
              required
            //   errorFlag={descriptionValidationflag && getErrorMsg("description", pageData?.description) != ""}
            //   errorMsg={getErrorMsg("description", pageData?.description)}
            fullWidth
           // txtStyle = {{height: '100%'}}
            multiline
            variant='outlined'
            value={props.value}
            placeholder={props.placeholder}
            rows={10}
            rowsMax={10}
            size="small"
            style={{backgroundColor:'white'}}
           sx={{
            color:'#08231B',
                border:'1px solid #08231B',
                borderRadius:'5px',
                backgroundColor:'white',
                fontFamily: 'Rubik',
            '& .MuiInputBase-input': {
                        padding:0,
                        paddingX: '4px',
                        fontSize: {xs:'8px',lg:'10px',xl:'13px'},
                        fontWeight: 600,
                        // color: theme.palette.inputs.main
                        color:'#08231B',
                        "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            fontWeight: 600,
                    },
              },
           }}
        
            type="text"
            inputProps={{
              maxLength
            }}
            shrink
            // placeholder="Hi , I would like to ..."
            // placeholder='What is your script about? Tell us more about what you want to incorporate in your script. (i.e. tone, important information, etc.)'
            // onChange={(event) => setInput(pageData?.description != "" ? event.target.value : event.target.value.trim() , "description", pageData, setPageData)}
            // value={pageData?.description}
            {...props}
            // required
            // error={submitFlag && getErrorMsz('name', pageData.name) != ""}
            // errorMsz={getE
            /> 

{required && errorFlag && (
        <Typography
       
          variant="body1"
          className="errorDom"
          component="span"
          sx={{fontFamily: 'Rubik', color: "rgb(211, 47, 47)",  fontSize: {xs:'6px',md:'7px',lg:'8px',xl:'13px'},  }}
        >
          <Box  mt={0.5} >{errorMsg}</Box>
        </Typography>
      )}
    </div>
  )
}

export default CustomTextbox