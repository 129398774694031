import { Backdrop, Box, Slide, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FinalScreen from '../FinalScreen';
import { getNamesFromIds } from '../../../../utils/commonServices';
import { imageAspectList, imageStyleList } from '../../../../constants/staticData';
import { imageStyleThumbs } from '../../../../constants/constants';


const PreviewCreateImage = (props) => {

    const { pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex, closeHandler, totalScreens } = props
    const createProjectReducer = useSelector((state) => state.createProjectReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
    const projectDetails = {
        type: getNamesFromIds(createProjectReducer?.commonPageData?.projectTypeId, dropdownReducer?.project_type)?.name,
        style: {
                name: getNamesFromIds(createProjectReducer?.imagePageData?.style, imageStyleList)?.name, 
                thumb: imageStyleThumbs[parseInt(createProjectReducer?.imagePageData?.style) - 1]
               },
        aspect: {
                 name: getNamesFromIds(createProjectReducer.imagePageData?.aspectRatio, imageAspectList)?.name,
                 height: getNamesFromIds(createProjectReducer.imagePageData?.aspectRatio, imageAspectList)?.height * 3,
                 width: getNamesFromIds(createProjectReducer.imagePageData?.aspectRatio, imageAspectList)?.width * 3,
                 text: getNamesFromIds(createProjectReducer.imagePageData?.aspectRatio, imageAspectList)?.ratio
                },
        
    }
   
    const [nameValidationFlag, setNameValidationFlag] = useState(false);
    const dispatch = useDispatch()
    const isMobileView = useMediaQuery('(max-width:599px)');

    // const createHandler = () => {
    //     console.log("create Image", createProjectReducer?.imagePageData?.style)
    // }

    return (
        <>
            <FinalScreen
                {...props}
               // createHandler={createHandler}
            >
                <Box className='flex col a-start j-start' gap={2} sx={{ width: '100%' }}>
                   { !contentReducer?.create_content_open && <Box className='flex row j-center a-start' >
                        <Typography sx={{ minWidth: '120px' }}>{`Name: `} </Typography>
                        <Typography sx={{ textAlign: 'justify' }}><Box component='div' sx={{ whiteSpace: 'wrap', maxHeight: '150px', overflowY: 'scroll' }} >{createProjectReducer?.commonPageData?.name}</Box></Typography>
                    </Box>}
                    <Box className='flex row j-center a-start' >
                        <Typography sx={{ minWidth: '120px' }}>Description: </Typography>
                        <Typography sx={{ textAlign: 'justify' }}><Box component='div' sx={{ whiteSpace: 'wrap', maxHeight: '150px', overflowY: 'scroll' }} >{createProjectReducer?.commonPageData?.description}</Box></Typography>
                    </Box>
                    <Box className='flex row j-start a-start' sx={{ width: '100%' }} >
                        <Box className='flex row j-center a-start' >
                            <Typography sx={{ minWidth: '120px' }}>Style: </Typography>
                            <Box sx={{ height: '80px', width: '80px', backgroundColor: '#FFFFFF', borderRadius: '8px', border: '1px solid #000000' }} ><img style={{ width: '80px', borderRadius: '8px', height: '80px' }} src={projectDetails?.style?.thumb} /><Typography textAlign='center' width='100%' >{projectDetails?.style?.name}</Typography></Box>
                        </Box>
                        <Box className='flex row j-between a-start' sx={{ ml: '5%' }} >
                            <Typography sx={{ minWidth: '120px' }}>Aspect Ratio: </Typography>
                            <Box className='flex col j-center a-center' >
                                <Box className='flex col j-center' sx={{ height: projectDetails?.aspect?.height , width: projectDetails?.aspect?.width, backgroundColor: '#FFFFFF', borderRadius: '6px', border: '1px solid #000000' }} ><Typography sx={{ color: 'black', fontSize: '14px', fontWeight: 600, width: '100%', textAlign: 'center' }} >{projectDetails?.aspect?.text}</Typography></Box>
                                <Typography sx={{ color: 'white', width: '100%', textAlign: 'center' }} >{projectDetails?.aspect?.name}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className='flex row j-start a-start' sx={{ width: '100%', mt: 3 }} >
                        <Box className='flex row j-center a-center' >
                            <Typography sx={{ minWidth: '120px' }}>Color Scheme: </Typography>
                        </Box>
                        {!!createProjectReducer.imagePageData?.grayscale ? 
                            <Box className='flex row j-center a-center' >
                                <Typography sx={{fontWeight: 700}}>Grayscale</Typography>
                            </Box>
                        : (createProjectReducer.imagePageData.primaryColor == '' && createProjectReducer.imagePageData.secondaryColor == '') ? <Box className='flex row j-center a-center' >
                        <Typography sx={{fontWeight: 700}}>No color preference</Typography>
                        </Box> : <>
                            <Box className='flex row j-between a-center'  >
                                <Typography >Primary -  </Typography>
                                <Box sx={{ height: '16px', width: '16px', backgroundColor: createProjectReducer.imagePageData.primaryColor, borderRadius: '2px', ml: 1, border: '1px solid #000000' }} /><Typography ml={1}>{createProjectReducer.imagePageData.primaryColor}</Typography>
                            </Box>
                            <Box className='flex row j-between a-center' sx={{ ml: '5%' }} >
                                <Typography >Secondary -  </Typography>
                                <Box sx={{ height: '16px', width: '16px', backgroundColor: createProjectReducer.imagePageData.secondaryColor, ml: 1, borderRadius: '2px', border: '1px solid #000000' }} /><Typography ml={1}>{createProjectReducer.imagePageData.secondaryColor}</Typography>
                            </Box>
                        </>}
                    </Box>

                </Box>
            </FinalScreen>
        </>
    )
}

export default PreviewCreateImage;