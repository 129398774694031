import axios from 'axios'


export const Request = (url, method, params, formDataFlag, headers, progressHandler, controller) => {

  let formData = new FormData();
 
  for (const key in params) {
   
    if(key.includes('[]')) {
      console.log("here", key)
      for(const obj of params[key]) {
        formData.append(key, obj )
      }
    } else {
    
      formData.append(key, params[key])
    }
   
  }

  //let formData = JSON.stringify(params)
  
  return axios({
    url: url,
    method: method, 
    // onUploadProgress: (progressEvent) => {
    //   //console.log("progressEvent.loaded " + progressEvent.loaded)
    //   //console.log("progressEvent.total " + progressEvent.total)
    //   let percent = Math.round(
    //     (progressEvent.loaded / progressEvent.total) * 100
    //   );
    //  progressHandler && progressHandler(percent);
    // },
    headers: buildHeaders(headers),
    params: !formDataFlag ? params : null,
    data: formDataFlag && formData,
    signal: controller && controller.signal,
  })
}

function buildHeaders(extraHeaders) {
  const token = window.localStorage.token
 // console.log("token", token)
  let headers = {
    ...extraHeaders
  }
  if (token){
    headers = { ...headers, Authorization: `Bearer ${token}` }
    //headers = { ...headers, "Authorization": `Bearer ${token}`, "product": process.env.REACT_APP_PRODUCT_ID}
  } 

  return { ...headers }
}

