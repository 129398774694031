
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { removeWhiteSpaces } from '../../../utils/commonServices';
import { renderHTML, textToArray } from '../utils/services';
import { useSelector } from 'react-redux';
import ScrollToBottom from '../../../components/ScrollToBottom';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';

const BlogContent = ({data}) => {
    // let item
    const [textToRender, setTextToRender] = useState([])
   // const scriptReducer = useSelector((state) => state.scriptReducer);
    // const [type, setType] = useState(!!data.content.type ? removeWhiteSpaces(data.content.type)?.toLowerCase(): 'script')
   const [scrollToIndex, setScrollToIndex] = useState(-1)
   const [tocArr, setTocArr] = useState([])
   const type = removeWhiteSpaces(data.content?.type)?.toLowerCase()

    useEffect(()=> {
      let tempArr =  textToArray(data.content?.generated_text);
      let isTOC = false;
      let tocList = [];
      const tocArray = tempArr?.map ((item, index)=> {
        if (String(item)?.startsWith('Table of Contents')) {
            // Set the flag to true when Table of Contents section is reached
            isTOC = true;
            return {text: item, isToc: false, tocIndex: null}
          } else if(String(item)?.startsWith('-') && isTOC) {
            // Add list items to Table of Contents
            tocList.push(item?.replace('-', "")?.trim())
            return {text: item, isToc: true, tocIndex: null}
           // tocContent.push(<li key={tocContent.length}>{paragraph.trim().substring(1)}</li>);
          } else {
            // Set the flag to false when Table of Contents section is over
            if(!!item)
                isTOC = false;
            let tocIndex = null; 
            tocList?.map((tocItem, i)=>{
                if(String(item)?.startsWith(`<b>${tocItem}`) || String(item)?.startsWith(tocItem)) {
                    tocIndex = i
                }
            })
            // Add regular content
            return {text: item, isToc: false, tocIndex: tocIndex}
            //regularContent.push(<p key={regularContent.length} dangerouslySetInnerHTML={{ __html: paragraph }} />);
          }
      })
      console.log({tocArray})
      setTocArr(tocList)
      setTextToRender(tocArray)
    }, [data]);
  return ( 
    <Box id='download-content'>
    {textToRender?.length > 0 ? textToRender?.map((item, index)=> {
    return  <Box component= 'div' onWheel ={()=> setScrollToIndex(-1)} width='100%' key={index}>
        {
            item.isToc ? <><span style={{ color: 'blue'}}>- </span><Typography component='span' onClick={()=>setScrollToIndex(tocArr?.indexOf(item.text?.replace("-", "")?.trim()))} dangerouslySetInnerHTML={renderHTML(item.text?.replace("-", "")?.trim())} style={{color: 'blue', cursor: 'pointer', textDecoration: 'underline', textUnderlinePosition: 'right'}} sx={{fontSize:{xs:'11px',md:'13px',xl:'18px'}, fontFamily:'Rubik', my:1}}/></>
                 :<>
                    { scrollToIndex == item.tocIndex && <ScrollToBottom />}
                    <Typography  dangerouslySetInnerHTML={renderHTML(item.text)} sx={{fontSize:{xs:'11px',md:'13px',xl:'18px'}, fontFamily:'Rubik', my:1}}/>
                </>
        }
     </Box>
   }) 
   : <Box width='100%' height='100%' className = 'flex col j-center' textAlign='center'>
        <Typography sx={{fontSize:{xs:'11px',md:'13px',xl:'18px'}, fontFamily:'Rubik', fontWeight: 600, my:1}}>Something went wrong! Please try again</Typography>
    </Box>}
    </Box>
    )
}

export default BlogContent