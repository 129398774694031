import CustomInput from "../../eventcreation/components/CustomInput";
import { makeStyles } from "@mui/styles";
import { customTheme as theme } from "../../../theme/customTheme";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { checkEmail, userSignup } from "../../../services/api";
import { afterValidate, setInput } from "../../../utils/commonServices";
import useValidator from "../../../hooks/useValidator";
// import useAuthRequest from "../../../hooks/useAuthRequest";
import CustomTooltip from "../../../components/CustomTooltip";
import PasswordInstructions from "../../../components/PasswordInstructions";
import movieIcon from '../../../assets/movieIcon.svg';
import { Box, Grid, Paper, Typography } from '@mui/material';
import UnauthLayout from '../components/UnauthLayout'
import CustomButton from "../../../components/CustomButton";
import { LoadingButton } from "@mui/lab";
import { gaEvents } from "../../../ga/actions";
import { PRODUCT_ID } from "../../../constants/constants";
import useAuthFlow from "../hooks/useAuthFlow";
import { useDispatch, useSelector } from "react-redux";
import DisclaimerPopup from "../components/DisclaimerPopup";
import { updateUserState } from "../../../redux/userSlice";


const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "start",
    minHeight: "100%",
  },
}));

const SignUp = () => {

  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const tempEmail = searchParams.get('email')
  const email = tempEmail?.replace(/ /g, '+')
  const organisation_name = searchParams.get('company_name')
  const token = searchParams.get('token')
  const pendingCallReducer = useSelector((state) => state.pendingCallReducer);
  const [openDisclaimerPopup, setOpenDisclaimerPopup] = useState(false);
  const userReducer = useSelector((state) => state.persistedReducers.userReducer);
  const navigate = useNavigate();

  const [pageData, setPageData] = useState({
    name: "",
    organisation_name: organisation_name !== ''? organisation_name : "",
    email: !!email ? email : '',
    password: "",
    password_confirmation: "",
    product_id: process.env.REACT_APP_PRODUCT_ID
  });

  const [snackbarProps, setSnackbarProps] = useState({});
  const [errorObjectArray, checkEmptyFields, checkValue] = useValidator(pageData);
  const {newUserSignup} = useAuthFlow()
  const dispatch = useDispatch()
 

  //Submit button handler function
  const submitClickHandler = async (event) => {
    checkEmptyFields(event);
    if(tempEmail === null){
     await checkValue(event);
    }else{
     await checkValue(event);
    }
    afterValidate(afterValidatCallback);
  };

  const afterValidatCallback =  () => {
    // gaEvents.joinClicked();
   // console.log(pageData)
    dispatch(updateUserState({signup_disclaimer: true}))
   // setOpenDisclaimerPopup(true)
  // newUserSignup(pageData)
    // await signup(pageData);
  };

  // useEffect(() => {
  //    if (pendingCallReducer?.signupStatus == "success") {
  //     navigate('/resendlink', {state: {pageData: pageData, title: 'Your account has been created', icon: movieIcon, btnText: 'Resend Email', message:'Please check your email and click on the verify now button to continue.', signIn: false}, replace:true})
  //    }
  // }, [pendingCallReducer?.signupStatus]);



  //code to detect enter key for editing Organization Name
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        submitClickHandler(event);
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [pageData]);



  return (
    <UnauthLayout signup>
      <Box className={classes.center} square component="Box">
        <Box
          container
          justifyContent="flex-start"
          spacing={3}
          mt={{xs:1, md:0,lg:4, xl:9}}
          sx={{height:'100%', overflowY:'scroll'}}
        >
          <Box item xs={11} sm={8} md={7}>
            <Box sx={{marginBottom:{xs:'15px',md:'20px',lg:'25px',xl:'38px'}}}>
              <Typography
                variant="h4"
                color="primary"
                style={{
                  fontWeight: 600,
                  color:'#FFFFFF'
                }}
                sx={{fontSize:{xs:'30px',sm:'25px',md:'35px',lg:'41px',xl:'60px'}, fontFamily:'Rubik'}}
              >
               Create your account.
              </Typography>
            </Box>
          </Box>

          <Box item xs={11} sm={8} md={7} gap={0.8} sx={{display:'flex', flexDirection:'column'}}>
            <CustomInput
              id={errorObjectArray[0]?.id}
              onFocus={checkEmptyFields}
              onBlur={checkValue}
              required
              errorFlag={errorObjectArray[0]?.errorFlag}
              errorMsg={errorObjectArray[0]?.error}
              type="text"
              max={40}
              placeholder="Name"
              onChange={(event) => {
                setInput(
                  pageData?.name != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "name",
                  pageData,
                  setPageData
                );
                checkEmptyFields(event);
              }}
              value={pageData?.name}
            />
        
              <CustomInput
              id={errorObjectArray[1]?.id}
             // onFocus={checkEmptyFields}
            //  onBlur={checkValue}
            // required
             //errorFlag={errorObjectArray[1]?.errorFlag}
            // errorMsg={errorObjectArray[1]?.error}
             disabled={organisation_name !== null}
              type="text"
              max={40}
              placeholder="Company Name"
              onChange={(event) => {
                setInput(
                  pageData?.organisation_name != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "organisation_name",
                  pageData,
                  setPageData
                );
               // checkEmptyFields(event);
              }}
              value={pageData?.organisation_name}
            />
            
           
            {/* <CustomInput
              id={errorObjectArray[2]?.id}
              onFocus={checkEmptyFields}
              onBlur={checkValue}
              // required
              // errorFlag={errorObjectArray[2]?.errorFlag}
              // errorMsg={errorObjectArray[2]?.error}
              // label="Phone Number"
              type="text"
              max={10}
              placeholder="Phone Number"
              onChange={(event) => {
                !isNaN(event.target.value) &&
                  setInput(
                    event.target.value.trim(),
                    "phone_number",
                    pageData,
                    setPageData
                  );
                checkEmptyFields(event);
              }}
              value={pageData?.phone_number}
            /> */}
           
              <CustomInput
              id={errorObjectArray[2]?.id}
              onFocus={checkEmptyFields}
              onBlur={checkValue}
              required
              errorFlag={errorObjectArray[2]?.errorFlag}
              errorMsg={errorObjectArray[2]?.error}
              type="text"
              max={40}
              placeholder="Email Address*"
              onChange={(event) => {
                setInput(
                  pageData?.email != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "email",
                  pageData,
                  setPageData
                );
                checkEmptyFields(event);
              }}
              value={pageData?.email}
            />
{/*             
              <CustomInput
               id={errorObjectArray[2]?.id}
              type="text"
              max={40}
              disabled={true}
              placeholder="Email Address*"
              onChange={(event) => {
                setInput(
                  pageData?.email != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "email",
                  pageData,
                  setPageData
                );
              }}
              value={pageData?.email}
            />
            */}
            
            <CustomTooltip
              arrow
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={<PasswordInstructions />}
              placement={window.innerWidth <= 1100 ? "bottom" : "right"}
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: window.innerWidth >= 1100 && [0, 40],
                    },
                  },
                ],
              }}
            >
              <CustomInput
                id={errorObjectArray[3]?.id}
                onFocus={checkEmptyFields}
                onBlur={checkValue}
                required
                errorFlag={errorObjectArray[3]?.errorFlag}
                errorMsg={errorObjectArray[3]?.error}
                type="password"
                max={40}
                placeholder="Create Password*"
                onChange={(event) => {
                  setInput(
                    pageData?.password != ""
                      ? event.target.value
                      : event.target.value.trim(),
                    "password",
                    pageData,
                    setPageData
                  );
                  checkEmptyFields(event);
                }}
                value={pageData?.password}
              />
    
            </CustomTooltip>
            <CustomInput
              id={errorObjectArray[4]?.id}
              onFocus={checkEmptyFields}
              onBlur={checkValue}
              required
              errorFlag={errorObjectArray[4]?.errorFlag}
              errorMsg={errorObjectArray[4]?.error}
              type="password"
              max={40}
              placeholder="Confirm Password*"
              onChange={(event) => {
                setInput(
                  pageData?.password_confirmation != ""
                    ? event.target.value
                    : event.target.value.trim(),
                  "password_confirmation",
                  pageData,
                  setPageData
                );
                checkEmptyFields(event);
              }}
              value={pageData?.password_confirmation}
            />
            
          </Box>

          <Box item xs={11} sm={8} md={7}>
            <Box
              container
              justifyContent="space-between"
              sx={{
                flexDirection: { xs: "column-reverse", sm: "row" },
                alignItems: "center",
              }}
            >

              <Box item xs={8} sm={4} sx={{display:'flex', justifyContent:'flex-end', marginTop:{xs:'20px',sm:'28px',md:'28px',lg:'33px',xl:'50px'}, gap:2}}>
                {/* <CustomButton
                  btnText="Join Now"
                  disabled={signupStatus === "loading"}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={submitClickHandler}
                  style={{borderRadius: '5px', backgroundColor:'#08231B', color:'#00D744', textTransform:'none', }} 
                  sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,}}
                /> */}
                 <LoadingButton
                    disabled={pendingCallReducer.signup}
                    onClick={submitClickHandler}
                    style={{borderRadius: '5px', backgroundColor:'#00CEFF', color:'#08231B', textTransform:'none', }} 
                    sx={{height:{xs:'18px',sm:'20px',md:'22px', lg:'23px', xl:'35px'}, width:{xs:'70px', lg:'90px',xl:'129px'}, fontSize:{xs:'10px',md:'10px',lg:'11px',xl:'16px'}, fontFamily:'Rubik', fontWeight:600,  minWidth: '110px !important' }}
                    loading={pendingCallReducer.signup}
                    loadingPosition="start"
                    variant="contained"
                    >
                   Join Now
               </LoadingButton> 
              </Box>

              <Box
                 sx={{ marginTop: { xs:'20px',sm:'10px',md:'15px',lg:'25px',xl:'70px' } }}
                item
                xs={12}
                sm={8}
                textAlign='center'
              ><Typography  sx={{fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13px',xl:'20px'}, fontFamily:'Rubik', color:'#ffffff'}}>
                {`Already Have an account? `}
                <Link
                  to="/signin"
                  style={{
                    textDecoration: "none",
                    fontWeight:600,
                    color:'#21D644'
                  }}
                  sx={{fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13px',xl:'20px'}, fontFamily:'Rubik',  fontWeight:600}}
                >
                  Log In.
                </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
        {userReducer.signup_disclaimer && <DisclaimerPopup submit = {()=> newUserSignup(pageData)} />}
    </UnauthLayout>
  )
}

export default SignUp