
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { removeWhiteSpaces } from '../../../utils/commonServices';
import { renderHTML, textToArray } from '../utils/services';
import { useSelector } from 'react-redux';

const ProjectContent = ({data}) => {
    // let item
    const [textToRender, setTextToRender] = useState([])
   // const scriptReducer = useSelector((state) => state.scriptReducer);
    // const [type, setType] = useState(!!data.content.type ? removeWhiteSpaces(data.content.type)?.toLowerCase(): 'script')
   // console.log("hakfhgkdfhbdkfbjsdf", scriptReducer.selected_project_details.personaId)
   const type = removeWhiteSpaces(data.content?.type)?.toLowerCase()

    useEffect(()=> {
      let tempArr = textToArray(data.content?.generated_text);
      setTextToRender(tempArr)
    }, [ data ]);

  return  (
    <Box id='download-content'>
    {textToRender?.length > 0 ? textToRender?.map((item, index)=> {
    return  <Box width='100%' key={index}>
        <Typography component='div' dangerouslySetInnerHTML={renderHTML(item)} sx={{fontSize:{xs:'11px',md:'13px',xl:'18px'}, fontFamily:'Rubik', my:1}}/>
     </Box>
   }) 
   : <Box width='100%' height='100%' className = 'flex col j-center' textAlign='center'>
        <Typography sx={{fontSize:{xs:'11px',md:'13px',xl:'18px'}, fontFamily:'Rubik', fontWeight: 600, my:1}}>Something went wrong! Please try again</Typography>
    </Box>}
    </Box>
    )
}

export default ProjectContent