import React from 'react';
import '../customRadioButton.css';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { ChildCare } from '@mui/icons-material';
import { isDisabled } from '@testing-library/user-event/dist/utils';

const CustomRadioButton = ({ id, radioSize, name, label, children , component, height, selectedValue, setSelectedValue, boldText, text, width, handleRadioChange, value, disabled}) => {

 

  // const handleRadioChange = (event) => {
  //   // setSelectedValue(event.target.value)
  //   // console.log(event.target.value)
  //   setSelectedValue((last) => {
  //     return { ...last, video_orientation: event.target.value }
  //   })
  // };


  return (
    <div className='flex col'>
      <input disabled = {disabled} checked={selectedValue == id} className='radio-input' id={id} value={id} name={name} type='radio' onChange={handleRadioChange} style={{display:'none'}}/>
      <label htmlFor={id} label={label}>
        <Box className='custom-radio'  sx={{width: width, backgroundColor:'#fff', border: '1px solid #08231B', borderRadius:'10px', height: height, position:'relative', boxShadow: selectedValue == id ? '0px 0px 20px #00D744' : 'none', cursor: !disabled && 'pointer'}}>
          {
            disabled ? <Typography sx={{fontSize: '12px', fontWeight: 600, color: 'orange', position: 'absolute', right: 10, top: 6}}>Coming soon</Typography> : !!radioSize && <Box className='flex a-center j-center' sx={{width:`${radioSize}px`, height:`${radioSize}px`, border: '1px solid #08231B', borderRadius:'50%', position:'absolute', top:6, right:6}}>
            {selectedValue == id && !!radioSize ? <Box sx={{width:`${radioSize - 6}px`, height:`${radioSize - 6}px`, borderRadius:'50%',backgroundColor:'#08231B'}}></Box> : null}
          </Box>
          }
          
          <Box sx={{width:'100%',height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            {component} 
          </Box>
        </Box>
      </label>

    { text &&  <Box mt={{xs:'15px',sm:4}} className='flex' gap={1} sx={{ justifyContent:'center'}}>
        <Typography  sx={{color:'white', fontWeight:600, fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13.5px',xl:'20px'}}}>{boldText}</Typography>
        <Typography sx={{color:'white', fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13.5px',xl:'20px'},}}>{text}</Typography>
      </Box>}
    </div>
  );
};

export default CustomRadioButton;












// import React from 'react';
// import '../customRadioButton.css';
// import { Box } from '@mui/material';
// import { useState } from 'react';
// import { ChildCare } from '@mui/icons-material';

// const CustomRadioButton = ({ id, name, label, children , project, selectedProjectType, setSelectedProjectType, component, height}) => {

//   // const [isSelected, setIsSelected] = useState(false)

//   const isSelected = id === selectedProjectType

//   const handleRadioChange = (event) => {
//     // setIsSelected(event.target.checked);
//     console.log(event.target.checked, id)
//     console.log(event)
//   };

//  console.log(selectedProjectType)

//   return (
//     <>
//       {/* <input className='radio-input' id={id} name={name} type='radio' checked={isSelected} onChange={handleRadioChange}/> */}
//       {/* <label htmlFor={id}> */}
//         <Box onClick={()=>setSelectedProjectType(id)}  sx={{width:'300px', backgroundColor:'#fff', border: '1px solid #08231B', borderRadius:'10px', height: height, position:'relative', boxShadow: isSelected ? '0px 0px 20px #00D744' : 'none', cursor:'pointer'}}>
//           <Box className='flex a-center j-center' sx={{width:'20px', height:'20px', border: '1px solid #08231B', borderRadius:'50%', position:'absolute', top:10, left:10}}>
//             {isSelected === true ? <Box sx={{width:'14px', height:'14px', borderRadius:'50%',backgroundColor:'#08231B'}}></Box> : null}
//           </Box>
//           <Box sx={{width:'100%',height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
//             {component} 
//           </Box>
//         </Box>
//       {/* </label> */}
//     </>
//   );
// };

// export default CustomRadioButton;