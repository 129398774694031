import React from 'react'
import CustomCard from '../../../components/CustomCard'
import { Avatar, Box, Button, Typography } from '@mui/material'
import { getInitials, hasPermission } from '../../../utils/commonServices'
import CustomButton from '../../../components/CustomButton'
import luciLogo from "../../../assets/copilot_logo_dark.png";
import { useDispatch, useSelector } from 'react-redux'
import FeedbackIcon from '@mui/icons-material/Feedback';
import ListIcon from '@mui/icons-material/List';
import { updateCommonState } from '../../../redux/commonSlice'
import { resetProjectCreationState } from '../../../redux/projectCreationSlice'
import CustomTooltip from '../../../components/CustomTooltip'

const MainLogoCard = () => {

    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    // const userPermissions = useSelector((state) => state.permissionsReducer.permissions)
    // const canAddOrganizationMembers = hasPermission(userPermissions, 'addMembers');
    const dispatch = useDispatch()
    const dashboardViewToggleHandler = ()=> {
        dispatch(updateCommonState({list_view: true}))
        dispatch(resetProjectCreationState())
    }


    const colors =['#00D744', 'black', '#6C00FF']


  return (
    <>
                <CustomCard               
                    margin='0px 0px 20px 0px'
                    padding='15% 8% 7.3% 8%'
                    sx={{ flex: 1, position:'relative' }}      
                >
                    <Box height='100%' className='flex col j-between a-center'>
                       
                        <Box textAlign='center' sx={{flex: 2, width: { xs: '70px', md: '125px', lg: '130px', xl: '170px' }}} >
                            <img src={luciLogo} width='100%' />
                        </Box>
                        <CustomTooltip title='List View' ><ListIcon onClick={dashboardViewToggleHandler} sx={{position:'absolute', right:12, top:10, cursor:'pointer'}}/></CustomTooltip>
                        <Box width="100%"  textAlign='center' sx={{ flex: 2, mt: {xs: 2, md: 3, lg: 3, xl: 8}}}>

                            <Typography sx={{fontFamily: 'Rubik', fontWeight: 300, fontSize: {xs:'18px',md:'24px',lg:'30px',xl:'42px'}}} > Hello</Typography>
                            <Typography sx={{  whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
                         fontFamily: 'Rubik', color: '#fff', fontWeight: 600, fontSize: {xs:'14px',md:'16px',lg:'20px',xl:'24px'}, textTransform:'capitalize'}} >{userReducer.user_name}</Typography>
                        </Box>
                        <Box width='100%' textAlign='center' sx={{flex: 1}} >
                        <Button
                        href="mailto:bugreport@lucihub.com"
                        variant= "outlined"
                        style={{
                            borderRadius: '5px',
                            border: "none",
                            textTransform:'none',
                            backgroundColor:'#00CEFF',  
                        }}
                        sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:800, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"},fontFamily:'Rubik'}}
                        
                        // color="secondary"
                        startIcon ={<FeedbackIcon style={{fontSize: 14}}/>}
                        >Send Feedback</Button>
                    </Box>
                        {/* <Box className='flex' gap={1} p={1.5}>
                            {testmembers?.slice(0, 3).map((item, index) => (
                                <Avatar
                                    sx={{
                                    borderRadius: '5px',
                                    backgroundColor: colors[index],
                                    color: 'white',
                                    width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    fontWeight:600
                                    }}
                                >
                                    {getInitials(item.name)}
                                </Avatar>
      
                            ))}
                            {testmembers?.length > 3 && (
                                <Avatar
                                    sx={{
                                    borderRadius: '5px',
                                    backgroundColor: '#ffffff !important',
                                    color: 'black',
                                    width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    border:'1px solid white',
                                    fontWeight:600
                                    }}
                                >
                                    +{testmembers?.length - 3}
                                </Avatar>
                            )}
                        </Box> */}
                        {/* <Box>
                            <CustomButton 
                            // btnText={canAddOrganizationMembers ? 'Add Members': 'View Members'}
                            btnText={ 'View Members'}
                            // disabled = {}
                            // onClick={()=>  dispatch(updateTeamState({invite_team_members_popup : true}))}  
                            // onClick={canAddOrganizationMembers ? addMembersClickHandler: viewMembersClickHandler}  
                            onClick={  viewMembersClickHandler}  
                            type='button'
                            style={{
                                borderRadius: '5px',
                                border: "none",          
                                textTransform:'none',
                                backgroundColor:'#00CEFF'
                                }}
                            sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'18px',md:'20px',lg:'22px',xl:"30px"}, width:{xs:'150px',sm:'150px',md:'150px',lg:'163px',xl:'255px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'16px'}}}
                            />
                        </Box> */}
                    </Box>
                </CustomCard>
    </>
  )
}

export default MainLogoCard