import { configureStore } from '@reduxjs/toolkit'
import userSlice from './userSlice'
import messageSlice from "./messageSlice"
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import dropdownSlice from './dropdownSlice'
import persistCombineReducers from 'redux-persist/es/persistCombineReducers'
import projectScriptSlice from './projectScriptSlice'
import projectCreationSlice from './projectCreationSlice'
import commonSlice from './commonSlice'
import pendingCallSlice from './pendingCallSlice'
import contentSlice from './contentSlice'
import storyboardSlice from './storyboardSlice'
import voiceSlice from './voiceSlice'

const persistConfig = { key: 'root', version: 1, storage }

const combinedPersist = persistCombineReducers(persistConfig, {
   userReducer: userSlice,
   dropdownReducer: dropdownSlice,
  })

 

export const store = configureStore({
    reducer: {
        persistedReducers: combinedPersist,
        messageReducer: messageSlice,
        scriptReducer: projectScriptSlice,
        contentReducer: contentSlice,
        createProjectReducer: projectCreationSlice,
        commonReducer: commonSlice,
        pendingCallReducer: pendingCallSlice,
        storyboardReducer: storyboardSlice,
        voiceReducer: voiceSlice
        
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})